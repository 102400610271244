import React from 'react';
import Page, { PageProps } from '../../components/Page';
import FormikForm from '../../components/FormikForm';
import { show, update } from '../../api/endpoints/Api/Tenants/UserGroups';
import {
  AddUserAction,
  CreateTenantUserGroupFormMap,
  DeleteAction,
  ListTenantUserGroupUserActions,
  UpdateTenantUserGroupFormLayout,
} from '../../resources/TenantUserGroup';
import useLoadApiData from '../../hooks/useLoadApiData';
import { useParams } from 'react-router';
import { ContentView } from '../../components/ContentSwitcher';
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import UserAvatar from '../../components/UserAvatar';
import { ListTenantUserColumns } from '../../resources/TenantUser';
import ListResourceTable from '../../components/ListResourceTable';
import { list as listUsers } from '../../api/endpoints/Api/Tenants/Users';

import useAuth from '../../hooks/useAuth';

const ShowAffiliateGroupPage = () => {
  const { id } = useParams<{ id: string }>();
  const { currentDashboard } = useAuth();
  const { apiData, loadApiData } = useLoadApiData(show)({
    tenantId: currentDashboard.id,
    id,
  });

  if (apiData === null) {
    return null;
  }

  const pageProps: PageProps = {
    pageHeaderProps: {
      title: 'Affiliate Group "{{name}}"',
      translationParams: { name: apiData.title },
      actions: [
        AddUserAction('Affiliate', '/affiliate/groups'),
        DeleteAction('affiliate', '/affiliate/groups'),
      ],
      breadCrumbs: {
        items: [
          {
            label: 'Dashboard',
            to: '/dashboard',
          },
          {
            label: 'Affiliate Groups',
            to: '/affiliate/groups',
          },
          {
            label: 'Affiliate Group "{{name}}"',
            translationParams: {
              name: apiData.title,
            },
          },
        ],
      },
    },
    pageTabs: {
      tabs: [
        {
          value: 'Information',
        },
        {
          value: 'Affiliates',
        },
      ],
    },
    title: 'Affiliate Group "{{name}}"',
    titleProps: { name: apiData.title },
    context: apiData,
  };

  return (
    <Page {...pageProps}>
      <ContentView value="Information">
        <Grid container spacing={3}>
          <Grid item lg={4} md={6} xl={3} xs={12}>
            <Card>
              <CardContent>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                  }}
                >
                  <Box
                    sx={{
                      p: 1,
                      border: (theme) => `1px dashed ${theme.palette.divider}`,
                      borderRadius: '50%',
                    }}
                  >
                    <UserAvatar user={apiData.tenant} size="xlarge" />
                  </Box>
                  <Typography
                    color="textPrimary"
                    sx={{ mt: 1 }}
                    variant="subtitle2"
                  >
                    {apiData.title}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={8} md={6} xl={9} xs={12}>
            <FormikForm
              endpoint={update}
              routeParams={{
                tenantId: apiData.tenant.id,
                id,
              }}
              formMap={CreateTenantUserGroupFormMap()}
              formLayout={UpdateTenantUserGroupFormLayout()}
              context={apiData}
              onSuccessMessage={
                'Affiliate Group "{{name}}" was updated successfully!'
              }
              onSuccessMessageParams={(response) => ({
                name: response.data.title,
              })}
              onSuccess={() => loadApiData()}
            />
          </Grid>
        </Grid>
      </ContentView>
      <ContentView value="Affiliates">
        <ListResourceTable
          endpoint={listUsers}
          field="tenant_users"
          routeParams={{ tenantId: apiData.tenant.id }}
          params={{ user_group: [id], roles: ['ROLE_PARTICIPANT'] }}
          actions={ListTenantUserGroupUserActions(
            'affiliate',
            '/affiliate/groups',
            apiData
          )}
          columns={ListTenantUserColumns('/affiliates')}
        />
      </ContentView>
    </Page>
  );
};

export default ShowAffiliateGroupPage;
