import {
  ApiEndpointBodyType,
  ApiEndpointParamsType,
  ApiEndpointResponseViewType,
  ApiEndpointRouteParamsType,
  ApiEndpointTypeAny,
  GetResponseErrorViewType,
  GetResponseViewType,
  isResponseSuccess,
} from '../api/client';
import React from 'react';
import NProgress from 'nprogress';
import { JsonErrorView } from '../api/types/App/Response';
import usePaginatedResultsNew from './usePaginatedResultsNew';
import { history } from '../redux';
import _ from 'lodash';

export type UseLoadApiDataPaginatedOptionsFor<T extends ApiEndpointTypeAny> = UseLoadApiDataPaginatedOptions<T>;

interface PaginatedQueryParams {
  offset?: number | null; // 0
  limit?: number | null; // 10
}

interface UseLoadApiDataPaginatedOptions<T extends ApiEndpointTypeAny> {
  showProgress?: boolean,
  additionalDeps?: any[],
  onSuccess?: (data: GetResponseViewType<T>) => void,
  onError?: (errors: JsonErrorView<GetResponseErrorViewType<T>>[]) => void,
  onNotFound?: () => void,
  onServerError?: (error) => void,
}

const useLoadApiDataPaginated = <T extends ApiEndpointTypeAny>(
  endpoint: T,
  field: keyof ApiEndpointResponseViewType<T>,
  perPageOptions: number[] = [5, 10, 25],
  startPageZero?: boolean,
  options: UseLoadApiDataPaginatedOptions<T> = {},
) => (
    initialRouteParams: ApiEndpointRouteParamsType<T> = {},
    initialParams: ApiEndpointParamsType<T> & PaginatedQueryParams = {},
    initialBody: ApiEndpointBodyType<T> = {},
  ) => {
    const [routeParams, setRouteParams] = React.useState<ApiEndpointRouteParamsType<T>>(initialRouteParams);
    const [body, setBody] = React.useState<ApiEndpointBodyType<T>>(initialBody);
    const [apiData, setApiData] = React.useState<GetResponseViewType<T> | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    if (options.additionalDeps === undefined) {
      const [additionalDeps] = React.useState<any[]>([]);
      options.additionalDeps = additionalDeps;
    }
    const {
      page,
      perPage,
      offset,
      setPage,
      handleOnPerPageChange,
      handleOnPageChange,
      totalPages,
    } = usePaginatedResultsNew({
      results: (apiData !== null ? apiData[field] : {
        total: 0,
        data: [],
      }),
      perPageOptions,
      startPageZero,
    });

    const calcParams = () => {
      const calcInitialParams = {
        ...{
          offset: 0,
          limit: perPageOptions[0] ?? 5,
        },
        ...initialParams,
      };

      const {
        offset: initialOffset,
        limit: initialLimit,
        ...restParams
      } = calcInitialParams;
      return {
        offset: offset ?? initialOffset,
        limit: perPage ?? initialLimit,
        ...restParams,
      };
    };

    const [params, setParams] = React.useState<ApiEndpointParamsType<T> & PaginatedQueryParams>(calcParams());

    React.useEffect(() => {
      const newParams = calcParams();

      if (_.isEqual(params, newParams) === false) {
        setParams(newParams);
      }
    }, [initialParams]);

    options.showProgress ??= true;
    options.onNotFound ??= () => history.push('/404');

    const loadApiData = React.useCallback(() => {
      (async () => {
        try {
          setIsLoading(true);
          if (options.showProgress === true) {
            NProgress.start();
          }

          const result = await endpoint(routeParams, { ...params, offset, limit: perPage }, body);
          setIsLoading(false);
          if (isResponseSuccess(result)) {
            setApiData(result.data);
            options.onSuccess?.(result.data);
          } else if (result.errors[0]?.code === 404) {
            options.onNotFound?.();
          } else {
            options.onError?.(result.errors);
          }

          if (options.showProgress === true) {
            NProgress.done();
          }
        } catch (e) {
          if (e instanceof Error && e.message.includes('is not provided for route')) {
            throw e;
          }

          if (options.showProgress === true) {
            NProgress.done();
          }

          options.onServerError?.(e);
        }
      })();
    }, [routeParams, params, body, options.additionalDeps, offset, perPage]);

    React.useEffect(() => {
      loadApiData();
    }, [loadApiData]);

    return {
      apiData,
      isLoading,
      setApiData,
      setRouteParams,
      setParams,
      setBody,
      loadApiData,
      page,
      perPage,
      offset,
      perPageOptions,
      setPage,
      handleOnPerPageChange,
      handleOnPageChange,
      totalPages,
    };
  };

export default useLoadApiDataPaginated;
