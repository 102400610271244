import React from 'react';
import Page, { PageProps } from '../../components/Page';
import ArrowLeft from '../../icons/ArrowLeft';
import FormikForm from '../../components/FormikForm';
import { addUser, show } from '../../api/endpoints/Api/Tenants/UserGroups';
import useAuth from '../../hooks/useAuth';
import { Redirect } from 'react-router-dom';
import {
  AddTenantUserToUserGroupFormLayout,
  AddTenantUserToUserGroupFormMap,
} from '../../resources/TenantUserGroup';
import useLoadApiData from '../../hooks/useLoadApiData';
import { tenantUserSelector } from '../../api/endpoints/Api/Tenants/Users';
import { useParams } from 'react-router';
import { DashboardType } from '../../redux/slices/User';

const AddTenantUserToUserGroupPage = () => {
  const { currentDashboard } = useAuth();
  const { id } = useParams<{ id: string }>();

  if (currentDashboard.type !== DashboardType.UserTenantAdmin) {
    return (<Redirect to="/" />);
  }

  const {
    apiData,
  } = useLoadApiData(show)({
    tenantId: currentDashboard.id,
    id,
  });

  const {
    apiData: userValues,
  } = useLoadApiData(tenantUserSelector)({ tenantId: currentDashboard.id }, { user_group: id, roles: ['ROLE_PARTICIPANT'] });

  if (apiData === null) {
    return null;
  }

  const pageProps: PageProps = {
    pageHeaderProps: {
      title: 'Add new affiliate to "{{name}}"',
      translationParams: {
        name: apiData.title
      },
      actions: [
        {
          type: 'route_link',
          to: () => `/affiliate/groups/${id}`,
          label: 'Cancel',
          icon: <ArrowLeft fontSize="small" />,
          variant: 'contained',
          color: 'secondary',
        },
      ],
      breadCrumbs: {
        items: [
          {
            label: 'Dashboard',
            to: '/dashboard',
          },
          {
            label: 'Affiliate Groups',
            to: '/affiliate/groups',
          },
        ],
      },
    },
    title: 'Add affiliate to "{{name}}"',
    titleProps: {
      name: apiData.title
    },
  };

  return (
    <Page {...pageProps}>
      <FormikForm
        endpoint={addUser} formMap={AddTenantUserToUserGroupFormMap('Affiliate', userValues ?? { values: [] })}
        formLayout={AddTenantUserToUserGroupFormLayout('Affiliate', userValues ?? { values: [] })} context={{}}
        onSuccessMessage={'Affiliate "{{name}}" was added to group "{{group}}" successfully!'}
        onSuccessMessageParams={((response) => ({
          name: response.data.user.full_name,
          group: apiData.title,
        }))}
        onSuccessRedirectTo={() => ({ pathname: `/affiliate/groups/${apiData.id}`, state: { pageTab: 'Affiliates' } })}
        routeParams={{
          tenantId: currentDashboard.id,
          id,
        }}
      />
    </Page>
  );
};
export default AddTenantUserToUserGroupPage;
