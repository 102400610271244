import { ListAgenciesRequestParams, CreateAgencyRequestBody, UpdateAgencyRequestBody } from '../../../types/App/Request/Agency';
import { ListAgencyView, FullAgencyView } from '../../../types/App/View/Agency';
import { apiEndpoint, NoBody, StandardErrors, NoParams } from '../../../client';
import { SuccessView } from '../../../types/App/View';

export * as Clients from './Clients';

export const list = apiEndpoint<ListAgencyView, ListAgenciesRequestParams, NoBody, StandardErrors>('GET')('/api/agencies/');

export const show = apiEndpoint<FullAgencyView, NoParams, NoBody, StandardErrors>('GET')('/api/agencies/{id}');

export const create = apiEndpoint<FullAgencyView, NoParams, CreateAgencyRequestBody, StandardErrors>('POST')('/api/agencies');

export const update = apiEndpoint<FullAgencyView, NoParams, UpdateAgencyRequestBody, StandardErrors>('PUT')('/api/agencies/{id}');

export const remove = apiEndpoint<SuccessView, NoParams, NoBody, StandardErrors>('DELETE')('/api/agencies/{id}');

export * as Tenants from './Tenants';

export * as Users from './Users';
