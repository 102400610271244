import React from 'react';
import { Box } from '@material-ui/core';
import { timestampFormat } from '../utils/timestampUtils';
import UserAvatar, { User } from './UserAvatar';
import { AuthorUserView } from '../api/types/App/View/User';

interface TimestampCellProp {
  value: number | null,
  author?: User,
}

const TimestampCell = (
  {
    value,
    author,
  }: TimestampCellProp,
) => (
  <Box
    sx={{
      alignItems: 'center',
      display: 'flex',
    }}
  >
    {author && (
      <UserAvatar user={author} size="mini" />
    )}
    <Box sx={{
      ml: author ? 1 : 0,
      whiteSpace: 'nowrap', }}
    >
      {timestampFormat(value)}
    </Box>
  </Box>
);

export const renderCreatedAt = (data: { created_at: number, created_by?: AuthorUserView }) => <TimestampCell value={data.created_at} author={data.created_by} />;
export const renderUpdatedAt = (data: { updated_at: number, updated_by?: AuthorUserView }) => <TimestampCell value={data.updated_at} author={data.updated_by} />;

export default TimestampCell;
