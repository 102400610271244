import { MapValueBuilder } from '../types/MapValues';

export const InvoicePaidStatusMap = MapValueBuilder([
  {
    value: 'paid',
    label: 'Paid',
    color: 'success',
  },
  {
    value: 'not_paid',
    label: 'Not Paid',
    color: 'warning',
  },
] as const);
