import React from 'react';
import { ActionTypes } from '../../types/ActionType';
import { Button, ButtonProps } from '@material-ui/core';
import { RouterLink } from '../../lib/RouterLink';
import { useTranslation } from 'react-i18next';
import { parseCalcType } from '../../types/CalculatedType';
import useDialog from '../../hooks/useDialog';
import { calculateProps } from '../../utils/propHelpers';

interface ButtonActionProp<V extends any> {
  context: V,
  action: ActionTypes<V>
}

const ButtonAction = <V extends any, >(
  {
    context,
    action,
  }: ButtonActionProp<V>): React.ReactElement => {
  const { t } = useTranslation();
  let props: ButtonProps & { component?: React.ElementType, to?: string } = {
    color: parseCalcType(action.color, context),
    startIcon: parseCalcType(action.icon, context),
    endIcon: parseCalcType(action.endIcon, context),
    sx: {
      ml: 1.5,
      my: {
        xs: 1,
        sm: 0,
      },
    },
    variant: parseCalcType(action.variant, context),
  };

  props.disabled = parseCalcType(action.disabled, context);

  switch (action.type) {
    case 'route_link':
      props.component = RouterLink;
      props.to = parseCalcType(action.to, context);
      break;
    case 'link':
      props.component = 'a';
      props.href = parseCalcType(action.href, context);
      break;
    case 'click':
      props.onClick = action.onClick(context);
      break;
    default:
  }

  let dialogEl;
  if (action.type === 'confirm_click') {
    const dialogOptions = parseCalcType(action.dialog, context);
    const { dialog, showDialog } = useDialog(dialogOptions);
    dialogEl = dialog;

    props.onClick = () => showDialog(context);
  }

  if (action.renderButton !== undefined) {
    return action.renderButton(props, context);
  }

  const labelParams = parseCalcType(action.labelParams, context) ?? {};

  props = calculateProps(action.buttonProps, props);

  return (
    <>
      {dialogEl}
      <Button {...props}>{t(parseCalcType(action.label, context), labelParams)}</Button>
    </>
  );
};

export default ButtonAction;
