import React from 'react';
import useAuth from '../../../hooks/useAuth';
import { DashboardType } from '../../../redux/slices/User';
import { Redirect } from 'react-router-dom';
import Page, { PageProps } from '../../../components/Page';
import PlusIcon from '../../../icons/Plus';
import ListResourceTable from '../../../components/ListResourceTable';
import { list } from '../../../api/endpoints/Api/Tenants/Contracts';
import {
  ListTenantContractColumns,
  ListTenantContractRowActions,
} from '../../../resources/TenantContract';
import { FindInPage } from '@material-ui/icons';

const ListContractsPage = () => {
  const { currentDashboard } = useAuth();

  if (
    currentDashboard.type !== DashboardType.UserTenantAdmin &&
    currentDashboard.type !== DashboardType.TenantAdmin
  ) {
    return <Redirect to="/" />;
  }

  const pageProps: PageProps = {
    pageHeaderProps: {
      title: 'Contracts',
      translationParams: {},
      actions: [
        {
          type: 'route_link',
          to: () => '/connections/contracts/create',
          label: 'Create new',
          icon: <PlusIcon fontSize="small" />,
          hidden: () => currentDashboard.type !== DashboardType.UserTenantAdmin,
          variant: 'contained',
          color: 'primary',
        },
        {
          type: 'route_link',
          to: () => '/connections/contracts/find',
          label: 'Find by Code',
          icon: <FindInPage fontSize="small" />,
          hidden: () => currentDashboard.type !== DashboardType.TenantAdmin,
          variant: 'contained',
          color: 'success',
        },
      ],
      breadCrumbs: {
        items: [
          {
            label: 'Dashboard',
            to: '/dashboard',
          },
          {
            label: 'Contracts',
            to: '/connections/contracts',
          },
        ],
      },
    },
    title: 'Contracts',
  };

  return (
    <Page {...pageProps}>
      <ListResourceTable
        endpoint={list}
        field="contracts"
        routeParams={{ tenantId: currentDashboard.id }}
        params={{}}
        columns={ListTenantContractColumns(
          currentDashboard.type === DashboardType.UserTenantAdmin
        )}
        actions={
          (currentDashboard.type === DashboardType.UserTenantAdmin &&
            ListTenantContractRowActions(currentDashboard)) ||
          undefined
        }
      />
    </Page>
  );
};

export default ListContractsPage;
