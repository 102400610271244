import { ResourceInterface, setResourceDefaults } from './Resource';
import { DataGridColumns } from '../components/DataGrid/Column';
import { Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import PencilAltIcon from '../icons/PencilAlt';
import React from 'react';
import { ActionList, ActionTypes } from '../types/ActionType';
import { DeleteForever, ExitToApp } from '@material-ui/icons';
import { renderCreatedAt, renderUpdatedAt } from '../components/TimestampCell';
import { UseDialogProps } from '../hooks/useDialog';
import simpleApiCall from '../hooks/simpleApiCall';
import { history } from '../redux';
import { remove, removeUser } from '../api/endpoints/Api/Tenants/UserGroups';
import { FullTenantUserGroupView } from '../api/types/App/View/TenantUserGroup';
import { RequestBodyFormMap } from '../types/FormTypes';
import {
  AddTenantUserToUserGroupRequestBody,
  CreateTenantUserGroupRequestBody,
} from '../api/types/App/Request/TenantUserGroup';
import { Yup } from '../lib/Yup';
import { FormLayoutCard } from '../types/FormLayoutType';
import { ListSelectorView } from '../api/types/App/View/Common';
import PlusIcon from '../icons/Plus';

import { FullTenantUserView } from '../api/types/App/View/TenantUser';

const resource: ResourceInterface = {};

const TenantTrackerResource = setResourceDefaults(resource);

export default TenantTrackerResource;

export const ListTenantUserGroupColumns = (baseUrl: string): DataGridColumns<FullTenantUserGroupView> => [
  {
    field: 'name',
    label: 'Title',
    renderCellValue: (
      {
        id,
        title,
      }: FullTenantUserGroupView,
    ) => (
      <Link
        color="textSecondary"
        component={RouterLink}
        to={`${baseUrl}/${id}`}
      >
        {title}
      </Link>
    ),
  },
  {
    field: 'created_at',
    label: 'Created At',
    renderCellValue: renderCreatedAt,
  },
  {
    field: 'updated_at',
    label: 'Updated At',
    renderCellValue: renderUpdatedAt,
  },
];

export const EditAction = (baseUrl: string): ActionTypes<FullTenantUserGroupView> => ({
  type: 'route_link',
  label: 'Edit',
  variant: 'contained',
  icon: <PencilAltIcon fontSize="small" />,
  to: (context) => `${baseUrl}/${context.id}`,
});

export const AddUserAction = (type: string, baseUrl: string): ActionTypes<FullTenantUserGroupView> => ({
  type: 'route_link',
  label: `Add ${type}`,
  color: 'success',
  variant: 'contained',
  icon: <PlusIcon fontSize="small" />,
  to: (context) => `${baseUrl}/${context.id}/add`,
});

export const DeleteAction = (type: string, baseUrl: string, reloadData?: () => void): ActionTypes<FullTenantUserGroupView> => ({
  type: 'confirm_click',
  label: 'Remove',
  variant: 'contained',
  color: 'error',
  icon: <DeleteForever fontSize="small" />,
  dialog: (context: FullTenantUserGroupView): UseDialogProps<FullTenantUserGroupView> => ({
    title: `Are you sure you want to delete ${type} group "{{name}}"?`,
    description: `This tenant ${type} and all related data will be deleted immediately. You can't undo this action.`,
    translationParams: {
      name: context.title,
    },
    level: 'error',
    closeOnOutsideClick: true,
    onConfirm: (c, close) => simpleApiCall(remove, {
      onSuccessToastMessage: `${type.charAt(0).toUpperCase() + type.slice(1)} group "{{name}}" was deleted successfully`,
      translationParams: { name: c.title },
      onSuccess: () => {
        close();
        if (reloadData !== undefined) {
          reloadData();
        } else {
          history.push(baseUrl);
        }
      },
    })({
      tenantId: c.tenant.id,
      id: c.id,
    }),
  }),
});

export const RemoveUserAction = (
  type: string,
  baseUrl: string,
  userGroup: FullTenantUserGroupView,
  reloadData?: () => void,
): ActionTypes<FullTenantUserView> => ({
  type: 'confirm_click',
  label: 'Remove from this group',
  variant: 'contained',
  color: 'error',
  icon: <ExitToApp fontSize="small" />,
  dialog: (context: FullTenantUserView): UseDialogProps<FullTenantUserView> => ({
    title: `Are you sure you want to remove ${type} "{{name}}" from group "{{group}}"?`,
    translationParams: {
      name: context.user.full_name,
      group: userGroup.title,
    },
    level: 'error',
    closeOnOutsideClick: true,
    onConfirm: (c, close) => simpleApiCall(removeUser, {
      onSuccessToastMessage: `${type.charAt(0).toUpperCase() + type.slice(1)} "{{name}}" was removed from group "{{group}}" successfully`,
      translationParams: {
        name: context.user.full_name,
        group: userGroup.title,
      },
      onSuccess: () => {
        close();
        if (reloadData !== undefined) {
          reloadData();
        } else {
          history.push(baseUrl);
        }
      },
    })({
      tenantId: userGroup.tenant.id,
      id: userGroup.id,
      userId: c.id,
    }),
  }),
});

export const ListTenantUserGroupUserActions = (type: string, baseUrl: string, userGroup: FullTenantUserGroupView) => (reloadData: () => void): ActionList<FullTenantUserView> => [
  RemoveUserAction(type, baseUrl, userGroup, reloadData),
];

export const ListTenantUserGroupActions = (type: string, baseUrl: string) => (reloadData: () => void): ActionList<FullTenantUserGroupView> => [
  EditAction(baseUrl),
  DeleteAction(type, baseUrl, reloadData),
];

export const CreateTenantUserGroupFormMap = (): RequestBodyFormMap<CreateTenantUserGroupRequestBody, {}> => ({
  title: {
    label: 'Title',
    name: 'title',
    initialValue: '',
    validationSchema: Yup.string()
      .required(),
  },
});

export const CreateTenantUserGroupFormLayout = (): FormLayoutCard<any> => ({
  type: 'card',
  title: 'Information',
  headerDivider: true,
  bottomDivider: true,
  sx: { mb: 2 },
  maxWidth: 'sm',
  elements: [
    {
      type: 'row',
      elements: [
        {
          type: 'column',
          xs: 12,
          elements: [
            {
              type: 'field',
              field: CreateTenantUserGroupFormMap().title,
            },
          ],
        },
      ],
    },
  ],
  bottomElements: [
    {
      type: 'box',
      sx: {
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2,
      },
      elements: [
        {
          type: 'form_button',
        },
      ],
    },
  ],
});

export const UpdateTenantUserGroupFormLayout = (): FormLayoutCard<any> => ({
  type: 'card',
  title: 'Information',
  headerDivider: true,
  bottomDivider: true,
  sx: { mb: 2 },
  elements: [
    {
      type: 'row',
      elements: [
        {
          type: 'column',
          xs: 12,
          elements: [
            {
              type: 'field',
              field: CreateTenantUserGroupFormMap().title,
            },
          ],
        },
      ],
    },
  ],
  bottomElements: [
    {
      type: 'box',
      sx: {
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2,
      },
      elements: [
        {
          type: 'form_button',
        },
      ],
    },
  ],
});

export const AddTenantUserToUserGroupFormMap = (type: string, userList: ListSelectorView): RequestBodyFormMap<AddTenantUserToUserGroupRequestBody, {}> => ({
  user: {
    label: type,
    name: 'user',
    type: 'autocomplete',
    initialValue: null,
    groupBy: (option) => option.label[0].toUpperCase(),
    listValues: userList.values,
    validationSchema: Yup.object()
      .nullable()
      .required(),
  },
});

export const AddTenantUserToUserGroupFormLayout = (type: string, userList: ListSelectorView): FormLayoutCard<any> => ({
  type: 'card',
  title: `Select ${type}`,
  headerDivider: true,
  bottomDivider: true,
  sx: { mb: 2 },
  maxWidth: 'sm',
  elements: [
    {
      type: 'row',
      elements: [
        {
          type: 'column',
          xs: 12,
          elements: [
            {
              type: 'field',
              field: AddTenantUserToUserGroupFormMap(type, userList).user,
            },
          ],
        },
      ],
    },
  ],
  bottomElements: [
    {
      type: 'box',
      sx: {
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2,
      },
      elements: [
        {
          type: 'form_button',
          label: 'Add',
        },
      ],
    },
  ],
});
