import React from 'react';
import { Alert, Box } from '@material-ui/core';
import {
  ApiEndpointBodyType,
  ApiEndpointParamsType,
  ApiEndpointReturnType,
  ApiEndpointRouteParamsType,
  ApiEndpointTypeAny,
  GetResponseErrorViewType,
  isResponseSuccess,
} from '../api/client';
import { useTranslation } from 'react-i18next';
import { FormikHelpers, FormikValues } from 'formik/dist/types';
import { JsonErrorView } from '../api/types/App/Response';
import { BaseErrorView, ValidationErrorView } from '../api/types/App/ErrorView';
import NProgress from 'nprogress';

export type UseFormikSubmitOptionsFor<T extends ApiEndpointTypeAny> = UseFormikSubmitOptions<T>;

export interface UseFormikSubmitOptions<T extends ApiEndpointTypeAny, Values extends FormikValues & ApiEndpointBodyType<T> = FormikValues> {
  beforeSubmit?: (
    values: Values,
    formikHelpers: FormikHelpers<Values>,
    setServerErrors: React.Dispatch<React.SetStateAction<GetResponseErrorViewType<T>>>,
  ) => boolean,
  onSuccess?: (
    response: ApiEndpointReturnType<T>,
    values: Values,
    formikHelpers: FormikHelpers<Values>,
    setServerErrors: React.Dispatch<React.SetStateAction<GetResponseErrorViewType<T>>>,
  ) => void
  onError?: (
    errors: JsonErrorView<GetResponseErrorViewType<T>>[],
    response: ApiEndpointReturnType<T>,
    values: Values,
    formikHelpers: FormikHelpers<Values>,
    setServerErrors: React.Dispatch<React.SetStateAction<GetResponseErrorViewType<T>>>,
  ) => void
  onServerError?: (
    error,
    values: Values,
    formikHelpers: FormikHelpers<Values>,
    setServerErrors: React.Dispatch<React.SetStateAction<GetResponseErrorViewType<T>>>,
  ) => void
}

const useFormikSubmit = <T extends ApiEndpointTypeAny, Values extends FormikValues & ApiEndpointBodyType<T> = FormikValues>(
  endpoint: T,
  options: UseFormikSubmitOptions<T, Values> = {},
) => (
    routeParams: ApiEndpointRouteParamsType<T> = {},
    params: ApiEndpointParamsType<T> = {},
  ) => {
    const { t } = useTranslation();
    const [serverErrorsValues, setServerErrors] = React.useState<JsonErrorView<GetResponseErrorViewType<T>>[]>([]);

    const handleSubmit = async (
      values: Values,
      formikHelpers: FormikHelpers<Values>,
    ): Promise<any> => {
      const beforeSubmitResult = options.beforeSubmit?.(values, formikHelpers, setServerErrors);
      if (beforeSubmitResult !== undefined && beforeSubmitResult === false) {
        return;
      }

      try {
        NProgress.start();
        setServerErrors([]);

        const result = await endpoint(routeParams, params, values);

        formikHelpers.setSubmitting(false);
        NProgress.done();
        if (isResponseSuccess(result)) {
          formikHelpers.setStatus({ success: true });

          options.onSuccess?.(result as ApiEndpointReturnType<T>, values, formikHelpers, setServerErrors);
        } else {
          formikHelpers.setStatus({ success: false });

          if (options.onError !== undefined) {
            options.onError?.(result.errors, result as ApiEndpointReturnType<T>, values, formikHelpers, setServerErrors);
          } else {
            const errors = [];
            result.errors.forEach((error) => {
              if (error.code === 400) {
                const { violations } = error.data as ValidationErrorView;
                if (Array.isArray(violations)) {
                  violations.forEach((violation) => {
                    formikHelpers.setFieldError(violation.path ?? '', t(violation.message) ?? '');
                  });

                  return;
                }

                errors.push(error);
              }
            });
            setServerErrors(errors);
          }
        }
      } catch (e) {
        if (e instanceof Error && e.message.includes('is not provided for route')) {
          throw e;
        }
        formikHelpers.setStatus({ success: false });
        formikHelpers.setSubmitting(false);
        NProgress.done();

        if (options.onError !== undefined) {
          options.onServerError?.(e, values, formikHelpers, setServerErrors);
        } else {
          const serverError: JsonErrorView<BaseErrorView> = {
            code: 500,
            data: [],
            message: e.message,
          };

          setServerErrors([serverError as JsonErrorView<GetResponseErrorViewType<T>>]);
        }
      }
    };

    const serverErrors = serverErrorsValues.length > 0 && (
    <Box sx={{ mb: 1 }}>
      {serverErrorsValues.map((error: JsonErrorView<any>) => (
        <Alert
          key={error.message}
          severity="error"
        >
          {t(error.message)}
        </Alert>
      ))}
    </Box>
    );

    return {
      serverErrors,
      serverErrorsValues,
      setServerErrors,
      handleSubmit,
    };
  };

export default useFormikSubmit;
