import { ResourceInterface, setResourceDefaults } from './Resource';
import { DataGridColumns } from '../components/DataGrid/Column';
import { Box, Checkbox, Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import PencilAltIcon from '../icons/PencilAlt';
import React from 'react';
import UserAvatar from '../components/UserAvatar';
import { ActionList, ActionTypes } from '../types/ActionType';
import { DeleteForever, MailOutline } from '@material-ui/icons';
import TimestampCell, {
  renderCreatedAt,
  renderUpdatedAt,
} from '../components/TimestampCell';
import { UseDialogProps } from '../hooks/useDialog';
import simpleApiCall from '../hooks/simpleApiCall';
import { history } from '../redux';
import { remove } from '../api/endpoints/Api/Tenants/Users';
import { FullTenantUserView } from '../api/types/App/View/TenantUser';
import { StatusLabels } from '../components/Label';
import { DetailRows } from '../components/DetailTable';
import { RequestBodyFormMap } from '../types/FormTypes';
import {
  CreateUserRequestBody,
  InviteUserRequestBody,
  UpdateTenantUserRequestBody,
} from '../api/types/App/Request/TenantUser';
import { FormLayoutCard } from '../types/FormLayoutType';
import { getMapValue, MapValue } from '../types/MapValues';
import { Yup } from '../lib/Yup';
import i18n from 'i18next';

const resource: ResourceInterface = {};

const TenantUserResource = setResourceDefaults(resource);

export default TenantUserResource;

export type TenantUserRoleType = 'ROLE_ADMIN' | 'ROLE_PARTICIPANT';
export const TenantUserRoleTypes = ['ROLE_ADMIN', 'ROLE_PARTICIPANT'];

export interface TenantUserRoleValue extends MapValue {
  value: TenantUserRoleType;
}

export const TenantUserRolesMap: TenantUserRoleValue[] = [
  {
    label: 'Manager',
    value: 'ROLE_ADMIN',
    color: 'error',
  },
  {
    label: 'Affiliate',
    value: 'ROLE_PARTICIPANT',
    color: 'success',
  },
];

export const ListTenantUserColumns = (
  baseUrl: string,
  full: boolean = true,
  showLink: boolean = true,
): DataGridColumns<FullTenantUserView> => [
  {
    field: 'user.full_name',
    label: 'Full Name',
    renderCellValue(row: FullTenantUserView) {
      const { id, user } = row;

      let urlLink = <>{user.full_name}</>;
      if (showLink === true) {
        urlLink = (
            <Link
                color="inherit"
                component={RouterLink}
                to={{
                  pathname: `${baseUrl}/${id}`,
                }}
                variant="subtitle2"
            >
              {user.full_name}
            </Link>
        );
      }

      return (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <UserAvatar user={user} />
          <Box sx={{ ml: 1 }}>
            {urlLink}
            <Typography color="textSecondary" variant="body2">
              <Link color="textSecondary" href={`mailto:${user.email}`}>
                {user.email}
              </Link>
            </Typography>
          </Box>
        </Box>
      );
    },
  },
  {
    field: 'status',
    label: 'Status',
    hidden: () => !full,
    renderCellValue: ({ roles }: FullTenantUserView) => (
      <StatusLabels
        values={roles.map((role) => getMapValue(TenantUserRolesMap, role))}
      />
    ),
  },
  {
    field: 'enabled',
    label: 'Enabled',
    hidden: () => !full,
    renderCellValue: ({ enabled }: FullTenantUserView) => (
      <Checkbox checked={enabled} color="primary" />
    ),
  },
  {
    field: 'activated',
    label: 'Activated',
    hidden: () => !full,
    renderCellValue: ({ user }: FullTenantUserView) => (
      <Checkbox checked={user.activated} color="success" />
    ),
  },
  {
    field: 'last_seen',
    label: 'Last Seen',
    renderCellValue: ({ user }: FullTenantUserView) => (
      <TimestampCell value={user.last_seen_at} />
    ),
  },
  {
    field: 'created_at',
    label: 'Created At',
    hidden: () => !full,
    renderCellValue: renderCreatedAt,
  },
  {
    field: 'updated_at',
    label: 'Updated At',
    hidden: () => !full,
    renderCellValue: renderUpdatedAt,
  },
];

export const EditAction = (
  baseUrl: string
): ActionTypes<FullTenantUserView> => ({
  type: 'route_link',
  label: 'Edit',
  variant: 'contained',
  icon: <PencilAltIcon fontSize="small" />,
  to: (context) => `${baseUrl}/${context.id}/edit`,
});

export const DeleteAction = (
  baseUrl: string,
  reloadData?: () => void
): ActionTypes<FullTenantUserView> => ({
  type: 'confirm_click',
  label: 'Remove',
  variant: 'contained',
  color: 'error',
  icon: <DeleteForever fontSize="small" />,
  dialog: (
    context: FullTenantUserView
  ): UseDialogProps<FullTenantUserView> => ({
    title: 'Are you sure you want to delete tenant user "{{name}}"?',
    description:
      "This tenant user and all related data will be deleted immediately. You can't undo this action.",
    translationParams: {
      name: context.user.full_name,
    },
    level: 'error',
    closeOnOutsideClick: true,
    onConfirm: (c, close) =>
      simpleApiCall(remove, {
        onSuccessToastMessage:
          'Tenant user "{{name}}" was deleted successfully',
        translationParams: { name: c.user.full_name },
        onSuccess: () => {
          close();
          if (reloadData !== undefined) {
            reloadData();
          } else {
            history.push(baseUrl);
          }
        },
      })({
        id: c.id,
        tenantId: c.tenant.id,
      }),
  }),
});

export const ListTenantUserActions =
  (baseUrl: string) =>
  (reloadData: () => void): ActionList<FullTenantUserView> =>
    [EditAction(baseUrl), DeleteAction(baseUrl, reloadData)];

export const TenantUserDetailInfoRows: DetailRows<FullTenantUserView> = [
  {
    field: 'user.full_name',
    label: 'Full Name',
  },
  {
    field: 'user.address1',
    label: 'Address 1',
  },
  {
    field: 'user.address2',
    label: 'Address 2',
  },
  {
    field: 'user.zip_code',
    label: 'Zip Code',
  },
  {
    field: 'user.city',
    label: 'City',
  },
  {
    field: 'user.country',
    label: 'Country',
  },
  {
    field: 'user.email',
    label: 'Email Address',
  },
  {
    field: 'user.telephone',
    label: 'Telephone',
  },
];

export const TenantUserDetailSettings = (
  affiliate: boolean
): DetailRows<FullTenantUserView> => [
  {
    field: 'enabled',
    label: 'Enabled',
    renderValue: ({ enabled }: FullTenantUserView) => (
      <Checkbox color="success" checked={enabled} disableRipple />
    ),
  },
  {
    field: 'user.activated',
    label: 'Activated',
    renderValue: ({ user }: FullTenantUserView) => (
      <Checkbox checked={user.activated} disableRipple />
    ),
  },
  {
    field: 'roles',
    label: 'Roles',
    hidden: affiliate,
    renderValue: ({ roles }: FullTenantUserView) => (
      <StatusLabels
        values={roles.map((role) => getMapValue(TenantUserRolesMap, role))}
      />
    ),
  },
  {
    field: 'notes',
    label: 'Notes',
  },
];

export const EditTenantUserFormMap: RequestBodyFormMap<
  UpdateTenantUserRequestBody,
  FullTenantUserView
> = {
  enabled: {
    name: 'enabled',
    label: 'Enabled',
    type: 'switch',
  },
  is_manager: {
    name: 'is_manager',
    label: 'Is Manager',
    type: 'switch',
    formFieldProp: {
      color: 'error',
    },
    initialValue: (context) => context.roles.indexOf('ROLE_ADMIN') !== -1,
  },
  is_participant: {
    name: 'is_participant',
    label: 'Is Participant',
    type: 'switch',
    formFieldProp: {
      color: 'success',
    },
    initialValue: (context) => context.roles.indexOf('ROLE_PARTICIPANT') !== -1,
  },
  notes: {
    name: 'notes',
    label: 'Notes',
    type: 'textarea',
  },
};

export const EditTenantUserFormLayout = (
  affiliate: boolean
): FormLayoutCard<FullTenantUserView> => ({
  type: 'card',
  title: 'Information',
  headerDivider: true,
  bottomDivider: true,
  elements: [
    {
      type: 'row',
      elements: [
        {
          type: 'column',
          xs: 12,
          sx: {
            alignItems: 'center',
            display: 'flex',
          },
          elements: [
            {
              type: 'row',
              elements: [
                {
                  type: 'column',
                  elements: [
                    {
                      type: 'field',
                      field: EditTenantUserFormMap.enabled,
                    },
                  ],
                },
                {
                  type: 'column',
                  hidden: () => affiliate,
                  elements: [
                    {
                      type: 'field',
                      field: EditTenantUserFormMap.is_manager,
                    },
                  ],
                },
                {
                  type: 'column',
                  hidden: () => affiliate,
                  elements: [
                    {
                      type: 'field',
                      field: EditTenantUserFormMap.is_participant,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'row',
      elements: [
        {
          type: 'column',
          xs: 12,
          md: 12,
          elements: [
            {
              type: 'field',
              field: EditTenantUserFormMap.notes,
            },
          ],
        },
      ],
    },
  ],
  bottomElements: [
    {
      type: 'box',
      sx: {
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2,
      },
      elements: [
        {
          type: 'form_button',
        },
      ],
    },
  ],
});

export const InviteUserFormMap = (
  email: string,
  roles: TenantUserRoleType[]
): RequestBodyFormMap<InviteUserRequestBody, any> => ({
  email: {
    name: 'email',
    label: 'Email Address',
    type: 'email',
    initialValue: email,
    validationSchema: Yup.string().email().required(),
  },
  roles: {
    name: 'roles',
    label: 'Roles',
    initialValue: roles,
    validationSchema: Yup.array(
      Yup.string().required().oneOf(TenantUserRoleTypes)
    ).min(1),
  },
  text: {
    name: 'text',
    label: 'Invitation Text',
    formFieldProp: {
      multiline: true,
      rows: 10,
    },
    helperText: 'Add __URL__ line to text to include invitation URL',
    validationSchema: Yup.string()
      .required()
      .test(
        'contains-invite-link',
        i18n.t('Invitation Text must contain __URL__ line for invitation URL!'),
        (value) => `${value}`.includes('__URL__')
      ),
  },
});

export const InviteUserFormLayout = (
  onBack: () => void,
  email: string,
  roles: TenantUserRoleType[],
  userType: string,
  stepNumber: string
): FormLayoutCard => ({
  type: 'card',
  elements: [
    {
      type: 'node',
      renderNode: () => (
        <Box>
          <Typography color="textPrimary" variant="h6">
            {`${stepNumber}. ${i18n.t('Invite {{type}}', {
              type: userType.charAt(0).toUpperCase() + userType.slice(1),
            })}`}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {i18n.t(
              'Please enter {{type}} invitation text to be sent to {{type}} email address',
              { type: userType }
            )}
          </Typography>
        </Box>
      ),
    },
    {
      type: 'row',
      gridProps: {
        spacing: 0,
      },
      elements: [
        {
          type: 'column',
          xs: 12,
          elements: [
            {
              type: 'field',
              field: InviteUserFormMap(email, roles).email,
            },
          ],
        },
        {
          type: 'column',
          xs: 12,
          elements: [
            {
              type: 'field',
              field: InviteUserFormMap(email, roles).text,
            },
          ],
        },
      ],
    },
    {
      type: 'box',
      sx: {
        mt: 1,
        display: 'flex',
        alignItems: 'center',
      },
      elements: [
        {
          type: 'button',
          onClick: () => onBack,
          hidden: () => onBack === undefined,
          color: 'primary',
          variant: 'text',
          buttonProps: {
            size: 'large',
            children: i18n.t('Previous'),
          },
        },
        {
          type: 'box',
          sx: {
            flexGrow: 1,
          },
        },
        {
          type: 'form_button',
          label: 'Send',
          startIcon: <MailOutline fontSize="small" />,
        },
      ],
    },
  ],
});

export const CreateUserFormMap = (
  email: string,
  roles: TenantUserRoleType[]
): RequestBodyFormMap<CreateUserRequestBody, any> => ({
  email: {
    name: 'email',
    label: 'Email Address',
    type: 'email',
    initialValue: email,
    validationSchema: Yup.string().email().required(),
  },
  full_name: {
    name: 'full_name',
    label: 'Full Name',
    initialValue: '',
    validationSchema: Yup.string().required(),
  },
  password: {
    label: 'Password',
    name: 'password',
    type: 'password',
    initialValue: '',
    validationSchema: Yup.string().required().min(8).max(100),
  },
  password_repeat: {
    label: 'Confirm password',
    name: 'password_repeat',
    type: 'password',
    initialValue: '',
    validationSchema: Yup.string()
      .required()
      .oneOf([Yup.ref('password'), null], () => i18n.t('Passwords must match')),
  },
  roles: {
    name: 'roles',
    label: 'Roles',
    initialValue: roles,
    validationSchema: Yup.array(
      Yup.string().required().oneOf(TenantUserRoleTypes)
    ).min(1),
  },
});

export const CreateUserFormLayout = (
  onBack: () => void,
  email: string,
  roles: TenantUserRoleType[],
  userType: string,
  stepNumber: string
): FormLayoutCard => ({
  type: 'card',
  elements: [
    {
      type: 'node',
      renderNode: () => (
        <Box>
          <Typography color="textPrimary" variant="h6">
            {`${stepNumber}. ${i18n.t('Create {{type}}', {
              type: userType.charAt(0).toUpperCase() + userType.slice(1),
            })}`}
          </Typography>
          <Typography color="textSecondary" variant="body1">
            {i18n.t('Please enter {{type}} details', { type: userType })}
          </Typography>
        </Box>
      ),
    },
    {
      type: 'row',
      gridProps: {
        spacing: 0,
      },
      elements: [
        {
          type: 'column',
          xs: 12,
          elements: [
            {
              type: 'field',
              field: CreateUserFormMap(email, roles).email,
            },
            {
              type: 'field',
              field: CreateUserFormMap(email, roles).full_name,
            },
            {
              type: 'field',
              field: CreateUserFormMap(email, roles).password,
            },
            {
              type: 'field',
              field: CreateUserFormMap(email, roles).password_repeat,
            },
          ],
        },
      ],
    },
    {
      type: 'box',
      sx: {
        mt: 1,
        display: 'flex',
        alignItems: 'center',
      },
      elements: [
        {
          type: 'button',
          onClick: () => onBack,
          hidden: () => onBack === undefined,
          color: 'primary',
          variant: 'text',
          buttonProps: {
            size: 'large',
            children: i18n.t('Previous'),
          },
        },
        {
          type: 'box',
          sx: {
            flexGrow: 1,
          },
        },
        {
          type: 'form_button',
          label: 'Create',
        },
      ],
    },
  ],
});
