import * as React from 'react';
import { TypographyProps } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon/SvgIcon';
import i18n from 'i18next';
import { smartListBuilder } from '../utils/smartList';

export interface MapValue<V extends string = string> {
  value: V;
  label?: string;
  icon?: React.ReactElement;
  color?: TypographyProps['color'] | SvgIconProps['color'] | string;
}

export const parseColorString = (
  color: MapValue['color']
): MapValue['color'] => {
  switch (color) {
    case 'primary':
      return 'primary.main';
    case 'secondary':
      return 'secondary.main';
    case 'error':
      return 'error.main';
    case 'warning':
      return 'warning.main';
    case 'info':
      return 'info.main';
    case 'success':
      return 'success.main';
    case 'text':
      return 'text.primary';
    default:
      return color;
  }
};

export const MapValueBuilder = <T extends readonly MapValue[]>(mapValues: T) =>
  mapValues;

export const getMapValue = <T extends MapValue[] | readonly MapValue[]>(
  map: T,
  value: T[number]['value'],
  defValue?: T[number]['value']
): T[number] => {
  const found = map.find((val) => val.value === value);

  if (!found && defValue !== undefined) {
    return getMapValue(map, defValue);
  }

  return found;
};

export const getFirstMapValue = <T extends MapValue>(map: T[]): T =>
  map.length > 0 ? map[0] : null;

export const buildMapValuesOptions = (
  map: MapValue[],
  translate: boolean = true
) =>
  map.map((value) => (
    <option key={value.value} value={value.value}>
      {translate ? i18n.t(value.label) : value.label.replaceAll(' ', '\u00A0')}
    </option>
  ));

export const mapValuesList = smartListBuilder<MapValue>().setField('value');
