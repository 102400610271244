import React from 'react';
import {
  ApiEndpointBodyType,
  ApiEndpointParamsType,
  ApiEndpointReturnType,
  ApiEndpointRouteParamsType,
  ApiEndpointTypeAny,
} from '../api/client';
import { FormikValues } from 'formik/dist/types';
import useFormikSubmit, {
  UseFormikSubmitOptions,
  UseFormikSubmitOptionsFor,
} from '../hooks/useFormikSubmit';
import {
  RequestBodyFormMap,
  RequestBodyFormMapContextType,
  requestBodyFormMapInitialValue,
  requestBodyFormMapValidationSchema,
} from '../types/FormTypes';
import { Form, FormikProvider, useFormik } from 'formik';
import FormLayout from './FormLayout';
import { FormLayoutElementTypes, WrappedContext } from '../types/FormLayoutType';
import { CalcType, parseCalcType } from '../types/CalculatedType';
import importTranslation from '../hooks/ut';
import { toast } from 'react-hot-toast';
import { useHistory } from 'react-router';
import { LocationDescriptor } from 'history';

export interface FormikFormProps<Endpoint extends ApiEndpointTypeAny,
  FormMap extends RequestBodyFormMap<any, any>,
  ContextType = RequestBodyFormMapContextType<FormMap>,
  Values extends FormikValues & ApiEndpointBodyType<Endpoint> = FormikValues,
  > {
  endpoint: Endpoint,
  formMap: FormMap,
  formLayout: FormLayoutElementTypes<ContextType>,
  context: ContextType,
  routeParams?: CalcType<ApiEndpointRouteParamsType<Endpoint>, ContextType>,
  params?: CalcType<ApiEndpointParamsType<Endpoint>, ContextType>,
  options?: UseFormikSubmitOptions<Endpoint, Values>,
  onSuccess?: (response: ApiEndpointReturnType<Endpoint>, values: Values) => void,
  onSuccessMessage?: CalcType<string, ContextType>,
  onSuccessMessageParams?: (response: ApiEndpointReturnType<Endpoint>) => Record<string, string>,
  onSuccessRedirectTo?: (response: ApiEndpointReturnType<Endpoint>) => LocationDescriptor,
  onSuccessResetForm?: CalcType<boolean, ContextType>,
}

const FormikForm = <Endpoint extends ApiEndpointTypeAny, FormMap extends RequestBodyFormMap<any, any>>(
  {
    endpoint,
    formMap,
    formLayout,
    context,
    routeParams = {},
    params = {},
    options = {},
    onSuccess,
    onSuccessMessage,
    onSuccessMessageParams,
    onSuccessRedirectTo,
    onSuccessResetForm,
  }: FormikFormProps<Endpoint, FormMap>,
) => {
  const { t } = importTranslation();
  const history = useHistory();
  const onSuccessHandler: UseFormikSubmitOptionsFor<Endpoint>['onSuccess'] = async (
    response,
    values,
    formikHelpers,
  ) => {
    if (onSuccessMessage !== undefined) {
      onSuccessMessage = parseCalcType(onSuccessMessage, context);

      let translationParams = {};
      if (typeof onSuccessMessageParams === 'function') {
        translationParams = onSuccessMessageParams(response);
      }

      toast.success(t(onSuccessMessage, translationParams));
    }

    if (onSuccessResetForm !== undefined) {
      onSuccessResetForm = parseCalcType(onSuccessResetForm, context);
      if (onSuccessResetForm === true) {
        formikHelpers.resetForm();
      }
    }

    if (onSuccessRedirectTo !== undefined) {
      history.push(onSuccessRedirectTo(response));
    }

    onSuccess?.(response, values);
  };

  options.onSuccess ??= onSuccessHandler;

  const {
    serverErrors,
    handleSubmit,
  } = useFormikSubmit(endpoint, options)(parseCalcType(routeParams, context), parseCalcType(params, context));

  const wrappedContext: WrappedContext<typeof context> = {
    context,
    formikContext: useFormik({
      initialValues: requestBodyFormMapInitialValue(formMap, context),
      validationSchema: () => requestBodyFormMapValidationSchema(formMap, wrappedContext),
      onSubmit: handleSubmit,
    }),
  };

  return (
    <FormikProvider value={wrappedContext.formikContext}>
      <Form>
        {serverErrors}
        <FormLayout layout={formLayout} context={wrappedContext} />
      </Form>
    </FormikProvider>
  );
};

export default FormikForm;
