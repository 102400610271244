import { CategoryMenuView, ListCategoriesView, CategoryView } from '../../../types/App/View/Category';
import { apiEndpoint, NoBody, NoParams, StandardErrors } from '../../../client';
import { CreateCategoryRequestBody } from '../../../types/App/Request/Category';
import { SuccessView } from '../../../types/App/View';

export const menu = apiEndpoint<CategoryMenuView, NoParams, NoBody, StandardErrors>('GET')('/api/category/menu');

export const list = apiEndpoint<ListCategoriesView, NoParams, NoBody, StandardErrors>('GET')('/api/category');

export const create = apiEndpoint<CategoryView, NoParams, CreateCategoryRequestBody, StandardErrors>('POST')('/api/category');

export const update = apiEndpoint<CategoryView, NoParams, CreateCategoryRequestBody, StandardErrors>('PUT')('/api/category/{id}');

export const remove = apiEndpoint<SuccessView, NoParams, NoBody, StandardErrors>('DELETE')('/api/category/{id}');
