import Papa from 'papaparse';
import FileSaver from 'file-saver';
import { DataGridConfig } from '../components/DataGrid';
import { columnGetValue } from '../components/DataGrid/Column';
import i18next from 'i18next';
import React, { isValidElement } from 'react';
import { calculateProps } from './propHelpers';

const reactNodeToString = (reactNode: React.ReactNode): string => {
  let string = '';
  if (typeof reactNode === 'string') {
    string = reactNode;
  } else if (typeof reactNode === 'number') {
    string = reactNode.toString();
  } else if (reactNode instanceof Array) {
    reactNode.forEach((child) => {
      string += ` ${reactNodeToString(child)}`;
    });
  } else if (isValidElement(reactNode)) {
    string += ` ${reactNodeToString(reactNode.props.children)}`;
  }

  return string;
};

const exportCsv = (filename: string, data) => {
  const csvContent = Papa.unparse(data);
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

  FileSaver.saveAs(blob, filename);
};

export const exportCsvDataGridConfig = (filename: string, config: DataGridConfig<any, any>) => {
  const data = config.rows.map((r) => {
    const row = {};

    config.columns.filter((column) => column.hidden === undefined || column.hidden() === false)
      .forEach((c) => {
        const colName = c.label !== '' ? i18next.t(String(c.label)) : '';
        const cellProps = calculateProps(c.cellProps, {
          align: c.align,
        });

        let value;
        if (c.renderCell !== undefined) {
          value = reactNodeToString(c.renderCell(r, cellProps));
        } else if (c.renderCellValue !== undefined) {
          const a = c.renderCellValue(r);
          value = reactNodeToString(a);
          console.log(a, value);
        } else {
          value = columnGetValue<any>(r, c);
        }

        row[colName] = value.replaceAll('\n', ' ').trim();
      });

    return row;
  });

  exportCsv(filename, data);
};

export default exportCsv;
