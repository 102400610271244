import type { ReactNode } from 'react';
import React, { ReactElement } from 'react';

export type CalcType<T> = T | (() => T);
export type CalcTypeValue<T, V = any> = T | ((value: V) => T);
export type CalcReactNode<T = ReactNode, P = any> = T | ((props: P) => T);

export type ModifyProps<T = any> = (props: T) => T;

export type HorizontalAlign = 'left' | 'right' | 'center';

export function calcTypeDecide<T>(type: CalcType<T>): T {
  if (typeof type === 'function') {
    return (type as () => T)();
  }

  return type;
}

export function calcRenderNode<P = any>(node: React.ElementType, renderFunc: (props: P) => ReactElement, props: P): ReactElement {
  if (typeof renderFunc === 'function') {
    return renderFunc(props);
  }

  return React.createElement<P>(node, props);
}

export function calcProps<P>(props: P, calc: (props: P) => P): P {
  if (typeof calc === 'function') {
    return calc(props);
  }

  return props;
}

export type ModifiableComponentProps<Element extends React.ElementType, Props = React.ComponentProps<Element>> =
  ((props: Props) => Props) | Partial<Props>;
type ExtractPropType<T extends ModifiableComponentProps<any>> = T extends (...args: any) => infer R ? R : never;

export const calculateProps = <T extends ModifiableComponentProps<any>, Props extends ExtractPropType<T> = ExtractPropType<T>>(
  modifier: T,
  inputProps: Props,
): Props => {
  if (modifier === undefined) {
    return inputProps;
  }

  if (typeof modifier === 'function') {
    return modifier(inputProps);
  }

  return { ...inputProps, ...modifier };
};

export type ModifiableComponentRender<Element extends React.ElementType, ContextType = any, Props = React.ComponentProps<Element>> =
  ((props: Props, context: ContextType, Component: Element) => React.ReactNode) | React.ReactNode;

type ExtractComponentPropType<T extends ModifiableComponentRender<any>> = T extends (props: infer P, context: any, Component: any) => React.ReactNode ? P : never;
type ExtractComponentType<T extends ModifiableComponentRender<any>> = T extends (props: any, context: any, Component: infer P) => React.ReactNode ? P : never;
type ExtractContextType<T extends ModifiableComponentRender<any>> = T extends (props: any, context: infer P, Component: any) => React.ReactNode ? P : never;

export const calculateComponent = <
  T extends ModifiableComponentRender<any>,
  ComponentType extends React.ElementType = ExtractComponentType<T>,
  ContextType = ExtractContextType<T>,
  Props extends React.ComponentProps<ComponentType> = ExtractComponentPropType<T>
  >(modifier: T, Component: ComponentType, props: Props, context: ContextType): React.ReactNode => {
  if (modifier === undefined || modifier === null) {
    return (<Component {...props} />);
  }

  if (typeof modifier === 'function') {
    return modifier(props, context, Component);
  }

  return modifier;
};
