import { RequestBodyFormMap } from '../types/FormTypes';
import { Yup } from '../lib/Yup';
import { FormLayoutCard } from '../types/FormLayoutType';
import { DataGridColumns } from '../components/DataGrid/Column';
import { Box, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import PencilAltIcon from '../icons/PencilAlt';
import React from 'react';
import UserAvatar from '../components/UserAvatar';
import { ActionList, ActionTypes } from '../types/ActionType';
import { DeleteForever, SwitchAccount } from '@material-ui/icons';
import { renderCreatedAt, renderUpdatedAt } from '../components/TimestampCell';
import { CreateAgencyRequestBody, UpdateAgencyRequestBody } from '../api/types/App/Request/Agency';
import { FullAgencyView } from '../api/types/App/View/Agency';
import { renderUserCell } from '../components/TableCell/UserCell';
import { UseDialogProps } from '../hooks/useDialog';
import simpleApiCall from '../hooks/simpleApiCall';
import { history, store } from '../redux';
import { remove } from '../api/endpoints/Api/Agencies';
import { ListSelectorView } from '../api/types/App/View/Common';
import { isSuperAdmin } from '../utils/superAdmin';
import { chooseDashboard, Dashboard, DashboardType } from '../redux/slices/User';

export const CreateAgencyFormMap = (valueList: ListSelectorView): RequestBodyFormMap<CreateAgencyRequestBody, {}> => ({
  name: {
    label: 'Title',
    name: 'name',
    initialValue: '',
    validationSchema: Yup.string()
      .required(),
  },
  client: {
    label: 'Client',
    name: 'client',
    type: 'autocomplete',
    initialValue: null,
    groupBy: (option) => option.label[0].toUpperCase(),
    listValues: valueList.values,
    validationSchema: Yup.object()
      .nullable()
      .required(),
  },
});

export const UpdateAgencyFormMap = (
  userList: ListSelectorView,
  clientList: ListSelectorView,
): RequestBodyFormMap<UpdateAgencyRequestBody, FullAgencyView> => ({
  name: {
    label: 'Title',
    name: 'name',
    initialValue: '',
    validationSchema: Yup.string()
      .required(),
  },
  admin: {
    label: 'Administrator',
    name: 'admin',
    type: 'autocomplete',
    initialValue: (context) => (context.admin_user === null ? null : {
      value: context.admin_user.id,
      label: context.admin_user.full_name,
    }),
    groupBy: (option) => option.label[0].toUpperCase(),
    listValues: userList.values,
    validationSchema: Yup.object()
      .nullable()
      .required(),
  },
  client: {
    label: 'Client',
    name: 'client',
    type: 'autocomplete',
    initialValue: (context) => (context.client === null ? null : {
      value: context.client.id,
      label: context.client.company_name,
    }),
    groupBy: (option) => option.label[0].toUpperCase(),
    listValues: clientList.values,
    validationSchema: Yup.object()
      .nullable()
      .required(),
  },
});

export const CreateAgencyFormLayout = (valueList: ListSelectorView): FormLayoutCard<any> => ({
  type: 'card',
  title: 'Information',
  headerDivider: true,
  bottomDivider: true,
  sx: { mb: 2 },
  maxWidth: 'sm',
  elements: [
    {
      type: 'row',
      elements: [
        {
          type: 'column',
          xs: 12,
          elements: [
            {
              type: 'field',
              field: CreateAgencyFormMap(valueList).name,
            },
          ],
        },
      ],
    },
    {
      type: 'row',
      elements: [
        {
          type: 'column',
          xs: 12,
          elements: [
            {
              type: 'field',
              field: CreateAgencyFormMap(valueList).client,
            },
          ],
        },
      ],
    },
  ],
  bottomElements: [
    {
      type: 'box',
      sx: {
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2,
      },
      elements: [
        {
          type: 'form_button',
        },
      ],
    },
  ],
});

export const UpdateAgencyFormLayout = (
  userList: ListSelectorView,
  clientList: ListSelectorView,
): FormLayoutCard<any> => ({
  type: 'card',
  title: 'Information',
  headerDivider: true,
  bottomDivider: true,
  sx: { mb: 2 },
  elements: [
    {
      type: 'row',
      elements: [
        {
          type: 'column',
          xs: 12,
          md: 6,
          elements: [
            {
              type: 'field',
              field: UpdateAgencyFormMap(userList, clientList).name,
            },
            {
              type: 'field',
              field: UpdateAgencyFormMap(userList, clientList).admin,
            },
          ],
        },
        {
          type: 'column',
          xs: 12,
          md: 6,
          elements: [
            {
              type: 'field',
              field: UpdateAgencyFormMap(userList, clientList).client,
            },
          ],
        },
      ],
    },
  ],
  bottomElements: [
    {
      type: 'box',
      sx: {
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2,
      },
      elements: [
        {
          type: 'form_button',
        },
      ],
    },
  ],
});

export const getId = (obj: FullAgencyView) => obj.id;

export const ListAgencyColumns = (): DataGridColumns<FullAgencyView> => [
  {
    field: 'title',
    label: 'Title',
    renderCellValue: (
      {
        id,
        apc,
        name,
      }: FullAgencyView,
    ) => (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <UserAvatar user={{
          id,
          company_name: name,
          apc,
        }}
        />
        <Box sx={{ ml: 1 }}>
          <Link
            color="inherit"
            component={RouterLink}
            to={{
              pathname: `/agencies/${id}`,
            }}
            variant="subtitle2"
          >
            {name}
          </Link>
        </Box>
      </Box>
    ),
  },
  {
    field: 'client',
    label: 'Client',
    renderCellValue: (
      {
        client,
      }: FullAgencyView,
    ) => (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <UserAvatar user={client} />
        <Box sx={{ ml: 1 }}>
          <Link
            color="inherit"
            component={RouterLink}
            to={{
              pathname: `/client/${client.id}`,
            }}
            variant="subtitle2"
          >
            {client.company_name}
          </Link>
        </Box>
      </Box>
    ),
  },
  {
    field: 'admin_user',
    label: 'Administrator User',
    renderCellValue: renderUserCell('admin_user'),
  },
  {
    field: 'created_at',
    label: 'Created At',
    renderCellValue: renderCreatedAt,
  },
  {
    field: 'updated_at',
    label: 'Updated At',
    renderCellValue: renderUpdatedAt,
  },
];

export const EditAction: ActionTypes<FullAgencyView> = {
  type: 'route_link',
  label: 'Edit',
  variant: 'contained',
  icon: <PencilAltIcon fontSize="small" />,
  to: (context) => `/agencies/${context.id}`,
};

export const DeleteAction = (reloadData?: () => void): ActionTypes<FullAgencyView> => ({
  type: 'confirm_click',
  label: 'Remove',
  variant: 'contained',
  color: 'error',
  icon: <DeleteForever fontSize="small" />,
  dialog: (context: FullAgencyView): UseDialogProps<FullAgencyView> => ({
    title: 'Are you sure you want to delete agency "{{name}}"?',
    description: 'This agency and all related data will be deleted immediately. You can\'t undo this action.',
    translationParams: {
      name: context.name,
    },
    level: 'error',
    closeOnOutsideClick: true,
    onConfirm: (c, close) => simpleApiCall(remove, {
      onSuccessToastMessage: 'Agency "{{name}}" was deleted successfully',
      translationParams: { name: c.name },
      onSuccess: () => {
        close();
        if (reloadData !== undefined) {
          reloadData();
        } else {
          history.push('/agencies');
        }
      },
    })({ id: c.id }),
  }),
});

const ImpersonateAgency: ActionTypes<FullAgencyView> = {
  type: 'click',
  label: 'Impersonate',
  variant: 'contained',
  icon: <SwitchAccount fontSize="small" />,
  hidden: !isSuperAdmin(),
  onClick: (ctx) => () => {
    const dashboard: Dashboard = {
      id: ctx.id,
      apc: ctx.apc,
      logo: ctx.name[0],
      title: `${ctx.name}`,
      role: 'Agency Manager',
      type: DashboardType.AgencyAdmin,
      agency: ctx,
    };

    store.dispatch(chooseDashboard(dashboard));
  }
};

export const ListAgencyRowActions = (reloadData: () => void): ActionList<FullAgencyView> => [
  ImpersonateAgency,
  EditAction,
  DeleteAction(reloadData),
];
