import { ChangeEvent } from 'react';
import { buildMapValuesOptions, getFirstMapValue, MapValue } from '../../types/MapValues';
import { ModifyProps } from '../../utils/propHelpers';
import { Box, BoxProps, TextField, TextFieldProps } from '@material-ui/core';
import { modifyProps, renderComponent, RenderComponent } from '../../types/RenderComponent';
import { useStateEffect } from '../../hooks/useStateEffect';
import { useTranslation } from 'react-i18next';
import { BaseDataGridFilter } from './Filter';
import { CalcType, parseCalcType } from '../../types/CalculatedType';

export interface DataGridSelectOptions {
  label?: string,
  values: MapValue[],
  current?: string,
  onChange?: (value: string) => void,
  fieldProps?: ModifyProps<TextFieldProps>,
  renderField?: RenderComponent<typeof TextField>,
  containerProps?: ModifyProps<BoxProps>,
  renderContainer?: RenderComponent<typeof Box>,
  translateValues?: boolean,
  hidden?: CalcType<boolean>,
}

export interface DataGridSelectFilter extends BaseDataGridFilter {
  type: 'select',
  config: DataGridSelectOptions,
}

interface SelectProps {
  config: DataGridSelectOptions;
}

const DataGridSelect = (props: SelectProps) => {
  const { config } = props;

  if (parseCalcType(config.hidden) === true) {
    return null;
  }

  const { t } = useTranslation();
  const [value, setValue] = useStateEffect<string>(config.current ? config.current : getFirstMapValue(config.values).value);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.value);

    config.onChange?.(event.target.value);
  };

  return renderComponent(
    Box,
    modifyProps(
      {
        sx: {
          m: 1,
          width: 240,
        },
        children: renderComponent(
          TextField,
          modifyProps(
            {
              label: t(config.label ?? 'Sort By'),
              select: true,
              SelectProps: {
                native: true,
              },
              variant: 'outlined',
              value,
              onChange: handleChange,
              children: buildMapValuesOptions(config.values, config.translateValues),
            },
            config.fieldProps,
          ),
          config.renderField,
        ),
      },
      config.containerProps,
    ),
    config.renderContainer,
  );
};

export default DataGridSelect;
