import { DataGridColumns } from '../components/DataGrid/Column';
import { ShortCommissionView } from '../api/types/App/View/Commission';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { ActionList, ActionTypes } from '../types/ActionType';
import UserAvatar from '../components/UserAvatar';
import i18n from 'i18next';
import { timestampFormat, timestampFormatDistanceToNowStrict } from '../utils/timestampUtils';
import { getMapValue, MapValue } from '../types/MapValues';
import { StatusLabel } from '../components/Label';
import { renderCreatedAt, renderUpdatedAt } from '../components/TimestampCell';
import numeral from '../lib/numeral';
import { formatMoneyValue } from '../types/Money';
import { Preview, Receipt, ThumbDown, ThumbUp } from '@material-ui/icons';
import { DetailRows } from '../components/DetailTable';
import MapValueChip from '../components/MapValueChip';
import { fromUnixTime, isFuture, isPast } from 'date-fns';
import { CampaignFinishReasons, getCampaignStatus, getCampaignStatusText } from './Campaign';
import { UseDialogProps } from '../hooks/useDialog';
import simpleApiCall from '../hooks/simpleApiCall';
import { history } from '../redux';
import { review } from '../api/endpoints/Api/Tenants/Commissions';

export const CommissionReviewStatusMap: MapValue[] = [
  {
    value: 'approved',
    label: 'Approved',
    color: 'success',
  },
  {
    value: 'rejected',
    label: 'Rejected',
    color: 'error',
  },
  {
    value: 'pending',
    label: 'Pending',
    color: 'primary',
  },
];

export const ListCommissionsColumns = (): DataGridColumns<ShortCommissionView> => [
  {
    field: 'campaign',
    label: 'Campaign',
    renderCellValue: (c) => (
      <Link
        color="textPrimary"
        component={RouterLink}
        to={`/campaigns/${c.campaign.id}`}
      >
        {c.campaign.title}
      </Link>
    ),
  },
  {
    field: 'campaign_participant',
    label: 'Participant',
    renderCellValue: (c) => (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <UserAvatar user={c.campaign_participant.user} size="mini" />
        <Box sx={{ ml: 1 }}>
          <Link
            color="inherit"
            component={RouterLink}
            to={{
              pathname: `/tenant/users/${c.campaign_participant.tenant_user.id}`,
            }}
            variant="subtitle2"
          >
            {c.campaign_participant.user.full_name}
          </Link>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            <Link
              color="textSecondary"
              href={`mailto:${c.campaign_participant.user.email}`}
            >
              {c.campaign_participant.user.email}
            </Link>
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    field: 'approved',
    label: 'Status',
    align: 'right',
    headerAlign: 'center',
    renderCellValue: (commission) => (
      <>
        {commission.approved === true && (
          <StatusLabel
            value={getMapValue(CommissionReviewStatusMap, 'approved')}
            tooltip={i18n.t('Approved {{time}} ago by "{{name}}"', {
              time: timestampFormatDistanceToNowStrict(commission.reviewed_at),
              name: commission.reviewed_by.full_name,
            })}
          />
        )}
        {commission.approved === false && (
          <StatusLabel
            value={getMapValue(CommissionReviewStatusMap, 'rejected')}
            tooltip={i18n.t('Rejected {{time}} ago by "{{name}}"', {
              time: timestampFormatDistanceToNowStrict(commission.reviewed_at),
              name: commission.reviewed_by.full_name,
            })}
          />
        )}
        {commission.approved === null && (
          <StatusLabel
            value={getMapValue(CommissionReviewStatusMap, 'pending')}
          />
        )}
      </>
    ),
    cellProps: {
      sx: {
        width: '1%',
        whiteSpace: 'nowrap',
      },
    },
  },
  {
    field: 'total_payout',
    label: 'Total Payout',
    align: 'right',
    headerAlign: 'right',
    renderCellValue: (c) => (
      <>
        {formatMoneyValue(c.total_payout)}
      </>
    ),
    headerProps: {
      sx: {
        width: '1%',
        whiteSpace: 'nowrap',
      },
    },
    cellProps: {
      sx: {
        width: '1%',
        whiteSpace: 'nowrap',
      },
    },
  },
  {
    field: 'total_conversions',
    label: 'Total Conversions',
    align: 'right',
    headerAlign: 'right',
    renderCellValue: (c) => (
      <>
        {numeral(c.total_conversions)
          .format('0,0')}
      </>
    ),
    cellProps: {
      sx: {
        width: '1%',
        whiteSpace: 'nowrap',
      },
    },
    headerProps: {
      sx: {
        width: '1%',
        whiteSpace: 'nowrap',
      },
    },
  },
  {
    field: 'created_at',
    label: 'Created At',
    renderCellValue: renderCreatedAt,
    align: 'right',
  },
  {
    field: 'updated_at',
    label: 'Updated At',
    renderCellValue: renderUpdatedAt,
    align: 'right',
  },
];

export const ApproveCommissionAction = (reloadData?: () => void): ActionTypes<ShortCommissionView> => ({
  type: 'confirm_click',
  label: 'Approve',
  variant: 'contained',
  color: 'success',
  iconColor: 'success',
  icon: <ThumbUp fontSize="small" />,
  hidden: (c) => c.approved !== null,
  dialog: (): UseDialogProps<ShortCommissionView> => ({
    title: 'Are you sure you want to approve commission?',
    description: 'This commission will be approved and invoice will be created.',
    translationParams: {},
    level: 'warning',
    closeOnOutsideClick: true,
    onConfirm: (c, close) => simpleApiCall(review, {
      onSuccessToastMessage: 'Commission approved successfully',
      translationParams: {},
      onSuccess: () => {
        close();
        if (reloadData !== undefined) {
          reloadData();
        } else {
          history.push('/commissions');
        }
      },
    })({
      id: c.id,
      tenantId: c.tenant.id,
      action: 'approve',
    }),
  }),
});

export const RejectCommissionAction = (reloadData?: () => void): ActionTypes<ShortCommissionView> => ({
  type: 'confirm_click',
  label: 'Reject',
  color: 'error',
  iconColor: 'error',
  variant: 'contained',
  icon: <ThumbDown fontSize="small" />,
  hidden: (c) => c.approved !== null,
  dialog: (): UseDialogProps<ShortCommissionView> => ({
    title: 'Are you sure you want to reject commission?',
    description: 'This commission will be rejected and no invoice will created.',
    translationParams: {},
    level: 'error',
    closeOnOutsideClick: true,
    onConfirm: (c, close) => simpleApiCall(review, {
      onSuccessToastMessage: 'Commission rejected successfully',
      translationParams: {},
      onSuccess: () => {
        close();
        if (reloadData !== undefined) {
          reloadData();
        } else {
          history.push('/commissions');
        }
      },
    })({
      id: c.id,
      tenantId: c.tenant.id,
      action: 'reject',
    }),
  }),
});

export const InvoiceAction: ActionTypes<ShortCommissionView> = {
  type: 'route_link',
  label: 'Invoice',
  color: 'primary',
  variant: 'contained',
  icon: <Receipt fontSize="small" />,
  hidden: (c) => c.invoice === null,
  to: (context) => `/commission/invoices/${context.commission_invoice_id}`,
};

export const ListCommissionsActions = (reloadData: () => void): ActionList => [
  ApproveCommissionAction(reloadData),
  RejectCommissionAction(reloadData),
  InvoiceAction,
  {
    type: 'route_link',
    label: 'Inspect',
    variant: 'contained',
    icon: <Preview fontSize="small" />,
    to: (context) => `/commissions/${context.id}`,
  },
];

export const CommissionDetailsTable: DetailRows<ShortCommissionView> = [
  {
    field: 'approved',
    label: 'Status',
    renderValue: (commission) => (
      <>
        {commission.approved === true && (
          <StatusLabel
            value={getMapValue(CommissionReviewStatusMap, 'approved')}
            tooltip={i18n.t('Approved {{time}} ago by "{{name}}"', {
              time: timestampFormatDistanceToNowStrict(commission.reviewed_at),
              name: commission.reviewed_by.full_name,
            })}
          />
        )}
        {commission.approved === false && (
          <StatusLabel
            value={getMapValue(CommissionReviewStatusMap, 'rejected')}
            tooltip={i18n.t('Rejected {{time}} ago by "{{name}}"', {
              time: timestampFormatDistanceToNowStrict(commission.reviewed_at),
              name: commission.reviewed_by.full_name,
            })}
          />
        )}
        {commission.approved === null && (
          <StatusLabel
            value={getMapValue(CommissionReviewStatusMap, 'pending')}
          />
        )}
      </>
    ),
  },
  {
    field: 'reviewed_by',
    label: 'Reviewed By',
    hidden: (c) => !c.reviewed_by,
    renderValue: (c) => (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <UserAvatar user={c.campaign_participant.user} size="mini" />
        <Box sx={{ ml: 1 }}>
          <Link
            color="inherit"
            component={RouterLink}
            to={{
              pathname: `/tenant/users/${c.campaign_participant.tenant_user.id}`,
            }}
            variant="subtitle2"
          >
            {c.campaign_participant.user.full_name}
          </Link>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            <Link
              color="textSecondary"
              href={`mailto:${c.campaign_participant.user.email}`}
            >
              {c.campaign_participant.user.email}
            </Link>
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    field: 'reviewed_at',
    label: 'Reviewed At',
    renderValue: (c) => timestampFormat(c.reviewed_at),
    hidden: (c) => !c.reviewed_at,
  },
  {
    field: 'created_at',
    label: 'Created At',
    renderValue: renderCreatedAt,
  },
  {
    field: 'total_conversions',
    label: 'Total Conversions',
    renderValue: (c) => (
      <>
        {numeral(c.total_conversions)
          .format('0,0')}
      </>
    ),
  },
  {
    field: 'total_payout',
    label: 'Total Payout',
    renderValue: (c) => (
      <>
        {formatMoneyValue(c.total_payout)}
      </>
    ),
  },
];

export const CommissionCampaignDetailsTable: DetailRows<ShortCommissionView> = [
  {
    field: 'campaign.title',
    label: 'Title',
    renderValue: (c) => (
      <Link
        color="textPrimary"
        component={RouterLink}
        to={`/campaigns/${c.campaign.id}`}
      >
        {c.campaign.title}
      </Link>
    ),
  },
  {
    field: 'campaign.status',
    label: 'Status',
    renderValue: ({ campaign }) => (
      <>
        {campaign.finished_at !== null && (
          <Tooltip
            title={i18n.t('Finished {{time}} ago', { time: timestampFormatDistanceToNowStrict(campaign.finished_at) })}
          >
            <MapValueChip
              color="error" chipProps={{ size: 'small' }}
              value={getMapValue(CampaignFinishReasons, campaign.finish_reason)}
            />
          </Tooltip>
        )}
        {campaign.finished_at === null && (
          <StatusLabel
            value={getCampaignStatus(campaign)}
            tooltip={getCampaignStatusText(campaign).label}
          />
        )}
      </>
    ),
  },
  {
    field: 'campaign.start_at',
    label: 'Starts At',
    renderValue: (c) => (
      <>
        {timestampFormat(c.campaign.start_at)}
        {isFuture(fromUnixTime(c.campaign.start_at)) && (
          <Typography variant="subtitle2">
            {i18n.t('Starts in {{time}}', { time: timestampFormatDistanceToNowStrict(c.campaign.start_at) })}
          </Typography>
        )}
        {isPast(fromUnixTime(c.campaign.start_at)) && (
          <Typography variant="subtitle2">
            {i18n.t('Started {{time}} ago', { time: timestampFormatDistanceToNowStrict(c.campaign.start_at) })}
          </Typography>
        )}
      </>
    ),
  },
  {
    field: 'campaign.end_at',
    label: 'Ends At',
    renderValue: (c) => (
      <>
        {timestampFormat(c.campaign.end_at)}
        {isFuture(fromUnixTime(c.campaign.end_at)) && (
          <Typography variant="subtitle2">
            {i18n.t('Ends in {{time}}', { time: timestampFormatDistanceToNowStrict(c.campaign.end_at) })}
          </Typography>
        )}
        {isPast(fromUnixTime(c.campaign.end_at)) && (
          <Typography variant="subtitle2">
            {i18n.t('Ended {{time}} ago', { time: timestampFormatDistanceToNowStrict(c.campaign.end_at) })}
          </Typography>
        )}
      </>
    ),
  },
  {
    field: 'campaign.created_at',
    label: 'Created At',
    renderValue: (c) => renderCreatedAt(c.campaign),
  },
];

export const CommissionParticipantDetailsTable: DetailRows<ShortCommissionView> = [
  {
    field: 'campaign_participant.user.full_name',
    label: 'Full Name',
  },
  {
    field: 'campaign_participant.user.address1',
    label: 'Address 1',
  },
  {
    field: 'campaign_participant.user.address2',
    label: 'Address 2',
  },
  {
    field: 'campaign_participant.user.zip_code',
    label: 'Zip Code',
  },
  {
    field: 'campaign_participant.user.city',
    label: 'City',
  },
  {
    field: 'campaign_participant.user.country',
    label: 'Country',
  },
  {
    field: 'campaign_participant.user.email',
    label: 'Email Address',
  },
  {
    field: 'campaign_participant.user.telephone',
    label: 'Telephone',
  },
];
