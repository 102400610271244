import type { FormEvent } from 'react';
import { useState } from 'react';
import { Box, Button, Card, FormHelperText, Paper, Radio, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { BaseUserCreateStep } from '../TenantUserCreateWizard';

interface ActionSelectStepProps extends BaseUserCreateStep {
  onNext?: (type: string) => void;
  onBack?: () => void;
}

const typeOptions = [
  {
    description: 'Send email to {{type}} with invite link',
    title: 'Invite',
    value: 'invite',
  },
  {
    description: 'Create {{type}} and fill its details.',
    title: 'Create',
    value: 'create',
  },
];

const ActionSelectStep = (
  {
    onBack,
    onNext,
    userType,
    stepNumber,
    ...other
  }: ActionSelectStepProps,
) => {
  const { t } = useTranslation();
  const [type, setType] = useState<string>(typeOptions[0].value);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleChange = (newType: string): void => {
    setType(newType);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    try {
      setIsSubmitting(true);

      // NOTE: Make API request

      if (onNext) {
        onNext(type);
      }
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      {...other}
    >
      <Card sx={{ p: 3 }}>
        <Typography
          color="textPrimary"
          variant="h6"
        >
          {`${stepNumber} . ${t('Select action')}`}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body1"
        >
          {t('What would you like to do?')}
        </Typography>
        <Box sx={{ mt: 2 }}>
          {typeOptions.map((typeOption) => (
            <Paper
              key={typeOption.title}
              sx={{
                alignItems: 'flex-start',
                display: 'flex',
                mb: 2,
                padding: 2,
              }}
              variant="outlined"
            >
              <Radio
                checked={type === typeOption.value}
                color="primary"
                onClick={(): void => handleChange(typeOption.value)}
              />
              <Box sx={{ ml: 2 }}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {t(typeOption.title)}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body2"
                >
                  {t(typeOption.description, { type: userType })}
                </Typography>
              </Box>
            </Paper>
          ))}
        </Box>
        {error && (
          <Box sx={{ mt: 2 }}>
            <FormHelperText error>
              {error}
            </FormHelperText>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            mt: 6,
          }}
        >
          {onBack && (
            <Button
              color="primary"
              onClick={onBack}
              size="large"
              variant="text"
            >
              {t('Previous')}
            </Button>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Button
            color="primary"
            disabled={isSubmitting}
            type="submit"
            variant="contained"
          >
            {t('Next')}
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default ActionSelectStep;
