import React from 'react';
import ListResourceTable from '../ListResourceTable';
import {
  campaignSelector,
  participantsSelectors,
} from '../../api/endpoints/Api/Tenants/Campaigns';
import { BaseCampaignView } from '../../api/types/App/View/Campaign';
import { DataGridConfig } from '../DataGrid';
import useLoadApiSelectorData from '../../hooks/useLoadApiSelectorData';
import { BaseTenantUserView } from '../../api/types/App/View/TenantUser';
import { AdminFullUserView } from '../../api/types/App/View/User';
import { assertNever } from '../../utils/common';
import { tenantUserSelector } from '../../api/endpoints/Api/Tenants/Users';
import { ShortCommissionInvoiceView } from '../../api/types/App/View/CommissionInvoice';
import { list } from '../../api/endpoints/Api/Tenants/Commission/Invoices';
import {
  ListCommissionInvoicesActions,
  ListCommissionInvoicesColumns,
} from '../../resources/CommissionInvoice';
import i18next from 'i18next';

interface BaseListCommissionConversionsProps {
  initialTab?: string,
  hideTabs?: boolean,
  hideFilters?: boolean,
  title?: string,
}

interface TenantCommissionInvoiceListProps extends BaseListCommissionConversionsProps {
  type: 'tenant_commissions',
  tenantId: string,
}

interface CampaignCommissionInvoiceListProps extends BaseListCommissionConversionsProps {
  type: 'campaign_commissions',
  campaign: BaseCampaignView,
}

interface TenantUserCommissionInvoiceListProps extends BaseListCommissionConversionsProps {
  type: 'tenant_user_commissions',
  tenantUser: BaseTenantUserView,
}

interface UserCommissionInvoiceListProps extends BaseListCommissionConversionsProps {
  type: 'user_commissions',
  user: AdminFullUserView,
}

type ListCommissionInvoicesProps =
  TenantCommissionInvoiceListProps
  | CampaignCommissionInvoiceListProps
  | TenantUserCommissionInvoiceListProps
  | UserCommissionInvoiceListProps;

const ListCommissionInvoices = (props: ListCommissionInvoicesProps) => {
  const {
    type,
    title,
    initialTab = 'all',
    hideFilters = false,
    hideTabs = false,
  } = props;
  const showTenantSelect = type === 'user_commissions';
  const showCampaignSelect = type === 'tenant_commissions' || type === 'tenant_user_commissions' || type === 'user_commissions';
  const showTenantUserSelect = type === 'tenant_commissions';
  const showParticipantSelect = type === 'campaign_commissions';

  let initialTenantId;
  let initialCampaignId = 'all';
  let initialTenantUserId = 'all';

  switch (type) {
    case 'tenant_commissions':

      initialTenantId = props.tenantId;
      break;
    case 'campaign_commissions':

      initialTenantId = props.campaign.tenant.id;
      initialCampaignId = props.campaign.id;
      break;
    case 'tenant_user_commissions':

      initialTenantId = props.tenantUser.tenant.id;
      initialTenantUserId = props.tenantUser.id;
      break;
    case 'user_commissions':

      initialTenantId = props.user.tenants[0]?.id;
      break;
    default:
      assertNever(type);
  }

  const [sort, setSort] = React.useState<string>('recordedAt|desc');
  const [currentTab, setCurrentTab] = React.useState<string>(initialTab);
  const [currentTenantId, setCurrentTenantId] = React.useState<string>(initialTenantId);
  const [currentCampaign, setCurrentCampaign] = React.useState<string>(initialCampaignId);
  const [currentTenantUser, setCurrentTenantUser] = React.useState<string>(initialTenantUserId);
  const [currentParticipant, setCurrentParticipant] = React.useState<string>('all');

  let participants;
  if (showParticipantSelect) {
    const { apiData } = useLoadApiSelectorData(participantsSelectors)({
      tenantId: currentTenantId,
      id: currentCampaign,
    });

    participants = apiData;
  }

  let tenantUsers;
  if (showTenantUserSelect) {
    const { apiData } = useLoadApiSelectorData(tenantUserSelector)({
      tenantId: currentTenantId,
    });

    tenantUsers = apiData;
  }

  let campaigns;
  if (showCampaignSelect) {
    const { apiData } = useLoadApiSelectorData(campaignSelector)({
      tenantId: currentTenantId,
    });

    campaigns = apiData;
  }

  let tenants;
  if (showTenantSelect) {
    tenants = {
      values: props.user.tenants.map((t) => ({
        value: t.id,
        label: t.name,
      }))
    };
  }

  const dataGridConfig: Partial<DataGridConfig<ShortCommissionInvoiceView, string>> = {
    title,
    tableProps: () => ({
      size: 'small',
    }),
    tabs: {
      tabs: [
        {
          label: 'All',
          value: 'all',
        },
        {
          label: 'Paid',
          value: 'paid',
        },
        {
          label: 'Not Paid',
          value: 'not_paid',
        },
      ],
      current: currentTab,
      onChange: (tab) => setCurrentTab(tab.value),
      hidden: hideTabs,
    },
    quickSort: {
      values: [
        {
          label: 'Created At (newest first)',
          value: 'createdAt|desc',
        },
        {
          label: 'Created At (oldest first)',
          value: 'createdAt|asc',
        },
      ],
      current: sort,
      onChange: setSort,
      hidden: hideFilters,
    },
    hideFilters,
    filters: [
      {
        type: 'select',
        config: {
          label: 'Tenant',
          values: [
            ...tenants?.values ?? [],
          ],
          current: currentTenantId,
          onChange: setCurrentTenantId,
          translateValues: false,
          hidden: () => !showTenantSelect || tenants?.values?.length === 0,
        },
      },
      {
        type: 'select',
        config: {
          label: 'Campaign',
          values: [
            {
              value: 'all',
              label: i18next.t('All'),
            },
            ...campaigns?.values ?? [],
          ],
          current: currentCampaign,
          onChange: setCurrentCampaign,
          translateValues: false,
          hidden: () => !showCampaignSelect || campaigns?.values?.length === 0,
        },
      },
      {
        type: 'select',
        config: {
          label: 'User',
          values: [
            {
              value: 'all',
              label: i18next.t('All'),
            },
            ...tenantUsers?.values ?? [],
          ],
          current: currentTenantUser,
          onChange: setCurrentTenantUser,
          translateValues: false,
          hidden: () => !showTenantUserSelect || tenantUsers?.values?.length === 0,
        },
      },
      {
        type: 'select',
        config: {
          label: 'Participant',
          values: [
            {
              label: i18next.t('All'),
              value: 'all',
            },
            ...participants?.values ?? [],
          ],
          current: currentParticipant,
          onChange: setCurrentParticipant,
          translateValues: false,
          hidden: () => !showParticipantSelect || participants?.values?.length === 0,
        },
      },
    ],
  };

  return (
    <ListResourceTable
      endpoint={list} field="invoices" routeParams={{
        tenantId: currentTenantId,
      }} params={{
        sort,
        status: currentTab !== 'all' ? currentTab : undefined,
        participant: currentParticipant !== 'all' ? currentParticipant : undefined,
        campaign: currentCampaign !== 'all' ? currentCampaign : undefined,
        tenant_user: currentTenantUser !== 'all' ? currentTenantUser : undefined,
        user: type === 'user_commissions' ? props.user.id : undefined,
      }}
      dataGridConfig={dataGridConfig}
      perPageOptions={[20, 50, 100]}
      columns={ListCommissionInvoicesColumns()}
      actions={ListCommissionInvoicesActions()}
    />
  );
};

export default ListCommissionInvoices;
