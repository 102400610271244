import React from 'react';
import { Document, Font, Image, Link, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { FullInvoiceView, InvoiceRowView } from '../../../api/types/App/View/Invoice';
import { useTranslation } from 'react-i18next';
import { timestampFormat } from '../../../utils/timestampUtils';
import { formatMoneyValue } from '../../../types/Money';
import numeral from '../../../lib/numeral';

interface InvoicePDFProps {
  invoice: FullInvoiceView,
  renderRowTitle?: (title: string, row: InvoiceRowView) => React.ReactNode,
}

const COL1_WIDTH = 60;
const COLN_WIDTH = (100 - COL1_WIDTH) / 2;

Font.register(
  {
    family: 'ArialUnicode',
    src: '/static/arial-unicode-ms.ttf'
  }
);

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
    padding: 24,
    fontFamily: 'ArialUnicode',
  },
  h4: {
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 1.235,
  },
  h6: {
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1.6,
  },
  subtitle2: {
    fontSize: 10,
    fontWeight: 500,
    lineHeight: 1.57,
  },
  body2: {
    fontSize: 10,
    lineHeight: 1.43,
  },
  gutterBottom: {
    marginBottom: 4,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  brand: {
    height: 50,
  },
  company: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 32,
  },
  references: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 32,
  },
  billing: {
    marginTop: 32,
  },
  items: {
    marginTop: 32,
  },
  notes: {
    marginTop: 32,
  },
  table: {
    display: 'flex',
    width: 'auto',
  },
  tableHeader: {},
  tableBody: {},
  tableRow: {
    borderBottomWidth: 1,
    borderColor: '#EEEEEE',
    borderStyle: 'solid',
    flexDirection: 'row',
  },
  tableCell1: {
    padding: 6,
    width: `${COL1_WIDTH}%`,
  },
  tableCellN: {
    padding: 6,
    width: `${COLN_WIDTH}%`,
  },
  alignRight: {
    textAlign: 'right',
  },
  alignCenter: {
    textAlign: 'center',
  },
});

const InvoicePDF = (props: InvoicePDFProps) => {
  const {
    invoice,
    renderRowTitle = (title) => title,
  } = props;
  const { t } = useTranslation();

  return (
    <Document>
      <Page
        size="A4"
        style={styles.page}
      >
        <View style={styles.header}>
          <Link src="https://www.baller.co.jp">
            <View>
              <Image
                source="/static/logo_dark.png"
                style={styles.brand}
              />
              <Text style={styles.h6}>
                www.baller.co.jp
              </Text>
            </View>
          </Link>
          <View>
            <Text style={styles.h4}>
              {t(invoice.paid === true ? 'Paid' : 'Not Paid')}
            </Text>
            <Text style={styles.subtitle2}>
              {t('Invoice # {{nr}}', { nr: invoice.number })}
            </Text>
            <Text style={styles.subtitle2}>
              {timestampFormat(invoice.created_at, 'PP')}
            </Text>
          </View>
        </View>

        {invoice.paid === true && (
          <View style={styles.references}>
            <View>
              <Text style={[styles.subtitle2, styles.gutterBottom]}>
                {t('Paid At')}
              </Text>
              <Text style={styles.body2}>
                {timestampFormat(invoice.paid_at)}
              </Text>
            </View>
            <View>
              <Text style={[styles.subtitle2, styles.gutterBottom]}>
                {t('Payment Type')}
              </Text>
              <Text style={styles.body2}>
                {invoice.payment_type ?? ' '}
              </Text>
            </View>
            <View>
              <Text style={[styles.subtitle2, styles.gutterBottom]}>
                {t('Payment Reference #')}
              </Text>
              <Text style={styles.body2}>
                {invoice.payment_reference_number ?? ' '}
              </Text>
            </View>
          </View>
        )}
        <View style={styles.references}>
          <View>
            <Text style={[styles.subtitle2, styles.gutterBottom]}>
              {t('Billed to')}
            </Text>
            {invoice.from.name !== '' && (
              <Text style={styles.body2}>
                {invoice.from.name ?? ' '}
              </Text>
            )}
            {invoice.from.address1 !== '' && (
              <Text style={styles.body2}>
                {invoice.from.address1 ?? ' '}
              </Text>
            )}
            {invoice.from.address2 !== '' && (
              <Text style={styles.body2}>
                {invoice.from.address2 ?? ' '}
              </Text>
            )}
            {(invoice.from.zip_code !== '' || invoice.from.city !== '') && (
              <Text style={styles.body2}>
                {`${invoice.from.zip_code ?? ' '} ${invoice.from.city ?? ' '}`}
              </Text>
            )}
            {invoice.from.country !== '' && (
              <Text style={styles.body2}>
                {invoice.from.country ?? ' '}
              </Text>
            )}
            {invoice.from.telephone !== '' && (
              <Text style={styles.body2}>
                <Link src={`tel:${invoice.from.telephone}`}>{invoice.from.telephone ?? ' '}</Link>
              </Text>
            )}
            {invoice.from.email !== '' && (
              <Text style={styles.body2}>
                <Link src={`mailto:${invoice.from.telephone}`}>{invoice.from.email ?? ' '}</Link>
              </Text>
            )}
          </View>
          <View style={{ textAlign: 'right' }}>
            <Text style={[styles.subtitle2, styles.gutterBottom, { display: 'flex', alignSelf: 'flex-end' }]}>
              {t('Receiver')}
            </Text>
            {invoice.to.name !== '' && (
              <Text style={styles.body2}>
                {invoice.to.name ?? ' '}
              </Text>
            )}
            {invoice.to.address1 !== '' && (
              <Text style={styles.body2}>
                {invoice.to.address1 ?? ' '}
              </Text>
            )}
            {invoice.to.address2 !== '' && (
              <Text style={styles.body2}>
                {invoice.to.address2 ?? ' '}
              </Text>
            )}
            {(invoice.to.zip_code !== '' || invoice.to.city !== '') && (
              <Text style={styles.body2}>
                {`${invoice.to.zip_code ?? ' '} ${invoice.to.city ?? ' '}`}
              </Text>
            )}
            {invoice.to.country !== '' && (
              <Text style={styles.body2}>
                {invoice.to.country ?? ' '}
              </Text>
            )}
            {invoice.to.telephone !== '' && (
              <Text style={styles.body2}>
                <Link src={`tel:${invoice.to.telephone}`}>{invoice.to.telephone ?? ' '}</Link>
              </Text>
            )}
            {invoice.to.email !== '' && (
              <Text style={styles.body2}>
                <Link src={`mailto:${invoice.to.telephone}`}>{invoice.to.email ?? ' '}</Link>
              </Text>
            )}
          </View>
        </View>
        <View style={styles.items}>
          <View style={styles.table}>
            <View style={styles.tableHeader}>
              <View style={styles.tableRow}>
                <View style={styles.tableCell1}>
                  <Text style={styles.h6}>
                    {t('Description')}
                  </Text>
                </View>
                <View style={styles.tableCellN}>
                  <Text style={[styles.h6, styles.alignCenter]}>
                    {t('Unit Price')}
                  </Text>
                </View>
                <View style={styles.tableCellN}>
                  <Text style={[styles.h6, styles.alignCenter]}>
                    {t('Quantity')}
                  </Text>
                </View>
                <View style={styles.tableCellN}>
                  <Text style={[styles.h6, styles.alignRight]}>
                    {t('Amount')}
                  </Text>
                </View>
              </View>
            </View>
            <View style={styles.tableBody}>
              {invoice.rows.map((row) => (
                <View
                  style={styles.tableRow}
                  key={row.id}
                >
                  <View style={styles.tableCell1}>
                    <Text style={styles.body2}>
                      {renderRowTitle(row.title, row)}
                    </Text>
                  </View>
                  <View style={styles.tableCellN}>
                    <Text style={[styles.body2, styles.alignCenter]}>
                      {formatMoneyValue(row.price)}
                    </Text>
                  </View>
                  <View style={styles.tableCellN}>
                    <Text style={[styles.body2, styles.alignCenter]}>
                      {numeral(row.count)
                        .format('0,0')}
                    </Text>
                  </View>
                  <View style={styles.tableCellN}>
                    <Text style={[styles.body2, styles.alignRight]}>
                      {formatMoneyValue(row.total)}
                    </Text>
                  </View>
                </View>
              ))}
              <View style={styles.tableRow}>
                <View style={styles.tableCell1} />
                <View style={styles.tableCellN} />
                <View style={styles.tableCellN} />
                <View style={styles.tableCellN}>
                  <Text style={[styles.body2, styles.alignRight]}>
                    {t('Sub-total')}
                  </Text>
                </View>
                <View style={styles.tableCellN}>
                  <Text style={[styles.body2, styles.alignRight]}>
                    {formatMoneyValue(invoice.sub_total)}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCell1} />
                <View style={styles.tableCellN} />
                <View style={styles.tableCellN} />
                <View style={styles.tableCellN}>
                  <Text style={[styles.body2, styles.alignRight]}>
                    {t('Taxes')}
                  </Text>
                </View>
                <View style={styles.tableCellN}>
                  <Text style={[styles.body2, styles.alignRight]}>
                    {formatMoneyValue(invoice.taxes)}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCell1} />
                <View style={styles.tableCellN} />
                <View style={styles.tableCellN} />
                <View style={styles.tableCellN}>
                  <Text style={[styles.body2, styles.alignRight]}>
                    {t('Total')}
                  </Text>
                </View>
                <View style={styles.tableCellN}>
                  <Text style={[styles.body2, styles.alignRight]}>
                    {formatMoneyValue(invoice.total)}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.notes}>
          {invoice.notes !== '' && (
            <>
              <Text style={[styles.h6, styles.gutterBottom]}>
                {t('Notes')}
              </Text>
              <Text style={styles.body2}>
                {invoice.notes}
              </Text>
            </>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default InvoicePDF;
