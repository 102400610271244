import React from 'react';
import { Avatar, Box, Button, Dialog as MUIDialog, Typography } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import { useTranslation } from 'react-i18next';
import { Check, Info } from '@material-ui/icons';

export type DialogLevel = 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';

interface DialogProp {
  title: string,
  description?: string,
  translationParams?: Record<string, string>
  icon?: React.ReactNode,
  cancelButtonText?: string,
  cancelButtonLevel?: DialogLevel,
  confirmButtonText?: string,
  confirmButtonLevel?: DialogLevel,
  level: DialogLevel,
  open?: boolean,
  onClose?: () => void,
  onConfirm?: () => void,
  onCancel?: () => void,
}

const Dialog = (props: DialogProp) => {
  const { t } = useTranslation();
  let {
    icon,
    cancelButtonLevel,
    cancelButtonText,
    confirmButtonText,
    confirmButtonLevel,
  } = props;

  const {
    title,
    description,
    translationParams = {},
    level,
    onClose,
    onConfirm,
    onCancel,
    open,
  } = props;

  cancelButtonLevel = cancelButtonLevel ?? 'success';
  cancelButtonText = cancelButtonText ?? 'Cancel';
  confirmButtonLevel = confirmButtonLevel ?? level;
  confirmButtonText = confirmButtonText ?? 'Confirm';

  if (icon === undefined) {
    switch (level) {
      case 'error':
      case 'warning':
        icon = <WarningIcon />;
        break;
      case 'success':
        icon = <Check />;
        break;
      default:
        icon = <Info />;
        break;
    }
  }

  const handleClose = () => {
    if (onClose !== undefined) {
      onClose();
    }
  };

  const handleCancel = () => {
    if (onCancel !== undefined) {
      onCancel();
    }
  };

  const handleConfirm = () => {
    if (onConfirm !== undefined) {
      onConfirm();
    }
  };

  return (
    <MUIDialog open={open ?? false} onClose={handleClose}>
      <Box
        sx={{
          display: 'flex',
          pb: 2,
          pt: 3,
          px: 3,
        }}
      >
        <Avatar
          sx={{
            backgroundColor: (theme) => alpha(theme.palette[level].main, 0.08),
            color: `${level}.main`,
            mr: 2,
          }}
        >
          {icon}
        </Avatar>
        <Box>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            {t(title, translationParams)}
          </Typography>
          <Typography
            color="textSecondary"
            sx={{ mt: 1 }}
            variant="body2"
          >
            {description && t(description, translationParams)}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          px: 3,
          py: 1.5,
        }}
      >
        <Button
          color={cancelButtonLevel}
          sx={{ mr: 2 }}
          variant="outlined"
          onClick={handleCancel}
        >
          {t(cancelButtonText, translationParams)}
        </Button>
        <Button
          sx={{
            backgroundColor: `${confirmButtonLevel}.main`,
            '&:hover': {
              backgroundColor: `${confirmButtonLevel}.dark`,
            },
          }}
          onClick={handleConfirm}
          variant="contained"
        >
          {t(confirmButtonText, translationParams)}
        </Button>
      </Box>
    </MUIDialog>
  );
};

export default Dialog;
