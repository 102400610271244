import React from 'react';
import { useRouteMatch } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import ListAffiliateGroupsPage from './AffiliateGroup/List';
import CreateAffiliateGroupPage from './AffiliateGroup/Create';
import ShowAffiliateGroupPage from './AffiliateGroup/Show';
import AddAffiliateToGroup from './AffiliateGroup/AddAffiliateToGroup';

const AffiliateGroups = () => {
  const { url: baseURL } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${baseURL}/create`}>
        <CreateAffiliateGroupPage />
      </Route>
      <Route exact path={`${baseURL}/:id`}>
        <ShowAffiliateGroupPage />
      </Route>
      <Route exact path={`${baseURL}/:id/add`}>
        <AddAffiliateToGroup />
      </Route>
      <Route path={`${baseURL}`}>
        <ListAffiliateGroupsPage />
      </Route>
    </Switch>
  );
};

export default AffiliateGroups;
