import React from 'react';
import Page, { PageProps } from '../../components/Page';
import ArrowLeft from '../../icons/ArrowLeft';
import FormikForm from '../../components/FormikForm';
import { create } from '../../api/endpoints/Api/Agencies';
import { clientSelector } from '../../api/endpoints/Api/Clients';
import useAuth from '../../hooks/useAuth';
import { Redirect } from 'react-router-dom';
import useLoadApiSelectorData from '../../hooks/useLoadApiSelectorData';
import { CreateAgencyFormLayout, CreateAgencyFormMap } from '../../resources/Agency';

const CreateAgencyPage = () => {
  const { currentDashboard } = useAuth();

  if (currentDashboard.type !== 'admin') {
    return (<Redirect to="/" />);
  }

  const {
    apiData: clientValues,
  } = useLoadApiSelectorData(clientSelector)();

  const pageProps: PageProps = {
    pageHeaderProps: {
      title: 'Add new agency',
      translationParams: {},
      actions: [
        {
          type: 'route_link',
          to: () => '/agencies',
          label: 'Cancel',
          icon: <ArrowLeft fontSize="small" />,
          variant: 'contained',
          color: 'secondary',
        },
      ],
      breadCrumbs: {
        items: [
          {
            label: 'Dashboard',
            to: '/dashboard',
          },
          {
            label: 'Agencies',
            to: '/agencies',
          },
        ],
      },
    },
    title: 'Add new agency',
  };

  return (
    <Page {...pageProps}>
      <FormikForm
        endpoint={create} formMap={CreateAgencyFormMap(clientValues ?? { values: [] })}
        formLayout={CreateAgencyFormLayout(clientValues ?? { values: [] })} context={{}}
        onSuccessMessage={'Agency "{{name}}" was created successfully!'}
        onSuccessMessageParams={((response) => ({
          name: response.data.name,
        }))}
        onSuccessRedirectTo={(response) => `/agencies/${response.data.id}`}
      />
    </Page>
  );
};

export default CreateAgencyPage;
