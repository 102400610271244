import React from 'react';
import { LoadingButton } from '@material-ui/lab';
import { Save } from '@material-ui/icons';
import importTranslation from '../hooks/ut';
import { useFormikContext } from 'formik';

export interface FormButtonProps extends React.ComponentPropsWithoutRef<typeof LoadingButton> {
  actionDisabled?: boolean,
  isSubmitting?: boolean,
}

const FormButton = (props: FormButtonProps) => {
  const { t } = importTranslation();
  const { isSubmitting: formikIsSubmitting } = useFormikContext();

  const {
    children = t('Save'),
    loadingPosition = 'start',
    startIcon = <Save />,
    color = 'primary',
    variant = 'contained',
    type = 'submit',
    sx = { mx: 1 },
    actionDisabled = false,
    isSubmitting = false,
    disabled: disabledProp = false,
    ...other
  } = props;

  const submitting = isSubmitting || formikIsSubmitting;
  const disabled = submitting || disabledProp;

  return (
    <LoadingButton
      loading={submitting}
      loadingPosition={loadingPosition}
      startIcon={startIcon}
      color={color}
      disabled={disabled}
      type={type}
      variant={variant}
      sx={sx}
      onClick={(e) => (actionDisabled === true ? e.preventDefault() : true)}
      {...other}
    >
      {children}
    </LoadingButton>
  );
};

export default FormButton;
