import { ListTenantUserGroupRequestParams, CreateTenantUserGroupRequestBody, AddTenantUserToUserGroupRequestBody } from '../../../../types/App/Request/TenantUserGroup';
import { ListTenantUserGroupView, FullTenantUserGroupView } from '../../../../types/App/View/TenantUserGroup';
import { apiEndpoint, NoBody, StandardErrors, NoParams } from '../../../../client';
import { ListSelectorView } from '../../../../types/App/View/Common';
import { SuccessView } from '../../../../types/App/View';
import { FullTenantUserView } from '../../../../types/App/View/TenantUser';

export const list = apiEndpoint<ListTenantUserGroupView, ListTenantUserGroupRequestParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/userGroups');

export const tenantUserGroupSelector = apiEndpoint<ListSelectorView, NoParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/userGroups/selector');

export const show = apiEndpoint<FullTenantUserGroupView, NoParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/userGroups/{id}');

export const create = apiEndpoint<FullTenantUserGroupView, NoParams, CreateTenantUserGroupRequestBody, StandardErrors>('POST')('/api/tenants/{tenantId}/userGroups');

export const update = apiEndpoint<FullTenantUserGroupView, NoParams, CreateTenantUserGroupRequestBody, StandardErrors>('PUT')('/api/tenants/{tenantId}/userGroups/{id}');

export const remove = apiEndpoint<SuccessView, NoParams, NoBody, StandardErrors>('DELETE')('/api/tenants/{tenantId}/userGroups/{id}');

export const removeUser = apiEndpoint<SuccessView, NoParams, NoBody, StandardErrors>('DELETE')('/api/tenants/{tenantId}/userGroups/{id}/user/{userId}');

export const addUser = apiEndpoint<FullTenantUserView, NoParams, AddTenantUserToUserGroupRequestBody, StandardErrors>('PUT')('/api/tenants/{tenantId}/userGroups/{id}/addUser');
