import { Path, PathHuman } from '../../types/Common';
import { TableCell, TableCellProps } from '@material-ui/core';
import { ReactElement, ReactNode } from 'react';
import { calculateProps, ModifiableComponentProps } from '../../utils/propHelpers';
import _ from 'lodash';

export interface DataGridColumn<DataType = any, Fields = Path<DataType>, Labels = PathHuman<DataType>> {
  field: Fields | string,
  label: Labels | string,
  align?: TableCellProps['align'],
  headerAlign?: TableCellProps['align'],
  renderCell?: (data: DataType, props: TableCellProps) => ReactElement,
  renderCellValue?: (data: DataType) => ReactNode,
  renderHeader?: (props: TableCellProps) => ReactElement,
  renderHeaderValue?: () => ReactElement,
  cellProps?: ModifiableComponentProps<typeof TableCell>,
  headerProps?: ModifiableComponentProps<typeof TableCell>,
  valueGetter?: (data: DataType) => any,
  hidden?: () => boolean,
}

export type DataGridColumns<DataType = any> = DataGridColumn<DataType>[];

interface ColumnProps<DataType> {
  children: {
    config: DataGridColumn<DataType>,
    data: DataType,
  },
}

export function columnGetValue<DataType>(data: DataType, column: DataGridColumn<DataType>) {
  if (column.valueGetter !== undefined) {
    return column.valueGetter(data);
  }

  return _.get(data, column.field);
}

const Column = <DataType, >(props: ColumnProps<DataType>): ReactElement => {
  const { children } = props;
  const {
    config,
    data,
  } = children;

  const cellProps = calculateProps(config.cellProps, {
    align: config.align,
  });

  if (config.renderCell !== undefined) {
    return config.renderCell(data, cellProps);
  }

  let value;
  if (config.renderCellValue !== undefined) {
    value = config.renderCellValue(data);
  } else {
    value = columnGetValue<DataType>(data, config);
  }

  return (
    <TableCell {...cellProps}>
      {value}
    </TableCell>
  );
};

export default Column;
