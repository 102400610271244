import type { ReactNode } from 'react';
import React from 'react';
import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import type { ListProps } from '@material-ui/core';
import { List, ListSubheader } from '@material-ui/core';
import NavItem from './NavItem';
import useQueryParams from '../hooks/useQueryParams';
import { useTranslation } from 'react-i18next';

interface Item {
  path?: string;
  url?: string;
  icon?: ReactNode;
  info?: ReactNode;
  exact?: boolean,
  strict?: boolean,
  children?: Item[];
  title: string;
  queryMatch?: (queryParams: URLSearchParams) => boolean,
}

interface NavSectionProps extends ListProps {
  items: Item[];
  pathname: string;
  title: string;
}

const renderNavItems = (
  {
    depth = 0,
    items,
    pathname,
  }: {
    items: Item[];
    pathname: string;
    depth?: number;
  },
): JSX.Element => (
  <List disablePadding>
    {items.reduce(
      // eslint-disable-next-line @typescript-eslint/no-use-before-define, no-use-before-define
      (acc, item) => reduceChildRoutes({
        acc,
        item,
        pathname,
        depth,
      }),
      [],
    )}
  </List>
);

const reduceChildRoutes = (
  {
    acc,
    pathname,
    item,
    depth,
  }: {
    acc: JSX.Element[];
    pathname: string;
    item: Item;
    depth: number;
  },
): Array<JSX.Element> => {
  const { t } = useTranslation();
  const key = `${item.title}-${depth}`;
  let exactMatch = item.path ? !!matchPath(pathname, {
    path: item.path,
    exact: item.exact ?? true,
    strict: item.strict ?? true,
  }) : false;

  let queryMatch = true;
  if (item.queryMatch !== undefined) {
    const query = useQueryParams();

    if (item.queryMatch(query) === false) {
      queryMatch = false;
    }
  }

  if (item.children) {
    let partialMatch = item.path ? !!matchPath(pathname, {
      path: item.path,
      exact: item.exact ?? true,
      strict: item.strict ?? true,
    }) : false;

    if (queryMatch === false) {
      partialMatch = false;
    }

    acc.push(
      <NavItem
        active={partialMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.url ?? item.path}
        title={t(item.title)}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          pathname,
        })}
      </NavItem>,
    );
  } else {
    if (queryMatch === false) {
      exactMatch = false;
    }

    acc.push(
      <NavItem
        active={exactMatch}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.url ?? item.path}
        title={t(item.title)}
      />,
    );
  }

  return acc;
};

const NavSection = (props: NavSectionProps) => {
  const { t } = useTranslation();
  const {
    items,
    pathname,
    title,
    ...other
  } = props;

  return (
    <List
      subheader={(
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: 'text.primary',
            fontSize: '0.75rem',
            lineHeight: 2.5,
            fontWeight: 700,
            textTransform: 'uppercase',
          }}
        >
          {t(title)}
        </ListSubheader>
      )}
      {...other}
    >
      {renderNavItems({
        items,
        pathname,
      })}
    </List>
  );
};

// eslint-disable-next-line
const LazyItemShape = () => ItemShape;
// eslint-disable-next-line
const ItemShape = PropTypes.shape({
  path: PropTypes.string,
  icon: PropTypes.node,
  info: PropTypes.node,
  children: PropTypes.arrayOf(LazyItemShape),
  title: PropTypes.string.isRequired,
});

NavSection.propTypes = {
  items: PropTypes.arrayOf(ItemShape),
  pathname: PropTypes.string,
  title: PropTypes.string,
};

NavSection.defaultProps = {
  items: [],
  pathname: null,
  title: null,
};

export default NavSection;
