import React from 'react';
import { getFirstMapValue, getMapValue, MapValue } from '../types/MapValues';
import {
  Tab as MUITab,
  TabProps as MUITabProps,
  Tabs as MUITabs,
  TabsProps as MUITabsProps,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CalcType, parseCalcType } from '../types/CalculatedType';

export interface Tab<ContextType = any> extends MapValue {
  tabProps?: MUITabProps,
  hidden?: CalcType<boolean, ContextType>,
}

export interface TabsProp<ContextType = any> {
  tabs: Tab<ContextType>[],
  currentTab?: Tab<ContextType>,
  tabsProps?: MUITabsProps,
  onTabChange?: (value: Tab) => void,
  renderTab?: (tab: Tab, props: MUITabProps) => React.ElementType,
  context?: ContextType
}

const Tabs = <ContextType extends any = any, >(props: TabsProp<ContextType>) => {
  const {
    tabs,
    currentTab: initialCurrentTab,
    onTabChange,
    tabsProps,
    renderTab,
    context,
  } = props;
  const { t } = useTranslation();
  const [currentTab, setCurrentTab] = React.useState<Tab<ContextType>>(initialCurrentTab ?? getFirstMapValue(tabs));

  React.useEffect(() => setCurrentTab(initialCurrentTab), [initialCurrentTab]);

  const handleTabsChange = (e, value) => {
    const tab = getMapValue(tabs, value);
    setCurrentTab(tab);
    onTabChange?.(tab);
  };

  const muiTabsProps: MUITabsProps = {
    indicatorColor: 'primary',
    onChange: handleTabsChange,
    scrollButtons: 'auto',
    textColor: 'primary',
    value: currentTab.value,
    variant: 'scrollable',
    ...tabsProps,
  };

  return (
    <MUITabs
      {...muiTabsProps}
    >
      {tabs.filter((tab) => parseCalcType(tab.hidden, context) !== true).map((tab) => {
        const tabProps: MUITabProps = {
          key: tab.value,
          label: t(tab.label ?? tab.value),
          value: tab.value,
          icon: tab.icon,
        };

        if (renderTab !== undefined) {
          return renderTab(tab, tabProps);
        }

        return <MUITab {...tabProps} />;
      })}
    </MUITabs>
  );
};

export default Tabs;
