import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import Logo from '../../components/Logo';
import gtm from '../../lib/gtm';
import { useTranslation } from 'react-i18next';
import useLoadApiData, { UseLoadApiDataOptionsFor } from '../../hooks/useLoadApiData';
import { useHistory, useParams } from 'react-router';
import { finishInvite, getInvite } from '../../api/endpoints/Api/User/Me';
import FormikForm from '../../components/FormikForm';
import { FinishInviteFormLayout, FinishInviteFormMap } from '../../resources/UserMe';
import { login } from '../../redux/slices/User';
import { useDispatch } from '../../redux';
import Page, { PageProps } from '../../components/Page';

const InviteUserPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { inviteCode } = useParams<{ inviteCode: string }>();

  const onError: UseLoadApiDataOptionsFor<typeof getInvite>['onError'] = (errors) => {
    if (errors[0].code === 404) {
      history.push('/');
    }
  };

  const { apiData } = useLoadApiData(getInvite, { onError })({ code: inviteCode });

  React.useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  if (apiData === null) {
    return null;
  }

  const pageProps: PageProps = {
    compactWidth: 'md',
    title: 'Accept Invitation',

  };

  return (
    <Page {...pageProps}>
      <Box
        sx={{ py: '80px' }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: 8,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 100,
                width: 300,
              }}
            />
          </RouterLink>
        </Box>
        <Card>
          <CardContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              p: 4,
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'space-between',
                mb: 2,
              }}
            >
              <div>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h4"
                >
                  {t('Accept Invitation')}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body2"
                >
                  {t('You have been invited to join Baller platform')}
                </Typography>
              </div>
            </Box>
            <Box
              sx={{
                flexGrow: 1,
                mt: 0,
              }}
            >
              <FormikForm
                endpoint={finishInvite} formMap={FinishInviteFormMap(apiData.email)}
                formLayout={FinishInviteFormLayout(apiData.email)} context={{}}
                onSuccessMessage="You successfully registered!"
                onSuccessRedirectTo={() => '/'}
                onSuccess={(response, values) => {
                  dispatch(login(response.data.email, values.password));
                }}
                routeParams={{ code: inviteCode }}
              />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Page>
  );
};

export default InviteUserPage;
