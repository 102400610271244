import {
  ApiEndpointBodyType,
  ApiEndpointParamsType,
  ApiEndpointRouteParamsType,
  ApiEndpointTypeAny,
  GetResponseErrorViewType,
  GetResponseViewType,
  isResponseSuccess,
} from '../api/client';
import React from 'react';
import NProgress from 'nprogress';
import { JsonErrorView } from '../api/types/App/Response';
import { history } from '../redux';
import _ from 'lodash';

export type UseLoadApiDataOptionsFor<T extends ApiEndpointTypeAny> = UseLoadApiDataOptions<T>;

export interface UseLoadApiDataOptions<T extends ApiEndpointTypeAny> {
  showProgress?: boolean,
  additionalDeps?: any[],
  onSuccess?: (data: GetResponseViewType<T>) => void,
  onError?: (errors: JsonErrorView<GetResponseErrorViewType<T>>[]) => void,
  onNotFound?: () => void,
  onServerError?: (error) => void,
}

const useLoadApiData = <T extends ApiEndpointTypeAny>(
  endpoint: T,
  options: UseLoadApiDataOptions<T> = {},
) => (
    initialRouteParams: ApiEndpointRouteParamsType<T> = {},
    initialParams: ApiEndpointParamsType<T> = {},
    initialBody: ApiEndpointBodyType<T> = {},
  ) => {
    const [routeParams, setRouteParams] = React.useState<ApiEndpointRouteParamsType<T>>(initialRouteParams);
    const [params, setParams] = React.useState<ApiEndpointParamsType<T>>(initialParams);
    const [body, setBody] = React.useState<ApiEndpointBodyType<T>>(initialBody);
    const [apiData, setApiData] = React.useState<GetResponseViewType<T> | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    if (options.additionalDeps === undefined) {
      const [additionalDeps] = React.useState<any[]>([]);
      options.additionalDeps = additionalDeps;
    }

    options.showProgress ??= true;
    options.onNotFound ??= () => history.push('/404');

    React.useEffect(() => {
      if (_.isEqual(params, initialParams) === false) {
        setParams(initialParams);
      }
    }, [initialParams]);

    const loadApiData = React.useCallback(() => {
      (async () => {
        try {
          setIsLoading(true);
          if (options.showProgress === true) {
            NProgress.start();
          }

          const result = await endpoint(routeParams, params, body);
          setIsLoading(false);
          if (isResponseSuccess(result)) {
            setApiData(result.data);

            options.onSuccess?.(result.data);
          } else if (result.errors[0]?.code === 404) {
            options.onNotFound?.();
          } else {
            options.onError?.(result.errors);
          }

          if (options.showProgress === true) {
            NProgress.done();
          }
        } catch (e) {
          if (e instanceof Error && e.message.includes('is not provided for route')) {
            throw e;
          }
          if (options.showProgress === true) {
            NProgress.done();
          }

          options.onServerError?.(e);
        }
      })();
    }, [routeParams, params, body, options.additionalDeps]);

    React.useEffect(() => {
      loadApiData();
    }, [loadApiData]);

    return {
      apiData,
      isLoading,
      setApiData,
      setRouteParams,
      setParams,
      setBody,
      loadApiData,
    };
  };

export default useLoadApiData;
