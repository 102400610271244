import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import i18next from 'i18next';
import type { AppThunk } from '../index';
import { UserMeView } from '../../api/types/App/View/User';
import { apiClient, isResponseSuccess } from '../../api/client';
import { getToken, removeToken, setToken } from '../../api/authToken';
import * as store from 'store';
import { login as apiLogin } from '../../api/endpoints/Api/Security';
import { show, updateLocale } from '../../api/endpoints/Api/User/Me';
import { BaseTenantView } from '../../api/types/App/View/Tenant';
import { UserMeTenantUserView } from '../../api/types/App/View/TenantUser';
import { BaseAgencyView } from '../../api/types/App/View/Agency';

export type InitializeAction = {
  isAuthenticated: boolean;
  user: UserMeView | null;
  accessToken: string;
};

export type LoginAction = {
  user: UserMeView;
  accessToken: string;
};
export type UpdateUserAction = {
  user: UserMeView;
};

export type ChooseDashboard = {
  dashboard: Dashboard;
};

export type ChangeLocaleAction = {
  user: UserMeView;
};

export const DashboardType = {
  Admin: 'admin',
  SuperAdmin: 'super_admin',
  AgencyAdmin: 'agency_admin',
  TenantAdmin: 'tenant_admin',
  TenantUser: 'tenant_user',
  UserTenantAdmin: 'user_tenant_admin',
  UserTenantUser: 'user_tenant_user',
} as const;

export type DashboardTypeValues =
  typeof DashboardType[keyof typeof DashboardType];

export interface Dashboard {
  type: DashboardTypeValues;
  apc: string | null;
  id: string;
  title: string;
  role: string;
  logo: string;
  tenantUser?: UserMeTenantUserView;
  tenant?: BaseTenantView;
  agency?: BaseAgencyView;
}

export interface UserState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  accessToken: string | null;
  user: UserMeView | null;
  dashboards: Dashboard[];
  currentDashboard: Dashboard | null;
}

const initialState: UserState = {
  isInitialized: false,
  isAuthenticated: false,
  accessToken: getToken(),
  user: null,
  dashboards: [],
  currentDashboard: null,
};

const generateDashboards = (state: UserState, user: UserMeView): void => {
  const availableDashboards = [];

  if (user.roles.includes('ROLE_ADMIN')) {
    availableDashboards.push({
      id: 'admin',
      apc: '#e7ae29',
      logo: 'A',
      title: 'Admin Dashboard',
      role: 'Admin',
      type: DashboardType.Admin,
    });
  }
  //
  // if (user.roles.includes('ROLE_SUPER_ADMIN')) {
  //   availableDashboards.push(
  //     {
  //       id: 'super_admin',
  //       apc: null,
  //       logo: 'SA',
  //       title: 'Super Admin Dashboard',
  //       role: 'Super Admin',
  //       type: DashboardType.SuperAdmin,
  //     },
  //   );
  // }

  user.owned_tenants.forEach((tenant) =>
    availableDashboards.push({
      id: tenant.id,
      apc: tenant.apc,
      logo: tenant.name[0],
      title: `${tenant.name}`,
      role: 'Manager',
      type: tenant.user_agency
        ? DashboardType.UserTenantAdmin
        : DashboardType.TenantAdmin,
      tenant,
    })
  );

  user.owned_agencies.forEach((agency) =>
    availableDashboards.push({
      id: agency.id,
      apc: agency.apc,
      logo: agency.name[0],
      title: agency.name,
      role: 'Agency Manager',
      type: DashboardType.AgencyAdmin,
      agency,
    })
  );

  user.tenant_users.forEach((tenantUser) => {
    if (
      tenantUser.roles.includes('ROLE_ADMIN') &&
      availableDashboards.filter(
        (d) =>
          d.id === tenantUser.tenant.id && d.type === DashboardType.TenantAdmin
      ).length === 0
    ) {
      availableDashboards.push({
        id: tenantUser.tenant.id,
        apc: tenantUser.tenant.apc,
        logo: tenantUser.tenant.name[0],
        title: tenantUser.tenant.name,
        role: 'Manager',
        type: tenantUser.tenant.user_agency
          ? DashboardType.UserTenantAdmin
          : DashboardType.TenantAdmin,
        tenant: tenantUser.tenant,
        tenantUser,
      });
    }

    if (tenantUser.roles.includes('ROLE_PARTICIPANT')) {
      availableDashboards.push({
        id: tenantUser.tenant.id,
        apc: tenantUser.tenant.apc,
        logo: tenantUser.tenant.name[0],
        title: tenantUser.tenant.name,
        role: 'Affiliate',
        type: tenantUser.tenant.user_agency
          ? DashboardType.UserTenantUser
          : DashboardType.TenantUser,
        tenant: tenantUser.tenant,
        tenantUser,
      });
    }
  });

  user.agency_users.forEach((agencyUser) => {
    if (
      agencyUser.roles.includes('ROLE_ADMIN') &&
      availableDashboards.filter(
        (d) =>
          d.id === agencyUser.agency.id && d.type === DashboardType.AgencyAdmin
      ).length === 0
    ) {
      availableDashboards.push({
        id: agencyUser.agency.id,
        apc: agencyUser.agency.apc,
        logo: agencyUser.agency.name[0],
        title: agencyUser.agency.name,
        role: 'Agency Manager',
        type: DashboardType.AgencyAdmin,
        agency: agencyUser.agency,
      });
    }
  });

  if (availableDashboards.length === 1) {
    const [dashboard] = availableDashboards;
    state.currentDashboard = dashboard;
    state.dashboards.push(dashboard);
    store.set('currentDashboard', `${dashboard.type}.${dashboard.id}`);
  } else {
    const priorityOrder: DashboardTypeValues[] = [
      DashboardType.SuperAdmin,
      DashboardType.Admin,
      DashboardType.AgencyAdmin,
      DashboardType.TenantAdmin,
      DashboardType.UserTenantAdmin,
      DashboardType.UserTenantUser,
      DashboardType.TenantUser,
    ];

    priorityOrder.forEach((type) => {
      availableDashboards.forEach((dashboard) => {
        if (dashboard.type === type) {
          state.dashboards.push(dashboard);
        }
      });
    });
  }
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    initialize(state: UserState, action: PayloadAction<InitializeAction>) {
      const { isAuthenticated, accessToken, user } = action.payload;

      state.isAuthenticated = isAuthenticated;
      state.accessToken = accessToken;
      state.user = user;
      state.isInitialized = true;

      apiClient.setAccessToken(accessToken);

      if (user) {
        generateDashboards(state, user);
        setTimeout(() => {
          i18next.changeLanguage(user.locale);
        });

        const storedDashboard = store.get('currentDashboard', false);
        if (storedDashboard !== false) {
          const [dashboardType, dashboardId] = storedDashboard.split('.');
          state.currentDashboard = state.dashboards.find(
            (d) => d.id === dashboardId && d.type === dashboardType
          );
        }
      }
    },
    login(state: UserState, action: PayloadAction<LoginAction>) {
      const { user, accessToken } = action.payload;

      state.user = user;
      state.accessToken = accessToken;
      state.isAuthenticated = true;

      apiClient.setAccessToken(accessToken);
      setToken(accessToken);
      setTimeout(() => {
        i18next.changeLanguage(user.locale);
      });

      generateDashboards(state, user);
    },
    updateUser(state: UserState, action: PayloadAction<UpdateUserAction>) {
      const { user } = action.payload;

      state.user = user as UserMeView;

      generateDashboards(state, user);
    },
    logout(state: UserState) {
      state.isAuthenticated = false;
      state.accessToken = null;
      state.user = null;
      state.dashboards = [];
      state.currentDashboard = null;
      apiClient.setAccessToken(null);
      removeToken();
      store.remove('currentDashboard');
    },
    chooseDashboard(state: UserState, action: PayloadAction<ChooseDashboard>) {
      state.currentDashboard = action.payload.dashboard;
      if (action.payload.dashboard === null) {
        store.remove('currentDashboard');
      } else {
        store.set(
          'currentDashboard',
          `${action.payload.dashboard.type}.${action.payload.dashboard.id}`
        );
      }
    },
    changeLocale(state: UserState, action: PayloadAction<ChangeLocaleAction>) {
      const { user } = action.payload;

      state.user = user;
    },
  },
});

export const initialize =
  (): AppThunk =>
  async (dispatch, getState): Promise<void> => {
    try {
      apiClient.setAccessToken(getState().user.accessToken);
      const response = await show({}, {}, {});

      if (response.status === 'okay') {
        dispatch(
          slice.actions.initialize({
            isAuthenticated: true,
            user: response.data.user,
            accessToken: response.data.security.token,
          })
        );
      } else {
        dispatch(
          slice.actions.initialize({
            isAuthenticated: false,
            user: null,
            accessToken: null,
          })
        );
      }
    } catch (e) {
      console.log(e);
      dispatch(
        slice.actions.initialize({
          isAuthenticated: false,
          user: null,
          accessToken: null,
        })
      );
    }
  };

export const login =
  (email: string, password: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await apiLogin(
      {},
      {},
      {
        email,
        password,
      }
    );

    if (isResponseSuccess(response) !== true) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw response.errors;
    }

    const action: LoginAction = {
      user: response.data.user,
      accessToken: response.data.security.token,
    };

    dispatch(slice.actions.login(action));
  };
export const updateUser =
  (user: UserMeView): AppThunk =>
  async (dispatch): Promise<void> => {
    const action: UpdateUserAction = {
      user,
    };

    dispatch(slice.actions.updateUser(action));
  };

export const logout =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.logout());
    dispatch(push('/'));
  };

export const chooseDashboard =
  (dashboard: Dashboard): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.chooseDashboard({ dashboard }));
  };

export const changeLocale =
  (locale: string): AppThunk =>
  async (dispatch): Promise<void> => {
    const meResponse = await updateLocale({}, {}, { locale });

    const action: ChangeLocaleAction = {
      user: meResponse.data,
    };

    dispatch(slice.actions.changeLocale(action));
  };

export const { reducer } = slice;

export default slice;
