import React from 'react';
import Page, { PageProps } from '../../../components/Page';
import ArrowLeft from '../../../icons/ArrowLeft';
import FormikForm from '../../../components/FormikForm';
import useAuth from '../../../hooks/useAuth';
import { Redirect } from 'react-router-dom';
import { DashboardType } from '../../../redux/slices/User';
import { find } from '../../../api/endpoints/Api/Tenants/Contracts';
import {
  FindContractFormLayout,
  FindContractFormMap,
} from '../../../resources/TenantContract';

const FindContractPage = () => {
  const { currentDashboard } = useAuth();

  if (currentDashboard.type !== DashboardType.TenantAdmin) {
    return <Redirect to="/" />;
  }

  const pageProps: PageProps = {
    pageHeaderProps: {
      title: 'Find Contract',
      translationParams: {},
      actions: [
        {
          type: 'route_link',
          to: () => '/connections/contracts',
          label: 'Cancel',
          icon: <ArrowLeft fontSize="small" />,
          variant: 'contained',
          color: 'secondary',
        },
      ],
      breadCrumbs: {
        items: [
          {
            label: 'Dashboard',
            to: '/dashboard',
          },
          {
            label: 'Contracts',
            to: '/connections/contracts',
          },
        ],
      },
    },
    title: 'Find Contract',
  };

  return (
    <Page {...pageProps}>
      <FormikForm
        endpoint={find}
        formMap={FindContractFormMap()}
        formLayout={FindContractFormLayout()}
        context={{}}
        onSuccessMessage="Contract {{title}} was found!"
        onSuccessMessageParams={(response) => ({
          title: response.data.title,
        })}
        onSuccessRedirectTo={(response) =>
          `/connections/contracts/${response.data.id}`
        }
        routeParams={{ tenantId: currentDashboard.id }}
      />
    </Page>
  );
};
export default FindContractPage;
