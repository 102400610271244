import React from 'react';
import ListResourceTable from '../ListResourceTable';
import { DataGridConfig } from '../DataGrid';
import { assertNever } from '../../utils/common';
import { ShortCommissionInvoiceView } from '../../api/types/App/View/CommissionInvoice';
import { list } from '../../api/endpoints/Api/Tenants/Contract/Invoices';
import {
  ListTenantContractInvoicesActions,
  ListTenantContractInvoicesColumns,
} from '../../resources/TenantContractInvoice';
import useAuth from '../../hooks/useAuth';

interface BaseListContractInvoicesProps {
  initialTab?: string;
  hideTabs?: boolean;
  hideFilters?: boolean;
  title?: string;
}

interface TenantContractInvoicesListProps
  extends BaseListContractInvoicesProps {
  type: 'user_agency_invoices';
  tenantId: string;
}

interface TenantContractClientInvoicesListProps
  extends BaseListContractInvoicesProps {
  type: 'user_agency_client_invoices';
  tenantId: string;
}

type ListTenantContractInvoicesProps =
  | TenantContractInvoicesListProps
  | TenantContractClientInvoicesListProps;

const ListTenantContractInvoices = (props: ListTenantContractInvoicesProps) => {
  const { currentDashboard } = useAuth();
  const {
    type,
    title,
    initialTab = 'all',
    hideFilters = false,
    hideTabs = false,
  } = props;
  // const showTenantSelect = type === 'user_agency_invoices' || type === 'user_agency_client_invoices';

  let initialTenantId;

  switch (type) {
    case 'user_agency_invoices':
      initialTenantId = props.tenantId;
      break;
    case 'user_agency_client_invoices':
      initialTenantId = props.tenantId;
      break;
    default:
      assertNever(type);
  }

  const [sort, setSort] = React.useState<string>('recordedAt|desc');
  const [currentTab, setCurrentTab] = React.useState<string>(initialTab);
  const [currentTenantId] = React.useState<string>(initialTenantId);

  const dataGridConfig: Partial<
    DataGridConfig<ShortCommissionInvoiceView, string>
  > = {
    title,
    tableProps: () => ({
      size: 'small',
    }),
    tabs: {
      tabs: [
        {
          label: 'All',
          value: 'all',
        },
        {
          label: 'Paid',
          value: 'paid',
        },
        {
          label: 'Not Paid',
          value: 'not_paid',
        },
      ],
      current: currentTab,
      onChange: (tab) => setCurrentTab(tab.value),
      hidden: hideTabs,
    },
    quickSort: {
      values: [
        {
          label: 'Created At (newest first)',
          value: 'createdAt|desc',
        },
        {
          label: 'Created At (oldest first)',
          value: 'createdAt|asc',
        },
      ],
      current: sort,
      onChange: setSort,
      hidden: hideFilters,
    },
    hideFilters,
    filters: [],
  };

  return (
    <ListResourceTable
      endpoint={list}
      field="invoices"
      routeParams={{
        tenantId: currentTenantId,
      }}
      params={{
        sort,
        status: currentTab !== 'all' ? currentTab : undefined,
        // client: type === 'user_agency_invoices' ? props.tenantId : undefined,
      }}
      dataGridConfig={dataGridConfig}
      perPageOptions={[20, 50, 100]}
      columns={ListTenantContractInvoicesColumns(currentDashboard)}
      actions={ListTenantContractInvoicesActions()}
    />
  );
};

export default ListTenantContractInvoices;
