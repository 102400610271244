import type { DropzoneOptions } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';
import {
  Box,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import DuplicateIcon from '../icons/Duplicate';
import XIcon from '../icons/X';
import bytesToSize from '../utils/bytesToSize';
import { useTranslation } from 'react-i18next';
import LinearProgressWithLabel from './LinearProgressWithLabel';
import FormButton from './FormButton';

export interface FileObject {
  file: File,
  uploaded: boolean,
  uploadedFileId: string | null,
  isUploading: boolean,
  isRemoving: boolean,
  progress: number | null,
}

interface FileDropzoneProps extends DropzoneOptions {
  files: FileObject[];
  onRemove: (file: any) => void;
  onRemoveAll: () => void;
  onUpload: () => void;
  uploadAvailable: boolean,
  isUploading?: boolean,
  isRemoving?: boolean,
}

const FileDropzone = (props: FileDropzoneProps) => {
  const {
    isUploading = false,
    isRemoving = false,
    accept,
    disabled,
    files,
    uploadAvailable = files.find((file) => file.uploaded === false) !== undefined,
    getFilesFromEvent,
    maxFiles,
    maxSize,
    minSize,
    noClick,
    noDrag,
    noDragEventsBubbling,
    noKeyboard,
    onDrop,
    onDropAccepted,
    onDropRejected,
    onFileDialogCancel,
    onRemove,
    onRemoveAll,
    onUpload,
    preventDropOnDocument,
    ...other
  } = props;

  const { t } = useTranslation();

  // We did not add the remaining props to avoid component complexity
  // but you can simply add it if you need to.
  const {
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept,
    maxFiles,
    maxSize,
    minSize,
    onDrop,
  });

  return (
    <div {...other}>
      <Box
        sx={{
          alignItems: 'center',
          border: 1,
          borderRadius: 1,
          borderColor: 'divider',
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          outline: 'none',
          p: 6,
          ...(
            isDragActive && {
              backgroundColor: 'action.active',
              opacity: 0.5,
            }
          ),
          '&:hover': {
            backgroundColor: 'action.hover',
            cursor: 'pointer',
            opacity: 0.5,
          },
        }}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <Box
          sx={{
            '& img': {
              width: 100,
            },
          }}
        >
          <img
            alt="Select file"
            src="/static/undraw_add_file2_gvbb.svg"
          />
        </Box>
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="h6"
          >
            {t(`Select file${(
              maxFiles && maxFiles === 1
            ) ? '' : 's'}`)}
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Typography
              color="textPrimary"
              variant="body1"
            >
              {t(`Drop file${(
                maxFiles && maxFiles === 1
              ) ? '' : 's'}`)}
              {' '}
              <Link
                color="primary"
                underline="always"
              >
                {t('browse')}
              </Link>
              {' '}
              {t('thorough your machine')}
            </Typography>
          </Box>
        </Box>
      </Box>
      {files.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <List>
            {files.map((file) => (
              <ListItem
                key={`${file.file.name}${file.file.size}`}
                sx={{
                  border: 1,
                  borderColor: 'divider',
                  borderRadius: 1,
                  '& + &': {
                    mt: 1,
                  },
                }}
              >
                <ListItemIcon>
                  <DuplicateIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={file.file.name}
                  primaryTypographyProps={{
                    color: 'textPrimary',
                    variant: 'subtitle2',
                  }}
                  secondary={bytesToSize(file.file.size)}
                />
                {file.isUploading === true && (
                  <Box flexGrow={1} sx={{ maxWidth: '350px' }}>
                    <LinearProgressWithLabel value={file.progress} />
                  </Box>
                )}
                {file.isUploading === false && (
                  <Tooltip title={t('Remove')}>
                    <IconButton
                      edge="end"
                      onClick={() => onRemove && onRemove(file)}
                    >
                      <XIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </ListItem>
            ))}
          </List>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 2,
            }}
          >
            <FormButton
              color="error"
              onClick={onRemoveAll}
              size="small"
              type="button"
              variant="text"
              isSubmitting={isRemoving}
              disabled={isUploading}
            >
              {t('Remove All')}
            </FormButton>
            <FormButton
              color="primary"
              onClick={onUpload}
              size="small"
              sx={{ ml: 2 }}
              type="button"
              variant="contained"
              isSubmitting={isUploading}
              disabled={!uploadAvailable || isRemoving}
            >
              {t('Upload')}
            </FormButton>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default FileDropzone;
