import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Divider, Typography } from '@material-ui/core';
import Logo from '../../../components/Logo';
import gtm from '../../../lib/gtm';
import Form from './Form';
import { useTranslation } from 'react-i18next';
import { siteConfig } from '../../../config';

const LoginPage = () => {
  const { t } = useTranslation();
  React.useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {`${t('Login')}${siteConfig.titleSuffix}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8,
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 100,
                  width: 300,
                }}
              />
            </RouterLink>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 2,
                }}
              >
                <div>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                  >
                    {t('Log in')}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {t('Log in on the platform')}
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 0,
                }}
              >
                <Form />
              </Box>
              <Divider sx={{ my: 3 }} />
              {/* <Link */}
              {/*  color="textSecondary" */}
              {/*  component={RouterLink} */}
              {/*  to="/authentication/password-recovery" */}
              {/*  variant="body2" */}
              {/* > */}
              {/*  {t('Forgot password')} */}
              {/* </Link> */}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default LoginPage;
