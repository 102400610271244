import { DataGridColumns } from '../components/DataGrid/Column';
import { Link as RouterLink } from 'react-router-dom';
import { Chip, ChipProps, Link, Tooltip } from '@material-ui/core';
import React from 'react';
import { ActionList, ActionTypes } from '../types/ActionType';
import i18n from 'i18next';
import { timestampFormatDistanceToNowStrict } from '../utils/timestampUtils';
import { getMapValue } from '../types/MapValues';
import { renderCreatedAt, renderUpdatedAt } from '../components/TimestampCell';
import { formatMoneyValue } from '../types/Money';
import { Paid, Preview } from '@material-ui/icons';
import { InvoicePaidStatusMap } from './Invoice';
import { RequestBodyFormMap } from '../types/FormTypes';
import { FormLayoutCard } from '../types/FormLayoutType';
import {
  FullTenantContractInvoiceView,
  ShortTenantContractInvoiceView,
} from '../api/types/App/View/TenantContractInvoice';
import { MarkAsPaidRequestBody } from '../api/types/App/Request/TenantContractInvoice';
import { Dashboard, DashboardType } from '../redux/slices/User';

export const ListTenantContractInvoicesColumns = (
  currentDashboard: Dashboard
): DataGridColumns<ShortTenantContractInvoiceView> => [
  {
    field: 'invoice.number',
    label: 'Invoice Number',
    renderCellValue: ({ id, invoice: i }) => (
      <Link
        color="textPrimary"
        component={RouterLink}
        to={`/connections/invoices/${id}`}
      >
        {i.number}
      </Link>
    ),
  },
  {
    field: 'invoice.paid',
    label: 'Status',
    align: 'right',
    headerAlign: 'center',
    renderCellValue: ({ invoice: i }) => (
      <>
        {i.paid === true && (
          <Tooltip
            title={i18n.t('Paid {{time}} ago', {
              time: timestampFormatDistanceToNowStrict(i.paid_at),
            })}
          >
            <Chip
              label={getMapValue(InvoicePaidStatusMap, 'paid').label}
              color={
                getMapValue(InvoicePaidStatusMap, 'paid')
                  .color as ChipProps['color']
              }
              size="small"
              clickable
              component={RouterLink}
              to={`/connections/invoices/${i.id}`}
            />
          </Tooltip>
        )}
        {i.paid === false && (
          <Chip
            label={getMapValue(InvoicePaidStatusMap, 'not_paid').label}
            color={
              getMapValue(InvoicePaidStatusMap, 'not_paid')
                .color as ChipProps['color']
            }
            size="small"
            clickable
            component={RouterLink}
            to={`/connections/invoices/${i.id}`}
          />
        )}
      </>
    ),
    cellProps: {
      sx: {
        width: '1%',
        whiteSpace: 'nowrap',
      },
    },
  },
  {
    field: 'total',
    label: 'Total',
    align: 'right',
    headerAlign: 'right',
    renderCellValue: ({ invoice: i }) => <>{formatMoneyValue(i.total)}</>,
    headerProps: {
      sx: {
        width: '1%',
        whiteSpace: 'nowrap',
      },
    },
    cellProps: {
      sx: {
        width: '1%',
        whiteSpace: 'nowrap',
      },
    },
  },
  {
    field: 'tenant_contract',
    label: 'Contract',
    align: 'left',
    headerAlign: 'center',
    renderCellValue: ({ tenant_contract: c }) => (
      <Link
        color="textPrimary"
        component={RouterLink}
        to={`/connections/contracts/${c.id}`}
      >
        {c.title}
      </Link>
    ),
  },
  {
    field: 'tenant_contract.destination.client',
    label: 'Client',
    align: 'left',
    headerAlign: 'center',
    hidden: () => currentDashboard.type !== DashboardType.UserTenantAdmin,
    renderCellValue: ({ tenant_contract: c }) => (
      <>{c.destination.client.company_name}</>
    ),
  },
  {
    field: 'tenant_contract.tenant.client',
    label: 'Affiliate Agency',
    align: 'left',
    headerAlign: 'center',
    hidden: () => currentDashboard.type !== DashboardType.TenantAdmin,
    renderCellValue: ({ tenant_contract: c }) => (
      <>{c.tenant.client.company_name}</>
    ),
  },
  {
    field: 'created_at',
    label: 'Created At',
    renderCellValue: renderCreatedAt,
    align: 'right',
  },
  {
    field: 'updated_at',
    label: 'Updated At',
    renderCellValue: renderUpdatedAt,
    align: 'right',
  },
];

export const MarkAsPaidAction = (
  isUser: boolean = false
): ActionTypes<FullTenantContractInvoiceView> => ({
  type: 'route_link',
  label: 'Mark As Paid',
  variant: 'contained',
  color: 'success',
  icon: <Paid fontSize="small" />,
  hidden: (c) => c.invoice.paid === true || isUser,
  to: (context) => `/connections/invoices/${context.id}/markAsPaid`,
});

export const ListTenantContractInvoicesActions = (): ActionList => [
  {
    type: 'route_link',
    label: 'Inspect',
    variant: 'contained',
    icon: <Preview fontSize="small" />,
    to: (context) => `/connections/invoices/${context.id}`,
  },
];

export const MarkAsPaidFormMap: RequestBodyFormMap<MarkAsPaidRequestBody, any> =
  {
    payment_reference_number: {
      name: 'payment_reference_number',
      label: 'Payment Reference Number',
      initialValue: '',
    },
    payment_type: {
      name: 'payment_type',
      label: 'Payment Type',
      initialValue: '',
    },
  };

export const MarkAsPaidFormLayout: FormLayoutCard<any> = {
  type: 'card',
  title: 'Mark As Paid Invoice',
  headerDivider: true,
  bottomDivider: true,
  elements: [
    {
      type: 'row',
      elements: [
        {
          type: 'column',
          xs: 12,
          elements: [
            {
              type: 'field',
              field: MarkAsPaidFormMap.payment_type,
            },
            {
              type: 'field',
              field: MarkAsPaidFormMap.payment_reference_number,
            },
          ],
        },
      ],
    },
  ],
  bottomElements: [
    {
      type: 'box',
      sx: {
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2,
      },
      elements: [
        {
          type: 'form_button',
        },
      ],
    },
  ],
};
