import React, { ChangeEvent } from 'react';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableRow,
} from '@material-ui/core';
import { useStateEffect } from '../../hooks/useStateEffect';
import { DataGridColumn, DataGridColumns } from './Column';
import Row from './Row';
import { ContextMenu } from '../../muiLib/ContextMenu';
import Scrollbar from '../Scrollbar';
import TableHead from './TableHead';
import Pagination, { DataGridPagination } from './Pagination';
import DataGridTabsToolbar, { DataGridTabToolbar } from './TabsToolbar';
import { ActionList } from '../../types/ActionType';
import { calculateProps, ModifiableComponentProps } from '../../utils/propHelpers';
import DataGridSelect, { DataGridSelectOptions } from './Select';
import { DataGridFilter, renderDataGridFilters } from './Filter';
import { BaseResourceView } from '../../api/types/App/View';
import { CalcType, parseCalcType } from '../../types/CalculatedType';
import { Download } from '@material-ui/icons';
import { exportCsvDataGridConfig } from '../../utils/exportCsv';
import { useTranslation } from 'react-i18next';

export interface DataGridConfig<DataType, IdType> {
  rows: DataType[],
  selected?: DataType[],
  columns: DataGridColumns<DataType>,
  hiddenColumns?: DataGridColumn<DataType>['field'][],
  idValueGetter?: (data: DataType) => IdType,
  pagination?: DataGridPagination,
  tabs?: DataGridTabToolbar,
  filters?: DataGridFilter[],
  hideFilters?: CalcType<boolean>,
  // search?: ListSearchConfig,
  quickSort?: DataGridSelectOptions,
  actions?: ActionList<DataType>,
  contextMenu?: ContextMenu<DataType>,
  tableProps?: ModifiableComponentProps<typeof Table>,
  rowProps?: CalcType<ModifiableComponentProps<typeof TableRow>, DataType>,
  cardProps?: ModifiableComponentProps<typeof Card>,
  title?: CalcType<string>,
  exportCsv?: boolean,
  exportCsvFilename?: string,
}

interface DataGridProp<DataType, IdType> {
  children: DataGridConfig<DataType, IdType>,
}

export interface DataGridTableProps<DataType> {
  actions?: ActionList<DataType> | ((reloadDatA: () => void) => ActionList<DataType>),
  selected?: DataType[],
  hiddenColumns?: string[],
}

const DataGrid = <DataType, IdType, >(props: DataGridProp<DataType, IdType>) => {
  const { children: config } = props;
  const { t } = useTranslation();
  config.selected ??= [];
  const [selectedValues, setSelectedValues] = useStateEffect<DataType[]>(config.selected);
  const selectedSome = selectedValues.length > 0 && selectedValues.length < config.rows.length;
  const selectedAll = selectedValues.length === config.rows.length;

  const handleSelectAll = (value: boolean): void => {
    setSelectedValues(value ? config.rows : []);
  };

  const handleSelectAllChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleSelectAll(event.target.checked);
  };

  const handleSelectOne = (row: DataType) => {
    if (!selectedValues.includes(row)) {
      setSelectedValues((prevSelected) => [...prevSelected, row]);
    } else {
      setSelectedValues((prevSelected) => prevSelected.filter((selected) => selected !== row));
    }
  };

  let tabs;
  if (config.tabs !== undefined && config.tabs.hidden !== true) {
    tabs = (
      <DataGridTabsToolbar>
        {config.tabs}
      </DataGridTabsToolbar>
    );
  }

  let filters;
  if (config.filters !== undefined && parseCalcType(config.hideFilters) !== true) {
    filters = renderDataGridFilters(config.filters);
  }

  //
  let searchBox;
  // if (config.search !== undefined) {
  //   searchBox = (
  //     <SearchBox>
  //       {config.search}
  //     </SearchBox>
  //   );
  // }
  //
  let quickSort;
  if (config.quickSort !== undefined) {
    quickSort = (
      <DataGridSelect config={config.quickSort} />
    );
  }

  let bulkActions;
  // if (config.actions !== undefined && selectedValues.length > 0) {
  //   bulkActions = (
  //     <BulkActions
  //       selectedAll={selectedAll} selectedSome={selectedSome}
  //       selectedAllChange={handleSelectAll}
  //     >
  //       {config.actions}
  //     </BulkActions>
  //   );
  // }

  let exportCsv;
  if (config.exportCsv === true) {
    const filename = config.exportCsvFilename ?? 'export.csv';
    const exportCsvAction = () => {
      exportCsvDataGridConfig(filename, config);
    };

    exportCsv = (
      <IconButton title={t('Download as CSV')} onClick={exportCsvAction}>
        <Download fontSize="small" />
      </IconButton>
    );
  }

  const tableProps = calculateProps(config.tableProps, {});

  const columns = config.columns.filter((column) => (config.hiddenColumns ?? []).includes(column.field) === false);

  const rows = config.rows.map((row) => {
    let id;
    if (config.idValueGetter !== undefined) {
      id = config.idValueGetter(row);
    } else {
      id = ((row as unknown as BaseResourceView).id as unknown as IdType);
    }
    return (
      <Row<DataType, IdType> key={`${id}`}>
        {{
          columns,
          data: row,
          id,
          contextMenu: config.contextMenu,
          onSelectChange: handleSelectOne,
          isSelected: selectedValues.find((selected) => selected === row) !== undefined,
          actions: config.actions,
          rowProps: calculateProps(parseCalcType(config.rowProps, row), {}),
        }}
      </Row>
    );
  });

  return (
    <Card {...calculateProps(config.cardProps, {})}>
      {config.title !== undefined && (
        <CardHeader sx={{ mb: 0, pb: 0 }} title={parseCalcType(config.title)} action={exportCsv} />
      )}
      {tabs}
      {tabs && (<Divider />)}

      {(quickSort || filters) && (
      <Box sx={{
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
        m: -1,
        p: 2,
      }}
      >
        <>
          {searchBox}
          {quickSort}
          {filters}
        </>
      </Box>
      )}
      {bulkActions}
      <Scrollbar>
        <Box sx={{ minWidth: 700 }}>
          <Table {...tableProps}>
            <TableHead>
              {{
                columns,
                actions: config.actions,
                selectedAll,
                selectedSome,
                selectedAllChange: handleSelectAllChange,
              }}
            </TableHead>
            <TableBody>
              {rows}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      {config.pagination !== undefined && (
      <Pagination>
        {config.pagination}
      </Pagination>
      )}
    </Card>
  );
};

export default DataGrid;
