import React from 'react';
import { AuthorUserView, BaseUserView } from '../../api/types/App/View/User';
import UserAvatar from '../UserAvatar';
import { Box, Link, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

interface UserCellProp {
  user: BaseUserView | null,
}

const UserCell = ({
  user,
}: UserCellProp) => {
  if (!user) {
    return null;
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <UserAvatar user={user} />
      <Box sx={{ ml: 1 }}>
        <Link
          color="inherit"
          component={RouterLink}
          to={{
            pathname: `/users/${user.id}`,
          }}
          variant="subtitle2"
        >
          {user.full_name}
        </Link>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          <Link
            color="textSecondary" href={`mailto:${user.email}`}
          >
            {user.email}
          </Link>
          {user.telephone && (
            <>
              {' | '}
              <Link
                color="textSecondary" href={`tel:${user.telephone}`}
              >
                {user.telephone}
              </Link>
            </>
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export const renderUserCell = (name: string) => (data) => (
  <UserCell
    user={(data as unknown)[name] as AuthorUserView}
  />
);

export default UserCell;
