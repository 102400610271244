import { ListTenantsRequestParams, CreateTenantRequestBody, UpdateTenantRequestBody } from '../../../../types/App/Request/Tenant';
import { ListTenantView, FullTenantView } from '../../../../types/App/View/Tenant';
import { apiEndpoint, NoBody, StandardErrors, NoParams } from '../../../../client';
import { SuccessView } from '../../../../types/App/View';

export const list = apiEndpoint<ListTenantView, ListTenantsRequestParams, NoBody, StandardErrors>('GET')('/api/agencies/{agencyId}/tenants/');

export const show = apiEndpoint<FullTenantView, NoParams, NoBody, StandardErrors>('GET')('/api/agencies/{agencyId}/tenants/{id}');

export const create = apiEndpoint<FullTenantView, NoParams, CreateTenantRequestBody, StandardErrors>('POST')('/api/agencies/{agencyId}/tenants');

export const update = apiEndpoint<FullTenantView, NoParams, UpdateTenantRequestBody, StandardErrors>('PUT')('/api/agencies/{agencyId}/tenants/{id}');

export const remove = apiEndpoint<SuccessView, NoParams, NoBody, StandardErrors>('DELETE')('/api/agencies/{agencyId}/tenants/{id}');
