import { Yup } from '../lib/Yup';
import i18next from 'i18next';
import { getBrowserLocale, LocaleTypeMap } from '../types/Locale';
import {
  ChangePasswordRequestBody,
  FinishRegisterUserRequestBody,
  UpdateUserRequestBody,
} from '../api/types/App/Request/UserMe';
import { UserMeView } from '../api/types/App/View/User';
import { RequestBodyFormMap } from '../types/FormTypes';
import { FormLayoutCard, FormLayoutGroup } from '../types/FormLayoutType';

export const UpdateProfileFormMap: RequestBodyFormMap<UpdateUserRequestBody, UserMeView> = {
  address1: {
    name: 'address1',
    label: 'Address 1',
    initialValue: '',
  },
  address2: {
    name: 'address2',
    label: 'Address 2',
    initialValue: '',
  },
  city: {
    label: 'City',
    name: 'city',
    initialValue: '',
  },
  country: {
    label: 'Country',
    name: 'country',
    initialValue: '',
  },
  email: {
    label: 'Email Address',
    name: 'email',
    initialValue: '',
    validationSchema: Yup.string()
      .email()
      .required(),
  },
  full_name: {
    label: 'Full Name',
    name: 'full_name',
    initialValue: '',
    validationSchema: Yup.string()
      .required(),
  },
  locale: {
    name: 'locale',
    label: 'Locale',
    initialValue: getBrowserLocale(),
  },
  telephone: {
    name: 'telephone',
    label: 'Telephone',
    initialValue: '',
  },
  zip_code: {
    name: 'zip_code',
    label: 'Zip Code',
    initialValue: '',
  },
};

export const ChangePasswordFormMap: RequestBodyFormMap<ChangePasswordRequestBody, UserMeView> = {
  password: {
    label: 'Your current password',
    name: 'password',
    type: 'password',
    initialValue: () => '',
    validationSchema: Yup.string()
      .required()
      .min(8)
      .max(100),
  },
  password_new: {
    label: 'New password',
    name: 'password_new',
    type: 'password',
    initialValue: () => '',
    validationSchema: Yup.string()
      .required()
      .min(8)
      .max(100),
  },
  password_new_confirm: {
    label: 'Confirm new password',
    name: 'password_new_confirm',
    type: 'password',
    initialValue: () => '',
    validationSchema: Yup.string()
      .required()
      .oneOf([Yup.ref('password_new'), null], () => i18next.t('Passwords must match')),
  },
};

export const GeneralSettingsFormLayout: FormLayoutCard<UserMeView> = {
  type: 'card',
  title: 'Profile',
  headerDivider: true,
  bottomDivider: true,
  sx: { mb: 2 },
  elements: [
    {
      type: 'row',
      elements: [
        {
          type: 'column',
          xs: 12,
          md: 6,
          elements: [
            {
              type: 'field',
              field: UpdateProfileFormMap.full_name,
            },
            {
              type: 'field',
              field: UpdateProfileFormMap.email,
            },
            {
              type: 'field',
              field: UpdateProfileFormMap.telephone,
            },
          ],
        },
        {
          type: 'column',
          xs: 12,
          md: 6,
          elements: [
            {
              type: 'field',
              field: UpdateProfileFormMap.address1,
            },
            {
              type: 'field',
              field: UpdateProfileFormMap.address2,
            },
            {
              type: 'field',
              field: UpdateProfileFormMap.zip_code,
            },
            {
              type: 'field',
              field: UpdateProfileFormMap.city,
            },
            {
              type: 'field',
              field: UpdateProfileFormMap.country,
            },
          ],
        },
      ],
    },
  ],
  bottomElements: [
    {
      type: 'box',
      sx: {
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2,
      },
      elements: [
        {
          type: 'form_button',
        },
      ],
    },
  ],
};

export const SecuritySettingsFormLayout: FormLayoutGroup<UserMeView> = {
  type: 'group',
  elements: [
    {
      type: 'card',
      title: 'Change Password',
      headerDivider: true,
      bottomDivider: true,
      sx: { mb: 2 },
      elements: [
        {
          type: 'row',
          elements: [
            {
              type: 'column',
              xs: 12,
              sm: 4,
              elements: [
                {
                  type: 'field',
                  field: ChangePasswordFormMap.password,
                },
              ],
            },
            {
              type: 'column',
              xs: 12,
              sm: 4,
              elements: [
                {
                  type: 'field',
                  field: ChangePasswordFormMap.password_new,
                },
              ],
            },
            {
              type: 'column',
              xs: 12,
              sm: 4,
              elements: [
                {
                  type: 'field',
                  field: ChangePasswordFormMap.password_new_confirm,
                },
              ],
            },
          ],
        },
      ],
      bottomElements: [
        {
          type: 'box',
          sx: {
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2,
          },
          elements: [
            {
              type: 'form_button',
              label: 'Change',
            },
          ],
        },
      ],
    },
  ],
};

export const FinishInviteFormMap = (email: string): RequestBodyFormMap<FinishRegisterUserRequestBody, any> => ({
  address1: {
    name: 'address1',
    label: 'Address 1',
    initialValue: '',
  },
  address2: {
    name: 'address2',
    label: 'Address 2',
    initialValue: '',
  },
  city: {
    label: 'City',
    name: 'city',
    initialValue: '',
  },
  country: {
    label: 'Country',
    name: 'country',
    initialValue: '',
  },
  email: {
    label: 'Email Address',
    name: 'email',
    initialValue: email,
    validationSchema: Yup.string()
      .email()
      .required(),
  },
  full_name: {
    label: 'Full Name',
    name: 'full_name',
    initialValue: '',
    validationSchema: Yup.string()
      .required(),
  },
  locale: {
    name: 'locale',
    label: 'Locale',
    initialValue: 'en',
    type: 'list',
    listValues: LocaleTypeMap,
  },
  telephone: {
    name: 'telephone',
    label: 'Telephone',
    initialValue: '',
  },
  zip_code: {
    name: 'zip_code',
    label: 'Zip Code',
    initialValue: '',
  },
  password: {
    label: 'Password',
    name: 'password',
    type: 'password',
    initialValue: () => '',
    validationSchema: Yup.string()
      .required()
      .min(8)
      .max(100),
  },
  password_repeat: {
    label: 'Confirm password',
    name: 'password_repeat',
    type: 'password',
    initialValue: () => '',
    validationSchema: Yup.string()
      .required()
      .oneOf([Yup.ref('password'), null], () => i18next.t('Passwords must match')),
  },
});

export const FinishInviteFormLayout = (email: string): FormLayoutGroup<UserMeView> => ({
  type: 'group',
  elements: [
    {
      type: 'row',
      elements: [
        {
          type: 'column',
          xs: 12,
          md: 6,
          elements: [
            {
              type: 'field',
              field: FinishInviteFormMap(email).full_name,
            },
            {
              type: 'field',
              field: FinishInviteFormMap(email).email,
            },
            {
              type: 'field',
              field: FinishInviteFormMap(email).telephone,
            },
            {
              type: 'field',
              field: FinishInviteFormMap(email).password,
            },
            {
              type: 'field',
              field: FinishInviteFormMap(email).password_repeat,
            },
          ],
        },
        {
          type: 'column',
          xs: 12,
          md: 6,
          elements: [
            {
              type: 'field',
              field: FinishInviteFormMap(email).address1,
            },
            {
              type: 'field',
              field: FinishInviteFormMap(email).address2,
            },
            {
              type: 'field',
              field: FinishInviteFormMap(email).zip_code,
            },
            {
              type: 'field',
              field: FinishInviteFormMap(email).city,
            },
            {
              type: 'field',
              field: FinishInviteFormMap(email).country,
            },
          ],
        },
      ],
    },
    {
      type: 'box',
      sx: {
        mt: 2,
      },
      elements: [
        {
          type: 'form_button',
          label: 'Register',
          buttonProps: {
            fullWidth: true,
            sx: {
              mx: 0,
            },
          },
        },
      ],
    },
  ],
});
