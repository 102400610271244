import { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box } from '@material-ui/core';

interface LoadingScreenProps {
  bgColor?: string
}

const LoadingScreen = ({ bgColor }: LoadingScreenProps) => {
  useEffect(() => {
    NProgress.start();

    return (): void => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      className="fffff"
      sx={{
        backgroundColor: bgColor ?? 'background.paper',
        minHeight: '100%',
      }}
    />
  );
};

export default LoadingScreen;
