import { ListUserRequestParams, ListUserLoginHistoryRequestParams, CreateUserRequestBody, UpdateUserRequestBody } from '../../../types/App/Request/User';
import { ListUserView, AdminFullUserView, ListUserLoginHistoryView, AdminListUserView } from '../../../types/App/View/User';
import { apiEndpoint, NoBody, StandardErrors, NoParams } from '../../../client';
import { ListSelectorView } from '../../../types/App/View/Common';
import { SuccessView } from '../../../types/App/View';

export const list = apiEndpoint<ListUserView, ListUserRequestParams, NoBody, StandardErrors>('GET')('/api/users');

export const userSelector = apiEndpoint<ListSelectorView, NoParams, NoBody, StandardErrors>('GET')('/api/users/selector');

export const show = apiEndpoint<AdminFullUserView, NoParams, NoBody, StandardErrors>('GET')('/api/users/{id}');

export const loginHistory = apiEndpoint<ListUserLoginHistoryView, ListUserLoginHistoryRequestParams, NoBody, StandardErrors>('GET')('/api/users/{id}/loginHistory');

export const create = apiEndpoint<AdminListUserView, NoParams, CreateUserRequestBody, StandardErrors>('POST')('/api/users');

export const update = apiEndpoint<AdminListUserView, NoParams, UpdateUserRequestBody, StandardErrors>('PUT')('/api/users/{id}');

export const remove = apiEndpoint<SuccessView, NoParams, NoBody, StandardErrors>('DELETE')('/api/users/{id}');

export * as Change from './Change';
