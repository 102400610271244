import { ListCommissionRequestParams } from '../../../../types/App/Request/Commission';
import { ListCommissionView, ShortCommissionView } from '../../../../types/App/View/Commission';
import { apiEndpoint, NoBody, StandardErrors, NoParams } from '../../../../client';
import { ListCommissionConversionRequestParams } from '../../../../types/App/Request/CommissionConversion';
import { ListCommissionConversionView } from '../../../../types/App/View/CommissionConversion';
import { SuccessView } from '../../../../types/App/View';

export const list = apiEndpoint<ListCommissionView, ListCommissionRequestParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/commissions');

export const show = apiEndpoint<ShortCommissionView, NoParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/commissions/{id}');

export const listConversions = apiEndpoint<ListCommissionConversionView, ListCommissionConversionRequestParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/commissions/{id}/conversions');

export const reviewConversion = apiEndpoint<SuccessView, NoParams, NoBody, StandardErrors>('POST')('/api/tenants/{tenantId}/commissions/{commissionId}/conversions/{id}/review/{action}');

export const review = apiEndpoint<ShortCommissionView, NoParams, NoBody, StandardErrors>('PUT')('/api/tenants/{tenantId}/commissions/{id}/review/{action}');
