import React, { ReactNode } from 'react';
import {
  Table,
  TableBody,
  TableBodyProps,
  TableCell,
  TableCellProps,
  TableProps,
  TableRow,
  TableRowProps,
  Typography,
} from '@material-ui/core';
import {
  ModifyComponentProps,
  modifyProps,
  renderComponent,
  RenderComponent,
} from '../types/RenderComponent';
import { AddCalculatedValues, parseCalcType } from '../types/CalculatedType';
import { tp } from '../i18n/TranslateProps';
import { TypographyProps } from '@material-ui/system';
import _ from 'lodash';
import { Path, PathHuman } from '../types/Common';

interface BaseDetailRow<ContextType, Fields = Path<ContextType>, LabelValues = PathHuman<ContextType>> {
  field: Fields | string,
  label: LabelValues | string,
  labelCellProps?: ModifyComponentProps<TableCellProps>,
  valueCellProps?: ModifyComponentProps<TableCellProps>,
  rowProps?: ModifyComponentProps<TableRowProps>,
  renderLabelCell?: RenderComponent<TableCellProps, typeof TableCell>,
  renderValueCell?: RenderComponent<TableCellProps, typeof TableCell>,
  renderRow?: RenderComponent<TableRowProps, typeof TableRow>,
  renderValue?: ReactNode,
  hidden?: boolean,
  labelTypoProps?: ModifyComponentProps<TypographyProps>,
  valueTypoProps?: ModifyComponentProps<TypographyProps>,
}

export type DetailRow<ContextType = any> = AddCalculatedValues<BaseDetailRow<ContextType>, ContextType, 'field' | 'label'>;
export type DetailRows<ContextType = any> = DetailRow<ContextType>[];

interface BaseDetailTableProps<ContextType = any> {
  rows: DetailRow<ContextType>[],
  tableProps?: ModifyComponentProps<TableProps>,
  tableBodyProps?: ModifyComponentProps<TableBodyProps>,
  renderTable?: RenderComponent<TableProps, typeof Table>,
  renderTableBody?: RenderComponent<TableBodyProps, typeof TableBody>,
  templateParams?: Record<string, string>,
}

export type DetailTableProps<ContextType = any> = AddCalculatedValues<BaseDetailTableProps<ContextType>, ContextType, 'rows'>;

const DetailTable = <ContextType extends any = any, >(
  {
    context,
    ...rest
  }: { context: ContextType } & DetailTableProps<ContextType>,
) => ((props: DetailTableProps) => {
    const {
      rows = [],
      tableProps,
      tableBodyProps,
      renderTable,
      renderTableBody,
      templateParams,
    } = props;

    const tableRows = rows.map((row) => {
      const {
        field,
        label,
        labelCellProps,
        valueCellProps,
        rowProps,
        renderLabelCell,
        renderValueCell,
        renderRow,
        renderValue,
        valueTypoProps,
        labelTypoProps,
        hidden,
      } = row;

      if (parseCalcType(hidden, context) === true) {
        return null;
      }

      let value;
      if (renderValue !== undefined) {
        value = parseCalcType(renderValue, context);
      } else {
        value = renderComponent(
          Typography,
          modifyProps(
            {
              color: 'textSecondary',
              variant: 'body2',
              children: _.get(context, field) ?? '',
            } as TypographyProps,
            parseCalcType(valueTypoProps, context),
          ),
          undefined,
          context,
        );
      }

      return renderComponent(
        TableRow,
        modifyProps(
          {
            key: String(field),
            children: (
              <>
                {
                renderComponent(
                  TableCell,
                  modifyProps(
                    {
                      children: renderComponent(
                        Typography,
                        modifyProps(
                          {
                            color: 'textPrimary',
                            variant: 'subtitle2',
                            children: tp(String(label), templateParams, context),
                          } as TypographyProps,
                          parseCalcType(labelTypoProps, context),
                        ),
                        undefined,
                        context,
                      ),
                    },
                    parseCalcType(labelCellProps, context),
                  ),
                  renderLabelCell,
                  context,
                )
              }
                {
                renderComponent(
                  TableCell,
                  modifyProps(
                    {
                      children: value,
                    },
                    parseCalcType(valueCellProps, context),
                  ),
                  renderValueCell,
                  context,
                )
              }
              </>
            ),
          },
          parseCalcType(rowProps, context),
        ),
        renderRow,
        context,
      );
    });

    return renderComponent(
      Table,
      modifyProps(
        {
          children: renderComponent(
            TableBody,
            modifyProps(
              {
                children: tableRows,
              },
              parseCalcType(tableBodyProps, context),
            ),
            renderTableBody,
            context,
          ),
        },
        parseCalcType(tableProps, context),
      ),
      renderTable,
      context,
    );
  })(rest);

export default DetailTable;
