import React from 'react';
import { ActionTypes } from '../../types/ActionType';
import { IconButton, IconButtonProps, Tooltip } from '@material-ui/core';
import { RouterLink } from '../../lib/RouterLink';
import { NotListedLocation } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { parseCalcType } from '../../types/CalculatedType';
import useDialog from '../../hooks/useDialog';
import { calculateProps } from '../../utils/propHelpers';

interface IconButtonActionProp<V extends any> {
  context: V;
  action: ActionTypes<V>;
}

const IconButtonAction = <V extends any>({
  context,
  action,
}: IconButtonActionProp<V>): React.ReactElement => {
  const { t } = useTranslation();
  let props: IconButtonProps & { component?: React.ElementType; to?: string } =
    {
      color: parseCalcType(action.iconColor, context),
    };

  props.disabled = parseCalcType(action.disabled, context);

  switch (action.type) {
    case 'route_link':
      props.component = RouterLink;
      props.to = parseCalcType(action.to, context);
      break;
    case 'link':
      props.component = 'a';
      // @ts-ignore
      props.href = parseCalcType(action.href, context);
      break;
    case 'click':
      props.onClick = action.onClick(context);
      break;
    default:
  }

  let dialogEl;
  if (action.type === 'confirm_click') {
    const dialogOptions = parseCalcType(action.dialog, context);
    const { dialog, showDialog } = useDialog(dialogOptions);
    dialogEl = dialog;

    props.onClick = () => showDialog(context);
  }

  if (action.renderIconButton !== undefined) {
    return action.renderIconButton(props, context);
  }

  const labelParams = parseCalcType(action.labelParams, context) ?? {};

  props = calculateProps(action.iconButtonProps, props);

  return (
    <>
      {dialogEl}
      <Tooltip title={t(parseCalcType(action.label, context), labelParams)}>
        <span>
          <IconButton {...props}>
            {parseCalcType(action.icon, context) ?? (
              <NotListedLocation fontSize="small" />
            )}
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
};

export default IconButtonAction;
