import { format, formatDistanceToNowStrict, fromUnixTime } from 'date-fns';
import { enGB, ja } from 'date-fns/locale';
import i18next from 'i18next';

const localeMap = {
  en: enGB,
  ja,
};

export const timestampFormat = (timestamp: number | null, formatStr: string = 'PPp', defaultValue: string = ''): string => {
  if (!timestamp) {
    return defaultValue;
  }

  return format(fromUnixTime(timestamp), formatStr, { locale: localeMap[i18next.language] });
};

export const timestampFormatDistanceToNowStrict = (timestamp: number | null, defaultValue: string = ''): string => {
  if (!timestamp) {
    return defaultValue;
  }

  return formatDistanceToNowStrict(fromUnixTime(timestamp), { locale: localeMap[i18next.language] });
};

export const formatDate = (value: number | Date, dateFormat: string = 'PPp'): string => format(value, dateFormat, { locale: localeMap[i18next.language] });
