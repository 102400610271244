import React, { useEffect } from 'react';
import { siteConfig } from '../config';
import { Helmet } from 'react-helmet-async';
import useSettings from '../hooks/useSettings';
import { Box, Container, ContainerProps } from '@material-ui/core';
import PageHeader, { PageHeaderProp } from './Page/PageHeader';
import PageTabs, { PageTabsProps } from './Page/PageTabs';
import { ContentSwitcher, ContentViewProps } from './ContentSwitcher';
import { getFirstMapValue, getMapValue } from '../types/MapValues';
import { Tab } from './Tabs';
import ut from '../hooks/useTranslation';
import gtm from '../lib/gtm';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';

export interface PageProps<ContextType = any> {
  title: string,
  titleProps?: Record<string, string>,
  rawTitle?: string,
  pageHeaderProps?: PageHeaderProp<ContextType>,
  renderHeader?: () => React.ReactNode,
  pageTabs?: PageTabsProps<ContextType>,
  children?: React.ReactNode;
  compactWidth?: ContainerProps['maxWidth'],
  context?: ContextType
}

const Page = <ContextType extends any = any, >(props: PageProps<ContextType>) => {
  const {
    title,
    titleProps = {},
    rawTitle,
    pageHeaderProps,
    compactWidth,
    pageTabs,
    children,
    context,
  } = props;
  const { settings } = useSettings();
  const location = useLocation<{ pageTab?: string }>();
  const history = useHistory();
  const [currentTabValue, setCurrentTabValue] = React.useState<string | null>(location?.state?.pageTab ?? null);

  let pageTitle;
  if (rawTitle !== undefined) {
    pageTitle = rawTitle;
  } else {
    pageTitle = `${ut(title, titleProps)}${siteConfig.titleSuffix}`;
  }

  if (pageTabs !== undefined) {
    pageTabs.context = context;

    if (!currentTabValue) {
      setCurrentTabValue(getFirstMapValue(pageTabs.tabs).value);
    }
  }

  if (pageTabs !== undefined) {
    pageTabs.currentTab = getMapValue(pageTabs.tabs, currentTabValue);

    const parentOnTabChange = pageTabs.onTabChange;
    pageTabs.onTabChange = (tab: Tab) => {
      setCurrentTabValue(tab.value);
      history.push({ ...location,
        state: {
          ...location.state,
          pageTab: tab.value,
        },
      });

      parentOnTabChange?.(tab);
    };
  }

  React.useEffect(() => {
    setCurrentTabValue(location?.state?.pageTab ?? currentTabValue);
  }, [location]);

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8,
        }}
      >
        <Container maxWidth={settings.compact ? (compactWidth ?? 'xl') : false}>
          {pageHeaderProps !== undefined && (<PageHeader {...pageHeaderProps} context={context} />)}
          {pageTabs !== undefined && (<PageTabs {...pageTabs} />)}

          <Box sx={{ mt: 3 }}>
            {pageTabs !== undefined ? (
              <ContentSwitcher current={currentTabValue}>
                {children as React.ReactElement<ContentViewProps>[]}
              </ContentSwitcher>
            ) : children}
          </Box>
        </Container>

      </Box>
    </>
  );
};

export default Page;
