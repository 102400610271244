import React from 'react';
import { ApiEndpointTypeAny, HasApiEndpointParams } from '../../api/client';
import FileDropzone, { FileObject } from '../FileDropzone';
import simpleApiCall from '../../hooks/simpleApiCall';
import { Prefix } from '../../types/Common';
import { DropzoneOptions } from 'react-dropzone';
import { getIn, useFormikContext } from 'formik';

interface FileDropZoneFieldProps<UploadEndpoint extends ApiEndpointTypeAny, RemoveEndpoint extends ApiEndpointTypeAny> extends Prefix<'upload', HasApiEndpointParams<UploadEndpoint>>,
  Prefix<'remove', HasApiEndpointParams<RemoveEndpoint>>, DropzoneOptions {
  fieldName: string,
}

const FileDropZoneField = <UploadEndpoint extends ApiEndpointTypeAny, RemoveEndpoint extends ApiEndpointTypeAny, >(props: FileDropZoneFieldProps<UploadEndpoint, RemoveEndpoint>) => {
  const {
    uploadEndpoint,
    removeEndpoint,
    fieldName,
    ...restProps
  } = props;

  const formikContext = useFormikContext();
  const [files, setFiles] = React.useState<FileObject[]>([]);

  const isUploading = files.filter((f) => f.isUploading === true).length > 0;
  const isRemoving = files.filter((f) => f.isRemoving === true).length > 0;
  const hasUnUploadedFiles = files.filter((f) => f.uploaded === false).length > 0;

  const updateFile = (file: FileObject) => {
    setFiles((prev) => prev.map((f) => {
      if (f.file.name === file.file.name) {
        return file;
      }

      return f;
    }));
  };

  const handleOnRemove = async (file: FileObject) => {
    if (file.uploaded) {
      file.isRemoving = true;
      updateFile(file);
      simpleApiCall(removeEndpoint)({ id: file.uploadedFileId }).then(() => {
        setFiles((prev) => prev.filter((f) => f !== file));

        const fieldValue = getIn(formikContext.values, fieldName) ?? [];
        formikContext.setFieldValue(fieldName, fieldValue.filter((f) => f.name !== file.file.name));
      });
    } else {
      setFiles((prev) => prev.filter((f) => f !== file));

      const fieldValue = getIn(formikContext.values, fieldName) ?? [];
      formikContext.setFieldValue(fieldName, fieldValue.filter((f) => f.name !== file.file.name));
    }
  };

  const handleOnRemoveAll = async () => {
    let fieldValue = getIn(formikContext.values, fieldName) ?? [];
    files.forEach((file) => {
      if (file.uploaded) {
        file.isRemoving = true;

        updateFile(file);
        simpleApiCall(removeEndpoint)({ id: file.uploadedFileId }).then(() => {
          setFiles((prev) => prev.filter((f) => f !== file));

          fieldValue = fieldValue.filter((f) => f.name !== file.file.name);
          formikContext.setFieldValue(fieldName, fieldValue);
        });
      } else {
        setFiles((prev) => prev.filter((f) => f !== file));

        fieldValue = fieldValue.filter((f) => f.name !== file.file.name);
        formikContext.setFieldValue(fieldName, fieldValue);
      }
    });
  };

  const handleOnUpload = async () => {
    const unUploadedFiles = files.filter((file) => file.uploaded === false);
    // eslint-disable-next-line no-restricted-syntax
    unUploadedFiles.forEach((file) => {
      file.isUploading = true;

      updateFile(file);
      const data = new FormData();
      data.append('image', file.file);

      // eslint-disable-next-line no-await-in-loop
      uploadEndpoint({}, {}, {}, {
        data,
        onUploadProgress: (e) => {
          file.progress = Math.round((e.loaded * 100) / e.total);

          updateFile(file);
        }
      }).then((result) => {
        file.isUploading = false;
        file.uploaded = true;
        file.uploadedFileId = result.data.id;

        const values = getIn(formikContext.values, fieldName) ?? [];
        let value = values.find((f) => f.name === file.file.name);

        if (value === undefined) {
          value = {};
          values.push(value);
        }

        value.id = file.uploadedFileId;
        value.uploaded = true;
        value.name = file.file.name;

        formikContext.setFieldValue(fieldName, values);
        updateFile(file);
      });
    });
  };

  const handleOnDrop = <T extends File>(
    acceptedFiles: T[],
  ) => {
    const newFiles: FileObject[] = acceptedFiles.filter((acceptedFile) => files.find((f) => f.file.name === acceptedFile.name) === undefined).map((acceptedFile) => ({
      file: acceptedFile,
      uploaded: false,
      isUploading: false,
      isRemoving: false,
      uploadedFileId: null,
      progress: null,
    }));

    const fieldValue = getIn(formikContext.values, fieldName) ?? [];
    newFiles.forEach((file) => {
      fieldValue.push({
        id: null,
        uploaded: false,
        name: file.file.name,
      });
    });

    formikContext.setFieldValue(fieldName, fieldValue);

    setFiles((prev) => [...prev, ...newFiles]);
  };

  return (
    <FileDropzone
      files={files} onRemove={handleOnRemove} onRemoveAll={handleOnRemoveAll}
      onUpload={handleOnUpload}
      onDrop={handleOnDrop} uploadAvailable={hasUnUploadedFiles} isUploading={isUploading} isRemoving={isRemoving}
      {...restProps}
    />
  );
};

export default FileDropZoneField;
