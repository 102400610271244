import React from 'react';

type ComponentType<T, C extends React.ComponentType> = React.ComponentType<React.ComponentProps<C> & T>;
type ComponentTypeProps<T, C extends ComponentType<T, C>> = React.ComponentProps<C> & T;

export type ModifyComponentPropsClosure<T> = (props: T) => T;
export type ModifyComponentProps<T> = T | ModifyComponentPropsClosure<T>;

type ModifyComponentPropType<T> = T extends ModifyComponentProps<infer U> ? U : never;

export const modifyProps = <T extends ModifyComponentProps<any>,
  Props = ModifyComponentPropType<T>>(
    props: Props,
    modifier: T,
  ): Props => {
  if (modifier === undefined || modifier === null) {
    return props;
  }

  if (typeof modifier === 'function') {
    return (modifier as ModifyComponentPropsClosure<Props>)(props);
  }

  return { ...props, ...modifier as Props };
};

export type RenderComponentClosure<T, C extends React.ComponentType<React.ComponentProps<C> & T> = any, Context = any> = (
  props: T,
  Component: C,
  context?: Context,
) => React.ReactNode;
export type RenderComponent<T, C extends React.ComponentType<React.ComponentProps<C> & T> = any, Context = any> =
  React.ReactNode
  | RenderComponentClosure<T, C, Context>;

export const renderComponent = <T, C extends React.ComponentType<React.ComponentProps<C> & T> = any, Context = any, >(
  Component: C,
  props: ComponentTypeProps<T, C>,
  renderComponentValue?: RenderComponent<T, C>,
  context?: Context,
) => {
  if (renderComponentValue === undefined || renderComponentValue === null) {
    return <Component {...props} />;
  }

  if (typeof renderComponentValue === 'function') {
    return renderComponentValue(props, Component, context);
  }

  return renderComponentValue;
};
