import React from 'react';
import { Alert, Box, Button } from '@material-ui/core';
import * as Yup from 'yup';
import { Field, Formik } from 'formik';
import { FormikHelpers, FormikValues } from 'formik/dist/types';
import { useTranslation } from 'react-i18next';
import useMounted from '../../../hooks/useMounted';
import { userLoginConfig } from '../../../config';
import { useDispatch } from '../../../redux';
import { login } from '../../../redux/slices/User';
import { TextField as ManoTextField } from '../../../components/Form/TextField';
import { JsonErrorView } from '../../../api/types/App/Response';

type HandleSubmit = <Values extends FormikValues = FormikValues>(
  values: Values, formikHelpers: FormikHelpers<Values>,
) => void | Promise<any>;

const Form = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mounted = useMounted();
  const [hasServerErrors, setHasServerErrors] = React.useState<boolean>(false);
  const [serverErrors, setServerErrors] = React.useState<JsonErrorView[]>([]);

  const initialValues = {
    email: userLoginConfig.email,
    password: userLoginConfig.password,
  };

  const validationSchema = Yup
    .object()
    .shape({
      email: Yup
        .string()
        .email()
        .max(255)
        .required(),
      password: Yup
        .string()
        .max(255)
        .required(),
    });

  const handleSubmitAction: HandleSubmit = async (values, {
    setStatus,
    setSubmitting,
  }) => {
    try {
      setServerErrors([]);
      setHasServerErrors(false);

      await dispatch(login(values.email, values.password));

      if (mounted.current) {
        setStatus({ success: true });
        setSubmitting(false);
      }
    } catch (err) {
      if (mounted.current) {
        setStatus({ success: false });
        setSubmitting(false);

        if (Array.isArray(err)) {
          setServerErrors(err);
        } else {
          setServerErrors([err]);
        }
        setHasServerErrors(true);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmitAction}
    >
      {({
        handleSubmit,
        isSubmitting,
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
        >
          {hasServerErrors && (
            <Box sx={{ mb: 1 }}>
              {serverErrors.map((error: JsonErrorView) => (
                <Alert
                  key={error.message}
                  severity="error"
                >
                  {t(error.message)}
                </Alert>
              ))}
            </Box>
          )}
          <Field
            component={ManoTextField}
            autoFocus
            name="email"
            type="email"
            label={t('Email Address')}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <Field
            component={ManoTextField}
            name="password"
            type="password"
            label={t('Password')}
            variant="outlined"
            margin="normal"
            fullWidth
          />
          <Box sx={{ mt: 2 }}>
            <Button
              color="primary"
              disabled={isSubmitting}
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              {t('Log In')}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Form;
