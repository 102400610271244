import _ from 'lodash';

interface ObjectToQueryStringHelperObject {
  keyPath: Array<string>;
  value: boolean | number | string;
}

function objectToQueryStringHelper(
  object: any,
  path: Array<string> = [],
  result: Array<ObjectToQueryStringHelperObject> = [],
): Array<ObjectToQueryStringHelperObject> {
  return Object.entries(object)
    .reduce((acc, [key, value]) => {
      if (!_.isNil(value) || !_.isEmpty(value)) {
        if (_.isObject(value)) {
          acc.push(...objectToQueryStringHelper(value, [...path, key], result));
        } else {
          acc.push({
            keyPath: [...path, key],
            value,
          });
        }
      }
      return acc;
    }, []);
}

export function queryParams(object: any): string {
  const simplifiedData = objectToQueryStringHelper(object);
  const queryStrings = simplifiedData.map(
    (
      {
        keyPath: [firstKey, ...otherKeys],
        value,
      },
    ) => {
      const nestedPath = otherKeys.map((key) => `[${key}]`)
        .join('');

      return `${firstKey}${nestedPath}=${!_.isNil(value) ? encodeURI(`${value}`) : ''}`;
    },
  );

  return queryStrings.join('&');
}
