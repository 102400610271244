import { ChangeEvent } from 'react';
import { ModifyProps } from '../../utils/propHelpers';
import {
  Box,
  BoxProps,
  FormControlLabel,
  FormControlLabelProps,
  Switch as MUISwitch,
  SwitchProps as MUISwitchProps,
} from '@material-ui/core';
import { modifyProps, renderComponent, RenderComponent } from '../../types/RenderComponent';
import { useTranslation } from 'react-i18next';
import { useStateEffect } from '../../hooks/useStateEffect';
import { BaseDataGridFilter } from './Filter';
import { CalcType, parseCalcType } from '../../types/CalculatedType';

export interface DataGridSwitchOptions {
  label: string,
  current?: boolean,
  onChange?: (value: boolean) => void,
  labelProps?: ModifyProps<FormControlLabelProps>,
  renderLabel?: RenderComponent<typeof FormControlLabel>,
  switchProps?: ModifyProps<MUISwitchProps>,
  renderSwitch?: RenderComponent<typeof MUISwitch>
  containerProps?: ModifyProps<BoxProps>,
  renderContainer?: RenderComponent<typeof Box>,
  hidden?: CalcType<boolean>,
}

export interface DataGridSwitchFilter extends BaseDataGridFilter {
  type: 'switch',
  config: DataGridSwitchOptions,
}

interface SwitchProps {
  config: DataGridSwitchOptions;
}

const DataGridSwitch = (props: SwitchProps) => {
  const { config } = props;

  if (parseCalcType(config.hidden) === true) {
    return null;
  }

  const { t } = useTranslation();
  const [value, setValue] = useStateEffect<boolean>(config.current ? config.current : false);

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setValue(event.target.checked);

    config.onChange?.(event.target.checked);
  };

  return renderComponent(
    Box,
    modifyProps(
      {
        sx: {
          m: 2,
        },
        children: renderComponent(
          FormControlLabel,
          modifyProps(
            {
              label: t(config.label),
              control: renderComponent(
                MUISwitch,
                modifyProps(
                  {
                    checked: value,
                    color: 'primary',
                    name: config.label,
                    onChange: handleChange,
                  },
                  config.switchProps,
                ),
                config.renderSwitch,
              ),
            },
            config.labelProps,
          ),
          config.renderLabel,
        ),
      },
      config.containerProps,
    ),
    config.renderContainer,
  );
};

export default DataGridSwitch;
