import React from 'react';
import Flags from 'country-flag-icons/react/3x2';
import { MapValue } from './MapValues';
import detectBrowserLanguage from 'detect-browser-language';
import { LanguagesMap } from './Languages';

export const getBrowserLocale = () => {
  const browserLocale = detectBrowserLanguage();
  const locale = LanguagesMap.find((lang) => lang.code === browserLocale);

  return locale?.value ?? 'en';
};

export const LocaleTypeMap: MapValue[] = [
  {
    value: 'en',
    icon: <Flags.GB title="English" width={20} height={20} />,
    label: 'English',
  },
  {
    value: 'ja',
    icon: <Flags.JP title="日本語" width={20} height={20} />,
    label: '日本語',
  },
];
