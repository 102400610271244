const firstLast = (arr) => {
  if (arr.length < 2) return arr;
  return [arr[0], arr[arr.length - 1]];
};

const getInitials = (name = ''): string => firstLast(
  name
    .replace(/[^a-zA-Z0-9 -]/, '')
    .replace(/\s+/, ' ')
    .split(' '),
)
  .map((v) => v && v[0].toUpperCase())
  .join('');

export default getInitials;
