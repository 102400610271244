import Quill from 'react-quill';
import React from 'react';
import { styled } from '@material-ui/core/styles';

const QuillEditorRoot = styled((props) => (
  <Quill {...props} />
))<{ error: boolean }>(
  ({ theme, error }) => (
    {
      border: 1,
      borderColor: error ? theme.palette.error.main : theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      borderStyle: 'solid',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'visible',
      '& a': {
        color: `${theme.palette.primary.main} !important`,
      },
      '& .ql-snow.ql-toolbar': {
        borderColor: theme.palette.divider,
        borderLeft: 'none',
        borderRight: 'none',
        borderTop: 'none',
        '& .ql-picker-label:hover': {
          color: theme.palette.primary.main
        },
        '& .ql-picker-label.ql-active': {
          color: theme.palette.primary.main
        },
        '& .ql-picker-item:hover': {
          color: theme.palette.primary.main
        },
        '& .ql-picker-item.ql-selected': {
          color: theme.palette.primary.main
        },
        '& button:hover': {
          color: theme.palette.primary.main,
          '& .ql-stroke': {
            stroke: theme.palette.primary.main
          }
        },
        '& button:focus': {
          color: theme.palette.primary.main,
          '& .ql-stroke': {
            stroke: theme.palette.primary.main
          }
        },
        '& button.ql-active': {
          '& .ql-stroke': {
            stroke: theme.palette.primary.main
          }
        },
        '& .ql-stroke': {
          stroke: theme.palette.text.primary
        },
        '& .ql-picker': {
          color: theme.palette.text.primary
        },
        '& .ql-picker-options': {
          backgroundColor: theme.palette.background.paper,
          border: 'none',
          borderRadius: theme.shape.borderRadius,
          boxShadow: theme.shadows[10],
          padding: theme.spacing(2)
        }
      },
      '& .ql-action': {
        color: `${theme.palette.primary.main} !important`,
      },
      '& .ql-preview': {
        color: `${theme.palette.success.main} !important`,
      },
      '& .ql-remove': {
        color: `${theme.palette.error.main} !important`,
      },
      '& .ql-tooltips': {
      },
      '& .ql-tooltip': {
        left: '0 !important;',
        backgroundColor: `${theme.palette.background.paper} !important`,
        color: `${theme.palette.text.primary} !important`,
        borderColor: `${theme.palette.divider} !important`,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[10],
        paddingTop: `${theme.spacing(1)} !important`,
        paddingBottom: `${theme.spacing(1)} !important`,
        paddingLeft: `${theme.spacing(2)} !important`,
        paddingRight: `${theme.spacing(2)} !important`,
        '& input': {
          backgroundColor: `${theme.palette.background.paper} !important`,
          color: `${theme.palette.text.primary} !important`,
          borderColor: `${theme.palette.divider} !important`,
          borderRadius: theme.shape.borderRadius,
          padding: '16px 14px 16px 14px !important',
          outline: 'none',
        },
        '& input:focus': {
          borderColor: `${theme.palette.primary.main} !important`,
        },
      },
      '& .ql-snow.ql-container': {
        borderBottom: 'none',
        borderColor: theme.palette.divider,
        borderLeft: 'none',
        borderRight: 'none',
        flexGrow: 1,
        '& .ql-editor': {
          color: theme.palette.text.primary,
          fontFamily: theme.typography.body1.fontFamily,
          fontSize: theme.typography.body1.fontSize,
          padding: theme.spacing(2),
          '&.ql-blank::before': {
            color: theme.palette.text.secondary,
            fontStyle: 'normal',
            left: theme.spacing(2)
          }
        }
      }
    }
  )
);

const QuillEditor = (props) => (
  <QuillEditorRoot {...props} />
);

export default QuillEditor;
