import React from 'react';
import { useRouteMatch } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import AffiliateCreate from './Affiliate/Create';
import ListAffiliatesPage from './Affiliate/List';
import ShowAffiliatePage from './Affiliate/Show';
import EditAffiliatePage from './Affiliate/Edit';

const Affiliates = () => {
  const { url: baseURL } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${baseURL}/create`}>
        <AffiliateCreate />
      </Route>
      <Route exact path={`${baseURL}/:id`}>
        <ShowAffiliatePage />
      </Route>
      <Route exact path={`${baseURL}/:id/edit`}>
        <EditAffiliatePage />
      </Route>
      <Route path={`${baseURL}`}>
        <ListAffiliatesPage />
      </Route>
    </Switch>
  );
};

export default Affiliates;
