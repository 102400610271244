import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-quill/dist/quill.snow.css';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { StyledEngineProvider } from '@material-ui/core';
import { LocalizationProvider } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { history, store } from './redux';
import { SettingsProvider } from './contexts/SettingsContext';
import AuthProvider from './contexts/JWTContext';
import LoadingScreen from './components/LoadingScreen';
import { isProd } from './utils/isProd';

if (isProd()) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.NODE_ENV,
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    normalizeDepth: 10,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <React.Suspense fallback={<LoadingScreen bgColor="#1c2531" />}>
        <ReduxProvider store={store}>
          <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SettingsProvider>
                <ConnectedRouter history={history}>
                  <AuthProvider>
                    <App history={history} />
                  </AuthProvider>
                </ConnectedRouter>
              </SettingsProvider>
            </LocalizationProvider>
          </StyledEngineProvider>
        </ReduxProvider>
      </React.Suspense>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an Analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
