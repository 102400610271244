import numeral from 'numeral';
import * as Yup from 'yup';
import { MapValue } from './MapValues';
import { MoneyView } from '../api/types/App/View/Common';

export interface Currency extends MapValue {
  sign: string;
  locale: string;
  format: string;
}

export interface Money {
  value: string;
  currency: string;
}

export const CurrencyValues = ['JPY', 'USD', 'EUR', 'GBP', 'AUD', 'CNY'];

export const MoneyValidationScheme = Yup.object()
  .required()
  .shape({
    value: Yup.number().required().moreThan(0),
    currency: Yup.string().required().oneOf(CurrencyValues),
  });

export const MoneyValueValidationSchema = Yup.string()
  .required()
  .test(
    'maxDigitsAfterDecimal',
    // eslint-disable-next-line no-template-curly-in-string
    '${path} is incorrect value',
    (number) => /^\d+\.?\d{0,2}$/.test(String(number))
  );

export const CurrencyList: Currency[] = [
  {
    label: 'YEN',
    value: 'JPY',
    sign: '¥',
    locale: 'ja',
    format: '$0,0',
  },
  {
    label: 'USD',
    value: 'USD',
    sign: '$',
    locale: 'en',
    format: '$0,0.00',
  },
  {
    label: 'EUR',
    value: 'EUR',
    sign: '€',
    locale: 'de',
    format: '$0,0.00',
  },
  {
    label: 'GBP',
    value: 'GBP',
    sign: '£',
    locale: 'en-gb',
    format: '$0,0.00',
  },
  {
    label: 'AUD',
    value: 'AUD',
    sign: '$',
    locale: 'en-au',
    format: '$0,0.00',
  },
  {
    label: 'CNY',
    value: 'CNY',
    sign: '¥',
    locale: 'chs',
    format: '$0,0.00',
  },
];

export function getCurrency(value: string): Currency | undefined {
  return CurrencyList.find((cur) => cur.value === value);
}

export function formatMoneyValue(value: MoneyView): string {
  const currency = getCurrency(value.currency);
  const prevLocale = numeral.options.currentLocale;
  numeral.locale(currency.locale);

  const result = numeral(value.value).format(currency.format);

  numeral.locale(prevLocale);

  return result;
}
