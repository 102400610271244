import React from 'react';
import { TableCell } from '@material-ui/core';
import { ActionList } from '../../types/ActionType';
import Actions from '../Actions';

interface RowActionsProps<DataType> {
  children: {
    actions: ActionList<DataType>,
    data: DataType,
  },
}

const RowActions = <DataType, >(props: RowActionsProps<DataType>) => {
  const { children } = props;
  const {
    actions,
    data,
  } = children;

  return (
    <TableCell align="right">
      <Actions type="icon" actions={actions} context={data} />
    </TableCell>
  );
};

export default RowActions;
