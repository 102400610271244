import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-locize-backend';
import 'numeral/locales/ja';
import 'numeral/locales/de';
import 'numeral/locales/en-gb';
import 'numeral/locales/en-au';
import { isProd } from './utils/isProd';
import { locizePlugin } from 'locize';
import LastUsed from 'locize-lastused';

const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_PROJECTID,
  apiKey: process.env.REACT_APP_LOCIZE_APIKEY,
  referenceLng: process.env.REACT_APP_LOCIZE_REFLNG,
  version: process.env.REACT_APP_LOCIZE_VERSION,
};

if (!isProd()) {
  i18n.use(LastUsed);
}

i18n
  .use(Backend)
  .use(locizePlugin)
  .use(initReactI18next)
  .init({
    debug: !isProd(),
    lng: 'en',
    fallbackLng: 'en',
    nsSeparator: false,
    keySeparator: false,
    saveMissing: false,
    interpolation: {
      escapeValue: false,
    },
    backend: locizeOptions,
    locizeLastUsed: locizeOptions,
    react: {
      bindI18n: 'languageChanged editorSaved',
    },
  });
