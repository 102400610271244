import React from 'react';
import type { ReactNode } from 'react';
import { useDispatch } from '../redux';
import { initialize } from '../redux/slices/User';
import PropTypes from 'prop-types';

interface AuthProviderProps {
  children: ReactNode
}

const AuthProvider = (props: AuthProviderProps) => {
  const { children } = props;
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(initialize());
  }, []);

  return (
    <>
      {children}
    </>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
