import { TablePagination, TablePaginationProps } from '@material-ui/core';
import { ChangeEvent, MouseEvent, ReactElement } from 'react';
import { calcProps, calcRenderNode, ModifyProps } from '../../utils/propHelpers';
import { useStateEffect } from '../../hooks/useStateEffect';

export interface DataGridPagination {
  page: number,
  count: number,
  rowsPerPage?: number,
  rowsPerPageOptions?: number[],
  onPageChange?: (newPage: number) => void,
  onRowsPerPageChange?: (newRowsPerPage: number) => void,
  renderPagination?: (props: TablePaginationProps) => ReactElement,
  props?: ModifyProps<TablePaginationProps>,
}

interface PaginationProps {
  children: DataGridPagination,
}

const Pagination = (props: PaginationProps) => {
  const {
    children: config,
  } = props;

  const [page, setPage] = useStateEffect<number>(config.page ?? 0);
  const [rowsPerPage, setRowsPerPage] = useStateEffect<number>(config.rowsPerPage);

  const handlePageChange = (event: MouseEvent<HTMLButtonElement> | null, newPage: number): void => {
    setPage(newPage);

    if (config.onPageChange !== undefined) {
      config.onPageChange(newPage);
    }
  };

  const handlePerPageChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newRowsPerPage = parseInt(event.target.value, 10);

    setRowsPerPage(newRowsPerPage);

    if (config.onRowsPerPageChange !== undefined) {
      config.onRowsPerPageChange(newRowsPerPage);
    }
  };

  let paginationProps: TablePaginationProps | { component: string } = {
    component: 'div',
    count: config.count,
    onPageChange: handlePageChange,
    onRowsPerPageChange: handlePerPageChange,
    page,
    rowsPerPage,
    rowsPerPageOptions: config.rowsPerPageOptions ?? [5, 10, 25],
  };

  paginationProps = calcProps<TablePaginationProps>(paginationProps as TablePaginationProps, config.props);
  return calcRenderNode<TablePaginationProps>(TablePagination, config.renderPagination, paginationProps as TablePaginationProps);
};

export default Pagination;
