import React from 'react';
import {ResourceInterface, setResourceDefaults} from './Resource';
import {RequestBodyFormMap} from '../types/FormTypes';
import {
    CreateCampaignRequestBody,
    UpdateCampaignRequestBody,
} from '../api/types/App/Request/Campaign';
import {DataGridColumns} from '../components/DataGrid/Column';
import {renderCreatedAt} from '../components/TimestampCell';
import {
    BaseCampaignView,
    FullCampaignView,
    ShortCampaignView,
} from '../api/types/App/View/Campaign';
import {
    Alert,
    Box,
    Checkbox,
    InputAdornment,
    Link,
    List,
    ListItem,
    ListItemIcon,
    Tooltip,
    Typography,
} from '@material-ui/core';
import {Link as RouterLink} from 'react-router-dom';
import {StatusLabel} from '../components/Label';
import {fromUnixTime, getUnixTime, isFuture, isPast} from 'date-fns';
import i18n from 'i18next';
import {
    timestampFormat,
    timestampFormatDistanceToNowStrict,
} from '../utils/timestampUtils';
import {
    AssignmentTurnedIn,
    Autorenew,
    Block,
    CallToAction,
    Clear,
    DeleteForever,
    DonutSmall,
    Drafts,
    ErrorOutline,
    EventBusy,
    Gavel,
    Http,
    ImageSharp,
    Link as LinkIcon,
    Category as CategoryIcon,
    MoneyOff,
    NotListedLocation,
    Schedule,
    ShoppingCart,
    ThumbUp,
} from '@material-ui/icons';
import {ActionList, ActionTypes, ClickAction} from '../types/ActionType';
import PencilAltIcon from '../icons/PencilAlt';
import {UseDialogProps} from '../hooks/useDialog';
import simpleApiCall from '../hooks/simpleApiCall';
import {history} from '../redux';
import {
    approve,
    finish,
    remove,
    removeImage,
} from '../api/endpoints/Api/Tenants/Campaigns';
import {DataGridTabs} from '../components/DataGrid/TabsToolbar';
import {
    FormLayoutBox,
    FormLayoutCard,
    FormLayoutGroup,
    WrappedContext,
} from '../types/FormLayoutType';
import {CreateCampaignLinkRequestBody} from '../api/types/App/Request/CampaignLink';
import {ListSelectorView} from '../api/types/App/View/Common';
import {getMapValue, MapValue} from '../types/MapValues';
import {Yup} from '../lib/Yup';
import {ArrayHelpers} from 'formik/dist/FieldArray';
import Plus from '../icons/Plus';
import ButtonAction from '../components/Action/ButtonAction';
import {
    ConversionTriggerRequestBody,
    ConversionTriggerSettingsRequestBody, ProductMatchTriggerSettingsRequestBody,
    SettingsRequestBody,
} from '../api/types/App/Request/Campaign/CreateCampaign';
import {getIn} from 'formik';
import FileDropZoneField from '../components/Form/FileDropZoneField';
import {remove as uploadRemove, upload} from '../api/endpoints/Api/Image';
import {UploadedImageRequestBody} from '../api/types/App/Request/Common';
import {CampaignImageView} from '../api/types/App/View/CampaignImage';
import IconButtonAction from '../components/Action/IconButtonAction';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {renderFormLayoutField} from '../components/FormLayout';
import {BaseTenantView} from '../api/types/App/View/Tenant';
import {CurrencyList, MoneyValueValidationSchema} from '../types/Money';
import MapValueChip from '../components/MapValueChip';

const resource: ResourceInterface = {};

const CampaignResource = setResourceDefaults(resource);

export default CampaignResource;

export const CampaignStatusMap: MapValue[] = [
    {
        label: 'Draft',
        value: 'draft',
        color: 'info',
        icon: <Drafts fontSize="small"/>,
    },
    {
        label: 'Upcoming',
        value: 'upcoming',
        color: 'primary',
        icon: <Schedule fontSize="small"/>,
    },
    {
        label: 'In Progress',
        value: 'in_progress',
        color: 'success',
        icon: <Autorenew fontSize="small"/>,
    },
    {
        label: 'Finished',
        value: 'finished',
        color: 'secondary',
        icon: <AssignmentTurnedIn fontSize="small"/>,
    },
    {
        label: 'Disabled',
        value: 'disabled',
        color: 'error',
        icon: <Clear fontSize="small"/>,
    },
    {
        label: 'Unknown',
        value: 'unknown',
        color: 'error',
        icon: <NotListedLocation fontSize="small"/>,
    },
];

interface ConversionTriggerTypeLabel extends MapValue {
    description: string;
    unit: string;
}

export const ConversionTriggerTypeLabels: ConversionTriggerTypeLabel[] = [
    {
        label: 'Campaign Link Click Out',
        value: 'click_out',
        description:
            'An event then someone opens campaign link (Does not requires JS Tracker).',
        icon: <LinkIcon fontSize="small"/>,
        unit: 'Click out',
        color: '#FFEA00',
    },
    // {
    //   label: 'Tracker Website Unique Visit',
    //   value: 'unique_view',
    //   description: 'An event then someone visits campaign (Requires JS Tracker).',
    //   icon: <Visibility fontSize="small" />,
    //   unit: 'Unique Visit',
    //   color: '#18FFFF',
    // },
    {
        label: 'Tracker Event',
        value: 'event',
        description:
            'An event then someone triggers tracker event (Requires JS Tracker with push event configured).',
        icon: <CallToAction fontSize="small"/>,
        unit: 'Event',
        color: '#25f900',
    },
    {
        label: 'Ecommerce Order',
        value: 'ecommerce_order',
        description:
            'An event then someone triggers ecommerce order on website (Requires JS Tracker with ecommerce event configured).',
        icon: <ShoppingCart fontSize="small"/>,
        unit: 'Order',
        color: '#FF1744',
    },
    {
        label: 'URL Visit',
        value: 'url_visit',
        description:
            'An event then someone visits specific URL on website (Requires JS Tracker).',
        icon: <Http fontSize="small"/>,
        unit: 'Visit',
        color: '#AA00FF',
    },
];

export const StringMatchTypeLabels: MapValue[] = [
    {
        value: 'exact',
        label: 'Exact Match',
    },
    // {
    //   value: 'regex',
    //   label: 'Regex',
    // },
    {
        value: 'beginsWith',
        label: 'Starts With',
    },
    {
        value: 'endsWith',
        label: 'Ends With',
    },
    {
        value: 'contains',
        label: 'Contains',
    },
];

export const ProductsFilterTypeLabels: MapValue[] = [
    {
        value: 'all',
        label: 'All',
    },
    {
        value: 'some',
        label: 'Some',
    },
    {
        value: 'none',
        label: 'None',
    },
];

export const ProductsFilterQtyTypeLabels: MapValue[] = [
    {
        value: 'eq',
        label: '=',
    },
    {
        value: 'ne',
        label: '!=',
    },
    {
        value: 'lt',
        label: '<',
    },
    {
        value: 'le',
        label: '<=',
    },
    {
        value: 'gt',
        label: '>',
    },
    {
        value: 'ge',
        label: '>=',
    },
];

export const CampaignFinishReasons: MapValue[] = [
    {
        value: 'manual',
        label: 'Manual Finish',
        icon: <Block fontSize="small"/>,
    },
    {
        value: 'expired',
        label: 'Reached end of campaign duration',
        icon: <EventBusy fontSize="small"/>,
    },
    {
        value: 'budget',
        label: 'Reached Budget Limit',
        icon: <MoneyOff fontSize="small"/>,
    },
    {
        value: 'conversion_count',
        label: 'Reached Conversion Count Limit',
        icon: <DonutSmall fontSize="small"/>,
    },
];

export type CampaignStatuses =
    | 'draft'
    | 'upcoming'
    | 'in_progress'
    | 'finished'
    | 'disabled'
    | 'unknown';

export const getCampaignStatus = (campaign: BaseCampaignView): MapValue => {
    let status = 'unknown';
    if (campaign.status === 'draft') {
        status = 'draft';
    } else if (campaign.status === 'finished') {
        status = 'finished';
    } else if (campaign.enabled === false) {
        status = 'disabled';
    } else {
        const start = fromUnixTime(campaign.start_at);
        const end = fromUnixTime(campaign.end_at);
        if (isFuture(start) && isFuture(end)) {
            status = 'upcoming';
        } else if (isPast(start) && isFuture(end)) {
            status = 'in_progress';
        } else if (isPast(start) && isPast(end)) {
            status = 'finished';
        }
    }

    return getMapValue(CampaignStatusMap, status);
};

export const getCampaignStatusText = (campaign: BaseCampaignView): MapValue => {
    const status = getCampaignStatus(campaign);

    let text = i18n.t('Unknown');
    if (status.value === 'draft') {
        text = i18n.t('Draft');
    }

    if (status.value === 'disabled') {
        text = i18n.t('Disabled');
    }

    if (status.value === 'upcoming') {
        text = i18n.t('Starts in {{time}}', {
            time: timestampFormatDistanceToNowStrict(campaign.start_at),
        });
    }

    if (status.value === 'in_progress') {
        text = i18n.t('Finishes in {{time}}', {
            time: timestampFormatDistanceToNowStrict(campaign.end_at),
        });
    }

    if (status.value === 'finished') {
        text = i18n.t('Finished {{time}} ago', {
            time: timestampFormatDistanceToNowStrict(
                campaign.finished_at ?? campaign.end_at
            ),
        });
    }

    return {
        ...status,
        label: text,
    };
};

export const CampaignPayoutUnitList: MapValue[] = [
    {
        label: 'Days',
        value: 'day',
    },
    {
        label: 'Conversions',
        value: 'conversion',
    },
    {
        label: 'Earning Amount',
        value: 'money',
    },
    {
        label: 'All After Campaign Ended',
        value: 'end_campaign',
    },
];

export const ListCampaignColumns = (): DataGridColumns<ShortCampaignView> => [
    {
        field: 'title',
        label: 'Title',
        renderCellValue: ({id, title}: ShortCampaignView) => (
            <Link color="textPrimary" component={RouterLink} to={`/campaigns/${id}`}>
                {title}
            </Link>
        ),
    },
    {
        field: 'status',
        label: 'Status',
        align: 'right',
        headerAlign: 'center',
        renderCellValue: (campaign) => (
            <>
                {campaign.finished_at !== null && (
                    <Tooltip
                        title={i18n.t('Finished {{time}} ago', {
                            time: timestampFormatDistanceToNowStrict(campaign.finished_at),
                        })}
                    >
                        <MapValueChip
                            color="error"
                            chipProps={{size: 'small'}}
                            value={getMapValue(CampaignFinishReasons, campaign.finish_reason)}
                        />
                    </Tooltip>
                )}
                {campaign.finished_at === null && (
                    <StatusLabel
                        value={getCampaignStatus(campaign)}
                        tooltip={getCampaignStatusText(campaign).label}
                    />
                )}
            </>
        ),
        cellProps: {
            sx: {
                width: '1%',
                whiteSpace: 'nowrap',
            },
        },
    },
    {
        field: 'enabled',
        label: 'Enabled',
        align: 'center',
        headerAlign: 'center',
        renderCellValue: ({enabled}: ShortCampaignView) => (
            <Checkbox checked={enabled} color="primary"/>
        ),
        cellProps: {
            sx: {
                width: '1%',
                whiteSpace: 'nowrap',
            },
        },
    },
    {
        field: 'start_at',
        label: 'Starts At',
        headerAlign: 'center',
        renderCellValue: ({start_at}: ShortCampaignView) => (
            <>
                {timestampFormat(start_at)}
                {isFuture(fromUnixTime(start_at)) && (
                    <Typography variant="subtitle2">
                        {i18n.t('Starts in {{time}}', {
                            time: timestampFormatDistanceToNowStrict(start_at),
                        })}
                    </Typography>
                )}
                {isPast(fromUnixTime(start_at)) && (
                    <Typography variant="subtitle2">
                        {i18n.t('Started {{time}} ago', {
                            time: timestampFormatDistanceToNowStrict(start_at),
                        })}
                    </Typography>
                )}
            </>
        ),
        cellProps: {
            sx: {
                width: '1%',
                whiteSpace: 'nowrap',
            },
        },
    },
    {
        field: 'end_at',
        label: 'Ends At',
        headerAlign: 'center',
        renderCellValue: ({end_at}: ShortCampaignView) => (
            <>
                {timestampFormat(end_at)}
                {isFuture(fromUnixTime(end_at)) && (
                    <Typography variant="subtitle2">
                        {i18n.t('Ends in {{time}}', {
                            time: timestampFormatDistanceToNowStrict(end_at),
                        })}
                    </Typography>
                )}
                {isPast(fromUnixTime(end_at)) && (
                    <Typography variant="subtitle2">
                        {i18n.t('Ended {{time}} ago', {
                            time: timestampFormatDistanceToNowStrict(end_at),
                        })}
                    </Typography>
                )}
            </>
        ),
        cellProps: {
            sx: {
                width: '1%',
                whiteSpace: 'nowrap',
            },
        },
    },
    {
        field: 'created_at',
        label: 'Created At',
        headerAlign: 'center',
        renderCellValue: renderCreatedAt,
        cellProps: {
            sx: {
                width: '1%',
                whiteSpace: 'nowrap',
            },
        },
    },
    // {
    //   field: 'updated_at',
    //   label: 'Updated At',
    //   renderCellValue: renderUpdatedAt,
    //   cellProps: {
    //     sx: {
    //       width: '1%',
    //       whiteSpace: 'nowrap',
    //     }
    //   },
    // },
];

export const FinishAction = (
    reloadData?: () => void
): ActionTypes<ShortCampaignView> => ({
    type: 'confirm_click',
    label: 'Finish',
    variant: 'contained',
    icon: <Gavel fontSize="small"/>,
    color: 'success',
    hidden: (context: ShortCampaignView) =>
        getCampaignStatus(context).value !== 'in_progress',
    dialog: (context: ShortCampaignView): UseDialogProps<ShortCampaignView> => ({
        title: 'Are you sure you want to finish campaign "{{name}}"?',
        description:
            'This campaign will be finished and no future conversion will be included.',
        translationParams: {
            name: context.title,
        },
        level: 'warning',
        closeOnOutsideClick: true,
        onConfirm: (c, close) =>
            simpleApiCall(finish, {
                onSuccessToastMessage: 'Campaign "{{name}}" was updated successfully',
                translationParams: {name: c.title},
                onSuccess: () => {
                    close();
                    if (reloadData !== undefined) {
                        reloadData();
                    } else {
                        history.push('/campaigns');
                    }
                },
            })({
                id: c.id,
                tenantId: c.tenant.id,
            }),
    }),
});

export const ApproveAction = (
    reloadData?: () => void
): ActionTypes<ShortCampaignView> => ({
    type: 'confirm_click',
    label: 'Approve Draft',
    variant: 'contained',
    icon: <ThumbUp fontSize="small"/>,
    color: 'success',
    hidden: (context: ShortCampaignView) => context.status !== 'draft',
    dialog: (context: ShortCampaignView): UseDialogProps<ShortCampaignView> => ({
        title: 'Are you sure you want to approve campaign "{{name}}"?',
        description:
            'This campaign will be approved and will be visible to affiliates.',
        translationParams: {
            name: context.title,
        },
        level: 'warning',
        closeOnOutsideClick: true,
        onConfirm: (c, close) =>
            simpleApiCall(approve, {
                onSuccessToastMessage: 'Campaign "{{name}}" was approved successfully',
                translationParams: {name: c.title},
                onSuccess: () => {
                    close();
                    if (reloadData !== undefined) {
                        reloadData();
                    } else {
                        history.push('/campaigns');
                    }
                },
            })({
                id: c.id,
                tenantId: c.tenant.id,
            }),
    }),
});

export const EditAction: ActionTypes<ShortCampaignView> = {
    type: 'route_link',
    label: 'Edit',
    variant: 'contained',
    icon: <PencilAltIcon fontSize="small"/>,
    to: (context) => `/campaigns/${context.id}/edit`,
};

export const DeleteAction = (
    reloadData?: () => void
): ActionTypes<ShortCampaignView> => ({
    type: 'confirm_click',
    label: 'Remove',
    variant: 'contained',
    color: 'error',
    icon: <DeleteForever fontSize="small"/>,
    dialog: (context: ShortCampaignView): UseDialogProps<ShortCampaignView> => ({
        title: 'Are you sure you want to delete campaign "{{name}}"?',
        description:
            "This campaign and all related data will be deleted immediately. You can't undo this action.",
        translationParams: {
            name: context.title,
        },
        level: 'error',
        closeOnOutsideClick: true,
        onConfirm: (c, close) =>
            simpleApiCall(remove, {
                onSuccessToastMessage: 'Campaign "{{name}}" was deleted successfully',
                translationParams: {name: c.title},
                onSuccess: () => {
                    close();
                    if (reloadData !== undefined) {
                        reloadData();
                    } else {
                        history.push('/campaigns');
                    }
                },
            })({
                id: c.id,
                tenantId: c.tenant.id,
            }),
    }),
});

export const ListCampaignActions = (
    reloadData: () => void
): ActionList<ShortCampaignView> => [EditAction, DeleteAction(reloadData)];

export const ListCampaignDataGridTabs = (): DataGridTabs => [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'Active',
        value: 'active',
    },
    {
        label: 'Upcoming',
        value: 'upcoming',
    },
    {
        label: 'Finished',
        value: 'finished',
    },
    {
        label: 'Disabled',
        value: 'disabled',
    },
];

interface CampaignLimitType extends MapValue {
    description: string;
    valueDescription: string;
}

export const CampaignLimitTypesMap: CampaignLimitType[] = [
    {
        value: 'budget',
        label: 'Budget',
        description: 'Limit campaign by maximum budget',
        valueDescription: 'Maximum campaign budget available',
    },
    {
        value: 'conversion_count',
        label: 'Conversion Count',
        description: 'Limit campaign by maximum conversion count',
        valueDescription: 'Maximum conversion count',
    },
];

export const CreateCampaignSettingsFormMap = (
    tenant?: BaseTenantView
): RequestBodyFormMap<SettingsRequestBody, any> => ({
    limit_type: {
        name: 'limit_type',
        label: 'Type',
        type: 'list',
        listValues: CampaignLimitTypesMap,
        initialValue: 'budget',
        formFieldProp: {
            margin: 'none',
            size: 'small',
        },
        helperText: ({formikContext}) =>
            getMapValue(
                CampaignLimitTypesMap,
                getIn(
                    formikContext.values,
                    `settings.${CreateCampaignSettingsFormMap().limit_type.name}`
                ),
                'budget'
            ).description,
    },
    limit_value: {
        name: 'limit_value',
        label: 'Amount',
        helperText: ({formikContext}) =>
            getMapValue(
                CampaignLimitTypesMap,
                getIn(
                    formikContext.values,
                    `settings.${CreateCampaignSettingsFormMap().limit_type.name}`
                ),
                'budget'
            ).valueDescription,
        initialValue: '1',
        validationSchema: ({formikContext}) => {
            if (
                getIn(
                    formikContext.values,
                    `settings.${CreateCampaignSettingsFormMap().limit_campaign.name}`
                ) !== true
            ) {
                return undefined;
            }

            if (
                getIn(
                    formikContext.values,
                    `settings.${CreateCampaignSettingsFormMap().limit_type.name}`
                ) === 'budget'
            ) {
                return MoneyValueValidationSchema;
            }

            return (
                Yup.number()
                    .required()
                    // eslint-disable-next-line no-template-curly-in-string
                    .integer('${path} must be an full number without decimal point')
                    .min(1)
            );
        },
        formFieldProp:
            ({formikContext}) =>
                (props) => {
                    let adornment;

                    if (
                        getIn(
                            formikContext.values,
                            `settings.${CreateCampaignSettingsFormMap().limit_type.name}`
                        ) === 'budget'
                    ) {
                        adornment = {
                            startAdornment: (
                                <InputAdornment position="start">
                                    {getMapValue(CurrencyList, tenant?.currency, 'JPY').sign}
                                </InputAdornment>
                            ),
                        };
                    }

                    return {
                        ...props,
                        margin: 'none',
                        size: 'small',
                        InputProps: adornment,
                    };
                },
    },
    payout_count: {
        name: 'payout_count',
        label: 'Every',
        helperText: 'Participants will get payout at every payout unit',
        validationSchema: Yup.number().required().min(1),
        initialValue: 1,
        formFieldProp: {
            margin: 'none',
        },
    },
    payout_requires_approval: {
        name: 'payout_requires_approval',
        label: 'Requires administrator approval for payout',
        type: 'switch',
        initialValue: false,
    },
     conversion_requires_approval: {
        name: 'conversion_requires_approval',
        label: 'Requires administrator approval of each conversion',
        type: 'switch',
        initialValue: false,
    },
    payout_unit: {
        name: 'payout_unit',
        label: 'Payout Unit',
        type: 'list',
        listValues: CampaignPayoutUnitList,
        initialValue: 'end_campaign',
        formFieldProp: {
            margin: 'none',
        },
    },
    stop_campaign_after_limit_reached: {
        name: 'stop_campaign_after_limit_reached',
        label: 'Stop campaign after limit reached',
        type: 'switch',
        initialValue: true,
    },
    limit_campaign: {
        name: 'limit_campaign',
        label: '',
        type: 'switch',
        initialValue: false,
        dontRenderLabel: true,
    },
});

export const CreateCampaignLinkFormMap: RequestBodyFormMap<CreateCampaignLinkRequestBody,
    any> = {
    title: {
        name: 'title',
        label: 'Title',
        formFieldProp: {
            size: 'small',
            margin: 'none',
        },
        initialValue: 'Main',
        validationSchema: Yup.string().required(),
    },
    url: {
        name: 'url',
        label: 'URL',
        type: 'url',
        formFieldProp: {
            size: 'small',
            margin: 'none',
        },
        initialValue: 'https://google.com',
        validationSchema: Yup.string().required().url(),
    },
};

export const CreateCampaignFormMap = (
    userGroups: ListSelectorView = {values: []},
    activeContracts: ListSelectorView = {values: []},
    categories: MapValue[] = []
): RequestBodyFormMap<CreateCampaignRequestBody, any> => ({
    category: {
        name: 'category',
        label: 'Category',
        type: 'select',
        listValues: [
            {
                value: '',
                label: '',
            },
            ...categories,
        ],
        initialValue: '',
        formFieldProp: {
            native: true,
        },
        dontTranslate: true,
    },
    all_users: {
        name: 'all_users',
        label: 'All Users',
        type: 'switch',
        initialValue: true,
        formFieldProp: {
            margin: 'none',
        },
    },
    images: {
        name: 'images',
        label: 'images',
        initialValue: [],
        validationSchema: () =>
            Yup.array().test(
                'images-uploaded',
                'There are not uploaded images present. Please upload images before submitting form.',
                (value) =>
                    value.find(
                        (img: UploadedImageRequestBody) => img.uploaded === false
                    ) === undefined
            ),
    },
    conversion_description: {
        name: 'conversion_description',
        label: 'Conversion Description',
        type: 'quill',
        initialValue: '',
        validationSchema: Yup.string().required(),
        formFieldProp: {
            placeholder: 'Conversion Description',
            margin: 'none',
            sx: {
                height: '200px',
            },
        },
    },
    conversion_triggers: {
        name: 'conversion_triggers',
        label: 'Conversion Triggers',
        type: 'form_map[]',
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        subFormMap: CreateCampaignConversionTriggerFormMap(),
        initialValue: [
            {
                type: 'click_out',
                trigger_settings: {
                    ip: false,
                    event_category: '*',
                    event_action: '*',
                    event_name: '*',
                    event_value: '*',
                    match_type: 'exact',
                    url: 'https://google.com',
                    products: [],
                    products_filter: false,
                    products_filter_type: 'all'
                },
                description: '',
                count: 1,
                payout_value: '1',
            },
        ],
        arrayValidationSchema: (shape) => Yup.array(shape).min(1),
    },
    description: {
        name: 'description',
        label: 'Description',
        type: 'quill',
        initialValue: '',
        validationSchema: Yup.string().required(),
        formFieldProp: {
            placeholder: 'Description',
            sx: {
                height: '300px',
            },
        },
    },
    enabled: {
        name: 'enabled',
        label: 'Enabled',
        type: 'switch',
        initialValue: true,
    },
    end_at: {
        name: 'end_at',
        label: 'End At',
        type: 'timestamp',
        initialValue: getUnixTime(new Date()) + 2592000,
    },
    links: {
        name: 'links',
        label: 'Campaign Links',
        type: 'form_map[]',
        subFormMap: CreateCampaignLinkFormMap,
        initialValue: [
            {
                title: 'Main',
                url: 'https://google.com',
            },
        ],
        arrayValidationSchema: (shape) => Yup.array(shape).min(1),
    },
    settings: {
        name: 'settings',
        label: 'Settings',
        type: 'form_map',
        subFormMap: CreateCampaignSettingsFormMap(),
    },
    start_at: {
        name: 'start_at',
        label: 'Start At',
        type: 'timestamp',
        initialValue: getUnixTime(new Date()),
    },
    title: {
        name: 'title',
        label: 'Title',
        initialValue: '',
        validationSchema: Yup.string().required(),
    },
    user_groups: {
        name: 'user_groups',
        label: 'User Groups',
        type: 'checkboxes',
        listValues: userGroups.values,
        initialValue: [],
        formFieldProp: {
            size: 'small',
            margin: 'none',
        },
        hidden: (context, values) => values.all_users === true,
    },
    contracts: {
        name: 'contracts',
        label: 'Contracts',
        type: 'checkboxes',
        listValues: activeContracts.values,
        initialValue: [],
        formFieldProp: {
            size: 'small',
            margin: 'none',
        },
    },
});

const addNewFieldArrayItemAction = (
    arrayHelpers: ArrayHelpers,
    initialValue: CreateCampaignLinkRequestBody
): ClickAction => ({
    type: 'click',
    label: 'Add',
    icon: <Plus fontSize="small"/>,
    variant: 'outlined',
    onClick: () => () => {
        arrayHelpers.push(initialValue);
    },
});

const removeFieldArrayItemAction = (
    index: number,
    arrayHelpers: ArrayHelpers
): ClickAction => ({
    type: 'click',
    label: 'Remove',
    color: 'error',
    icon: <DeleteForever fontSize="small"/>,
    onClick: () => () => {
        arrayHelpers.remove(index);
    },
});

export const CreateCampaignLinkFormLayout = (
    userGroups: ListSelectorView,
    activeContracts: ListSelectorView,
    categories: MapValue[],
    arrayHelpers: ArrayHelpers,
    initialValue: CreateCampaignLinkRequestBody
): FormLayoutCard<any> => ({
    type: 'card',
    title: 'Campaign Links',
    cardContentProps: {
        sx: {
            pt: 0,
        },
    },
    header: {
        action: (
            <ButtonAction
                action={addNewFieldArrayItemAction(arrayHelpers, initialValue[0])}
                context={{}}
            />
        ),
    },
    elements: [
        {
            type: 'component',
            component: List,
            elements: [
                {
                    type: 'array',
                    field: CreateCampaignFormMap(userGroups, activeContracts, categories)
                        .links,
                    values: ({formikContext}) => formikContext.values,
                    arrayElements: (index) => [
                        {
                            type: 'component',
                            component: ListItem,
                            componentProps: {
                                sx: {
                                    pt: 1,
                                    border: 1,
                                    borderColor: 'divider',
                                    borderRadius: 1,
                                    '& + &': {
                                        mt: 1,
                                    },
                                },
                            },
                            elements: [
                                {
                                    type: 'node',
                                    renderNode: () => (
                                        <ListItemIcon>
                                            <LinkIcon fontSize="small"/>
                                        </ListItemIcon>
                                    ),
                                },
                                {
                                    type: 'box',
                                    boxProps: () => (boxProps) => ({
                                        ...boxProps,
                                        flexGrow: 1,
                                    }),
                                    elements: [
                                        {
                                            type: 'row',
                                            gridProps: {
                                                spacing: 2,
                                            },

                                            elements: [
                                                {
                                                    type: 'column',
                                                    sm: 4,
                                                    xs: 12,
                                                    elements: [
                                                        {
                                                            type: 'field',
                                                            namePrefix: `${
                                                                CreateCampaignFormMap(
                                                                    userGroups,
                                                                    activeContracts,
                                                                    categories
                                                                ).links.name
                                                            }[${index}].`,
                                                            field: CreateCampaignLinkFormMap.title,
                                                        },
                                                    ],
                                                },
                                                {
                                                    type: 'column',
                                                    sm: 8,
                                                    xs: 12,
                                                    elements: [
                                                        {
                                                            type: 'field',
                                                            namePrefix: `${
                                                                CreateCampaignFormMap(
                                                                    userGroups,
                                                                    activeContracts,
                                                                    categories
                                                                ).links.name
                                                            }[${index}].`,
                                                            field: CreateCampaignLinkFormMap.url,
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    type: 'actions',
                                    label: 'Delete',
                                    actionType: 'icon',
                                    actions: [removeFieldArrayItemAction(index, arrayHelpers)],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            type: 'field_array_errors',
            field: CreateCampaignFormMap(userGroups, activeContracts, categories)
                .links,
            errors: ({formikContext}) => formikContext.errors,
            formatString: (error) => error,
        },
    ],
});

export const ProductMatchTriggerSettingsRequestBodyFormMap = (): RequestBodyFormMap<ProductMatchTriggerSettingsRequestBody, any> => ({
    sku: {
        name: "sku",
        label: "SKU",
        initialValue: "",
        formFieldProp: {
            size: 'small',
            margin: 'none',
        },
        validationSchema: Yup.string().required(),
    },
    quantity: {
        name: "quantity",
        label: "Quantity",
        initialValue: 1,
        formFieldProp: {
            size: 'small',
            margin: 'none',
            type: 'number',
        },
        validationSchema: Yup.number().required().positive().min(1).integer(),
    },
    type: {
        name: "type",
        label: "Type",
        type: 'list',
        listValues: ProductsFilterQtyTypeLabels,
        initialValue: "eq",
        formFieldProp: {
            size: 'small',
            margin: 'none',
        }
    },
})

export const CreateCampaignConversionTriggerSettingsFormMap = (
    typeField: string = ''
): RequestBodyFormMap<ConversionTriggerSettingsRequestBody, any> => ({
    event_action: {
        name: 'event_action',
        label: 'Event Action',
        initialValue: '*',
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        validationSchema: ({formikContext}) =>
            getIn(formikContext.values, typeField) !== 'event'
                ? undefined
                : Yup.string().required(),
        formFieldProp: {
            size: 'small',
        },
    },
    event_category: {
        name: 'event_category',
        label: 'Event Category',
        initialValue: '*',
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        validationSchema: ({formikContext}) =>
            getIn(formikContext.values, typeField) !== 'event'
                ? undefined
                : Yup.string().required(),
        formFieldProp: {
            size: 'small',
        },
    },
    event_name: {
        name: 'event_name',
        label: 'Event Name',
        initialValue: '*',
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        validationSchema: ({formikContext}) =>
            getIn(formikContext.values, typeField) !== 'event'
                ? undefined
                : Yup.string().required(),
        formFieldProp: {
            size: 'small',
        },
    },
    event_value: {
        name: 'event_value',
        label: 'Event Value',
        initialValue: '*',
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        validationSchema: ({formikContext}) =>
            getIn(formikContext.values, typeField) !== 'event'
                ? undefined
                : Yup.string().required(),
        formFieldProp: {
            size: 'small',
        },
    },
    ip: {
        name: 'ip',
        label: undefined,
        type: 'switch',
        initialValue: true,
        textPrimary: 'Only count unique visitor IP addresses',
        textSecondary: 'Only 1 unique visit per IP address will be counted',
    },
    match_type: {
        name: 'match_type',
        label: 'Match Type',
        type: 'list',
        listValues: StringMatchTypeLabels,
        initialValue: 'exact',
        formFieldProp: {
            size: 'small',
        },
    },
    url: {
        name: 'url',
        label: 'URL',
        initialValue: '*',
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        validationSchema: ({formikContext}) =>
            getIn(formikContext.values, typeField) !== 'url_match'
                ? undefined
                : Yup.string().required(),
        formFieldProp: {
            size: 'small',
        },
    },
    products_filter: {
        name: 'products_filter',
        label: "Order Items Filter",
        type: "switch",
        initialValue: false
    },
    products_filter_type: {
        name: 'products_filter_type',
        label: 'Condition Type',
        type: 'list',
        listValues: ProductsFilterTypeLabels,
        initialValue: "all",
        formFieldProp: {
            size: 'small'
        }
    },
    products: {
        name: 'products',
        label: 'Products',
        type: 'form_map[]',
        subFormMap: ProductMatchTriggerSettingsRequestBodyFormMap(),
        initialValue: [],
    }
});

export const CreateCampaignConversionTriggerFormMap = (
    index: number = 0,
    tenant?: BaseTenantView
): RequestBodyFormMap<ConversionTriggerRequestBody, any> => ({
    count: {
        name: 'count',
        label: 'Count',
        helperText: 'How many triggers needed for 1 payout',
        initialValue: 1,
        formFieldProp: {
            size: 'small',
        },
    },
    description: {
        name: 'description',
        label: 'Description',
        type: 'quill',
        initialValue: '',
        validationSchema: Yup.string().required(),
        formFieldProp: {
            placeholder: 'Description',
        },
    },
    payout_value: {
        name: 'payout_value',
        label: 'Amount',
        initialValue: '0',
        formFieldProp: {
            margin: 'none',
            size: 'small',
            InputProps: {
                startAdornment: (
                    <InputAdornment position="start">
                        {getMapValue(CurrencyList, tenant?.currency, 'JPY').sign}
                    </InputAdornment>
                ),
            },
        },
        validationSchema: MoneyValueValidationSchema,
    },
    trigger_settings: {
        name: 'trigger_settings',
        label: 'Trigger Settings',
        type: 'form_map',
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        subFormMap: () =>
            CreateCampaignConversionTriggerSettingsFormMap(
                `${CreateCampaignFormMap().conversion_triggers.name}[${index}].${
                    CreateCampaignConversionTriggerFormMap(index).type.name
                }`
            ),
        initialValue: {
            ip: false,
            event_category: '*',
            event_action: '*',
            event_name: '*',
            event_value: '*',
            match_type: 'exact',
            url: 'https://google.com',
            products_filter: false,
            products_filter_type: 'all',
            products: [],
        },
    },
    type: {
        name: 'type',
        label: 'Type',
        type: 'list',
        listValues: ConversionTriggerTypeLabels,
        formFieldProp: {
            margin: 'none',
            size: 'small',
        },
        initialValue: 'click_out',
        helperText: ({formikContext}) =>
            getMapValue(
                ConversionTriggerTypeLabels,
                getIn(
                    formikContext.values,
                    `${CreateCampaignFormMap().conversion_triggers.name}[${index}].${
                        CreateCampaignConversionTriggerFormMap(index).type.name
                    }`
                ),
                'click_out'
            ).description,
    },
});


const addNewFieldArrayOrderItemAction = (
    arrayHelpers: ArrayHelpers,
    initialValue: ProductMatchTriggerSettingsRequestBody
): ClickAction => ({
    type: 'click',
    label: 'Add',
    icon: <Plus fontSize="small"/>,
    variant: 'outlined',
    onClick: () => () => {
        arrayHelpers.push(initialValue);
    },
});

const removeFieldArrayOrderItemAction = (
    index: number,
    arrayHelpers: ArrayHelpers
): ClickAction => ({
    type: 'click',
    label: 'Remove',
    color: 'error',
    icon: <DeleteForever fontSize="small"/>,
    onClick: () => () => {
        arrayHelpers.remove(index);
    },
});

export const CreateProductMatchTriggerSettingsRequestBodyFormLayout = (
    prefix: string,
    tenant: BaseTenantView,
    userGroups: ListSelectorView,
    categories: MapValue[],
    arrayHelpers: ArrayHelpers
): FormLayoutBox<any> => ({
    type: "box",
    elements: [
        {
            type: 'box',
            sx: {
                display: 'flex',
                flexDirection: 'row',
            },
            elements: [
                {
                    type: 'box',
                    sx: {
                        flexGrow: 1,
                    },
                    elements: [
                        {
                            type: 'field',
                            namePrefix: prefix,
                            field: CreateCampaignConversionTriggerSettingsFormMap().products_filter_type
                        },
                    ],
                },
                {
                    type: 'box',
                    sx: {
                        mt: 2,
                    },
                    elements: [
                        {
                            type: 'actions',
                            actionType: 'button',
                            actions: [
                                addNewFieldArrayOrderItemAction(arrayHelpers, {sku: '', quantity: 1, type: 'eq'})
                            ],
                        },
                    ],
                },
            ],
        },
        {
            type: 'component',
            component: List,
            elements: [
                {
                    type: 'array',
                    namePrefix: prefix,
                    field: CreateCampaignConversionTriggerSettingsFormMap().products,
                    values: ({formikContext}) => formikContext.values,
                    arrayElements: (index) => [

                        {
                            type: 'component',
                            component: ListItem,
                            componentProps: {
                                sx: {
                                    pt: 1,
                                    border: 1,
                                    borderColor: 'divider',
                                    borderRadius: 1,
                                    '& + &': {
                                        mt: 1,
                                    },
                                },
                            },
                            elements: [
                                {
                                    type: 'node',
                                    renderNode: () => (
                                        <ListItemIcon>
                                            <CategoryIcon fontSize="small"/>
                                        </ListItemIcon>
                                    ),
                                },
                                {
                                    type: 'box',
                                    boxProps: () => (boxProps) => ({
                                        ...boxProps,
                                        flexGrow: 1,
                                    }),
                                    elements: [
                                        {
                                            type: 'row',
                                            gridProps: {
                                                spacing: 2,
                                            },

                                            elements: [
                                                {
                                                    type: 'column',
                                                    sm: 4,
                                                    xs: 12,
                                                    elements: [
                                                        {
                                                            type: 'field',
                                                            namePrefix: `${prefix}products[${index}].`,
                                                            field: ProductMatchTriggerSettingsRequestBodyFormMap().sku,
                                                        },
                                                    ],
                                                },
                                                {
                                                    type: 'column',
                                                    sm: 2,
                                                    xs: 12,
                                                    elements: [
                                                        {
                                                            type: 'field',
                                                            namePrefix: `${prefix}products[${index}].`,
                                                            field: ProductMatchTriggerSettingsRequestBodyFormMap().type,
                                                        },
                                                    ],
                                                },
                                                {
                                                    type: 'column',
                                                    sm: 4,
                                                    xs: 12,
                                                    elements: [
                                                        {
                                                            type: 'field',
                                                            namePrefix: `${prefix}products[${index}].`,
                                                            field: ProductMatchTriggerSettingsRequestBodyFormMap().quantity,
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    type: 'actions',
                                    label: 'Delete',
                                    actionType: 'icon',
                                    actions: [removeFieldArrayOrderItemAction(index, arrayHelpers)],
                                },
                            ]
                        }
                    ]
                }


            ]
        },
        {
            type: 'field_array_errors',
            namePrefix: prefix,
            field: CreateCampaignConversionTriggerSettingsFormMap().products,
            errors: ({formikContext}) => formikContext.errors,
            formatString: (error) => error,
        },
    ]
});

export const CreateCampaignConversionTriggerFormLayout = (
    tenant: BaseTenantView,
    userGroups: ListSelectorView,
    categories: MapValue[],
    arrayHelpers: ArrayHelpers,
    initialValue: ConversionTriggerRequestBody
): FormLayoutCard<any> => ({
    type: 'card',
    title: 'Conversion Triggers',
    cardContentProps: {
        sx: {
            pt: 0,
        },
    },
    header: {
        action: (
            <ButtonAction
                action={addNewFieldArrayItemAction(arrayHelpers, initialValue[0])}
                context={{}}
            />
        ),
    },
    elements: [
        {
            type: 'box',
            sx: {
                mt: 0,
            },
            elements: [
                {
                    type: 'array',
                    field: CreateCampaignFormMap().conversion_triggers,
                    values: ({formikContext}) => formikContext.values,
                    arrayElements: (index) => [
                        {
                            type: 'box',
                            sx: {
                                p: 2,
                                border: 1,
                                borderColor: 'divider',
                                borderRadius: 1,
                                '& + &': {
                                    mt: 1,
                                },
                            },
                            elements: [
                                {
                                    type: 'box',
                                    sx: {
                                        display: 'flex',
                                        flexDirection: 'row',
                                    },
                                    elements: [
                                        {
                                            type: 'box',
                                            sx: {
                                                flexGrow: 1,
                                            },
                                            elements: [
                                                {
                                                    type: 'field',
                                                    namePrefix: `${
                                                        CreateCampaignFormMap().conversion_triggers.name
                                                    }[${index}].`,
                                                    field:
                                                    CreateCampaignConversionTriggerFormMap(index).type,
                                                },
                                            ],
                                        },
                                        {
                                            type: 'box',
                                            elements: [
                                                {
                                                    type: 'actions',
                                                    actionType: 'button',
                                                    actions: [
                                                        removeFieldArrayItemAction(index, arrayHelpers),
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    type: 'row',
                                    gridProps: {
                                        spacing: 2,
                                    },
                                    elements: [
                                        {
                                            type: 'column',
                                            xs: 12,
                                            hidden: ({formikContext}) =>
                                                getIn(
                                                    formikContext.values,
                                                    `${
                                                        CreateCampaignFormMap().conversion_triggers.name
                                                    }[${index}].${
                                                        CreateCampaignConversionTriggerFormMap(index).type
                                                            .name
                                                    }`
                                                ) !== 'unique_view',
                                            elements: [
                                                {
                                                    type: 'box',
                                                    sx: {
                                                        mt: 2,
                                                        ml: 2,
                                                    },
                                                    elements: [
                                                        {
                                                            type: 'field',
                                                            namePrefix: `${
                                                                CreateCampaignFormMap().conversion_triggers.name
                                                            }[${index}].${
                                                                CreateCampaignConversionTriggerFormMap(index)
                                                                    .trigger_settings.name
                                                            }.`,
                                                            field:
                                                            CreateCampaignConversionTriggerSettingsFormMap()
                                                                .ip,
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                        {
                                            type: 'column',
                                            xs: 12,
                                            hidden: ({formikContext}) =>
                                                getIn(
                                                    formikContext.values,
                                                    `${
                                                        CreateCampaignFormMap().conversion_triggers.name
                                                    }[${index}].${
                                                        CreateCampaignConversionTriggerFormMap(index).type
                                                            .name
                                                    }`
                                                ) !== 'url_visit',
                                            elements: [
                                                {
                                                    type: 'box',
                                                    sx: {
                                                        mt: 2,
                                                    },
                                                    elements: [
                                                        {
                                                            type: 'row',
                                                            elements: [
                                                                {
                                                                    type: 'column',
                                                                    xs: 12,
                                                                    elements: [
                                                                        {
                                                                            type: 'field',
                                                                            namePrefix: `${
                                                                                CreateCampaignFormMap()
                                                                                    .conversion_triggers.name
                                                                            }[${index}].${
                                                                                CreateCampaignConversionTriggerFormMap(
                                                                                    index
                                                                                ).trigger_settings.name
                                                                            }.`,
                                                                            field:
                                                                            CreateCampaignConversionTriggerSettingsFormMap()
                                                                                .match_type,
                                                                        },
                                                                        {
                                                                            type: 'field',
                                                                            namePrefix: `${
                                                                                CreateCampaignFormMap()
                                                                                    .conversion_triggers.name
                                                                            }[${index}].${
                                                                                CreateCampaignConversionTriggerFormMap(
                                                                                    index
                                                                                ).trigger_settings.name
                                                                            }.`,
                                                                            field:
                                                                            CreateCampaignConversionTriggerSettingsFormMap()
                                                                                .url,
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                        {
                                            type: 'column',
                                            xs: 12,
                                            hidden: ({formikContext}) =>
                                                getIn(
                                                    formikContext.values,
                                                    `${
                                                        CreateCampaignFormMap().conversion_triggers.name
                                                    }[${index}].${
                                                        CreateCampaignConversionTriggerFormMap(index).type
                                                            .name
                                                    }`
                                                ) !== 'event',
                                            elements: [
                                                {
                                                    type: 'box',
                                                    sx: {
                                                        mt: 2,
                                                    },
                                                    elements: [
                                                        {
                                                            type: 'row',
                                                            elements: [
                                                                {
                                                                    type: 'column',
                                                                    xs: 12,
                                                                    md: 6,
                                                                    elements: [
                                                                        {
                                                                            type: 'field',
                                                                            namePrefix: `${
                                                                                CreateCampaignFormMap()
                                                                                    .conversion_triggers.name
                                                                            }[${index}].${
                                                                                CreateCampaignConversionTriggerFormMap(
                                                                                    index
                                                                                ).trigger_settings.name
                                                                            }.`,
                                                                            field:
                                                                            CreateCampaignConversionTriggerSettingsFormMap()
                                                                                .event_category,
                                                                        },
                                                                        {
                                                                            type: 'field',
                                                                            namePrefix: `${
                                                                                CreateCampaignFormMap()
                                                                                    .conversion_triggers.name
                                                                            }[${index}].${
                                                                                CreateCampaignConversionTriggerFormMap(
                                                                                    index
                                                                                ).trigger_settings.name
                                                                            }.`,
                                                                            field:
                                                                            CreateCampaignConversionTriggerSettingsFormMap()
                                                                                .event_action,
                                                                        },
                                                                    ],
                                                                },
                                                                {
                                                                    type: 'column',
                                                                    xs: 12,
                                                                    md: 6,
                                                                    elements: [
                                                                        {
                                                                            type: 'field',
                                                                            namePrefix: `${
                                                                                CreateCampaignFormMap()
                                                                                    .conversion_triggers.name
                                                                            }[${index}].${
                                                                                CreateCampaignConversionTriggerFormMap(
                                                                                    index
                                                                                ).trigger_settings.name
                                                                            }.`,
                                                                            field:
                                                                            CreateCampaignConversionTriggerSettingsFormMap()
                                                                                .event_name,
                                                                        },
                                                                        {
                                                                            type: 'field',
                                                                            namePrefix: `${
                                                                                CreateCampaignFormMap()
                                                                                    .conversion_triggers.name
                                                                            }[${index}].${
                                                                                CreateCampaignConversionTriggerFormMap(
                                                                                    index
                                                                                ).trigger_settings.name
                                                                            }.`,
                                                                            field:
                                                                            CreateCampaignConversionTriggerSettingsFormMap()
                                                                                .event_value,
                                                                        },
                                                                    ],
                                                                },
                                                            ],
                                                        },
                                                        {
                                                            type: 'node',
                                                            renderNode: () => (
                                                                <Box
                                                                    sx={{
                                                                        mt: 1,
                                                                    }}
                                                                >
                                                                    <Alert severity="info" variant="outlined">
                                                                        {i18n.t(
                                                                            'Use "*" to match any value in the field'
                                                                        )}
                                                                    </Alert>
                                                                </Box>
                                                            ),
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                        {
                                            type: 'column',
                                            xs: 12,
                                            hidden: ({formikContext}) =>
                                                getIn(
                                                    formikContext.values,
                                                    `${
                                                        CreateCampaignFormMap().conversion_triggers.name
                                                    }[${index}].${
                                                        CreateCampaignConversionTriggerFormMap(index).type
                                                            .name
                                                    }`
                                                ) !== 'ecommerce_order',
                                            elements: [
                                                {
                                                    type: 'field',
                                                    namePrefix: `${
                                                        CreateCampaignFormMap()
                                                            .conversion_triggers.name
                                                    }[${index}].${
                                                        CreateCampaignConversionTriggerFormMap(
                                                            index
                                                        ).trigger_settings.name
                                                    }.`,
                                                    field:
                                                    CreateCampaignConversionTriggerSettingsFormMap()
                                                        .products_filter,
                                                },
                                                {
                                                    type: 'box',
                                                    hidden: ({formikContext}) =>
                                                        getIn(
                                                            formikContext.values,
                                                            `${
                                                                CreateCampaignFormMap()
                                                                    .conversion_triggers.name
                                                            }[${index}].${
                                                                CreateCampaignConversionTriggerFormMap(
                                                                    index
                                                                ).trigger_settings.name
                                                            }.${CreateCampaignConversionTriggerSettingsFormMap().products_filter.name}`
                                                        ) !== true,
                                                    elements: [
                                                        {
                                                            type: 'field_array',
                                                            title: 'Order Item Filter',
                                                            namePrefix: `${
                                                                CreateCampaignFormMap()
                                                                    .conversion_triggers.name
                                                            }[${index}].${
                                                                CreateCampaignConversionTriggerFormMap(
                                                                    index
                                                                ).trigger_settings.name
                                                            }.`,
                                                            field: CreateCampaignConversionTriggerSettingsFormMap().products,
                                                            formLayout: (
                                                                productFilterArrayHelpers
                                                            ) =>
                                                                CreateProductMatchTriggerSettingsRequestBodyFormLayout(
                                                                    `${
                                                                        CreateCampaignFormMap()
                                                                            .conversion_triggers.name
                                                                    }[${index}].${
                                                                        CreateCampaignConversionTriggerFormMap(
                                                                            index
                                                                        ).trigger_settings.name
                                                                    }.`,
                                                                    tenant,
                                                                    userGroups,
                                                                    categories,
                                                                    productFilterArrayHelpers
                                                                ),
                                                        },
                                                    ]
                                                }
                                            ],
                                        },
                                        {
                                            type: 'column',
                                            xs: 12,
                                            elements: [
                                                {
                                                    type: 'field',
                                                    namePrefix: `${
                                                        CreateCampaignFormMap().conversion_triggers.name
                                                    }[${index}].`,
                                                    field:
                                                    CreateCampaignConversionTriggerFormMap(index)
                                                        .description,
                                                },
                                            ],
                                        },
                                        // {
                                        //   type: 'column',
                                        //   xs: 12,
                                        //   elements: [
                                        //     {
                                        //       type: 'field',
                                        //       namePrefix: `${CreateCampaignFormMap().conversion_triggers.name}[${index}].`,
                                        //       field: CreateCampaignConversionTriggerFormMap(index).count,
                                        //     },
                                        //   ],
                                        // },
                                        {
                                            type: 'column',
                                            xs: 12,
                                            elements: [
                                                {
                                                    type: 'field',
                                                    namePrefix: `${
                                                        CreateCampaignFormMap().conversion_triggers.name
                                                    }[${index}].`,
                                                    field: CreateCampaignConversionTriggerFormMap(
                                                        index,
                                                        tenant
                                                    ).payout_value,
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            type: 'field_array_errors',
            field: CreateCampaignFormMap().conversion_triggers,
            errors: ({formikContext}) => formikContext.errors,
            formatString: (error) => error,
        },
    ],
});

export const CreateCampaignFormLayout = (
    tenant: BaseTenantView,
    userGroups: ListSelectorView,
    activeContracts: ListSelectorView,
    categories: MapValue[]
): FormLayoutGroup<any> => ({
    type: 'group',
    elements: [
        {
            type: 'row',
            elements: [
                {
                    type: 'column',
                    xs: 12,
                    md: 6,
                    lg: 8,
                    elements: [
                        {
                            type: 'card',
                            elements: [
                                {
                                    type: 'field',
                                    field: CreateCampaignFormMap().title,
                                },
                                {
                                    type: 'field',
                                    field: CreateCampaignFormMap().description,
                                },
                            ],
                        },
                        {
                            type: 'card',
                            title: 'Upload Images',
                            elements: [
                                {
                                    type: 'node',
                                    renderNode: (
                                        <FileDropZoneField
                                            fieldName="images"
                                            uploadEndpoint={upload}
                                            removeEndpoint={uploadRemove}
                                            accept="image/*"
                                            maxSize={2 * 1024 * 1024}
                                        />
                                    ),
                                },
                                {
                                    type: 'field_array_errors',
                                    field: CreateCampaignFormMap().images,
                                    errors: ({formikContext}) => formikContext.errors,
                                    formatString: (error) => error,
                                },
                            ],
                        },
                        {
                            type: 'field_array',
                            field: CreateCampaignFormMap().links,
                            formLayout: (
                                arrayHelpers,
                                initialValue: CreateCampaignLinkRequestBody
                            ) =>
                                CreateCampaignLinkFormLayout(
                                    userGroups,
                                    activeContracts,
                                    categories,
                                    arrayHelpers,
                                    initialValue
                                ),
                        },
                        {
                            type: 'card',
                            title: 'User Groups',
                            cardContentProps: {
                                sx: {
                                    pt: 0,
                                },
                            },
                            elements: [
                                {
                                    type: 'row',
                                    gridProps: () => (gridProps) => ({
                                        ...gridProps,
                                        spacing: 0,
                                    }),
                                    elements: [
                                        {
                                            type: 'column',
                                            sx: {
                                                alignItems: 'center',
                                                display: 'flex',
                                                pt: 0,
                                            },
                                            elements: [
                                                {
                                                    type: 'field',
                                                    field: CreateCampaignFormMap(
                                                        userGroups,
                                                        activeContracts,
                                                        categories
                                                    ).all_users,
                                                },
                                                {
                                                    type: 'box',
                                                    sx: {
                                                        ml: 2,
                                                    },
                                                    elements: [
                                                        {
                                                            type: 'field',
                                                            field: CreateCampaignFormMap(
                                                                userGroups,
                                                                activeContracts,
                                                                categories
                                                            ).user_groups,
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            type: 'card',
                            title: 'Connections Contracts',
                            hidden: () => activeContracts.values.length === 0,
                            cardContentProps: {
                                sx: {
                                    pt: 0,
                                },
                            },
                            elements: [
                                {
                                    type: 'row',
                                    gridProps: () => (gridProps) => ({
                                        ...gridProps,
                                        spacing: 0,
                                    }),
                                    elements: [
                                        {
                                            type: 'column',
                                            sx: {
                                                alignItems: 'center',
                                                display: 'flex',
                                                pt: 0,
                                            },
                                            elements: [
                                                {
                                                    type: 'box',
                                                    sx: {
                                                        ml: 2,
                                                    },
                                                    elements: [
                                                        {
                                                            type: 'field',
                                                            field: CreateCampaignFormMap(
                                                                userGroups,
                                                                activeContracts,
                                                                categories
                                                            ).contracts,
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            type: 'card',
                            title: 'Conversion Description',
                            elements: [
                                {
                                    type: 'field',
                                    field: CreateCampaignFormMap().conversion_description,
                                },
                            ],
                        },
                        {
                            type: 'field_array',
                            title: 'Conversion Triggers',
                            field: CreateCampaignFormMap().conversion_triggers,
                            formLayout: (
                                arrayHelpers,
                                initialValue: ConversionTriggerRequestBody
                            ) =>
                                CreateCampaignConversionTriggerFormLayout(
                                    tenant,
                                    userGroups,
                                    categories,
                                    arrayHelpers,
                                    initialValue
                                ),
                        },
                        // {
                        //   type: 'card',
                        //   title: 'Payout Settings',
                        //   elements: [
                        //     {
                        //       type: 'row',
                        //       elements: [
                        //         {
                        //           type: 'column',
                        //           xs: 8,
                        //           elements: [
                        //             {
                        //               type: 'field',
                        //               namePrefix: 'settings.',
                        //               field: CreateCampaignSettingsFormMap().payout_count,
                        //             },
                        //           ],
                        //         },
                        //         {
                        //           type: 'column',
                        //           xs: 4,
                        //           elements: [
                        //             {
                        //               type: 'field',
                        //               namePrefix: 'settings.',
                        //               field: CreateCampaignSettingsFormMap().payout_unit,
                        //             },
                        //           ],
                        //         },
                        //       ],
                        //     },
                        //     {
                        //       type: 'row',
                        //       elements: [
                        //         {
                        //           type: 'column',
                        //           elements: [
                        //             {
                        //               type: 'field',
                        //               namePrefix: 'settings.',
                        //               field: CreateCampaignSettingsFormMap().payout_requires_approval,
                        //             },
                        //           ],
                        //         },
                        //       ],
                        //     },
                        //   ],
                        // },
                        {
                            type: 'card',
                            title: 'Campaign Limit',
                            header: (context) => (props) => ({
                                ...props,
                                action: renderFormLayoutField(
                                    {
                                        type: 'field',
                                        namePrefix: 'settings.',
                                        field: CreateCampaignSettingsFormMap().limit_campaign,
                                    },
                                    context
                                ),
                            }),
                            elements: [
                                {
                                    type: 'group',
                                    hidden: ({formikContext}) =>
                                        getIn(
                                            formikContext.values,
                                            `${CreateCampaignFormMap().settings.name}.${
                                                CreateCampaignSettingsFormMap().limit_campaign.name
                                            }`
                                        ) !== true,
                                    elements: [
                                        {
                                            type: 'row',
                                            elements: [
                                                {
                                                    type: 'column',
                                                    xs: 12,
                                                    elements: [
                                                        {
                                                            type: 'field',
                                                            namePrefix: 'settings.',
                                                            field: CreateCampaignSettingsFormMap().limit_type,
                                                        },
                                                    ],
                                                },
                                                {
                                                    type: 'column',
                                                    xs: 12,
                                                    elements: [
                                                        {
                                                            type: 'field',
                                                            namePrefix: 'settings.',
                                                            field:
                                                            CreateCampaignSettingsFormMap(tenant)
                                                                .limit_value,
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    type: 'column',
                    xs: 12,
                    md: 6,
                    lg: 4,
                    elements: [
                        {
                            type: 'card',
                            title: 'Settings',
                            cardContentProps: {
                                sx: {
                                    pt: 0,
                                },
                            },
                            elements: [
                                {
                                    type: 'field',
                                    field: CreateCampaignFormMap(
                                        userGroups,
                                        activeContracts,
                                        categories
                                    ).category,
                                },
                                {
                                    type: 'field',
                                    field: CreateCampaignFormMap().start_at,
                                },
                                {
                                    type: 'field',
                                    field: CreateCampaignFormMap().end_at,
                                },
                                {
                                  type: 'field',
                                  namePrefix: 'settings.',
                                  field: CreateCampaignSettingsFormMap(tenant).conversion_requires_approval,
                                },
                                {
                                    type: 'field',
                                    field: CreateCampaignFormMap().enabled,
                                },
                            ],
                        },
                        {
                            type: 'box',
                            sx: {
                                display: 'flex',
                                justifyContent: 'flex-end',
                                mt: 3,
                            },
                            elements: [
                                {
                                    type: 'form_button',
                                    label: ({formikContext}) =>
                                        formikContext.submitCount === 0 || formikContext.isValid
                                            ? 'Create Campaign'
                                            : 'There are some errors',
                                    color: ({formikContext}) =>
                                        formikContext.submitCount === 0 || formikContext.isValid
                                            ? 'primary'
                                            : 'error',
                                    actionDisabled: ({formikContext}) =>
                                        formikContext.submitCount === 0 || formikContext.isValid
                                            ? undefined
                                            : false,
                                    startIcon: ({formikContext}: WrappedContext) =>
                                        formikContext.submitCount === 0 ||
                                        formikContext.isValid ? undefined : (
                                            <ErrorOutline fontSize="small"/>
                                        ),
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
});

export const DeleteImageAction = (
    campaign: BaseCampaignView,
    reloadData?: () => void
): ActionTypes<CampaignImageView> => ({
    type: 'confirm_click',
    label: 'Remove Image',
    variant: 'contained',
    color: 'error',
    icon: <DeleteForever fontSize="small"/>,
    dialog: (): UseDialogProps<CampaignImageView> => ({
        title: 'Are you sure you want to delete this image??',
        level: 'error',
        closeOnOutsideClick: true,
        onConfirm: (c, close) =>
            simpleApiCall(removeImage, {
                onSuccessToastMessage: 'Image was deleted successfully',
                onSuccess: () => {
                    close();

                    reloadData();
                },
            })({
                id: c.id,
                campaignId: campaign.id,
                tenantId: campaign.tenant.id,
            }),
    }),
});

export const UpdateCampaignFormMap = (
    categories: MapValue[]
): RequestBodyFormMap<UpdateCampaignRequestBody, FullCampaignView> => ({
    category: {
        name: 'category',
        label: 'Category',
        type: 'select',
        listValues: [
            {
                value: '',
                label: '',
            },
            ...categories,
        ],
        initialValue: ({category}: FullCampaignView) =>
            String(category?.id ?? ''),
        formFieldProp: {
            native: true,
        },
        dontTranslate: true,
    },
    enabled: {
        name: 'enabled',
        label: 'Enabled',
        type: 'switch',
        initialValue: true,
    },
    images: {
        name: 'images',
        label: 'images',
    },
    new_images: {
        name: 'new_images',
        label: 'images',
        initialValue: [],
        validationSchema: () =>
            Yup.array().test(
                'images-uploaded',
                'There are not uploaded images present. Please upload images before submitting form.',
                (value) =>
                    value.find(
                        (img: UploadedImageRequestBody) => img.uploaded === false
                    ) === undefined
            ),
    },
    conversion_description: {
        name: 'conversion_description',
        label: 'Conversion Description',
        type: 'quill',
        initialValue: '',
        validationSchema: Yup.string().required(),
        formFieldProp: {
            placeholder: 'Conversion Description',
            margin: 'none',
            sx: {
                height: '200px',
            },
        },
    },
    description: {
        name: 'description',
        label: 'Description',
        type: 'quill',
        initialValue: '',
        validationSchema: Yup.string().required(),
        formFieldProp: {
            placeholder: 'Description',
            sx: {
                height: '300px',
            },
        },
    },
    end_at: {
        name: 'end_at',
        label: 'End At',
        type: 'timestamp',
        initialValue: getUnixTime(new Date()) + 2592000,
    },
    settings: {
        name: 'settings',
        label: 'Settings',
        type: 'form_map',
        subFormMap: CreateCampaignSettingsFormMap(),
    },
    start_at: {
        name: 'start_at',
        label: 'Start At',
        type: 'timestamp',
        initialValue: getUnixTime(new Date()),
    },
    title: {
        name: 'title',
        label: 'Title',
        initialValue: '',
        validationSchema: Yup.string().required(),
    },
});

export const UpdateCampaignFormLayout = (
    campaign: FullCampaignView,
    categories: MapValue[],
    reloadApiData: () => void
): FormLayoutGroup<FullCampaignView> => ({
    type: 'group',
    elements: [
        {
            type: 'row',
            elements: [
                {
                    type: 'column',
                    xs: 12,
                    md: 6,
                    lg: 8,
                    elements: [
                        {
                            type: 'card',
                            elements: [
                                {
                                    type: 'field',
                                    field: UpdateCampaignFormMap(categories).title,
                                },
                                {
                                    type: 'field',
                                    field: UpdateCampaignFormMap(categories).description,
                                },
                            ],
                        },
                        {
                            type: 'card',
                            title: 'Images',
                            elements: [
                                {
                                    type: 'node',
                                    renderNode: (context: WrappedContext<FullCampaignView>) => {
                                        const {formikContext} = context;
                                        const field = UpdateCampaignFormMap(categories).images.name;
                                        const imageList = getIn(formikContext.values, field);
                                        const reorder = (list, startIndex, endIndex) => {
                                            const result = Array.from(list);
                                            const [removed] = result.splice(startIndex, 1);
                                            result.splice(endIndex, 0, removed);

                                            return result;
                                        };

                                        const onDragEnd = (result) => {
                                            if (!result.destination) {
                                                return;
                                            }
                                            const images = reorder(
                                                imageList,
                                                result.source.index,
                                                result.destination.index
                                            );

                                            formikContext.setFieldValue(field, images);
                                        };

                                        return (
                                            <DragDropContext onDragEnd={onDragEnd}>
                                                <Droppable droppableId="droppable">
                                                    {(provided) => (
                                                        <List
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                        >
                                                            {imageList.map(
                                                                (image: CampaignImageView, index) => (
                                                                    <Draggable
                                                                        key={image.id}
                                                                        draggableId={image.id}
                                                                        index={index}
                                                                    >
                                                                        {(itemProvided) => (
                                                                            <ListItem
                                                                                ref={itemProvided.innerRef}
                                                                                ContainerProps={{
                                                                                    ref: itemProvided.innerRef,
                                                                                }}
                                                                                {...itemProvided.draggableProps}
                                                                                {...itemProvided.dragHandleProps}
                                                                                sx={{
                                                                                    border: 1,
                                                                                    borderColor: 'divider',
                                                                                    borderRadius: 1,
                                                                                    '& + &': {
                                                                                        mt: 1,
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <ListItemIcon>
                                                                                    <ImageSharp fontSize="small"/>
                                                                                </ListItemIcon>
                                                                                <Box flexGrow={1}>
                                                                                    <img
                                                                                        src={image.url}
                                                                                        alt={image.id}
                                                                                        style={{maxHeight: '100px'}}
                                                                                    />
                                                                                </Box>
                                                                                <IconButtonAction
                                                                                    context={image}
                                                                                    action={DeleteImageAction(
                                                                                        campaign,
                                                                                        reloadApiData
                                                                                    )}
                                                                                />
                                                                            </ListItem>
                                                                        )}
                                                                    </Draggable>
                                                                )
                                                            )}
                                                            {provided.placeholder}
                                                        </List>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        );
                                    },
                                },
                            ],
                        },
                        {
                            type: 'card',
                            title: 'Upload New Images',
                            elements: [
                                {
                                    type: 'node',
                                    renderNode: (
                                        <FileDropZoneField
                                            fieldName="new_images"
                                            uploadEndpoint={upload}
                                            removeEndpoint={uploadRemove}
                                            accept="image/*"
                                            maxSize={2 * 1024 * 1024}
                                        />
                                    ),
                                },
                                {
                                    type: 'field_array_errors',
                                    field: UpdateCampaignFormMap(categories).new_images,
                                    errors: ({formikContext}) => formikContext.errors,
                                    formatString: (error) => error,
                                },
                            ],
                        },
                        {
                            type: 'card',
                            title: 'Conversion Description',
                            elements: [
                                {
                                    type: 'field',
                                    field:
                                    UpdateCampaignFormMap(categories).conversion_description,
                                },
                            ],
                        },
                        // {
                        //   type: 'card',
                        //   title: 'Payout Settings',
                        //   elements: [
                        //     {
                        //       type: 'row',
                        //       elements: [
                        //         {
                        //           type: 'column',
                        //           xs: 8,
                        //           elements: [
                        //             {
                        //               type: 'field',
                        //               namePrefix: 'settings.',
                        //               field: CreateCampaignSettingsFormMap().payout_count,
                        //             },
                        //           ],
                        //         },
                        //         {
                        //           type: 'column',
                        //           xs: 4,
                        //           elements: [
                        //             {
                        //               type: 'field',
                        //               namePrefix: 'settings.',
                        //               field: CreateCampaignSettingsFormMap().payout_unit,
                        //             },
                        //           ],
                        //         },
                        //       ],
                        //     },
                        //     {
                        //       type: 'row',
                        //       elements: [
                        //         {
                        //           type: 'column',
                        //           elements: [
                        //             {
                        //               type: 'field',
                        //               namePrefix: 'settings.',
                        //               field: CreateCampaignSettingsFormMap().payout_requires_approval,
                        //             },
                        //           ],
                        //         },
                        //       ],
                        //     },
                        //   ],
                        // },
                        {
                            type: 'card',
                            title: 'Campaign Limit',
                            header: (context) => (props) => ({
                                ...props,
                                action: renderFormLayoutField(
                                    {
                                        type: 'field',
                                        namePrefix: 'settings.',
                                        field: CreateCampaignSettingsFormMap().limit_campaign,
                                    },
                                    context
                                ),
                            }),
                            elements: [
                                {
                                    type: 'group',
                                    hidden: ({formikContext}) =>
                                        getIn(
                                            formikContext.values,
                                            `${UpdateCampaignFormMap(categories).settings.name}.${
                                                CreateCampaignSettingsFormMap().limit_campaign.name
                                            }`
                                        ) !== true,
                                    elements: [
                                        {
                                            type: 'row',
                                            elements: [
                                                {
                                                    type: 'column',
                                                    xs: 12,
                                                    elements: [
                                                        {
                                                            type: 'field',
                                                            namePrefix: 'settings.',
                                                            field: CreateCampaignSettingsFormMap().limit_type,
                                                        },
                                                    ],
                                                },
                                                {
                                                    type: 'column',
                                                    xs: 12,
                                                    elements: [
                                                        {
                                                            type: 'field',
                                                            namePrefix: 'settings.',
                                                            field: CreateCampaignSettingsFormMap(
                                                                campaign.tenant
                                                            ).limit_value,
                                                        },
                                                    ],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                {
                    type: 'column',
                    xs: 12,
                    md: 6,
                    lg: 4,
                    elements: [
                        {
                            type: 'card',
                            title: 'Settings',
                            cardContentProps: {
                                sx: {
                                    pt: 0,
                                },
                            },
                            elements: [
                                {
                                    type: 'field',
                                    field: UpdateCampaignFormMap(categories).category,
                                },
                                {
                                    type: 'field',
                                    field: UpdateCampaignFormMap(categories).start_at,
                                },
                                {
                                    type: 'field',
                                    field: UpdateCampaignFormMap(categories).end_at,
                                },
                                {
                                    type: 'field',
                                    field: UpdateCampaignFormMap(categories).enabled,
                                },
                            ],
                        },
                        {
                            type: 'box',
                            sx: {
                                display: 'flex',
                                justifyContent: 'flex-end',
                                mt: 3,
                            },
                            elements: [
                                {
                                    type: 'form_button',
                                    label: ({formikContext}) =>
                                        formikContext.submitCount === 0 || formikContext.isValid
                                            ? 'Update Campaign'
                                            : 'There are some errors',
                                    color: ({formikContext}) =>
                                        formikContext.submitCount === 0 || formikContext.isValid
                                            ? 'primary'
                                            : 'error',
                                    actionDisabled: ({formikContext}) =>
                                        formikContext.submitCount === 0 || formikContext.isValid
                                            ? undefined
                                            : false,
                                    startIcon: ({formikContext}: WrappedContext) =>
                                        formikContext.submitCount === 0 ||
                                        formikContext.isValid ? undefined : (
                                            <ErrorOutline fontSize="small"/>
                                        ),
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
});
