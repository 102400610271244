import React from 'react';
import { FullInvoiceView, InvoiceRowView } from '../../api/types/App/View/Invoice';
import {
  Box,
  Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import Scrollbar from '../Scrollbar';
import Logo from '../Logo';
import { formatMoneyValue } from '../../types/Money';
import { useTranslation } from 'react-i18next';
import numeral from '../../lib/numeral';
import { timestampFormat } from '../../utils/timestampUtils';

interface InvoicePreviewProps {
  invoice: FullInvoiceView;
  renderRowTitle?: (title: string, row: InvoiceRowView) => React.ReactNode,
}

const InvoicePreview = (props: InvoicePreviewProps) => {
  const {
    invoice,
    renderRowTitle = (title) => title,
    ...other
  } = props;

  const { t } = useTranslation();

  return (
    <Paper {...other}>
      <Scrollbar>
        <Box
          sx={{
            minWidth: 800,
            p: 6,
          }}
        >
          <Grid
            container
            justifyContent="space-between"
          >
            <Grid item>
              <Logo />
              <Typography
                color="textPrimary"
                variant="h6"
              >
                www.baller.co.jp
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                align="right"
                color="textPrimary"
                variant="h4"
              >
                {t(invoice.paid === true ? 'Paid' : 'Not Paid')}
              </Typography>
              <Typography
                align="right"
                color="textPrimary"
                variant="subtitle2"
              >
                {t('Invoice # {{nr}}', { nr: invoice.number })}
              </Typography>
              <Typography
                align="right"
                color="textPrimary"
                variant="subtitle2"
              >
                {timestampFormat(invoice.created_at, 'PP')}
              </Typography>
            </Grid>
          </Grid>
          {invoice.paid === true && (
          <Box sx={{ my: 4 }}>
            <Grid
              container
              justifyContent="space-between"
            >
              <Grid item>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="subtitle2"
                >
                  {t('Paid At')}
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body2"
                >
                  {timestampFormat(invoice.paid_at)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="subtitle2"
                >
                  {t('Payment Type')}
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body2"
                >
                  {invoice.payment_type}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="subtitle2"
                >
                  {t('Payment Reference #')}
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body2"
                >
                  {invoice.payment_reference_number}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          )}
          <Box sx={{ my: 4 }}>
            <Grid
              container
              justifyContent="space-between"
            >
              <Grid item>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="subtitle1"
                >
                  {t('Billed to')}
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body2"
                >
                  {invoice.from.name}
                  <br />
                  {invoice.from.address1}
                  <br />
                  {invoice.from.address2}
                  <br />
                  {invoice.from.zip_code}
                  {' '}
                  {invoice.from.city}
                  <br />
                  {invoice.from.country}
                  <br />
                  <Link component="a" href={`tel:${invoice.from.telephone}`}>{invoice.from.telephone}</Link>
                  <br />
                  <Link component="a" href={`mailto:${invoice.from.email}`}>{invoice.from.email}</Link>
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="subtitle1"
                  align="right"
                >
                  {t('Receiver')}
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="body2"
                  align="right"
                >
                  {invoice.to.name}
                  <br />
                  {invoice.to.address1}
                  <br />
                  {invoice.to.address2}
                  <br />
                  {invoice.to.zip_code}
                  {' '}
                  {invoice.to.city}
                  <br />
                  {invoice.to.country}
                  <br />
                  <Link component="a" href={`tel:${invoice.to.telephone}`}>{invoice.to.telephone}</Link>
                  <br />
                  <Link component="a" href={`mailto:${invoice.to.email}`}>{invoice.to.email}</Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {t('Description')}
                </TableCell>
                <TableCell align="center">
                  {t('Unit Price')}
                </TableCell>
                <TableCell align="center">
                  {t('Quantity')}
                </TableCell>
                <TableCell align="right">
                  {t('Amount')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoice.rows.map((row) => (
                <TableRow key={row.id}>
                  <TableCell>
                    {renderRowTitle(row.title, row)}
                  </TableCell>
                  <TableCell align="center">
                    {formatMoneyValue(row.price)}
                  </TableCell>
                  <TableCell align="center">
                    {numeral(row.count)
                      .format('0,0')}
                  </TableCell>
                  <TableCell align="right">
                    {formatMoneyValue(row.total)}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell colSpan={3} align="right">
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    {t('Sub-total')}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {formatMoneyValue(invoice.sub_total)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} align="right">
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                  }}
                  >
                    {invoice.taxes_description !== '' && (
                      <Typography variant="body2" gutterBottom>
                        {`(${invoice.taxes_description})`}
                      </Typography>
                    )}
                    <Typography
                      sx={{ ml: 1 }}
                      color="textPrimary"
                      gutterBottom
                      variant="subtitle2"
                    >
                      {t('Taxes')}
                    </Typography>
                  </Box>
                </TableCell>
                <TableCell align="right">
                  {formatMoneyValue(invoice.taxes)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} align="right">
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="subtitle2"
                  >
                    {t('Total')}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  {formatMoneyValue(invoice.total)}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Box sx={{ mt: 2 }}>
            {invoice.notes !== '' && (
              <>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h6"
                >
                  {t('Notes')}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body2"
                >
                  {invoice.notes}
                </Typography>
              </>
            )}
          </Box>
        </Box>
      </Scrollbar>
    </Paper>
  );
};

export default InvoicePreview;
