import type { FormEvent } from 'react';
import { useState } from 'react';
import { Box, Button, Card, FormHelperText, Paper, Radio, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TenantUserRoleType } from '../../../resources/TenantUser';
import { BaseUserCreateStep } from '../TenantUserCreateWizard';

interface UserTypeStepProps extends BaseUserCreateStep {
  onNext?: (roles: TenantUserRoleType[]) => void;
  onBack?: () => void;
  onComplete?: () => void;
  roles?: string[],
}

const typeOptions = [
  {
    description: 'User which can participate in campaigns',
    title: 'Affiliate',
    value: ['ROLE_PARTICIPANT'],
  },
  {
    description: 'User which can manage this account.',
    title: 'Manager',
    value: ['ROLE_ADMIN'],
  },
  {
    description: 'User which can manage this account and participate in campaigns',
    title: 'Affiliate & Manager',
    value: ['ROLE_PARTICIPANT', 'ROLE_ADMIN'],
  },
];

const UserTypeStep = (
  {
    onBack,
    onNext,
    roles,
    stepNumber,
    userType,
    ...other
  }: UserTypeStepProps,
) => {
  let filteredOptions = typeOptions;
  if (roles && roles.length > 0) {
    filteredOptions = filteredOptions.filter((o) => o.value.every((v) => roles.includes(v)));
  }

  const { t } = useTranslation();
  const [type, setType] = useState<TenantUserRoleType[]>(filteredOptions[0].value as TenantUserRoleType[]);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const handleChange = (newType: TenantUserRoleType[]): void => {
    setType(newType);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    try {
      setIsSubmitting(true);

      // NOTE: Make API request

      if (onNext) {
        onNext(type as TenantUserRoleType[]);
      }
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      {...other}
    >
      <Card sx={{ p: 3 }}>
        <Typography
          color="textPrimary"
          variant="h6"
        >
          {`${stepNumber}. ${t('Select {{type}} type', { type: userType })}`}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body1"
        >
          {t('Which kind of {{type}} would you like to create?', { type: userType })}
        </Typography>
        <Box sx={{ mt: 2 }}>
          {filteredOptions.map((typeOption) => (
            <Paper
              key={typeOption.title}
              sx={{
                alignItems: 'flex-start',
                display: 'flex',
                mb: 2,
                padding: 2,
              }}
              variant="outlined"
            >
              <Radio
                checked={type === typeOption.value}
                color="primary"
                onClick={(): void => handleChange(typeOption.value as TenantUserRoleType[])}
              />
              <Box sx={{ ml: 2 }}>
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {t(typeOption.title)}
                </Typography>
                <Typography
                  color="textSecondary"
                  variant="body2"
                >
                  {t(typeOption.description)}
                </Typography>
              </Box>
            </Paper>
          ))}
        </Box>
        {error && (
          <Box sx={{ mt: 2 }}>
            <FormHelperText error>
              {error}
            </FormHelperText>
          </Box>
        )}
        <Box
          sx={{
            display: 'flex',
            mt: 6,
          }}
        >
          {onBack && (
            <Button
              color="primary"
              onClick={onBack}
              size="large"
              variant="text"
            >
              {t('Previous')}
            </Button>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Button
            color="primary"
            disabled={isSubmitting}
            type="submit"
            variant="contained"
          >
            {t('Next')}
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default UserTypeStep;
