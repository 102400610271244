import React from 'react';
import Page, { PageProps } from '../../components/Page';
import ArrowLeft from '../../icons/ArrowLeft';
import FormikForm from '../../components/FormikForm';
import { create } from '../../api/endpoints/Api/Tenants/UserGroups';
import useAuth from '../../hooks/useAuth';
import { Redirect } from 'react-router-dom';
import {
  CreateTenantUserGroupFormLayout,
  CreateTenantUserGroupFormMap,
} from '../../resources/TenantUserGroup';
import { DashboardType } from '../../redux/slices/User';

const CreateAffiliateGroupPage = () => {
  const { currentDashboard } = useAuth();

  if (currentDashboard.type !== DashboardType.UserTenantAdmin) {
    return (<Redirect to="/" />);
  }

  const pageProps: PageProps = {
    pageHeaderProps: {
      title: 'Add new affiliate group',
      translationParams: {},
      actions: [
        {
          type: 'route_link',
          to: () => '/affiliate/groups',
          label: 'Cancel',
          icon: <ArrowLeft fontSize="small" />,
          variant: 'contained',
          color: 'secondary',
        },
      ],
      breadCrumbs: {
        items: [
          {
            label: 'Dashboard',
            to: '/dashboard',
          },
          {
            label: 'Affiliate Groups',
            to: '/affiliate/groups',
          },
        ],
      },
    },
    title: 'Add new affiliate group',
  };

  return (
    <Page {...pageProps}>
      <FormikForm
        endpoint={create} formMap={CreateTenantUserGroupFormMap()}
        formLayout={CreateTenantUserGroupFormLayout()} context={{}}
        onSuccessMessage={'Affiliate group "{{name}}" was created successfully!'}
        onSuccessMessageParams={((response) => ({
          name: response.data.title,
        }))}
        onSuccessRedirectTo={(response) => `/affiliate/groups/${response.data.id}`}
        routeParams={{ tenantId: currentDashboard.id }}
      />
    </Page>
  );
};
export default CreateAffiliateGroupPage;
