import React from 'react';
import useAuth from '../../../hooks/useAuth';
import {
  InviteUserFormLayout,
  InviteUserFormMap,
  TenantUserRoleType,
} from '../../../resources/TenantUser';
import FormikForm from '../../FormikForm';
import { invite } from '../../../api/endpoints/Api/Tenants/Users';
import { BaseUserCreateStep } from '../TenantUserCreateWizard';

interface InviteFormStepProps extends BaseUserCreateStep {
  onBack?: () => void;
  email: string;
  roles: TenantUserRoleType[],
  successUrl: string,
}

const InviteFormStep = (
  {
    onBack,
    email,
    roles,
    userType,
    stepNumber,
    successUrl,
  }: InviteFormStepProps,
) => {
  const { currentDashboard } = useAuth();

  return (
    <FormikForm
      endpoint={invite} formMap={InviteUserFormMap(email, roles)} formLayout={InviteUserFormLayout(onBack, email, roles, userType, stepNumber)} context={{}}
      routeParams={{ tenantId: currentDashboard.id }}
      onSuccessMessage={'{{type}} "{{name}}" invitation was sent successfully!'}
      onSuccessMessageParams={((response) => ({
        name: response.data.user.full_name,
        type: userType.charAt(0).toUpperCase() + userType.slice(1),
      }))}
      onSuccessRedirectTo={() => successUrl}
    />
  );
};

export default InviteFormStep;
