import React from 'react';
import useQueryParams from './useQueryParams';
import { PaginatedResultView } from '../api/types/App/View/Common';
import { BaseView } from '../api/types/App/View';

interface UsePaginatedResultNewProps<T extends BaseView> {
  startPageZero?: boolean,
  results: PaginatedResultView<T>,
  perPageOptions: number[],
}

const pageToOffset = (page: number, limit: number, startPageZero: boolean | undefined) => {
  if (!startPageZero) {
    return (page - 1) * limit;
  }

  return page * limit;
};

const parsePage = (input: string | null, startPageZero: boolean | undefined) => {
  let page = startPageZero ? 0 : 1;
  if (input) {
    page = parseInt(input, 10) || (startPageZero ? 0 : 1);
  }

  if (page < 0) {
    page = startPageZero ? 0 : 1;
  }

  return page;
};

const parsePerPage = (input: string, perPageOptions: number[]) => {
  let perPage;
  if (input) {
    perPage = parseInt(input, 10) || (perPageOptions[0] ?? 10);
  }

  if (perPageOptions.includes(perPage) === false) {
    perPage = (perPageOptions[0] ?? 10);
  }

  return perPage;
};

const totalPagesCalc = <T extends BaseView>(
  result: PaginatedResultView<T>,
  perPage: number,
) => Math.ceil((result?.total ?? 0) / perPage);

const usePaginatedResultsNew = <T extends BaseView, >(props: UsePaginatedResultNewProps<T>) => {
  const query = useQueryParams();
  const queryPage = parsePage(query.get('page'), props.startPageZero);
  const queryPerPage = parsePerPage(query.get('per_page'), props.perPageOptions);

  const [page, setPage] = React.useState<number>(queryPage);
  const [perPage, setPerPage] = React.useState<number>(queryPerPage);
  const [offset, setOffset] = React.useState<number>(pageToOffset(page, perPage, props.startPageZero));

  const handleOnPageChange = (e, p) => {
    setPage(p);
    setOffset(pageToOffset(p, perPage, props.startPageZero));
  };

  const wrappedSetPage = (p) => {
    setPage(p);
    setOffset(pageToOffset(p, perPage, props.startPageZero));
  };

  const handleOnPerPageChange = (pp: number) => {
    setPerPage(pp);
    wrappedSetPage(props.startPageZero ? 0 : 1);
  };

  let totalPages = totalPagesCalc(props.results, perPage);
  totalPages = totalPages === 0 ? 1 : totalPages;

  return {
    page,
    setPage: wrappedSetPage,
    totalPages,
    perPage,
    offset,
    perPageOptions: props.perPageOptions,
    handleOnPageChange,
    handleOnPerPageChange,
  };
};

export default usePaginatedResultsNew;
