import React from 'react';
import {
  Breadcrumbs as MUIBreadcrumbs,
  BreadcrumbsProps as MUIBreadcrumbsProps,
  Link,
  LinkProps,
  Typography,
} from '@material-ui/core';
import ChevronRight from '../icons/ChevronRight';
import { RouterLink } from '../lib/RouterLink';
import { useTranslation } from 'react-i18next';

export interface BreadcrumbItem {
  to?: string,
  label: string,
  translationParams?: Record<string, string>
  props?: LinkProps,
}

export type BreadcrumbItems = BreadcrumbItem[];

export interface BreadcrumbsProp {
  items: BreadcrumbItems,
  breadcrumbProps?: MUIBreadcrumbsProps
}

const Breadcrumbs = (props: BreadcrumbsProp) => {
  const {
    items,
    breadcrumbProps,
  } = props;
  const { t } = useTranslation();

  const bcProps: MUIBreadcrumbsProps = {
    'aria-label': 'breadcrumb',
    separator: <ChevronRight fontSize="small" />,
    sx: { mt: 1 },
    ...breadcrumbProps,
  };

  const len = items.length - 1;

  return (
    <MUIBreadcrumbs {...bcProps}>
      {items.map((item, i) => (item.to !== undefined ? (
        <Link {...{
          key: item.to,
          component: RouterLink,
          to: item.to,
          color: len === i ? 'textSecondary' : 'textPrimary',
          variant: 'subtitle2',
          ...item.props,
        }}
        >
          {t(item.label, item.translationParams ?? {})}
        </Link>
      ) : (
        <Typography {...{
          key: item.label,
          color: len === i ? 'textSecondary' : 'textPrimary',
          variant: 'subtitle2',
        }}
        >
          {t(item.label, item.translationParams ?? {})}
        </Typography>
      )))}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
