import React from 'react';
import Page, { PageProps } from '../../../components/Page';
import useLoadApiData from '../../../hooks/useLoadApiData';
import { useParams } from 'react-router';
import useAuth from '../../../hooks/useAuth';
import {
  Box,
  Button,
  Chip,
  ChipProps,
  Dialog,
  Divider,
  Tooltip,
  Typography,
} from '@material-ui/core';
import ut from '../../../hooks/useTranslation';
import i18n from 'i18next';
import { timestampFormatDistanceToNowStrict } from '../../../utils/timestampUtils';
import { getMapValue } from '../../../types/MapValues';
import { InvoicePaidStatusMap } from '../../../resources/Invoice';
import InvoicePreview from '../../../components/Invoice/InvoicePreview';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import InvoicePDF from '../../../components/Invoice/PDF/InvoicePDF';
import ArrowLeft from '../../../icons/ArrowLeft';
import { useTranslation } from 'react-i18next';
import { show } from '../../../api/endpoints/Api/Tenants/Contract/Invoices';
import { FullTenantContractInvoiceView } from '../../../api/types/App/View/TenantContractInvoice';
import { MarkAsPaidAction } from '../../../resources/TenantContractInvoice';

const ShowTenantContractInvoicePage = () => {
  const { currentDashboard } = useAuth();
  const [showPDF, setShowPDF] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const { id } = useParams<{ id: string }>();
  const { apiData } = useLoadApiData(show)({
    tenantId: currentDashboard.id,
    id,
  });

  if (apiData === null) {
    return null;
  }

  const rowTitleRender = (title) => title;

  const invoicePDF = (
    <InvoicePDF invoice={apiData.invoice} renderRowTitle={rowTitleRender} />
  );

  const pageProps: PageProps<FullTenantContractInvoiceView> = {
    pageHeaderProps: {
      renderTitleContainer: (p, { invoice: i }) => (
        <>
          <Typography color="textPrimary" variant="h5">
            {ut('Connection Invoice #{{number}}', { number: i.number })}
            <>
              {i.paid === true && (
                <Tooltip
                  title={i18n.t('Paid {{time}} ago', {
                    time: timestampFormatDistanceToNowStrict(i.paid_at),
                  })}
                >
                  <Chip
                    label={getMapValue(InvoicePaidStatusMap, 'paid').label}
                    color={
                      getMapValue(InvoicePaidStatusMap, 'paid')
                        .color as ChipProps['color']
                    }
                    sx={{ ml: 2 }}
                  />
                </Tooltip>
              )}
              {i.paid === false && (
                <Chip
                  label={getMapValue(InvoicePaidStatusMap, 'not_paid').label}
                  color={
                    getMapValue(InvoicePaidStatusMap, 'not_paid')
                      .color as ChipProps['color']
                  }
                  sx={{ ml: 2 }}
                />
              )}
            </>
          </Typography>
        </>
      ),
      actions: [
        MarkAsPaidAction(currentDashboard.type === 'user_tenant_admin'),
        {
          type: 'click',
          label: 'Preview PDF',
          color: 'primary',
          variant: 'outlined',
          onClick: () => () => {
            setShowPDF(true);
          },
        },
        {
          type: 'custom',
          label: 'Download PDF',
          renderButton: () => (
            <PDFDownloadLink
              document={invoicePDF}
              fileName={`${apiData.invoice.number}`}
              style={{ textDecoration: 'none' }}
            >
              <Button color="primary" variant="contained" sx={{ ml: 1 }}>
                {t('Download PDF')}
              </Button>
            </PDFDownloadLink>
          ),
        },
      ],
      breadCrumbs: {
        items: [
          {
            label: 'Dashboard',
            to: '/dashboard',
          },
          {
            label: 'Connection Invoices',
            to: '/connections/invoices',
          },
          {
            label: 'Invoice #{{number}}',
            translationParams: {
              number: apiData.invoice.number,
            },
          },
        ],
      },
    },
    title: 'Contract Invoice #{{number}}',
    titleProps: {
      number: apiData.invoice.number,
    },
    context: apiData,
    compactWidth: 'md',
  };

  return (
    <Page {...pageProps}>
      <Divider sx={{ my: 3 }} />
      <InvoicePreview
        invoice={apiData.invoice}
        renderRowTitle={rowTitleRender}
      />
      <Dialog open={showPDF} fullScreen>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'background.default',
              p: 2,
            }}
          >
            <Button
              color="primary"
              startIcon={<ArrowLeft fontSize="small" />}
              onClick={(): void => setShowPDF(false)}
              variant="contained"
            >
              Back
            </Button>
          </Box>
          <Box sx={{ flexGrow: 1 }}>
            <PDFViewer height="100%" style={{ border: 'none' }} width="100%">
              {invoicePDF}
            </PDFViewer>
          </Box>
        </Box>
      </Dialog>
    </Page>
  );
};

export default ShowTenantContractInvoicePage;
