import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ArrowForwardIos } from '@material-ui/icons';

import Logo from '../components/Logo';
import gtm from '../lib/gtm';
import { siteConfig } from '../config';
import { useDispatch, useSelector } from '../redux';
import { chooseDashboard, Dashboard, logout } from '../redux/slices/User';
import { getAvatarColor } from '../utils/colors';

const ChooseDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  React.useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  const handleLogout = async (): Promise<void> => {
    dispatch(logout());
  };

  const handleDashboardSelect = (dashboard: Dashboard) => async (): Promise<void> => {
    dispatch(chooseDashboard(dashboard));
  };

  const destinations = (
    <List disablePadding>
      {user.dashboards.map((dashboard, i) => (
        <ListItem
          divider={i < user.dashboards.length - 1}
          button
          key={`${dashboard.type}-${dashboard.id}`}
          onClick={handleDashboardSelect(dashboard)}
        >
          <ListItemAvatar>
            <Avatar
              sx={{
                ...getAvatarColor(dashboard.apc),
                fontSize: 14,
                fontWeight: 600,
              }}
            >
              {dashboard.logo}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${t(dashboard.role)} "${dashboard.title}"`}
            primaryTypographyProps={{
              color: 'textPrimary',
              variant: 'subtitle2',
            }}
          />
          <ArrowForwardIos fontSize="small" />
        </ListItem>
      ))}
    </List>
  );

  return (
    <>
      <Helmet>
        <title>
          {`${t('Choose Dashboard')}${siteConfig.titleSuffix}`}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8,
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 100,
                  width: 300,
                }}
              />
            </RouterLink>
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4,
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 2,
                }}
              >
                <div>
                  {user.dashboards.length > 0 && (
                    <>
                      <Typography
                        color="textPrimary"
                        gutterBottom
                        variant="h4"
                      >
                        {t('Choose Dashboard')}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                      >
                        {t('Your user has access to multiple dashboards')}
                      </Typography>
                    </>
                  )}
                  {user.dashboards.length === 0 && (
                    <>
                      <Typography
                        color="textPrimary"
                        gutterBottom
                        variant="h4"
                      >
                        {t('Your user is not assigned to any dashboards')}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                      >
                        {t('Please contact your manager regarding access to the dashboards.')}
                      </Typography>
                    </>
                  )}
                </div>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 0,
                }}
              >
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {destinations}
                </Card>
              </Box>
              <Divider sx={{ my: 3 }} />
              <Button
                color="secondary"
                onClick={handleLogout}
                variant="text"
              >
                {t('Sign Out')}
              </Button>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default ChooseDashboard;
