import type { ReactNode } from 'react';
import React from 'react';
import type { Theme, TypographyProps } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import type { SxProps } from '@material-ui/system';
import { useTranslation } from 'react-i18next';
import { MapValue } from '../types/MapValues';
import { styled } from '@material-ui/core/styles';

export interface LabelProps {
  children?: ReactNode;
  color?: TypographyProps['color'];
  style?: {};
  sx?: SxProps<Theme>;
  tooltip?: string,
}

interface LabelRootProps {
  styleProps: {
    color: MapValue['color'];
  };
}

const LabelRoot = styled('span')<LabelRootProps>(
  (
    (
      {
        theme,
        styleProps,
      },
    ) => {
      const backgroundColor = theme.palette[String(styleProps.color) ?? 'primary'].main;
      const color = theme.palette[String(styleProps.color) ?? 'primary'].contrastText;

      return {
        alignItems: 'center',
        backgroundColor,
        borderRadius: theme.shape.borderRadius,
        color,
        cursor: 'default',
        display: 'inline-flex',
        flexGrow: 0,
        flexShrink: 0,
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.pxToRem(11),
        fontWeight: theme.typography.fontWeightMedium,
        justifyContent: 'center',
        letterSpacing: 0.5,
        minWidth: 20,
        paddingBottom: theme.spacing(0.5),
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        paddingTop: theme.spacing(0.5),
        textTransform: 'uppercase',
        whiteSpace: 'nowrap',
      };
    }
  ),
);

const Label = (props: LabelProps) => {
  const {
    color = 'primary',
    children,
    tooltip,
    ...other
  } = props;

  const styleProps = { color };

  const label = (
    <LabelRoot
      styleProps={styleProps}
      {...other}
    >
      {children}
    </LabelRoot>
  );

  if (tooltip !== undefined && tooltip !== null) {
    return <Tooltip title={tooltip}>{label}</Tooltip>;
  }

  return label;
};

interface StatusLabelProps extends LabelProps {
  value: MapValue,
}

export const StatusLabel = (
  {
    value,
    ...props
  }: StatusLabelProps,
) => {
  const { t } = useTranslation();

  return (
    <Label color={value.color} {...props}>
      {t(value.label ?? value.value)}
    </Label>
  );
};

interface StatusLabelsProps extends LabelProps {
  values: MapValue[],
}

export const StatusLabels = (
  {
    values = [],
  }: StatusLabelsProps,
) => (
  <>
    {values.map((value) => <StatusLabel key={value.value} value={value} sx={{ mr: 1 }} />)}
  </>
);

export default Label;
