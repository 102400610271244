import React from 'react';

export interface ContentViewProps {
  value: string,
  children: React.ReactNode,
}

export const ContentView = ({ children }: ContentViewProps) => <>{children}</>;

interface ContentSwitcherProp {
  current: string
  children: React.ReactElement<ContentViewProps>[],
}

export const ContentSwitcher = (props: ContentSwitcherProp) => {
  const {
    children,
    current,
  } = props;

  return (
    <>
      {React.Children.toArray(children)
        .find((child) => React.isValidElement<ContentViewProps>(child) && child.props.value === current)}
    </>
  );
};
