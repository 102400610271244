import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Link,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import importTranslation from '../../hooks/ut';
import { FullTenantContractView } from '../../api/types/App/View/TenantContract';
import { Link as RouterLink } from 'react-router-dom';

interface AffiliateGroupsProps {
  contract: FullTenantContractView;
}

const AffiliateGroups = (props: AffiliateGroupsProps) => {
  const { contract } = props;
  const { t } = importTranslation();

  if (contract.all_users) {
    return null;
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Card>
        <CardHeader title={t('Selected Affiliate Groups')} />
        <Divider />
        <CardContent
          sx={{
            p: 0,
            '&:last-child': {
              p: 0,
            },
          }}
        >
          <List>
            {contract.user_groups.map((contract_user_group) => (
              <ListItem
                key={contract_user_group.id}
                divider
                sx={{
                  '&:last-of-type': {
                    border: 0,
                  },
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Link
                          color="textPrimary"
                          component={RouterLink}
                          to={`/affiliate/groups/${contract_user_group.user_group.id}`}
                        >
                          <Typography color="textPrimary" variant="subtitle2">
                            {contract_user_group.user_group.title}
                          </Typography>
                        </Link>

                        <Link
                          color="textPrimary"
                          component={RouterLink}
                          variant="body2"
                          sx={{ ml: 2 }}
                          to={{
                            pathname: `/affiliate/groups/${contract_user_group.user_group.id}`,
                            state: { pageTab: 'Affiliates' },
                          }}
                        >
                          {t('{{number}} Affiliates', {
                            number: contract_user_group.user_group.user_count,
                          })}
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AffiliateGroups;
