import React from 'react';
import { MapValue } from '../types/MapValues';
import {
  calculateComponent,
  calculateProps,
  ModifiableComponentProps,
  ModifiableComponentRender,
} from '../utils/propHelpers';
import importTranslation from '../hooks/ut';
import { Chip, ChipProps } from '@material-ui/core';

interface MapValueChipProps<T extends MapValue = MapValue> {
  value: T,
  noIcon?: boolean,
  noText?: boolean,
  noTranslate?: boolean,
  hidden?: boolean,
  color?: MapValue['color'],
  chipProps?: ModifiableComponentProps<typeof Chip>,
  renderChip?: ModifiableComponentRender<typeof Chip, T>,
  forwardedRef?: React.ForwardedRef<HTMLDivElement>,
}

const MapValueChip = (props: MapValueChipProps) => {
  const {
    value,
    noIcon = false,
    noText = false,
    noTranslate = false,
    color,
    chipProps,
    renderChip,
    hidden = false,
    forwardedRef,
    ...other
  } = props;

  if (hidden === true) {
    return null;
  }

  const { t } = importTranslation();

  return (
    <>
      {
        calculateComponent(
          renderChip,
          Chip,
          calculateProps(
            chipProps,
            {
              ...other,
              ref: forwardedRef,
              color: (value.color ?? color) as ChipProps['color'],
              icon: noIcon === true ? undefined : value.icon,
              // eslint-disable-next-line no-nested-ternary
              label: noText !== true ? noTranslate !== true ? t(value.label) : value.label : undefined,
            },
          ),
          value,
        )
      }
    </>
  );
};

export default React.forwardRef<HTMLDivElement, MapValueChipProps>((props, ref) => (
  <MapValueChip {...props} forwardedRef={ref} />
));
