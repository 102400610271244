import { Box, Link, Tooltip, Typography } from '@material-ui/core';
import ExternalLink from '../icons/ExternalLink';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { DataGridColumns } from '../components/DataGrid/Column';
import { CampaignLinkStatView } from '../api/types/App/View/CampaignLink';
import numeral from '../lib/numeral';
import { CampaignStatView, ShortCampaignView } from '../api/types/App/View/Campaign';
import { timestampFormat, timestampFormatDistanceToNowStrict } from '../utils/timestampUtils';
import { fromUnixTime, isFuture, isPast } from 'date-fns';
import i18n from 'i18next';
import MapValueChip from '../components/MapValueChip';
import { getMapValue } from '../types/MapValues';
import { StatusLabel } from '../components/Label';
import { CampaignFinishReasons, getCampaignStatus, getCampaignStatusText } from './Campaign';

export const ListCampaignLinksColumns: DataGridColumns<CampaignLinkStatView> = [
  {
    field: 'title',
    label: 'Title',
    headerProps: (props) => ({ ...props, sx: { width: '35%', } }),
    renderCellValue: (
      {
        title,
        url,
      }: CampaignLinkStatView,
    ) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <ExternalLink
          fontSize="small"
          sx={{
            color: 'text.secondary',
            cursor: 'pointer',
          }}
        />
        <Link
          component="a"
          href={url}
          color="textPrimary"
          sx={{ ml: 2 }}
          variant="body2"
        >
          {title}
        </Link>
      </Box>
    ),
  },
  {
    field: 'campaign_title',
    label: 'Campaign Title',
    renderCellValue: (
      {
        campaign_id,
        campaign_title,
      }: CampaignLinkStatView,
    ) => (
      <Link
        component={RouterLink}
        to={`/campaigns/${campaign_id}`}
        color="textPrimary"
        variant="body2"
      >
        {campaign_title}
      </Link>
    ),
  },
  {
    field: 'conversion',
    label: 'Conversions',
    renderCellValue: (
      {
        conversions,
      }: CampaignLinkStatView,
    ) => numeral(conversions)
      .format('0,0'),
  },
  {
    field: 'click_out',
    label: 'Click Outs',
    renderCellValue: (row) => `${numeral(row.click_outs).format('0,0')} / ${numeral(row.unique_click_outs).format('0,0')}`,
  },
  {
    field: 'unique_visits',
    label: 'Unique Visits',
    renderCellValue: (
      {
        unique_visit,
        unique_visit_ip,
      }: CampaignLinkStatView,
    ) => `${numeral(unique_visit)
      .format('0,0')} / ${numeral(unique_visit_ip)
      .format('0,0')}`,
  },
  {
    field: 'total_visit',
    label: 'Total Visits',
    renderCellValue: (
      {
        total_visits,
      }: CampaignLinkStatView,
    ) => numeral(total_visits)
      .format('0,0'),
  },
];

export const ListCampaignStatsColumns: DataGridColumns<CampaignStatView> = [
  {
    field: 'title',
    label: 'Title',
    headerProps: (props) => ({ ...props, sx: { width: '35%', } }),
    renderCellValue: (
      {
        id,
        title,
      }: CampaignStatView,
    ) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Link
          component={RouterLink}
          to={`/campaigns/${id}`}
          color="textPrimary"
          sx={{ ml: 2 }}
          variant="body2"
        >
          {title}
        </Link>
      </Box>
    ),
  },
  {
    field: 'start_at',
    label: 'Starts At',
    renderCellValue: ({ start_at }: CampaignStatView) => (
      <>
        {timestampFormat(start_at)}
        {isFuture(fromUnixTime(start_at)) && (
          <Typography variant="body2">
            {i18n.t('Starts in {{time}}', { time: timestampFormatDistanceToNowStrict(start_at) })}
          </Typography>
        )}
        {isPast(fromUnixTime(start_at)) && (
          <Typography variant="body2">
            {i18n.t('Started {{time}} ago', { time: timestampFormatDistanceToNowStrict(start_at) })}
          </Typography>
        )}
      </>
    ),
  },
  {
    field: 'end_at',
    label: 'Ends At',
    renderCellValue: ({ end_at }: CampaignStatView) => (
      <>
        {timestampFormat(end_at)}
        {isFuture(fromUnixTime(end_at)) && (
          <Typography variant="body2">
            {i18n.t('Expires in {{time}}', { time: timestampFormatDistanceToNowStrict(end_at) })}
          </Typography>
        )}
        {isPast(fromUnixTime(end_at)) && (
          <Typography variant="body2">
            {i18n.t('Expired {{time}} ago', { time: timestampFormatDistanceToNowStrict(end_at) })}
          </Typography>
        )}
      </>
    ),
  },
  {
    field: 'conversion',
    label: 'Conversions',
    renderCellValue: (
      {
        conversions,
      }: CampaignStatView,
    ) => numeral(conversions)
      .format('0,0'),
  },
  {
    field: 'click_out',
    label: 'Click Outs',
    renderCellValue: (row) => `${numeral(row.click_outs).format('0,0')} / ${numeral(row.unique_click_outs).format('0,0')}`,
  },
  {
    field: 'unique_visits',
    label: 'Unique Visits',
    renderCellValue: (
      {
        unique_visit,
        unique_visit_ip,
      }: CampaignStatView,
    ) => `${numeral(unique_visit)
      .format('0,0')} / ${numeral(unique_visit_ip)
      .format('0,0')}`,
  },
  {
    field: 'total_visit',
    label: 'Total Visits',
    renderCellValue: (
      {
        total_visits,
      }: CampaignStatView,
    ) => numeral(total_visits)
      .format('0,0'),
  },
];

export const ListActiveCampaignDashboardColumns: DataGridColumns<ShortCampaignView> = [
  {
    field: 'title',
    label: 'Title',
    headerProps: (props) => ({ ...props, sx: { width: '35%', } }),
    renderCellValue: (
      {
        id,
        title,
      },
    ) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Link
          component={RouterLink}
          to={`/campaigns/${id}`}
          color="textPrimary"
          sx={{ ml: 2 }}
          variant="body2"
        >
          {title}
        </Link>
      </Box>
    ),
  },
  {
    field: 'start_at',
    label: 'Starts At',
    renderCellValue: ({ start_at }) => (
      <>
        {timestampFormat(start_at)}
        {isFuture(fromUnixTime(start_at)) && (
          <Typography variant="body2">
            {i18n.t('Starts in {{time}}', { time: timestampFormatDistanceToNowStrict(start_at) })}
          </Typography>
        )}
        {isPast(fromUnixTime(start_at)) && (
          <Typography variant="body2">
            {i18n.t('Started {{time}} ago', { time: timestampFormatDistanceToNowStrict(start_at) })}
          </Typography>
        )}
      </>
    ),
  },
  {
    field: 'end_at',
    label: 'Ends At',
    renderCellValue: ({ end_at }) => (
      <>
        {timestampFormat(end_at)}
        {isFuture(fromUnixTime(end_at)) && (
          <Typography variant="body2">
            {i18n.t('Expires in {{time}}', { time: timestampFormatDistanceToNowStrict(end_at) })}
          </Typography>
        )}
        {isPast(fromUnixTime(end_at)) && (
          <Typography variant="body2">
            {i18n.t('Expired {{time}} ago', { time: timestampFormatDistanceToNowStrict(end_at) })}
          </Typography>
        )}
      </>
    ),
  },
];
export const ListRecentlyFinishedCampaignDashboardColumns: DataGridColumns<ShortCampaignView> = [
  {
    field: 'title',
    label: 'Title',
    headerProps: (props) => ({ ...props, sx: { width: '35%', } }),
    renderCellValue: (
      {
        id,
        title,
      },
    ) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Link
          component={RouterLink}
          to={`/campaigns/${id}`}
          color="textPrimary"
          sx={{ ml: 2 }}
          variant="body2"
        >
          {title}
        </Link>
      </Box>
    ),
  },
  {
    field: 'finish_reason',
    label: 'Finish Reason',
    renderCellValue: (campaign) => (
      <>
        {campaign.finished_at !== null && (
          <Tooltip title={i18n.t('Finished {{time}} ago', { time: timestampFormatDistanceToNowStrict(campaign.finished_at) })}>
            <MapValueChip color="error" chipProps={{ size: 'small' }} value={getMapValue(CampaignFinishReasons, campaign.finish_reason)} />
          </Tooltip>
        )}
        {campaign.finished_at === null && (
          <StatusLabel
            value={getCampaignStatus(campaign)}
            tooltip={getCampaignStatusText(campaign).label}
          />
        )}
      </>
    ),
  },
  {
    field: 'finished_at',
    label: 'Finished At',
    renderCellValue: ({ finished_at }) => (
      <>
        {timestampFormat(finished_at)}
        {isPast(fromUnixTime(finished_at)) && (
          <Typography variant="body2">
            {i18n.t('Finished {{time}} ago', { time: timestampFormatDistanceToNowStrict(finished_at) })}
          </Typography>
        )}
      </>
    ),
  },
];
