import React from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { History, LocationState } from 'history';
import { Router } from 'react-router';
import { Toaster } from 'react-hot-toast';
import './i18n';
import './i18n/Yup';
import useSettings from './hooks/useSettings';
import useScrollReset from './hooks/useScrollReset';
import Routes from './routes';
import gtm from './lib/gtm';
import { gtmConfig } from './config';
import createCustomTheme from './theme';
import SplashScreen from './components/SplashScreen';
import useAuth from './hooks/useAuth';
import { ThemeProvider as ThemeProviderContext } from './contexts/ThemeContext';
import { useTranslation } from 'react-i18next';

interface AppProps {
  history: History<LocationState>,
}

const App = ({ history }: AppProps) => {
  const { settings } = useSettings();
  const { i18n } = useTranslation();
  const auth = useAuth();

  useScrollReset();

  React.useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
    language: i18n.language,
  });

  const content = (
    <Router history={history}>
      <Routes />
    </Router>
  );

  return (
    <ThemeProviderContext theme={theme}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toaster position="top-center" toastOptions={{ duration: 3000 }} />
        {auth.isInitialized ? content : <SplashScreen />}
      </ThemeProvider>
    </ThemeProviderContext>
  );
};

export default App;
