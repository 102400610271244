import React from 'react';
import { Box, Grid, GridProps, Typography } from '@material-ui/core';
import Breadcrumbs, { BreadcrumbsProp } from '../Breadcrumbs';
import Actions from '../Actions';
import { ActionTypes } from '../../types/ActionType';
import ut from '../../hooks/useTranslation';
import {
  calculateComponent,
  calculateProps,
  ModifiableComponentProps,
  ModifiableComponentRender,
} from '../../utils/propHelpers';

export interface PageHeaderProp<ContextType = any> {
  title?: string,
  translationParams?: Record<string, string>,
  breadCrumbs?: BreadcrumbsProp,
  subHeader?: () => React.ReactNode,
  actions?: ActionTypes<ContextType>[],
  gridContainerProps?: GridProps
  gridTitleProps?: GridProps
  gridActionProps?: GridProps
  context?: ContextType,
  renderTitleContainer?: ModifiableComponentRender<typeof Box, ContextType>,
  titleContainerProps?: ModifiableComponentProps<typeof Box>
}

const PageHeader = (props: PageHeaderProp) => {
  const {
    title,
    translationParams,
    subHeader,
    breadCrumbs,
    actions,
    gridContainerProps,
    gridActionProps,
    gridTitleProps,
    context,
    renderTitleContainer,
    titleContainerProps,
  } = props;

  const containerProps: GridProps = {
    alignItems: 'center',
    container: true,
    justifyContent: 'space-between',
    spacing: 3,
    ...gridContainerProps,
  };

  const itemTitleProps: GridProps = {
    item: true,
    ...gridTitleProps,
  };

  const itemActionProps: GridProps = {
    item: true,
    ...gridActionProps,
  };

  return (
    <Grid {...containerProps}>
      <Grid {...itemTitleProps}>
        {
          calculateComponent(
            renderTitleContainer,
            Box,
            calculateProps(
              titleContainerProps,
              {
                children: (
                  <>
                    {title !== undefined && (
                      <Typography
                        color="textPrimary"
                        variant="h5"
                      >
                        {ut(title, translationParams)}
                      </Typography>
                    )}
                  </>
                ),
              },
            ),
            context,
          )
        }
        {subHeader !== undefined && subHeader()}
        {breadCrumbs !== undefined && (
          <Breadcrumbs items={breadCrumbs.items} breadcrumbProps={breadCrumbs.breadcrumbProps} />)}
      </Grid>
      <Grid {...itemActionProps}>
        <Box sx={{ m: -1 }}>
          <Actions type="button" actions={actions ?? []} context={context} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default PageHeader;
