import axiosClient, { AxiosInstance, AxiosRequestConfig, Method as AxiosMethod } from 'axios';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { ApiClientError } from './error/ApiClientError';
import { ApiClientUnknownResponseError } from './error/ApiClientUnknownResponseError';
import { ApiClientErrorInterface } from './error/ApiClientErrorInterface';
import { App } from './types';
import { BaseView } from './types/App/View';
import { BaseErrorView, NotFoundErrorView, ValidationErrorView } from './types/App/ErrorView';
import { BaseRequestBody, BaseRequestParams } from './types/App/Request';
import { JsonResponse } from './types/App/Response';
import { store } from '../redux';
import { logout } from '../redux/slices/User';
import { queryParams as queryParamsGenerator } from '../utils/query';

export interface NoBody {
}

export interface NoParams {
}

export type StandardErrors = NotFoundErrorView | ValidationErrorView | BaseErrorView;

export interface ApiConfig {
  logger: boolean,
  serverBaseURL: string
}

const apiConfig: ApiConfig = {
  logger: process.env.NODE_ENV !== 'production',
  serverBaseURL: process.env.REACT_APP_API_URL,
};

/* eslint-disable  @typescript-eslint/no-unused-vars */
type ExtractRouteParams<T extends string> = string extends T ? Record<string, string> : T extends `${infer Start}{${infer Param}}${infer Rest}` ? { [k in Param | keyof ExtractRouteParams<Rest>]: string } : T extends `${infer Start}{${infer Param}}` ? { [k in Param]: string } : {};
/* eslint-enable  @typescript-eslint/no-unused-vars */

const httpMethodColors: Record<AxiosMethod, string> = {
  OPTIONS: '#7C3AED',
  options: '#7C3AED',
  GET: '#2563EB',
  get: '#2563EB',
  HEAD: '#818CF8',
  head: '#818CF8',
  POST: '#16A34A',
  post: '#16A34A',
  PUT: '#F59E0B',
  put: '#F59E0B',
  DELETE: '#B91C1C',
  delete: '#B91C1C',
  LINK: '#A78BFA',
  link: '#A78BFA',
  UNLINK: '#22D3EE',
  unlink: '#22D3EE',
  PATCH: '#FACC15',
  patch: '#FACC15',
  PURGE: '#c6ff00',
  purge: '#c6ff00',
};

const statusColors: Record<App.Response.JsonResponse<App.View.BaseView, App.ErrorView.BaseErrorView>['status'], string> = {
  okay: '#84CC16',
  error: '#EF4444',
};

const repeat = (str: string, times: number) => (new Array(times + 1)).join(str);

const pad = (
  num: number,
  maxLength: number,
) => repeat('0', maxLength - num.toString().length) + num;

const formatTime = (time: Date) => `${pad(time.getHours(), 2)}:${pad(time.getMinutes(), 2)}:${pad(time.getSeconds(), 2)}.${pad(time.getMilliseconds(), 3)}`;

const timer = (typeof performance !== 'undefined' && performance !== null) && typeof performance.now === 'function'
  ? performance : Date;

export default class ApiClient {
  private readonly logger: boolean;

  private accessToken: string | null;

  private client: AxiosInstance;

  constructor(public config: ApiConfig) {
    this.logger = config.logger;
    this.client = axiosClient.create({
      baseURL: config.serverBaseURL,
      headers: {
        'Content-Type': 'application/json',
      },
    });

    this.client.interceptors.request.use(
      (requestConfig) => {
        if (this.accessToken) {
          requestConfig.headers.Authorization = `Bearer ${this.accessToken}`;
        }

        return requestConfig;
      },
      (error) => {
        Promise.reject(error);
      },
    );
  }

  setAccessToken(token: string | null): void {
    this.accessToken = token;
  }

  private preRequestLogger(method: AxiosMethod, path: string, body: BaseRequestBody): void {
    if (this.logger) {
      const formattedTime = formatTime(new Date());

      console.group(
        `%cAPI Client v1.0  %c${method}%c "${path}" %c${formattedTime}`,
        'color: #01ab56; font-weight: bold;',
        `background-color: ${httpMethodColors[method]}; color: #fff; padding: 2px 5px;`,
        'color: #2196f3',
        'color: gray; font-weight: lighter;',
      );

      console.info('%cBody: ', 'color: #2196f3; font-weight: bold', body);
    }
  }

  private postRequestLogger<ResponseErrorViews extends App.ErrorView.BaseErrorView,
    ResponseBodyView extends App.View.BaseView>(
    started: number,
    response: App.Response.JsonResponse<ResponseBodyView, ResponseErrorViews>,
  ): void {
    if (this.logger) {
      const took = timer.now() - started;

      console.info(
        `%cResult: %c${response.status} %c${response.code}%c (${took.toFixed(2)} ms)`,
        'color: #FACC15; font-weight: bold',
        `color: ${statusColors[response.status]}; font-weight: light;`,
        `background-color: ${statusColors[response.status]}; color: #fff; padding: 2px 5px;`,
        'color: #A3E635; font-weight: light;',
        response,
      );

      console.info(
        '%cData: ',
        'color: #4ADE80; font-weight: bold',
        response.data ?? {},
      );

      console.group(
        '%cErrors: ',
        'color: #0EA5E9; font-weight: bold',
      );

      for (let i = 0; i < response.errors.length; i += 1) {
        const error = response.errors[i];

        console.info(
          `%c${error.code}:%c ${error.message}`,
          'color: #f44336; font-weight: bold;',
          'color: #f44336;',
          error.data ?? {},
        );
      }

      console.groupEnd();
      console.groupEnd();
    }
  }

  private postRequestError(started: number, error: ApiClientErrorInterface): void {
    if (this.logger) {
      const took = timer.now() - started;
      const responseCode = error.response?.status ?? 'Unknown';

      console.info(
        `%cError: %c${error.message} (${error.response?.statusText}) %c${responseCode}%c (${took.toFixed(2)} ms)`,
        'color: #FACC15; font-weight: bold',
        `color: ${statusColors.error}; font-weight: light;`,
        `background-color: ${statusColors.error}; color: #fff; padding: 2px 5px;`,
        'color: #A3E635; font-weight: light;',
        error,
      );

      console.groupEnd();
    }

    this.handleError(error);
  }

  private handleError(error: ApiClientErrorInterface): void {
    if (this.logger) {
      console.error(error);
    }

    const { t } = useTranslation();

    toast.error(t`Error Occurred while trying to communicate with server`);
  }

  async request<ResponseBodyView extends App.View.BaseView, ResponseErrorViews extends App.ErrorView.BaseErrorView>(
    method: AxiosMethod,
    path: string,
    body: BaseRequestBody = {},
    config: AxiosRequestConfig = {},
  ): Promise<App.Response.JsonResponse<ResponseBodyView, ResponseErrorViews>> {
    const started = timer.now();

    this.preRequestLogger(method, path, body);

    let response: App.Response.JsonResponse<ResponseBodyView, ResponseErrorViews>;
    try {
      const result = await this.client.request({
        url: path,
        method: method as AxiosMethod,
        data: body,
        ...config,
      });

      const { data } = result;
      if (
        Object.prototype.hasOwnProperty.call(data, 'status')
        && Object.prototype.hasOwnProperty.call(data, 'code')
        && Object.prototype.hasOwnProperty.call(data, 'data')
        && Object.prototype.hasOwnProperty.call(data, 'errors')
      ) {
        response = data as App.Response.JsonResponse<ResponseBodyView, ResponseErrorViews>;

        this.postRequestLogger(started, response);

        return response;
      }

      const exception: ApiClientError = new ApiClientUnknownResponseError('Unknown Response Received', `${result.status}`, null);
      exception.request = result.config;
      exception.response = result;

      this.postRequestError(started, exception);

      throw exception;
    } catch (error) {
      if (error instanceof ApiClientUnknownResponseError) {
        throw error;
      }

      if (error.response) {
        const { response: { data } } = error;
        if (
          Object.prototype.hasOwnProperty.call(data, 'status')
          && Object.prototype.hasOwnProperty.call(data, 'code')
          && Object.prototype.hasOwnProperty.call(data, 'data')
          && Object.prototype.hasOwnProperty.call(data, 'errors')
        ) {
          response = data as App.Response.JsonResponse<ResponseBodyView, ResponseErrorViews>;

          this.postRequestLogger(started, response);
          if (data?.code === 401 || data?.code === 403) {
            if (this.accessToken) {
              store.dispatch(logout());
            }
          }

          return response;
        }

        const exception: ApiClientError = new ApiClientUnknownResponseError(
          error.message, error.code, error,
        );
        exception.request = error.config;
        exception.response = error.response;

        this.postRequestError(started, exception);

        throw exception;
      } else {
        const exception: ApiClientError = new ApiClientError(error.message, error.code, error);

        this.postRequestError(started, exception);

        throw exception;
      }
    }
  }
}

const apiEndpointRouteBuild = <Route extends string>(
  route: Route,
  params: ExtractRouteParams<Route>,
): string => route.replace(
    /{([^}]+)}/g,
    (substring, param: string) => {
      if (!(param in params)) {
        throw Error(`Parameter "${param}" is not provided for route "${route}"`);
      }

      return (params as Record<string, string>)[param];
    },
  );

export interface HasApiEndpointParams<Endpoint extends ApiEndpointTypeAny> {
  endpoint: Endpoint,
  routeParams?: ApiEndpointRouteParamsType<Endpoint>,
  requestParams?: ApiEndpointParamsType<Endpoint>,
  requestBody?: ApiEndpointBodyType<Endpoint>,
}

export type ApiEndpointType<RV extends BaseView,
  RP extends BaseRequestParams,
  RB extends BaseRequestBody,
  RE extends BaseErrorView,
  Route extends string> = (
    routeParams: ExtractRouteParams<Route>,
    params: RP,
    body: RB,
    config?: AxiosRequestConfig,
  ) => PromiseLike<JsonResponse<RV, RE>>;
export type ApiEndpointTypeAny = ApiEndpointType<any, any, any, any, any>;

export const apiClient = new ApiClient(apiConfig);
export const apiEndpoint = <RV extends BaseView,
  RP extends BaseRequestParams,
  RB extends BaseRequestBody,
  RE extends BaseErrorView,
  >(method: AxiosMethod) => <Route extends string>(routeUrl: Route): ApiEndpointType<RV, RP, RB, RE, Route> => (
    routeParams: ExtractRouteParams<Route>,
    params: RP,
    body: RB,
    config?: AxiosRequestConfig,
  ) => new Promise<JsonResponse<RV, RE>>(
    (resolve, reject) => {
      let url = apiEndpointRouteBuild(routeUrl, routeParams);
      const queryParams = queryParamsGenerator(params);
      if (queryParams.length > 0) {
        url += `?${queryParams}`;
      }
      apiClient.request<RV, RE>(method, url, body, config ?? {})
        .then((result) => {
          resolve(result);
        })
        .catch(() => reject(new Error('Server Error Occurred')));
    },
  );

export type ApiEndpointRouteParamsType<T extends ApiEndpointTypeAny> = Parameters<T>[0];
export type ApiEndpointParamsType<T extends ApiEndpointTypeAny> = Parameters<T>[1];
export type ApiEndpointBodyType<T extends ApiEndpointTypeAny> = Parameters<T>[2];

export type PromiseType<T> = T extends PromiseLike<infer U> ? U : never;
export type ApiEndpointResponseViewType<T> = T extends JsonResponse<infer V, any> ? V : never;
export type ApiEndpointResponseErrorViewType<T> = T extends JsonResponse<any, infer V> ? V : never;
export type ApiEndpointReturnPromiseType<T extends ApiEndpointTypeAny> = ReturnType<T>;
export type ApiEndpointReturnType<T extends ApiEndpointTypeAny> = PromiseType<ApiEndpointReturnPromiseType<T>>;

export type GetResponseViewType<T extends ApiEndpointTypeAny> =
  ApiEndpointResponseViewType<ApiEndpointReturnType<T>>
  | null;
export type GetResponseErrorViewType<T extends ApiEndpointTypeAny> =
  ApiEndpointResponseErrorViewType<ApiEndpointReturnType<T>>
  | null;

export const isResponseSuccess = <T extends JsonResponse<any, any>>(response: T) => response?.status === 'okay';
