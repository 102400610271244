import React from 'react';
import { ButtonBaseProps, PopoverProps } from '@material-ui/core';

const usePopover = (initialState: boolean = false) => {
  const anchorRef = React.useRef<HTMLButtonElement | null>(null);
  const [openState, setOpenState] = React.useState<boolean>(initialState);

  const open = () => setOpenState(true);
  const close = () => setOpenState(false);
  const toggle = () => setOpenState((prev) => !prev);
  const setState = (state: boolean) => setOpenState(state);

  const popoverProps: PopoverProps = {
    anchorEl: anchorRef.current,
    open: openState,
    onClose: close,
  };

  const buttonProps: ButtonBaseProps = {
    ref: anchorRef,
    onClick: open,
  };

  return {
    popoverProps,
    buttonProps,
    openState,
    open,
    close,
    toggle,
    setState,
  };
};

export default usePopover;
