import React from 'react';
import ReactMarkdown from 'react-markdown';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { Link, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Components, ReactMarkdownProps } from 'react-markdown/src/ast-to-react';

type MarkdownProps = ReactMarkdownProps;

const MarkdownTable = (props: MarkdownProps) => {
  const { children } = props;
  return (
    <TableContainer component={Paper}>
      <Table size="small" aria-label="a dense table">{children}</Table>
    </TableContainer>
  );
};

const MarkdownTableCell = (props: MarkdownProps) => {
  const { children } = props;

  return <TableCell><Typography>{children}</Typography></TableCell>;
};

const MarkdownTableRow = (props: MarkdownProps) => {
  const { children } = props;

  return <TableRow>{children}</TableRow>;
};

const MarkdownTableBody = (props: MarkdownProps) => {
  const { children } = props;

  return <TableBody>{children}</TableBody>;
};

const MarkdownTableHead = (props: MarkdownProps) => {
  const { children } = props;

  return <TableHead>{children}</TableHead>;
};

const MarkdownHeader = (props: MarkdownProps & { level: number }) => {
  const {
    level,
    ...other
  } = props;
  let variant;
  switch (level) {
    case 1:
      variant = 'h5';
      break;
    case 2:
      variant = 'h6';
      break;
    case 3:
      variant = 'subtitle1';
      break;
    case 4:
      variant = 'subtitle2';
      break;
    default:
      variant = 'h6';
      break;
  }

  return <Typography variant={variant} {...other} />;
};

const MarkdownLink = (
  props: React.ClassAttributes<HTMLAnchorElement> &
  React.AnchorHTMLAttributes<HTMLAnchorElement> &
  ReactMarkdownProps
) => {
  const { children, href, target, style } = props;

  return <Link component="a" href={href} target={target} style={style}>{children}</Link>;
};

const MarkdownParagraph = (props: MarkdownProps) => {
  const { children } = props;

  return <Typography>{children}</Typography>;
};

const renderers: Components = {
  a: MarkdownLink,
  h1: MarkdownHeader,
  h2: MarkdownHeader,
  h3: MarkdownHeader,
  h4: MarkdownHeader,
  h5: MarkdownHeader,
  h6: MarkdownHeader,
  p: MarkdownParagraph,
  table: MarkdownTable,
  tbody: MarkdownTableBody,
  thead: MarkdownTableHead,
  tr: MarkdownTableRow,
  td: MarkdownTableCell,
  th: MarkdownTableCell,
};

export default function Markdown(props) {
  return <ReactMarkdown components={renderers} {...props} />;
}
