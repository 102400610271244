import { calcProps, ModifyProps } from '../utils/propHelpers';
import {
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuItemProps,
  MenuProps,
  Typography,
} from '@material-ui/core';
import type { ReactElement, ReactNode } from 'react';
import { useState } from 'react';

export interface ContextMenuItem<T> {
  label: string,
  icon?: ReactNode,
  color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
  hidden?: (context: T) => boolean,
  action: (context: T) => void,
  props?: ModifyProps<MenuItemProps>,
  render?: (props: MenuItemProps) => ReactElement,
}

export type ContextMenuItems<T> = ContextMenuItem<T>[];

export interface ContextMenu<T> {
  menuItems: ContextMenuItems<T>,
  disabled?: (context: T) => boolean,
  onOpen?: () => void,
  onClose?: () => void,
  props?: ModifyProps<MenuProps>,
}

interface ContextMenuState {
  x: number | null,
  y: number | null,
}

const initialState: ContextMenuState = {
  x: null,
  y: null,
};

const useContextMenu = <T, >(config: ContextMenu<T>, context: T) => {
  const [state, setState] = useState<ContextMenuState>(initialState);

  let disabled = false;
  if (config.disabled !== undefined) {
    disabled = config.disabled(context);
  }

  const onContextMenu = (e: MouseEvent) => {
    e.preventDefault();

    if (disabled === true) {
      return;
    }

    setState({
      x: e.clientX,
      y: e.clientY,
    });

    if (config.onOpen !== undefined) {
      config.onOpen();
    }
  };

  const handleClose = () => {
    setState(initialState);

    if (config.onClose !== undefined) {
      config.onClose();
    }
  };

  let menuProps: MenuProps = {
    keepMounted: true,
    open: state.x !== null,
    onClose: handleClose,
    anchorReference: 'anchorPosition',
    anchorPosition: state.x !== null && state.y !== null
      ? {
        top: state.y,
        left: state.x,
      } : undefined,
  };

  menuProps = calcProps<MenuProps>(menuProps, config.props);

  const items = config.menuItems.filter((item) => item.hidden === undefined || item.hidden(context) === false)
    .map((item, i) => {
      if (item.label === '-') {
        const key = `---${i}`;
        return <Divider key={key} sx={{ my: 1 }} />;
      }

      const handleClick = () => {
        setState(initialState);

        item.action(context);
      };

      let itemProps: MenuItemProps = {
        key: item.label,
        onClick: handleClick,
        color: item.color,
      };

      itemProps = calcProps<MenuItemProps>(itemProps, item.props);
      if (typeof item.render === 'function') {
        return item.render(itemProps);
      }
      let value = <>{item.label}</>;
      if (item.icon !== undefined) {
        value = (
          <>
            <ListItemIcon>
              {item.icon}
            </ListItemIcon>
            <Typography color={item.color} variant="inherit">{item.label}</Typography>
          </>
        );
      }

      return (
        <MenuItem {...itemProps}>
          {value}
        </MenuItem>
      );
    });

  const component = (
    <Menu {...menuProps}>
      {items}
    </Menu>
  );

  return [onContextMenu, component];
};

export default useContextMenu;
