import React from 'react';
import Page, { PageProps } from '../../components/Page';
import { ContentView } from '../../components/ContentSwitcher';
import { useParams } from 'react-router';
import useLoadApiData from '../../hooks/useLoadApiData';
import { show } from '../../api/endpoints/Api/Tenants/Users';

import {
  DeleteAction,
  EditAction,
  TenantUserDetailInfoRows,
  TenantUserDetailSettings,
} from '../../resources/TenantUser';
import { Card, CardHeader, Divider, Grid } from '@material-ui/core';
import DetailTable from '../../components/DetailTable';
import useAuth from '../../hooks/useAuth';

const ShowAffiliatePage = () => {
  const { id } = useParams<{ id: string }>();
  const { currentDashboard } = useAuth();

  const {
    apiData,
  } = useLoadApiData(show)({
    id,
    tenantId: currentDashboard.id,
  });

  if (apiData === null) {
    return null;
  }

  const pageProps: PageProps = {
    pageHeaderProps: {
      title: 'Affiliate "{{name}}"',
      translationParams: {
        name: apiData.user.full_name,
      },
      actions: [
        EditAction('/affiliates'),
        DeleteAction('/affiliates'),
      ],
      breadCrumbs: {
        items: [
          {
            label: 'Dashboard',
            to: '/dashboard',
          },
          {
            label: 'Affiliates',
            to: '/affiliates',
          },
          {
            label: 'Affiliate "{{name}}"',
            translationParams: {
              name: apiData.user.full_name,
            },
          },
        ],
      },
    },
    context: apiData,
    title: 'Affiliate "{{name}}"',
    titleProps: {
      name: apiData.user.full_name,
    },
    pageTabs: {
      tabs: [
        {
          value: 'Information',
        },
      ],
    },
  };

  return (
    <Page {...pageProps}>
      <ContentView value="Information">
        <Grid container spacing={3}>
          <Grid item md={8} xs={12}>
            <Card>
              <CardHeader title="Contact Details" />
              <Divider />
              <DetailTable context={apiData} rows={TenantUserDetailInfoRows} />
            </Card>
          </Grid>
          <Grid item md={4} xs={12}>
            <Card>
              <CardHeader title="Settings" />
              <Divider />
              <DetailTable context={apiData} rows={TenantUserDetailSettings(true)} />
            </Card>
          </Grid>
        </Grid>
      </ContentView>
    </Page>
  );
};

export default ShowAffiliatePage;
