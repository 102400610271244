export const LanguagesMap = [
  {
    code: 'af',
    value: 'af',
    label: 'Afrikaans',
    title: 'Afrikaans',
  },
  {
    code: 'af-NA',
    value: 'af',
    label: 'Afrikaans (Namibia)',
    title: 'Afrikaans',
  },
  {
    code: 'af-ZA',
    value: 'af',
    label: 'Afrikaans (South Africa)',
    title: 'Afrikaans',
  },
  {
    code: 'ak',
    value: 'ak',
    label: 'Akan',
    title: 'Akan',
  },
  {
    code: 'ak-GH',
    value: 'ak',
    label: 'Akan (Ghana)',
    title: 'Akan',
  },
  {
    code: 'sq',
    value: 'sq',
    label: 'Albanian',
    title: 'Albanian',
  },
  {
    code: 'sq-AL',
    value: 'sq',
    label: 'Albanian (Albania)',
    title: 'Albanian',
  },
  {
    code: 'sq-XK',
    value: 'sq',
    label: 'Albanian (Kosovo)',
    title: 'Albanian',
  },
  {
    code: 'sq-MK',
    value: 'sq',
    label: 'Albanian (Macedonia)',
    title: 'Albanian',
  },
  {
    code: 'am',
    value: 'am',
    label: 'Amharic',
    title: 'Amharic',
  },
  {
    code: 'am-ET',
    value: 'am',
    label: 'Amharic (Ethiopia)',
    title: 'Amharic',
  },
  {
    code: 'ar',
    value: 'ar',
    label: 'Arabic',
    title: 'Arabic',
  },
  {
    code: 'ar-DZ',
    value: 'ar',
    label: 'Arabic (Algeria)',
    title: 'Arabic',
  },
  {
    code: 'ar-BH',
    value: 'ar',
    label: 'Arabic (Bahrain)',
    title: 'Arabic',
  },
  {
    code: 'ar-TD',
    value: 'ar',
    label: 'Arabic (Chad)',
    title: 'Arabic',
  },
  {
    code: 'ar-KM',
    value: 'ar',
    label: 'Arabic (Comoros)',
    title: 'Arabic',
  },
  {
    code: 'ar-DJ',
    value: 'ar',
    label: 'Arabic (Djibouti)',
    title: 'Arabic',
  },
  {
    code: 'ar-EG',
    value: 'ar',
    label: 'Arabic (Egypt)',
    title: 'Arabic',
  },
  {
    code: 'ar-ER',
    value: 'ar',
    label: 'Arabic (Eritrea)',
    title: 'Arabic',
  },
  {
    code: 'ar-IQ',
    value: 'ar',
    label: 'Arabic (Iraq)',
    title: 'Arabic',
  },
  {
    code: 'ar-IL',
    value: 'ar',
    label: 'Arabic (Israel)',
    title: 'Arabic',
  },
  {
    code: 'ar-JO',
    value: 'ar',
    label: 'Arabic (Jordan)',
    title: 'Arabic',
  },
  {
    code: 'ar-KW',
    value: 'ar',
    label: 'Arabic (Kuwait)',
    title: 'Arabic',
  },
  {
    code: 'ar-LB',
    value: 'ar',
    label: 'Arabic (Lebanon)',
    title: 'Arabic',
  },
  {
    code: 'ar-LY',
    value: 'ar',
    label: 'Arabic (Libya)',
    title: 'Arabic',
  },
  {
    code: 'ar-MR',
    value: 'ar',
    label: 'Arabic (Mauritania)',
    title: 'Arabic',
  },
  {
    code: 'ar-MA',
    value: 'ar',
    label: 'Arabic (Morocco)',
    title: 'Arabic',
  },
  {
    code: 'ar-OM',
    value: 'ar',
    label: 'Arabic (Oman)',
    title: 'Arabic',
  },
  {
    code: 'ar-PS',
    value: 'ar',
    label: 'Arabic (Palestinian Territories)',
    title: 'Arabic',
  },
  {
    code: 'ar-QA',
    value: 'ar',
    label: 'Arabic (Qatar)',
    title: 'Arabic',
  },
  {
    code: 'ar-SA',
    value: 'ar',
    label: 'Arabic (Saudi Arabia)',
    title: 'Arabic',
  },
  {
    code: 'ar-SO',
    value: 'ar',
    label: 'Arabic (Somalia)',
    title: 'Arabic',
  },
  {
    code: 'ar-SS',
    value: 'ar',
    label: 'Arabic (South Sudan)',
    title: 'Arabic',
  },
  {
    code: 'ar-SD',
    value: 'ar',
    label: 'Arabic (Sudan)',
    title: 'Arabic',
  },
  {
    code: 'ar-SY',
    value: 'ar',
    label: 'Arabic (Syria)',
    title: 'Arabic',
  },
  {
    code: 'ar-TN',
    value: 'ar',
    label: 'Arabic (Tunisia)',
    title: 'Arabic',
  },
  {
    code: 'ar-AE',
    value: 'ar',
    label: 'Arabic (United Arab Emirates)',
    title: 'Arabic',
  },
  {
    code: 'ar-EH',
    value: 'ar',
    label: 'Arabic (Western Sahara)',
    title: 'Arabic',
  },
  {
    code: 'ar-YE',
    value: 'ar',
    label: 'Arabic (Yemen)',
    title: 'Arabic',
  },
  {
    code: 'hy',
    value: 'hy',
    label: 'Armenian',
    title: 'Armenian',
  },
  {
    code: 'hy-AM',
    value: 'hy',
    label: 'Armenian (Armenia)',
    title: 'Armenian',
  },
  {
    code: 'as',
    value: 'as',
    label: 'Assamese',
    title: 'Assamese',
  },
  {
    code: 'as-IN',
    value: 'as',
    label: 'Assamese (India)',
    title: 'Assamese',
  },
  {
    code: 'az',
    value: 'az',
    label: 'Azerbaijani',
    title: 'Azerbaijani',
  },
  {
    code: 'az-AZ',
    value: 'az',
    label: 'Azerbaijani (Azerbaijan)',
    title: 'Azerbaijani',
  },
  {
    code: 'az-Cyrl-AZ',
    value: 'az',
    label: 'Azerbaijani (Cyrillic, Azerbaijan)',
    title: 'Azerbaijani',
  },
  {
    code: 'az-Cyrl',
    value: 'az',
    label: 'Azerbaijani (Cyrillic)',
    title: 'Azerbaijani',
  },
  {
    code: 'az-Latn-AZ',
    value: 'az',
    label: 'Azerbaijani (Latin, Azerbaijan)',
    title: 'Azerbaijani',
  },
  {
    code: 'az-Latn',
    value: 'az',
    label: 'Azerbaijani (Latin)',
    title: 'Azerbaijani',
  },
  {
    code: 'bm',
    value: 'bm',
    label: 'Bambara',
    title: 'Bambara',
  },
  {
    code: 'bm-Latn-ML',
    value: 'bm',
    label: 'Bambara (Latin, Mali)',
    title: 'Bambara',
  },
  {
    code: 'bm-Latn',
    value: 'bm',
    label: 'Bambara (Latin)',
    title: 'Bambara',
  },
  {
    code: 'eu',
    value: 'eu',
    label: 'Basque',
    title: 'Basque',
  },
  {
    code: 'eu-ES',
    value: 'eu',
    label: 'Basque (Spain)',
    title: 'Basque',
  },
  {
    code: 'be',
    value: 'be',
    label: 'Belarusian',
    title: 'Belarusian',
  },
  {
    code: 'be-BY',
    value: 'be',
    label: 'Belarusian (Belarus)',
    title: 'Belarusian',
  },
  {
    code: 'bn',
    value: 'bn',
    label: 'Bengali',
    title: 'Bengali',
  },
  {
    code: 'bn-BD',
    value: 'bn',
    label: 'Bengali (Bangladesh)',
    title: 'Bengali',
  },
  {
    code: 'bn-IN',
    value: 'bn',
    label: 'Bengali (India)',
    title: 'Bengali',
  },
  {
    code: 'bs',
    value: 'bs',
    label: 'Bosnian',
    title: 'Bosnian',
  },
  {
    code: 'bs-BA',
    value: 'bs',
    label: 'Bosnian (Bosnia & Herzegovina)',
    title: 'Bosnian',
  },
  {
    code: 'bs-Cyrl-BA',
    value: 'bs',
    label: 'Bosnian (Cyrillic, Bosnia & Herzegovina)',
    title: 'Bosnian',
  },
  {
    code: 'bs-Cyrl',
    value: 'bs',
    label: 'Bosnian (Cyrillic)',
    title: 'Bosnian',
  },
  {
    code: 'bs-Latn-BA',
    value: 'bs',
    label: 'Bosnian (Latin, Bosnia & Herzegovina)',
    title: 'Bosnian',
  },
  {
    code: 'bs-Latn',
    value: 'bs',
    label: 'Bosnian (Latin)',
    title: 'Bosnian',
  },
  {
    code: 'br',
    value: 'br',
    label: 'Breton',
    title: 'Breton',
  },
  {
    code: 'br-FR',
    value: 'br',
    label: 'Breton (France)',
    title: 'Breton',
  },
  {
    code: 'bg',
    value: 'bg',
    label: 'Bulgarian',
    title: 'Bulgarian',
  },
  {
    code: 'bg-BG',
    value: 'bg',
    label: 'Bulgarian (Bulgaria)',
    title: 'Bulgarian',
  },
  {
    code: 'my',
    value: 'my',
    label: 'Burmese',
    title: 'Burmese',
  },
  {
    code: 'my-MM',
    value: 'my',
    label: 'Burmese (Myanmar (Burma))',
    title: 'Burmese',
  },
  {
    code: 'ca',
    value: 'ca',
    label: 'Catalan',
    title: 'Catalan',
  },
  {
    code: 'ca-AD',
    value: 'ca',
    label: 'Catalan (Andorra)',
    title: 'Catalan',
  },
  {
    code: 'ca-FR',
    value: 'ca',
    label: 'Catalan (France)',
    title: 'Catalan',
  },
  {
    code: 'ca-IT',
    value: 'ca',
    label: 'Catalan (Italy)',
    title: 'Catalan',
  },
  {
    code: 'ca-ES',
    value: 'ca',
    label: 'Catalan (Spain)',
    title: 'Catalan',
  },
  {
    code: 'zh',
    value: 'zh',
    label: 'Chinese',
    title: 'Chinese',
  },
  {
    code: 'zh-CN',
    value: 'zh',
    label: 'Chinese (China)',
    title: 'Chinese',
  },
  {
    code: 'zh-HK',
    value: 'zh',
    label: 'Chinese (Hong Kong SAR China)',
    title: 'Chinese',
  },
  {
    code: 'zh-MO',
    value: 'zh',
    label: 'Chinese (Macau SAR China)',
    title: 'Chinese',
  },
  {
    code: 'zh-Hans-CN',
    value: 'zh',
    label: 'Chinese (Simplified, China)',
    title: 'Chinese',
  },
  {
    code: 'zh-Hans-HK',
    value: 'zh',
    label: 'Chinese (Simplified, Hong Kong SAR China)',
    title: 'Chinese',
  },
  {
    code: 'zh-Hans-MO',
    value: 'zh',
    label: 'Chinese (Simplified, Macau SAR China)',
    title: 'Chinese',
  },
  {
    code: 'zh-Hans-SG',
    value: 'zh',
    label: 'Chinese (Simplified, Singapore)',
    title: 'Chinese',
  },
  {
    code: 'zh-Hans',
    value: 'zh',
    label: 'Chinese (Simplified)',
    title: 'Chinese',
  },
  {
    code: 'zh-SG',
    value: 'zh',
    label: 'Chinese (Singapore)',
    title: 'Chinese',
  },
  {
    code: 'zh-TW',
    value: 'zh',
    label: 'Chinese (Taiwan)',
    title: 'Chinese',
  },
  {
    code: 'zh-Hant-HK',
    value: 'zh',
    label: 'Chinese (Traditional, Hong Kong SAR China)',
    title: 'Chinese',
  },
  {
    code: 'zh-Hant-MO',
    value: 'zh',
    label: 'Chinese (Traditional, Macau SAR China)',
    title: 'Chinese',
  },
  {
    code: 'zh-Hant-TW',
    value: 'zh',
    label: 'Chinese (Traditional, Taiwan)',
    title: 'Chinese',
  },
  {
    code: 'zh-Hant',
    value: 'zh',
    label: 'Chinese (Traditional)',
    title: 'Chinese',
  },
  {
    code: 'kw',
    value: 'kw',
    label: 'Cornish',
    title: 'Cornish',
  },
  {
    code: 'kw-GB',
    value: 'kw',
    label: 'Cornish (United Kingdom)',
    title: 'Cornish',
  },
  {
    code: 'hr',
    value: 'hr',
    label: 'Croatian',
    title: 'Croatian',
  },
  {
    code: 'hr-BA',
    value: 'hr',
    label: 'Croatian (Bosnia & Herzegovina)',
    title: 'Croatian',
  },
  {
    code: 'hr-HR',
    value: 'hr',
    label: 'Croatian (Croatia)',
    title: 'Croatian',
  },
  {
    code: 'cs',
    value: 'cs',
    label: 'Czech',
    title: 'Czech',
  },
  {
    code: 'cs-CZ',
    value: 'cs',
    label: 'Czech (Czech Republic)',
    title: 'Czech',
  },
  {
    code: 'da',
    value: 'da',
    label: 'Danish',
    title: 'Danish',
  },
  {
    code: 'da-DK',
    value: 'da',
    label: 'Danish (Denmark)',
    title: 'Danish',
  },
  {
    code: 'da-GL',
    value: 'da',
    label: 'Danish (Greenland)',
    title: 'Danish',
  },
  {
    code: 'nl',
    value: 'nl',
    label: 'Dutch',
    title: 'Dutch',
  },
  {
    code: 'nl-AW',
    value: 'nl',
    label: 'Dutch (Aruba)',
    title: 'Dutch',
  },
  {
    code: 'nl-BE',
    value: 'nl',
    label: 'Dutch (Belgium)',
    title: 'Dutch',
  },
  {
    code: 'nl-BQ',
    value: 'nl',
    label: 'Dutch (Caribbean Netherlands)',
    title: 'Dutch',
  },
  {
    code: 'nl-CW',
    value: 'nl',
    label: 'Dutch (Curaçao)',
    title: 'Dutch',
  },
  {
    code: 'nl-NL',
    value: 'nl',
    label: 'Dutch (Netherlands)',
    title: 'Dutch',
  },
  {
    code: 'nl-SX',
    value: 'nl',
    label: 'Dutch (Sint Maarten)',
    title: 'Dutch',
  },
  {
    code: 'nl-SR',
    value: 'nl',
    label: 'Dutch (Suriname)',
    title: 'Dutch',
  },
  {
    code: 'dz',
    value: 'dz',
    label: 'Dzongkha',
    title: 'Dzongkha',
  },
  {
    code: 'dz-BT',
    value: 'dz',
    label: 'Dzongkha (Bhutan)',
    title: 'Dzongkha',
  },
  {
    code: 'en',
    value: 'en',
    label: 'English',
    title: 'English',
  },
  {
    code: 'en-AS',
    value: 'en',
    label: 'English (American Samoa)',
    title: 'English',
  },
  {
    code: 'en-AI',
    value: 'en',
    label: 'English (Anguilla)',
    title: 'English',
  },
  {
    code: 'en-AG',
    value: 'en',
    label: 'English (Antigua & Barbuda)',
    title: 'English',
  },
  {
    code: 'en-AU',
    value: 'en',
    label: 'English (Australia)',
    title: 'English',
  },
  {
    code: 'en-BS',
    value: 'en',
    label: 'English (Bahamas)',
    title: 'English',
  },
  {
    code: 'en-BB',
    value: 'en',
    label: 'English (Barbados)',
    title: 'English',
  },
  {
    code: 'en-BE',
    value: 'en',
    label: 'English (Belgium)',
    title: 'English',
  },
  {
    code: 'en-BZ',
    value: 'en',
    label: 'English (Belize)',
    title: 'English',
  },
  {
    code: 'en-BM',
    value: 'en',
    label: 'English (Bermuda)',
    title: 'English',
  },
  {
    code: 'en-BW',
    value: 'en',
    label: 'English (Botswana)',
    title: 'English',
  },
  {
    code: 'en-IO',
    value: 'en',
    label: 'English (British Indian Ocean Territory)',
    title: 'English',
  },
  {
    code: 'en-VG',
    value: 'en',
    label: 'English (British Virgin Islands)',
    title: 'English',
  },
  {
    code: 'en-CM',
    value: 'en',
    label: 'English (Cameroon)',
    title: 'English',
  },
  {
    code: 'en-CA',
    value: 'en',
    label: 'English (Canada)',
    title: 'English',
  },
  {
    code: 'en-KY',
    value: 'en',
    label: 'English (Cayman Islands)',
    title: 'English',
  },
  {
    code: 'en-CX',
    value: 'en',
    label: 'English (Christmas Island)',
    title: 'English',
  },
  {
    code: 'en-CC',
    value: 'en',
    label: 'English (Cocos (Keeling) Islands)',
    title: 'English',
  },
  {
    code: 'en-CK',
    value: 'en',
    label: 'English (Cook Islands)',
    title: 'English',
  },
  {
    code: 'en-DG',
    value: 'en',
    label: 'English (Diego Garcia)',
    title: 'English',
  },
  {
    code: 'en-DM',
    value: 'en',
    label: 'English (Dominica)',
    title: 'English',
  },
  {
    code: 'en-ER',
    value: 'en',
    label: 'English (Eritrea)',
    title: 'English',
  },
  {
    code: 'en-FK',
    value: 'en',
    label: 'English (Falkland Islands)',
    title: 'English',
  },
  {
    code: 'en-FJ',
    value: 'en',
    label: 'English (Fiji)',
    title: 'English',
  },
  {
    code: 'en-GM',
    value: 'en',
    label: 'English (Gambia)',
    title: 'English',
  },
  {
    code: 'en-GH',
    value: 'en',
    label: 'English (Ghana)',
    title: 'English',
  },
  {
    code: 'en-GI',
    value: 'en',
    label: 'English (Gibraltar)',
    title: 'English',
  },
  {
    code: 'en-GD',
    value: 'en',
    label: 'English (Grenada)',
    title: 'English',
  },
  {
    code: 'en-GU',
    value: 'en',
    label: 'English (Guam)',
    title: 'English',
  },
  {
    code: 'en-GG',
    value: 'en',
    label: 'English (Guernsey)',
    title: 'English',
  },
  {
    code: 'en-GY',
    value: 'en',
    label: 'English (Guyana)',
    title: 'English',
  },
  {
    code: 'en-HK',
    value: 'en',
    label: 'English (Hong Kong SAR China)',
    title: 'English',
  },
  {
    code: 'en-IN',
    value: 'en',
    label: 'English (India)',
    title: 'English',
  },
  {
    code: 'en-IE',
    value: 'en',
    label: 'English (Ireland)',
    title: 'English',
  },
  {
    code: 'en-IM',
    value: 'en',
    label: 'English (Isle of Man)',
    title: 'English',
  },
  {
    code: 'en-JM',
    value: 'en',
    label: 'English (Jamaica)',
    title: 'English',
  },
  {
    code: 'en-JE',
    value: 'en',
    label: 'English (Jersey)',
    title: 'English',
  },
  {
    code: 'en-KE',
    value: 'en',
    label: 'English (Kenya)',
    title: 'English',
  },
  {
    code: 'en-KI',
    value: 'en',
    label: 'English (Kiribati)',
    title: 'English',
  },
  {
    code: 'en-LS',
    value: 'en',
    label: 'English (Lesotho)',
    title: 'English',
  },
  {
    code: 'en-LR',
    value: 'en',
    label: 'English (Liberia)',
    title: 'English',
  },
  {
    code: 'en-MO',
    value: 'en',
    label: 'English (Macau SAR China)',
    title: 'English',
  },
  {
    code: 'en-MG',
    value: 'en',
    label: 'English (Madagascar)',
    title: 'English',
  },
  {
    code: 'en-MW',
    value: 'en',
    label: 'English (Malawi)',
    title: 'English',
  },
  {
    code: 'en-MY',
    value: 'en',
    label: 'English (Malaysia)',
    title: 'English',
  },
  {
    code: 'en-MT',
    value: 'en',
    label: 'English (Malta)',
    title: 'English',
  },
  {
    code: 'en-MH',
    value: 'en',
    label: 'English (Marshall Islands)',
    title: 'English',
  },
  {
    code: 'en-MU',
    value: 'en',
    label: 'English (Mauritius)',
    title: 'English',
  },
  {
    code: 'en-FM',
    value: 'en',
    label: 'English (Micronesia)',
    title: 'English',
  },
  {
    code: 'en-MS',
    value: 'en',
    label: 'English (Montserrat)',
    title: 'English',
  },
  {
    code: 'en-NA',
    value: 'en',
    label: 'English (Namibia)',
    title: 'English',
  },
  {
    code: 'en-NR',
    value: 'en',
    label: 'English (Nauru)',
    title: 'English',
  },
  {
    code: 'en-NZ',
    value: 'en',
    label: 'English (New Zealand)',
    title: 'English',
  },
  {
    code: 'en-NG',
    value: 'en',
    label: 'English (Nigeria)',
    title: 'English',
  },
  {
    code: 'en-NU',
    value: 'en',
    label: 'English (Niue)',
    title: 'English',
  },
  {
    code: 'en-NF',
    value: 'en',
    label: 'English (Norfolk Island)',
    title: 'English',
  },
  {
    code: 'en-MP',
    value: 'en',
    label: 'English (Northern Mariana Islands)',
    title: 'English',
  },
  {
    code: 'en-PK',
    value: 'en',
    label: 'English (Pakistan)',
    title: 'English',
  },
  {
    code: 'en-PW',
    value: 'en',
    label: 'English (Palau)',
    title: 'English',
  },
  {
    code: 'en-PG',
    value: 'en',
    label: 'English (Papua New Guinea)',
    title: 'English',
  },
  {
    code: 'en-PH',
    value: 'en',
    label: 'English (Philippines)',
    title: 'English',
  },
  {
    code: 'en-PN',
    value: 'en',
    label: 'English (Pitcairn Islands)',
    title: 'English',
  },
  {
    code: 'en-PR',
    value: 'en',
    label: 'English (Puerto Rico)',
    title: 'English',
  },
  {
    code: 'en-RW',
    value: 'en',
    label: 'English (Rwanda)',
    title: 'English',
  },
  {
    code: 'en-WS',
    value: 'en',
    label: 'English (Samoa)',
    title: 'English',
  },
  {
    code: 'en-SC',
    value: 'en',
    label: 'English (Seychelles)',
    title: 'English',
  },
  {
    code: 'en-SL',
    value: 'en',
    label: 'English (Sierra Leone)',
    title: 'English',
  },
  {
    code: 'en-SG',
    value: 'en',
    label: 'English (Singapore)',
    title: 'English',
  },
  {
    code: 'en-SX',
    value: 'en',
    label: 'English (Sint Maarten)',
    title: 'English',
  },
  {
    code: 'en-SB',
    value: 'en',
    label: 'English (Solomon Islands)',
    title: 'English',
  },
  {
    code: 'en-ZA',
    value: 'en',
    label: 'English (South Africa)',
    title: 'English',
  },
  {
    code: 'en-SS',
    value: 'en',
    label: 'English (South Sudan)',
    title: 'English',
  },
  {
    code: 'en-SH',
    value: 'en',
    label: 'English (St. Helena)',
    title: 'English',
  },
  {
    code: 'en-KN',
    value: 'en',
    label: 'English (St. Kitts & Nevis)',
    title: 'English',
  },
  {
    code: 'en-LC',
    value: 'en',
    label: 'English (St. Lucia)',
    title: 'English',
  },
  {
    code: 'en-VC',
    value: 'en',
    label: 'English (St. Vincent & Grenadines)',
    title: 'English',
  },
  {
    code: 'en-SD',
    value: 'en',
    label: 'English (Sudan)',
    title: 'English',
  },
  {
    code: 'en-SZ',
    value: 'en',
    label: 'English (Swaziland)',
    title: 'English',
  },
  {
    code: 'en-TZ',
    value: 'en',
    label: 'English (Tanzania)',
    title: 'English',
  },
  {
    code: 'en-TK',
    value: 'en',
    label: 'English (Tokelau)',
    title: 'English',
  },
  {
    code: 'en-TO',
    value: 'en',
    label: 'English (Tonga)',
    title: 'English',
  },
  {
    code: 'en-TT',
    value: 'en',
    label: 'English (Trinidad & Tobago)',
    title: 'English',
  },
  {
    code: 'en-TC',
    value: 'en',
    label: 'English (Turks & Caicos Islands)',
    title: 'English',
  },
  {
    code: 'en-TV',
    value: 'en',
    label: 'English (Tuvalu)',
    title: 'English',
  },
  {
    code: 'en-UM',
    value: 'en',
    label: 'English (U.S. Outlying Islands)',
    title: 'English',
  },
  {
    code: 'en-VI',
    value: 'en',
    label: 'English (U.S. Virgin Islands)',
    title: 'English',
  },
  {
    code: 'en-UG',
    value: 'en',
    label: 'English (Uganda)',
    title: 'English',
  },
  {
    code: 'en-GB',
    value: 'en',
    label: 'English (United Kingdom)',
    title: 'English',
  },
  {
    code: 'en-US',
    value: 'en',
    label: 'English (United States)',
    title: 'English',
  },
  {
    code: 'en-VU',
    value: 'en',
    label: 'English (Vanuatu)',
    title: 'English',
  },
  {
    code: 'en-ZM',
    value: 'en',
    label: 'English (Zambia)',
    title: 'English',
  },
  {
    code: 'en-ZW',
    value: 'en',
    label: 'English (Zimbabwe)',
    title: 'English',
  },
  {
    code: 'eo',
    value: 'eo',
    label: 'Esperanto',
    title: 'Esperanto',
  },
  {
    code: 'et',
    value: 'et',
    label: 'Estonian',
    title: 'Estonian',
  },
  {
    code: 'et-EE',
    value: 'et',
    label: 'Estonian (Estonia)',
    title: 'Estonian',
  },
  {
    code: 'ee',
    value: 'ee',
    label: 'Ewe',
    title: 'Ewe',
  },
  {
    code: 'ee-GH',
    value: 'ee',
    label: 'Ewe (Ghana)',
    title: 'Ewe',
  },
  {
    code: 'ee-TG',
    value: 'ee',
    label: 'Ewe (Togo)',
    title: 'Ewe',
  },
  {
    code: 'fo',
    value: 'fo',
    label: 'Faroese',
    title: 'Faroese',
  },
  {
    code: 'fo-FO',
    value: 'fo',
    label: 'Faroese (Faroe Islands)',
    title: 'Faroese',
  },
  {
    code: 'fi',
    value: 'fi',
    label: 'Finnish',
    title: 'Finnish',
  },
  {
    code: 'fi-FI',
    value: 'fi',
    label: 'Finnish (Finland)',
    title: 'Finnish',
  },
  {
    code: 'fr',
    value: 'fr',
    label: 'French',
    title: 'French',
  },
  {
    code: 'fr-DZ',
    value: 'fr',
    label: 'French (Algeria)',
    title: 'French',
  },
  {
    code: 'fr-BE',
    value: 'fr',
    label: 'French (Belgium)',
    title: 'French',
  },
  {
    code: 'fr-BJ',
    value: 'fr',
    label: 'French (Benin)',
    title: 'French',
  },
  {
    code: 'fr-BF',
    value: 'fr',
    label: 'French (Burkina Faso)',
    title: 'French',
  },
  {
    code: 'fr-BI',
    value: 'fr',
    label: 'French (Burundi)',
    title: 'French',
  },
  {
    code: 'fr-CM',
    value: 'fr',
    label: 'French (Cameroon)',
    title: 'French',
  },
  {
    code: 'fr-CA',
    value: 'fr',
    label: 'French (Canada)',
    title: 'French',
  },
  {
    code: 'fr-CF',
    value: 'fr',
    label: 'French (Central African Republic)',
    title: 'French',
  },
  {
    code: 'fr-TD',
    value: 'fr',
    label: 'French (Chad)',
    title: 'French',
  },
  {
    code: 'fr-KM',
    value: 'fr',
    label: 'French (Comoros)',
    title: 'French',
  },
  {
    code: 'fr-CG',
    value: 'fr',
    label: 'French (Congo - Brazzaville)',
    title: 'French',
  },
  {
    code: 'fr-CD',
    value: 'fr',
    label: 'French (Congo - Kinshasa)',
    title: 'French',
  },
  {
    code: 'fr-CI',
    value: 'fr',
    label: 'French (Côte d’Ivoire)',
    title: 'French',
  },
  {
    code: 'fr-DJ',
    value: 'fr',
    label: 'French (Djibouti)',
    title: 'French',
  },
  {
    code: 'fr-GQ',
    value: 'fr',
    label: 'French (Equatorial Guinea)',
    title: 'French',
  },
  {
    code: 'fr-FR',
    value: 'fr',
    label: 'French (France)',
    title: 'French',
  },
  {
    code: 'fr-GF',
    value: 'fr',
    label: 'French (French Guiana)',
    title: 'French',
  },
  {
    code: 'fr-PF',
    value: 'fr',
    label: 'French (French Polynesia)',
    title: 'French',
  },
  {
    code: 'fr-GA',
    value: 'fr',
    label: 'French (Gabon)',
    title: 'French',
  },
  {
    code: 'fr-GP',
    value: 'fr',
    label: 'French (Guadeloupe)',
    title: 'French',
  },
  {
    code: 'fr-GN',
    value: 'fr',
    label: 'French (Guinea)',
    title: 'French',
  },
  {
    code: 'fr-HT',
    value: 'fr',
    label: 'French (Haiti)',
    title: 'French',
  },
  {
    code: 'fr-LU',
    value: 'fr',
    label: 'French (Luxembourg)',
    title: 'French',
  },
  {
    code: 'fr-MG',
    value: 'fr',
    label: 'French (Madagascar)',
    title: 'French',
  },
  {
    code: 'fr-ML',
    value: 'fr',
    label: 'French (Mali)',
    title: 'French',
  },
  {
    code: 'fr-MQ',
    value: 'fr',
    label: 'French (Martinique)',
    title: 'French',
  },
  {
    code: 'fr-MR',
    value: 'fr',
    label: 'French (Mauritania)',
    title: 'French',
  },
  {
    code: 'fr-MU',
    value: 'fr',
    label: 'French (Mauritius)',
    title: 'French',
  },
  {
    code: 'fr-YT',
    value: 'fr',
    label: 'French (Mayotte)',
    title: 'French',
  },
  {
    code: 'fr-MC',
    value: 'fr',
    label: 'French (Monaco)',
    title: 'French',
  },
  {
    code: 'fr-MA',
    value: 'fr',
    label: 'French (Morocco)',
    title: 'French',
  },
  {
    code: 'fr-NC',
    value: 'fr',
    label: 'French (New Caledonia)',
    title: 'French',
  },
  {
    code: 'fr-NE',
    value: 'fr',
    label: 'French (Niger)',
    title: 'French',
  },
  {
    code: 'fr-RE',
    value: 'fr',
    label: 'French (Réunion)',
    title: 'French',
  },
  {
    code: 'fr-RW',
    value: 'fr',
    label: 'French (Rwanda)',
    title: 'French',
  },
  {
    code: 'fr-SN',
    value: 'fr',
    label: 'French (Senegal)',
    title: 'French',
  },
  {
    code: 'fr-SC',
    value: 'fr',
    label: 'French (Seychelles)',
    title: 'French',
  },
  {
    code: 'fr-BL',
    value: 'fr',
    label: 'French (St. Barthélemy)',
    title: 'French',
  },
  {
    code: 'fr-MF',
    value: 'fr',
    label: 'French (St. Martin)',
    title: 'French',
  },
  {
    code: 'fr-PM',
    value: 'fr',
    label: 'French (St. Pierre & Miquelon)',
    title: 'French',
  },
  {
    code: 'fr-CH',
    value: 'fr',
    label: 'French (Switzerland)',
    title: 'French',
  },
  {
    code: 'fr-SY',
    value: 'fr',
    label: 'French (Syria)',
    title: 'French',
  },
  {
    code: 'fr-TG',
    value: 'fr',
    label: 'French (Togo)',
    title: 'French',
  },
  {
    code: 'fr-TN',
    value: 'fr',
    label: 'French (Tunisia)',
    title: 'French',
  },
  {
    code: 'fr-VU',
    value: 'fr',
    label: 'French (Vanuatu)',
    title: 'French',
  },
  {
    code: 'fr-WF',
    value: 'fr',
    label: 'French (Wallis & Futuna)',
    title: 'French',
  },
  {
    code: 'ff',
    value: 'ff',
    label: 'Fulah',
    title: 'Fulah',
  },
  {
    code: 'ff-CM',
    value: 'ff',
    label: 'Fulah (Cameroon)',
    title: 'Fulah',
  },
  {
    code: 'ff-GN',
    value: 'ff',
    label: 'Fulah (Guinea)',
    title: 'Fulah',
  },
  {
    code: 'ff-MR',
    value: 'ff',
    label: 'Fulah (Mauritania)',
    title: 'Fulah',
  },
  {
    code: 'ff-SN',
    value: 'ff',
    label: 'Fulah (Senegal)',
    title: 'Fulah',
  },
  {
    code: 'gl',
    value: 'gl',
    label: 'Galician',
    title: 'Galician',
  },
  {
    code: 'gl-ES',
    value: 'gl',
    label: 'Galician (Spain)',
    title: 'Galician',
  },
  {
    code: 'lg',
    value: 'lg',
    label: 'Ganda',
    title: 'Ganda',
  },
  {
    code: 'lg-UG',
    value: 'lg',
    label: 'Ganda (Uganda)',
    title: 'Ganda',
  },
  {
    code: 'ka',
    value: 'ka',
    label: 'Georgian',
    title: 'Georgian',
  },
  {
    code: 'ka-GE',
    value: 'ka',
    label: 'Georgian (Georgia)',
    title: 'Georgian',
  },
  {
    code: 'de',
    value: 'de',
    label: 'German',
    title: 'German',
  },
  {
    code: 'de-AT',
    value: 'de',
    label: 'German (Austria)',
    title: 'German',
  },
  {
    code: 'de-BE',
    value: 'de',
    label: 'German (Belgium)',
    title: 'German',
  },
  {
    code: 'de-DE',
    value: 'de',
    label: 'German (Germany)',
    title: 'German',
  },
  {
    code: 'de-LI',
    value: 'de',
    label: 'German (Liechtenstein)',
    title: 'German',
  },
  {
    code: 'de-LU',
    value: 'de',
    label: 'German (Luxembourg)',
    title: 'German',
  },
  {
    code: 'de-CH',
    value: 'de',
    label: 'German (Switzerland)',
    title: 'German',
  },
  {
    code: 'el',
    value: 'el',
    label: 'Greek',
    title: 'Greek',
  },
  {
    code: 'el-CY',
    value: 'el',
    label: 'Greek (Cyprus)',
    title: 'Greek',
  },
  {
    code: 'el-GR',
    value: 'el',
    label: 'Greek (Greece)',
    title: 'Greek',
  },
  {
    code: 'gu',
    value: 'gu',
    label: 'Gujarati',
    title: 'Gujarati',
  },
  {
    code: 'gu-IN',
    value: 'gu',
    label: 'Gujarati (India)',
    title: 'Gujarati',
  },
  {
    code: 'ha',
    value: 'ha',
    label: 'Hausa',
    title: 'Hausa',
  },
  {
    code: 'ha-GH',
    value: 'ha',
    label: 'Hausa (Ghana)',
    title: 'Hausa',
  },
  {
    code: 'ha-Latn-GH',
    value: 'ha',
    label: 'Hausa (Latin, Ghana)',
    title: 'Hausa',
  },
  {
    code: 'ha-Latn-NE',
    value: 'ha',
    label: 'Hausa (Latin, Niger)',
    title: 'Hausa',
  },
  {
    code: 'ha-Latn-NG',
    value: 'ha',
    label: 'Hausa (Latin, Nigeria)',
    title: 'Hausa',
  },
  {
    code: 'ha-Latn',
    value: 'ha',
    label: 'Hausa (Latin)',
    title: 'Hausa',
  },
  {
    code: 'ha-NE',
    value: 'ha',
    label: 'Hausa (Niger)',
    title: 'Hausa',
  },
  {
    code: 'ha-NG',
    value: 'ha',
    label: 'Hausa (Nigeria)',
    title: 'Hausa',
  },
  {
    code: 'he',
    value: 'he',
    label: 'Hebrew',
    title: 'Hebrew',
  },
  {
    code: 'he-IL',
    value: 'he',
    label: 'Hebrew (Israel)',
    title: 'Hebrew',
  },
  {
    code: 'hi',
    value: 'hi',
    label: 'Hindi',
    title: 'Hindi',
  },
  {
    code: 'hi-IN',
    value: 'hi',
    label: 'Hindi (India)',
    title: 'Hindi',
  },
  {
    code: 'hu',
    value: 'hu',
    label: 'Hungarian',
    title: 'Hungarian',
  },
  {
    code: 'hu-HU',
    value: 'hu',
    label: 'Hungarian (Hungary)',
    title: 'Hungarian',
  },
  {
    code: 'is',
    value: 'is',
    label: 'Icelandic',
    title: 'Icelandic',
  },
  {
    code: 'is-IS',
    value: 'is',
    label: 'Icelandic (Iceland)',
    title: 'Icelandic',
  },
  {
    code: 'ig',
    value: 'ig',
    label: 'Igbo',
    title: 'Igbo',
  },
  {
    code: 'ig-NG',
    value: 'ig',
    label: 'Igbo (Nigeria)',
    title: 'Igbo',
  },
  {
    code: 'id',
    value: 'id',
    label: 'Indonesian',
    title: 'Indonesian',
  },
  {
    code: 'id-ID',
    value: 'id',
    label: 'Indonesian (Indonesia)',
    title: 'Indonesian',
  },
  {
    code: 'ga',
    value: 'ga',
    label: 'Irish',
    title: 'Irish',
  },
  {
    code: 'ga-IE',
    value: 'ga',
    label: 'Irish (Ireland)',
    title: 'Irish',
  },
  {
    code: 'it',
    value: 'it',
    label: 'Italian',
    title: 'Italian',
  },
  {
    code: 'it-IT',
    value: 'it',
    label: 'Italian (Italy)',
    title: 'Italian',
  },
  {
    code: 'it-SM',
    value: 'it',
    label: 'Italian (San Marino)',
    title: 'Italian',
  },
  {
    code: 'it-CH',
    value: 'it',
    label: 'Italian (Switzerland)',
    title: 'Italian',
  },
  {
    code: 'ja',
    value: 'ja',
    label: 'Japanese',
    title: 'Japanese',
  },
  {
    code: 'ja-JP',
    value: 'ja',
    label: 'Japanese (Japan)',
    title: 'Japanese',
  },
  {
    code: 'kl',
    value: 'kl',
    label: 'Kalaallisut',
    title: 'Kalaallisut',
  },
  {
    code: 'kl-GL',
    value: 'kl',
    label: 'Kalaallisut (Greenland)',
    title: 'Kalaallisut',
  },
  {
    code: 'kn',
    value: 'kn',
    label: 'Kannada',
    title: 'Kannada',
  },
  {
    code: 'kn-IN',
    value: 'kn',
    label: 'Kannada (India)',
    title: 'Kannada',
  },
  {
    code: 'ks',
    value: 'ks',
    label: 'Kashmiri',
    title: 'Kashmiri',
  },
  {
    code: 'ks-Arab-IN',
    value: 'ks',
    label: 'Kashmiri (Arabic, India)',
    title: 'Kashmiri',
  },
  {
    code: 'ks-Arab',
    value: 'ks',
    label: 'Kashmiri (Arabic)',
    title: 'Kashmiri',
  },
  {
    code: 'ks-IN',
    value: 'ks',
    label: 'Kashmiri (India)',
    title: 'Kashmiri',
  },
  {
    code: 'kk',
    value: 'kk',
    label: 'Kazakh',
    title: 'Kazakh',
  },
  {
    code: 'kk-Cyrl-KZ',
    value: 'kk',
    label: 'Kazakh (Cyrillic, Kazakhstan)',
    title: 'Kazakh',
  },
  {
    code: 'kk-Cyrl',
    value: 'kk',
    label: 'Kazakh (Cyrillic)',
    title: 'Kazakh',
  },
  {
    code: 'kk-KZ',
    value: 'kk',
    label: 'Kazakh (Kazakhstan)',
    title: 'Kazakh',
  },
  {
    code: 'km',
    value: 'km',
    label: 'Khmer',
    title: 'Khmer',
  },
  {
    code: 'km-KH',
    value: 'km',
    label: 'Khmer (Cambodia)',
    title: 'Khmer',
  },
  {
    code: 'ki',
    value: 'ki',
    label: 'Kikuyu',
    title: 'Kikuyu',
  },
  {
    code: 'ki-KE',
    value: 'ki',
    label: 'Kikuyu (Kenya)',
    title: 'Kikuyu',
  },
  {
    code: 'rw',
    value: 'rw',
    label: 'Kinyarwanda',
    title: 'Kinyarwanda',
  },
  {
    code: 'rw-RW',
    value: 'rw',
    label: 'Kinyarwanda (Rwanda)',
    title: 'Kinyarwanda',
  },
  {
    code: 'ko',
    value: 'ko',
    label: 'Korean',
    title: 'Korean',
  },
  {
    code: 'ko-KP',
    value: 'ko',
    label: 'Korean (North Korea)',
    title: 'Korean',
  },
  {
    code: 'ko-KR',
    value: 'ko',
    label: 'Korean (South Korea)',
    title: 'Korean',
  },
  {
    code: 'ky',
    value: 'ky',
    label: 'Kyrgyz',
    title: 'Kyrgyz',
  },
  {
    code: 'ky-Cyrl-KG',
    value: 'ky',
    label: 'Kyrgyz (Cyrillic, Kyrgyzstan)',
    title: 'Kyrgyz',
  },
  {
    code: 'ky-Cyrl',
    value: 'ky',
    label: 'Kyrgyz (Cyrillic)',
    title: 'Kyrgyz',
  },
  {
    code: 'ky-KG',
    value: 'ky',
    label: 'Kyrgyz (Kyrgyzstan)',
    title: 'Kyrgyz',
  },
  {
    code: 'lo',
    value: 'lo',
    label: 'Lao',
    title: 'Lao',
  },
  {
    code: 'lo-LA',
    value: 'lo',
    label: 'Lao (Laos)',
    title: 'Lao',
  },
  {
    code: 'lv',
    value: 'lv',
    label: 'Latvian',
    title: 'Latvian',
  },
  {
    code: 'lv-LV',
    value: 'lv',
    label: 'Latvian (Latvia)',
    title: 'Latvian',
  },
  {
    code: 'ln',
    value: 'ln',
    label: 'Lingala',
    title: 'Lingala',
  },
  {
    code: 'ln-AO',
    value: 'ln',
    label: 'Lingala (Angola)',
    title: 'Lingala',
  },
  {
    code: 'ln-CF',
    value: 'ln',
    label: 'Lingala (Central African Republic)',
    title: 'Lingala',
  },
  {
    code: 'ln-CG',
    value: 'ln',
    label: 'Lingala (Congo - Brazzaville)',
    title: 'Lingala',
  },
  {
    code: 'ln-CD',
    value: 'ln',
    label: 'Lingala (Congo - Kinshasa)',
    title: 'Lingala',
  },
  {
    code: 'lt',
    value: 'lt',
    label: 'Lithuanian',
    title: 'Lithuanian',
  },
  {
    code: 'lt-LT',
    value: 'lt',
    label: 'Lithuanian (Lithuania)',
    title: 'Lithuanian',
  },
  {
    code: 'lu',
    value: 'lu',
    label: 'Luba-Katanga',
    title: 'Luba-Katanga',
  },
  {
    code: 'lu-CD',
    value: 'lu',
    label: 'Luba-Katanga (Congo - Kinshasa)',
    title: 'Luba-Katanga',
  },
  {
    code: 'lb',
    value: 'lb',
    label: 'Luxembourgish',
    title: 'Luxembourgish',
  },
  {
    code: 'lb-LU',
    value: 'lb',
    label: 'Luxembourgish (Luxembourg)',
    title: 'Luxembourgish',
  },
  {
    code: 'mk',
    value: 'mk',
    label: 'Macedonian',
    title: 'Macedonian',
  },
  {
    code: 'mk-MK',
    value: 'mk',
    label: 'Macedonian (Macedonia)',
    title: 'Macedonian',
  },
  {
    code: 'mg',
    value: 'mg',
    label: 'Malagasy',
    title: 'Malagasy',
  },
  {
    code: 'mg-MG',
    value: 'mg',
    label: 'Malagasy (Madagascar)',
    title: 'Malagasy',
  },
  {
    code: 'ms',
    value: 'ms',
    label: 'Malay',
    title: 'Malay',
  },
  {
    code: 'ms-BN',
    value: 'ms',
    label: 'Malay (Brunei)',
    title: 'Malay',
  },
  {
    code: 'ms-Latn-BN',
    value: 'ms',
    label: 'Malay (Latin, Brunei)',
    title: 'Malay',
  },
  {
    code: 'ms-Latn-MY',
    value: 'ms',
    label: 'Malay (Latin, Malaysia)',
    title: 'Malay',
  },
  {
    code: 'ms-Latn-SG',
    value: 'ms',
    label: 'Malay (Latin, Singapore)',
    title: 'Malay',
  },
  {
    code: 'ms-Latn',
    value: 'ms',
    label: 'Malay (Latin)',
    title: 'Malay',
  },
  {
    code: 'ms-MY',
    value: 'ms',
    label: 'Malay (Malaysia)',
    title: 'Malay',
  },
  {
    code: 'ms-SG',
    value: 'ms',
    label: 'Malay (Singapore)',
    title: 'Malay',
  },
  {
    code: 'ml',
    value: 'ml',
    label: 'Malayalam',
    title: 'Malayalam',
  },
  {
    code: 'ml-IN',
    value: 'ml',
    label: 'Malayalam (India)',
    title: 'Malayalam',
  },
  {
    code: 'mt',
    value: 'mt',
    label: 'Maltese',
    title: 'Maltese',
  },
  {
    code: 'mt-MT',
    value: 'mt',
    label: 'Maltese (Malta)',
    title: 'Maltese',
  },
  {
    code: 'gv',
    value: 'gv',
    label: 'Manx',
    title: 'Manx',
  },
  {
    code: 'gv-IM',
    value: 'gv',
    label: 'Manx (Isle of Man)',
    title: 'Manx',
  },
  {
    code: 'mr',
    value: 'mr',
    label: 'Marathi',
    title: 'Marathi',
  },
  {
    code: 'mr-IN',
    value: 'mr',
    label: 'Marathi (India)',
    title: 'Marathi',
  },
  {
    code: 'mn',
    value: 'mn',
    label: 'Mongolian',
    title: 'Mongolian',
  },
  {
    code: 'mn-Cyrl-MN',
    value: 'mn',
    label: 'Mongolian (Cyrillic, Mongolia)',
    title: 'Mongolian',
  },
  {
    code: 'mn-Cyrl',
    value: 'mn',
    label: 'Mongolian (Cyrillic)',
    title: 'Mongolian',
  },
  {
    code: 'mn-MN',
    value: 'mn',
    label: 'Mongolian (Mongolia)',
    title: 'Mongolian',
  },
  {
    code: 'ne',
    value: 'ne',
    label: 'Nepali',
    title: 'Nepali',
  },
  {
    code: 'ne-IN',
    value: 'ne',
    label: 'Nepali (India)',
    title: 'Nepali',
  },
  {
    code: 'ne-NP',
    value: 'ne',
    label: 'Nepali (Nepal)',
    title: 'Nepali',
  },
  {
    code: 'nd',
    value: 'nd',
    label: 'North Ndebele',
    title: 'North',
  },
  {
    code: 'nd-ZW',
    value: 'nd',
    label: 'North Ndebele (Zimbabwe)',
    title: 'North',
  },
  {
    code: 'se',
    value: 'se',
    label: 'Northern Sami',
    title: 'Northern',
  },
  {
    code: 'se-FI',
    value: 'se',
    label: 'Northern Sami (Finland)',
    title: 'Northern',
  },
  {
    code: 'se-NO',
    value: 'se',
    label: 'Northern Sami (Norway)',
    title: 'Northern',
  },
  {
    code: 'se-SE',
    value: 'se',
    label: 'Northern Sami (Sweden)',
    title: 'Northern',
  },
  {
    code: 'no',
    value: 'no',
    label: 'Norwegian',
    title: 'Norwegian',
  },
  {
    code: 'no-NO',
    value: 'no',
    label: 'Norwegian (Norway)',
    title: 'Norwegian',
  },
  {
    code: 'nb',
    value: 'nb',
    label: 'Norwegian Bokmål',
    title: 'Norwegian',
  },
  {
    code: 'nb-NO',
    value: 'nb',
    label: 'Norwegian Bokmål (Norway)',
    title: 'Norwegian',
  },
  {
    code: 'nb-SJ',
    value: 'nb',
    label: 'Norwegian Bokmål (Svalbard & Jan Mayen)',
    title: 'Norwegian',
  },
  {
    code: 'nn',
    value: 'nn',
    label: 'Norwegian Nynorsk',
    title: 'Norwegian',
  },
  {
    code: 'nn-NO',
    value: 'nn',
    label: 'Norwegian Nynorsk (Norway)',
    title: 'Norwegian',
  },
  {
    code: 'or',
    value: 'or',
    label: 'Oriya',
    title: 'Oriya',
  },
  {
    code: 'or-IN',
    value: 'or',
    label: 'Oriya (India)',
    title: 'Oriya',
  },
  {
    code: 'om',
    value: 'om',
    label: 'Oromo',
    title: 'Oromo',
  },
  {
    code: 'om-ET',
    value: 'om',
    label: 'Oromo (Ethiopia)',
    title: 'Oromo',
  },
  {
    code: 'om-KE',
    value: 'om',
    label: 'Oromo (Kenya)',
    title: 'Oromo',
  },
  {
    code: 'os',
    value: 'os',
    label: 'Ossetic',
    title: 'Ossetic',
  },
  {
    code: 'os-GE',
    value: 'os',
    label: 'Ossetic (Georgia)',
    title: 'Ossetic',
  },
  {
    code: 'os-RU',
    value: 'os',
    label: 'Ossetic (Russia)',
    title: 'Ossetic',
  },
  {
    code: 'ps',
    value: 'ps',
    label: 'Pashto',
    title: 'Pashto',
  },
  {
    code: 'ps-AF',
    value: 'ps',
    label: 'Pashto (Afghanistan)',
    title: 'Pashto',
  },
  {
    code: 'fa',
    value: 'fa',
    label: 'Persian',
    title: 'Persian',
  },
  {
    code: 'fa-AF',
    value: 'fa',
    label: 'Persian (Afghanistan)',
    title: 'Persian',
  },
  {
    code: 'fa-IR',
    value: 'fa',
    label: 'Persian (Iran)',
    title: 'Persian',
  },
  {
    code: 'pl',
    value: 'pl',
    label: 'Polish',
    title: 'Polish',
  },
  {
    code: 'pl-PL',
    value: 'pl',
    label: 'Polish (Poland)',
    title: 'Polish',
  },
  {
    code: 'pt',
    value: 'pt',
    label: 'Portuguese',
    title: 'Portuguese',
  },
  {
    code: 'pt-AO',
    value: 'pt',
    label: 'Portuguese (Angola)',
    title: 'Portuguese',
  },
  {
    code: 'pt-BR',
    value: 'pt',
    label: 'Portuguese (Brazil)',
    title: 'Portuguese',
  },
  {
    code: 'pt-CV',
    value: 'pt',
    label: 'Portuguese (Cape Verde)',
    title: 'Portuguese',
  },
  {
    code: 'pt-GW',
    value: 'pt',
    label: 'Portuguese (Guinea-Bissau)',
    title: 'Portuguese',
  },
  {
    code: 'pt-MO',
    value: 'pt',
    label: 'Portuguese (Macau SAR China)',
    title: 'Portuguese',
  },
  {
    code: 'pt-MZ',
    value: 'pt',
    label: 'Portuguese (Mozambique)',
    title: 'Portuguese',
  },
  {
    code: 'pt-PT',
    value: 'pt',
    label: 'Portuguese (Portugal)',
    title: 'Portuguese',
  },
  {
    code: 'pt-ST',
    value: 'pt',
    label: 'Portuguese (São Tomé & Príncipe)',
    title: 'Portuguese',
  },
  {
    code: 'pt-TL',
    value: 'pt',
    label: 'Portuguese (Timor-Leste)',
    title: 'Portuguese',
  },
  {
    code: 'pa',
    value: 'pa',
    label: 'Punjabi',
    title: 'Punjabi',
  },
  {
    code: 'pa-Arab-PK',
    value: 'pa',
    label: 'Punjabi (Arabic, Pakistan)',
    title: 'Punjabi',
  },
  {
    code: 'pa-Arab',
    value: 'pa',
    label: 'Punjabi (Arabic)',
    title: 'Punjabi',
  },
  {
    code: 'pa-Guru-IN',
    value: 'pa',
    label: 'Punjabi (Gurmukhi, India)',
    title: 'Punjabi',
  },
  {
    code: 'pa-Guru',
    value: 'pa',
    label: 'Punjabi (Gurmukhi)',
    title: 'Punjabi',
  },
  {
    code: 'pa-IN',
    value: 'pa',
    label: 'Punjabi (India)',
    title: 'Punjabi',
  },
  {
    code: 'pa-PK',
    value: 'pa',
    label: 'Punjabi (Pakistan)',
    title: 'Punjabi',
  },
  {
    code: 'qu',
    value: 'qu',
    label: 'Quechua',
    title: 'Quechua',
  },
  {
    code: 'qu-BO',
    value: 'qu',
    label: 'Quechua (Bolivia)',
    title: 'Quechua',
  },
  {
    code: 'qu-EC',
    value: 'qu',
    label: 'Quechua (Ecuador)',
    title: 'Quechua',
  },
  {
    code: 'qu-PE',
    value: 'qu',
    label: 'Quechua (Peru)',
    title: 'Quechua',
  },
  {
    code: 'ro',
    value: 'ro',
    label: 'Romanian',
    title: 'Romanian',
  },
  {
    code: 'ro-MD',
    value: 'ro',
    label: 'Romanian (Moldova)',
    title: 'Romanian',
  },
  {
    code: 'ro-RO',
    value: 'ro',
    label: 'Romanian (Romania)',
    title: 'Romanian',
  },
  {
    code: 'rm',
    value: 'rm',
    label: 'Romansh',
    title: 'Romansh',
  },
  {
    code: 'rm-CH',
    value: 'rm',
    label: 'Romansh (Switzerland)',
    title: 'Romansh',
  },
  {
    code: 'rn',
    value: 'rn',
    label: 'Rundi',
    title: 'Rundi',
  },
  {
    code: 'rn-BI',
    value: 'rn',
    label: 'Rundi (Burundi)',
    title: 'Rundi',
  },
  {
    code: 'ru',
    value: 'ru',
    label: 'Russian',
    title: 'Russian',
  },
  {
    code: 'ru-BY',
    value: 'ru',
    label: 'Russian (Belarus)',
    title: 'Russian',
  },
  {
    code: 'ru-KZ',
    value: 'ru',
    label: 'Russian (Kazakhstan)',
    title: 'Russian',
  },
  {
    code: 'ru-KG',
    value: 'ru',
    label: 'Russian (Kyrgyzstan)',
    title: 'Russian',
  },
  {
    code: 'ru-MD',
    value: 'ru',
    label: 'Russian (Moldova)',
    title: 'Russian',
  },
  {
    code: 'ru-RU',
    value: 'ru',
    label: 'Russian (Russia)',
    title: 'Russian',
  },
  {
    code: 'ru-UA',
    value: 'ru',
    label: 'Russian (Ukraine)',
    title: 'Russian',
  },
  {
    code: 'sg',
    value: 'sg',
    label: 'Sango',
    title: 'Sango',
  },
  {
    code: 'sg-CF',
    value: 'sg',
    label: 'Sango (Central African Republic)',
    title: 'Sango',
  },
  {
    code: 'gd',
    value: 'gd',
    label: 'Scottish Gaelic',
    title: 'Scottish',
  },
  {
    code: 'gd-GB',
    value: 'gd',
    label: 'Scottish Gaelic (United Kingdom)',
    title: 'Scottish',
  },
  {
    code: 'sr',
    value: 'sr',
    label: 'Serbian',
    title: 'Serbian',
  },
  {
    code: 'sr-BA',
    value: 'sr',
    label: 'Serbian (Bosnia & Herzegovina)',
    title: 'Serbian',
  },
  {
    code: 'sr-Cyrl-BA',
    value: 'sr',
    label: 'Serbian (Cyrillic, Bosnia & Herzegovina)',
    title: 'Serbian',
  },
  {
    code: 'sr-Cyrl-XK',
    value: 'sr',
    label: 'Serbian (Cyrillic, Kosovo)',
    title: 'Serbian',
  },
  {
    code: 'sr-Cyrl-ME',
    value: 'sr',
    label: 'Serbian (Cyrillic, Montenegro)',
    title: 'Serbian',
  },
  {
    code: 'sr-Cyrl-RS',
    value: 'sr',
    label: 'Serbian (Cyrillic, Serbia)',
    title: 'Serbian',
  },
  {
    code: 'sr-Cyrl',
    value: 'sr',
    label: 'Serbian (Cyrillic)',
    title: 'Serbian',
  },
  {
    code: 'sr-XK',
    value: 'sr',
    label: 'Serbian (Kosovo)',
    title: 'Serbian',
  },
  {
    code: 'sr-Latn-BA',
    value: 'sr',
    label: 'Serbian (Latin, Bosnia & Herzegovina)',
    title: 'Serbian',
  },
  {
    code: 'sr-Latn-XK',
    value: 'sr',
    label: 'Serbian (Latin, Kosovo)',
    title: 'Serbian',
  },
  {
    code: 'sr-Latn-ME',
    value: 'sr',
    label: 'Serbian (Latin, Montenegro)',
    title: 'Serbian',
  },
  {
    code: 'sr-Latn-RS',
    value: 'sr',
    label: 'Serbian (Latin, Serbia)',
    title: 'Serbian',
  },
  {
    code: 'sr-Latn',
    value: 'sr',
    label: 'Serbian (Latin)',
    title: 'Serbian',
  },
  {
    code: 'sr-ME',
    value: 'sr',
    label: 'Serbian (Montenegro)',
    title: 'Serbian',
  },
  {
    code: 'sr-RS',
    value: 'sr',
    label: 'Serbian (Serbia)',
    title: 'Serbian',
  },
  {
    code: 'sh',
    value: 'sh',
    label: 'Serbo-Croatian',
    title: 'Serbo-Croatian',
  },
  {
    code: 'sh-BA',
    value: 'sh',
    label: 'Serbo-Croatian (Bosnia & Herzegovina)',
    title: 'Serbo-Croatian',
  },
  {
    code: 'sn',
    value: 'sn',
    label: 'Shona',
    title: 'Shona',
  },
  {
    code: 'sn-ZW',
    value: 'sn',
    label: 'Shona (Zimbabwe)',
    title: 'Shona',
  },
  {
    code: 'ii',
    value: 'ii',
    label: 'Sichuan Yi',
    title: 'Sichuan',
  },
  {
    code: 'ii-CN',
    value: 'ii',
    label: 'Sichuan Yi (China)',
    title: 'Sichuan',
  },
  {
    code: 'si',
    value: 'si',
    label: 'Sinhala',
    title: 'Sinhala',
  },
  {
    code: 'si-LK',
    value: 'si',
    label: 'Sinhala (Sri Lanka)',
    title: 'Sinhala',
  },
  {
    code: 'sk',
    value: 'sk',
    label: 'Slovak',
    title: 'Slovak',
  },
  {
    code: 'sk-SK',
    value: 'sk',
    label: 'Slovak (Slovakia)',
    title: 'Slovak',
  },
  {
    code: 'sl',
    value: 'sl',
    label: 'Slovenian',
    title: 'Slovenian',
  },
  {
    code: 'sl-SI',
    value: 'sl',
    label: 'Slovenian (Slovenia)',
    title: 'Slovenian',
  },
  {
    code: 'so',
    value: 'so',
    label: 'Somali',
    title: 'Somali',
  },
  {
    code: 'so-DJ',
    value: 'so',
    label: 'Somali (Djibouti)',
    title: 'Somali',
  },
  {
    code: 'so-ET',
    value: 'so',
    label: 'Somali (Ethiopia)',
    title: 'Somali',
  },
  {
    code: 'so-KE',
    value: 'so',
    label: 'Somali (Kenya)',
    title: 'Somali',
  },
  {
    code: 'so-SO',
    value: 'so',
    label: 'Somali (Somalia)',
    title: 'Somali',
  },
  {
    code: 'es',
    value: 'es',
    label: 'Spanish',
    title: 'Spanish',
  },
  {
    code: 'es-AR',
    value: 'es',
    label: 'Spanish (Argentina)',
    title: 'Spanish',
  },
  {
    code: 'es-BO',
    value: 'es',
    label: 'Spanish (Bolivia)',
    title: 'Spanish',
  },
  {
    code: 'es-IC',
    value: 'es',
    label: 'Spanish (Canary Islands)',
    title: 'Spanish',
  },
  {
    code: 'es-EA',
    value: 'es',
    label: 'Spanish (Ceuta & Melilla)',
    title: 'Spanish',
  },
  {
    code: 'es-CL',
    value: 'es',
    label: 'Spanish (Chile)',
    title: 'Spanish',
  },
  {
    code: 'es-CO',
    value: 'es',
    label: 'Spanish (Colombia)',
    title: 'Spanish',
  },
  {
    code: 'es-CR',
    value: 'es',
    label: 'Spanish (Costa Rica)',
    title: 'Spanish',
  },
  {
    code: 'es-CU',
    value: 'es',
    label: 'Spanish (Cuba)',
    title: 'Spanish',
  },
  {
    code: 'es-DO',
    value: 'es',
    label: 'Spanish (Dominican Republic)',
    title: 'Spanish',
  },
  {
    code: 'es-EC',
    value: 'es',
    label: 'Spanish (Ecuador)',
    title: 'Spanish',
  },
  {
    code: 'es-SV',
    value: 'es',
    label: 'Spanish (El Salvador)',
    title: 'Spanish',
  },
  {
    code: 'es-GQ',
    value: 'es',
    label: 'Spanish (Equatorial Guinea)',
    title: 'Spanish',
  },
  {
    code: 'es-GT',
    value: 'es',
    label: 'Spanish (Guatemala)',
    title: 'Spanish',
  },
  {
    code: 'es-HN',
    value: 'es',
    label: 'Spanish (Honduras)',
    title: 'Spanish',
  },
  {
    code: 'es-MX',
    value: 'es',
    label: 'Spanish (Mexico)',
    title: 'Spanish',
  },
  {
    code: 'es-NI',
    value: 'es',
    label: 'Spanish (Nicaragua)',
    title: 'Spanish',
  },
  {
    code: 'es-PA',
    value: 'es',
    label: 'Spanish (Panama)',
    title: 'Spanish',
  },
  {
    code: 'es-PY',
    value: 'es',
    label: 'Spanish (Paraguay)',
    title: 'Spanish',
  },
  {
    code: 'es-PE',
    value: 'es',
    label: 'Spanish (Peru)',
    title: 'Spanish',
  },
  {
    code: 'es-PH',
    value: 'es',
    label: 'Spanish (Philippines)',
    title: 'Spanish',
  },
  {
    code: 'es-PR',
    value: 'es',
    label: 'Spanish (Puerto Rico)',
    title: 'Spanish',
  },
  {
    code: 'es-ES',
    value: 'es',
    label: 'Spanish (Spain)',
    title: 'Spanish',
  },
  {
    code: 'es-US',
    value: 'es',
    label: 'Spanish (United States)',
    title: 'Spanish',
  },
  {
    code: 'es-UY',
    value: 'es',
    label: 'Spanish (Uruguay)',
    title: 'Spanish',
  },
  {
    code: 'es-VE',
    value: 'es',
    label: 'Spanish (Venezuela)',
    title: 'Spanish',
  },
  {
    code: 'sw',
    value: 'sw',
    label: 'Swahili',
    title: 'Swahili',
  },
  {
    code: 'sw-KE',
    value: 'sw',
    label: 'Swahili (Kenya)',
    title: 'Swahili',
  },
  {
    code: 'sw-TZ',
    value: 'sw',
    label: 'Swahili (Tanzania)',
    title: 'Swahili',
  },
  {
    code: 'sw-UG',
    value: 'sw',
    label: 'Swahili (Uganda)',
    title: 'Swahili',
  },
  {
    code: 'sv',
    value: 'sv',
    label: 'Swedish',
    title: 'Swedish',
  },
  {
    code: 'sv-AX',
    value: 'sv',
    label: 'Swedish (Åland Islands)',
    title: 'Swedish',
  },
  {
    code: 'sv-FI',
    value: 'sv',
    label: 'Swedish (Finland)',
    title: 'Swedish',
  },
  {
    code: 'sv-SE',
    value: 'sv',
    label: 'Swedish (Sweden)',
    title: 'Swedish',
  },
  {
    code: 'tl',
    value: 'tl',
    label: 'Tagalog',
    title: 'Tagalog',
  },
  {
    code: 'tl-PH',
    value: 'tl',
    label: 'Tagalog (Philippines)',
    title: 'Tagalog',
  },
  {
    code: 'ta',
    value: 'ta',
    label: 'Tamil',
    title: 'Tamil',
  },
  {
    code: 'ta-IN',
    value: 'ta',
    label: 'Tamil (India)',
    title: 'Tamil',
  },
  {
    code: 'ta-MY',
    value: 'ta',
    label: 'Tamil (Malaysia)',
    title: 'Tamil',
  },
  {
    code: 'ta-SG',
    value: 'ta',
    label: 'Tamil (Singapore)',
    title: 'Tamil',
  },
  {
    code: 'ta-LK',
    value: 'ta',
    label: 'Tamil (Sri Lanka)',
    title: 'Tamil',
  },
  {
    code: 'te',
    value: 'te',
    label: 'Telugu',
    title: 'Telugu',
  },
  {
    code: 'te-IN',
    value: 'te',
    label: 'Telugu (India)',
    title: 'Telugu',
  },
  {
    code: 'th',
    value: 'th',
    label: 'Thai',
    title: 'Thai',
  },
  {
    code: 'th-TH',
    value: 'th',
    label: 'Thai (Thailand)',
    title: 'Thai',
  },
  {
    code: 'bo',
    value: 'bo',
    label: 'Tibetan',
    title: 'Tibetan',
  },
  {
    code: 'bo-CN',
    value: 'bo',
    label: 'Tibetan (China)',
    title: 'Tibetan',
  },
  {
    code: 'bo-IN',
    value: 'bo',
    label: 'Tibetan (India)',
    title: 'Tibetan',
  },
  {
    code: 'ti',
    value: 'ti',
    label: 'Tigrinya',
    title: 'Tigrinya',
  },
  {
    code: 'ti-ER',
    value: 'ti',
    label: 'Tigrinya (Eritrea)',
    title: 'Tigrinya',
  },
  {
    code: 'ti-ET',
    value: 'ti',
    label: 'Tigrinya (Ethiopia)',
    title: 'Tigrinya',
  },
  {
    code: 'to',
    value: 'to',
    label: 'Tongan',
    title: 'Tongan',
  },
  {
    code: 'to-TO',
    value: 'to',
    label: 'Tongan (Tonga)',
    title: 'Tongan',
  },
  {
    code: 'tr',
    value: 'tr',
    label: 'Turkish',
    title: 'Turkish',
  },
  {
    code: 'tr-CY',
    value: 'tr',
    label: 'Turkish (Cyprus)',
    title: 'Turkish',
  },
  {
    code: 'tr-TR',
    value: 'tr',
    label: 'Turkish (Turkey)',
    title: 'Turkish',
  },
  {
    code: 'uk',
    value: 'uk',
    label: 'Ukrainian',
    title: 'Ukrainian',
  },
  {
    code: 'uk-UA',
    value: 'uk',
    label: 'Ukrainian (Ukraine)',
    title: 'Ukrainian',
  },
  {
    code: 'ur',
    value: 'ur',
    label: 'Urdu',
    title: 'Urdu',
  },
  {
    code: 'ur-IN',
    value: 'ur',
    label: 'Urdu (India)',
    title: 'Urdu',
  },
  {
    code: 'ur-PK',
    value: 'ur',
    label: 'Urdu (Pakistan)',
    title: 'Urdu',
  },
  {
    code: 'ug',
    value: 'ug',
    label: 'Uyghur',
    title: 'Uyghur',
  },
  {
    code: 'ug-Arab-CN',
    value: 'ug',
    label: 'Uyghur (Arabic, China)',
    title: 'Uyghur',
  },
  {
    code: 'ug-Arab',
    value: 'ug',
    label: 'Uyghur (Arabic)',
    title: 'Uyghur',
  },
  {
    code: 'ug-CN',
    value: 'ug',
    label: 'Uyghur (China)',
    title: 'Uyghur',
  },
  {
    code: 'uz',
    value: 'uz',
    label: 'Uzbek',
    title: 'Uzbek',
  },
  {
    code: 'uz-AF',
    value: 'uz',
    label: 'Uzbek (Afghanistan)',
    title: 'Uzbek',
  },
  {
    code: 'uz-Arab-AF',
    value: 'uz',
    label: 'Uzbek (Arabic, Afghanistan)',
    title: 'Uzbek',
  },
  {
    code: 'uz-Arab',
    value: 'uz',
    label: 'Uzbek (Arabic)',
    title: 'Uzbek',
  },
  {
    code: 'uz-Cyrl-UZ',
    value: 'uz',
    label: 'Uzbek (Cyrillic, Uzbekistan)',
    title: 'Uzbek',
  },
  {
    code: 'uz-Cyrl',
    value: 'uz',
    label: 'Uzbek (Cyrillic)',
    title: 'Uzbek',
  },
  {
    code: 'uz-Latn-UZ',
    value: 'uz',
    label: 'Uzbek (Latin, Uzbekistan)',
    title: 'Uzbek',
  },
  {
    code: 'uz-Latn',
    value: 'uz',
    label: 'Uzbek (Latin)',
    title: 'Uzbek',
  },
  {
    code: 'uz-UZ',
    value: 'uz',
    label: 'Uzbek (Uzbekistan)',
    title: 'Uzbek',
  },
  {
    code: 'vi',
    value: 'vi',
    label: 'Vietnamese',
    title: 'Vietnamese',
  },
  {
    code: 'vi-VN',
    value: 'vi',
    label: 'Vietnamese (Vietnam)',
    title: 'Vietnamese',
  },
  {
    code: 'cy',
    value: 'cy',
    label: 'Welsh',
    title: 'Welsh',
  },
  {
    code: 'cy-GB',
    value: 'cy',
    label: 'Welsh (United Kingdom)',
    title: 'Welsh',
  },
  {
    code: 'fy',
    value: 'fy',
    label: 'Western Frisian',
    title: 'Western',
  },
  {
    code: 'fy-NL',
    value: 'fy',
    label: 'Western Frisian (Netherlands)',
    title: 'Western',
  },
  {
    code: 'yi',
    value: 'yi',
    label: 'Yiddish',
    title: 'Yiddish',
  },
  {
    code: 'yo',
    value: 'yo',
    label: 'Yoruba',
    title: 'Yoruba',
  },
  {
    code: 'yo-BJ',
    value: 'yo',
    label: 'Yoruba (Benin)',
    title: 'Yoruba',
  },
  {
    code: 'yo-NG',
    value: 'yo',
    label: 'Yoruba (Nigeria)',
    title: 'Yoruba',
  },
  {
    code: 'zu',
    value: 'zu',
    label: 'Zulu',
    title: 'Zulu',
  },
  {
    code: 'zu-ZA',
    value: 'zu',
    label: 'Zulu (South Africa)',
    title: 'Zulu',
  },
];
