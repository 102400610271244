export type CalcTypeClosure<T, C> = (context: C) => T;
export type CalcType<T, C = any> = T | (CalcTypeClosure<T, C>);

export const parseCalcType = <T, V = any>(value: CalcType<T, V>, context?: V): T => {
  if (typeof value === 'function') {
    return (value as CalcTypeClosure<T, V>)(context);
  }

  return value;
};

export type AddCalculatedValues<Type, ContextType, SkipFields extends keyof Type = never> = ({
  [Field in SkipFields]: Type[Field]
} & {
  [Field in keyof Omit<Type, SkipFields>]: CalcType<Type[Field], ContextType>
});
