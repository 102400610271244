import { SecurityUserMeView } from '../../../../types/App/View/Security';
import { apiEndpoint, NoBody, NoParams, StandardErrors } from '../../../../client';
import { UserMeView } from '../../../../types/App/View/User';
import { FinishRegisterUserRequestBody, UpdateLocaleRequestBody, ChangePasswordRequestBody, UpdateSettingsRequestBody, UpdateUserRequestBody } from '../../../../types/App/Request/UserMe';

export const show = apiEndpoint<SecurityUserMeView, NoParams, NoBody, StandardErrors>('GET')('/api/user/me');

export const getInvite = apiEndpoint<UserMeView, NoParams, NoBody, StandardErrors>('GET')('/api/user/me/invite/{code}');

export const finishInvite = apiEndpoint<UserMeView, NoParams, FinishRegisterUserRequestBody, StandardErrors>('PUT')('/api/user/me/invite/{code}');

export const updateLocale = apiEndpoint<UserMeView, NoParams, UpdateLocaleRequestBody, StandardErrors>('PUT')('/api/user/me/locale');

export const updatePassword = apiEndpoint<UserMeView, NoParams, ChangePasswordRequestBody, StandardErrors>('PUT')('/api/user/me/password');

export const updateSettings = apiEndpoint<UserMeView, NoParams, UpdateSettingsRequestBody, StandardErrors>('PUT')('/api/user/me/settings');

export const update = apiEndpoint<UserMeView, NoParams, UpdateUserRequestBody, StandardErrors>('PUT')('/api/user/me');
