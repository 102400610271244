import {
  ArrayLocale,
  BooleanLocale,
  DateLocale,
  MixedLocale,
  NumberLocale,
  ObjectLocale,
  StringLocale,
} from 'yup/lib/locale';
import i18n from 'i18next';
import printValue from 'yup/lib/util/printValue';
import { setLocale } from 'yup';

interface YupLocaleObject {
  mixed: Required<MixedLocale>
  string: Required<StringLocale>,
  number: Required<NumberLocale>,
  date: Required<DateLocale>,
  object: Required<ObjectLocale>,
  array: Required<ArrayLocale>,
  boolean: Required<BooleanLocale>,
}

const yupLocale: YupLocaleObject = {
  mixed: {
    default: (path) => i18n.t('{{path}} is invalid', path),
    required: (path) => i18n.t('{{path}} is a required field', path),
    oneOf: (path) => i18n.t('{{path}} must be one of the following values: {{values}}', path),
    notOneOf: (path) => i18n.t('{{path}} must not be one of the following values: {{values}}', path),
    defined: (path) => i18n.t('{{path}} must be defined', path),
    notType: (path) => {
      const isCast = path.originalValue != null && path.originalValue !== path.value;
      let msg = i18n.t('{{path}} must be a `{{type}}` type, ', path)
        + i18n.t('but the final value was: `{{value}}`', { value: printValue(path.value, true) })
        + (isCast
          ? i18n.t(' (cast from the value `{{value`).', { value: printValue(path.originalValue, true) })
          : '.');

      if (path.value === null) {
        msg += `\n${i18n.t(' If "null" is intended as an empty value be sure to mark the schema as `.nullable()`')}`;
      }

      return msg;
    },
  },
  string: {
    length: (path) => i18n.t('{{path}} must be exactly {{length}} characters', path),
    min: (path) => i18n.t('{{path}} must be at least {{min}} characters', path),
    max: (path) => i18n.t('{{path}} must be at most {{max}} characters', path),
    matches: (path) => i18n.t('{{path}} must match the following: "{{regex}}"', path),
    email: (path) => i18n.t('{{path}} must be a valid email', path),
    url: (path) => i18n.t('{{path}} must be a valid URL', path),
    uuid: (path) => i18n.t('{{path}} must be a valid UUID', path),
    trim: (path) => i18n.t('{{path}} must be a trimmed string', path),
    lowercase: (path) => i18n.t('{{path}} must be a lowercase string', path),
    uppercase: (path) => i18n.t('{{path}} must be a upper case string', path),
  },
  number: {
    min: (path) => i18n.t('{{path}} must be greater than or equal to {{min}}', path),
    max: (path) => i18n.t('{{path}} must be less than or equal to {{max}}', path),
    lessThan: (path) => i18n.t('{{path}} must be less than {{less}}', path),
    moreThan: (path) => i18n.t('{{path}} must be greater than {{more}}', path),
    positive: (path) => i18n.t('{{path}} must be a positive number', path),
    negative: (path) => i18n.t('{{path}} must be a negative number', path),
    integer: (path) => i18n.t('{{path}} must be an integer', path),
  },
  date: {
    min: (path) => i18n.t('{{path}} field must be later than {{min}}', path),
    max: (path) => i18n.t('{{path}} field must be at earlier than {{max}}', path),
  },
  object: {
    noUnknown: (path) => i18n.t('{{path}} field has unspecified keys: {{unknown}}', path),
  },
  array: {
    min: (path) => i18n.t('{{path}} field must have at least {{min}} items', path),
    max: (path) => i18n.t('{{path}} field must have less than or equal to {{max}} items', path),
    length: (path) => i18n.t('{{path}} must have {{length}} items', path),
  },
  boolean: {
    isValue: (path) => i18n.t('{{path}} field must be {{value}}', path),
  },
};

setLocale(yupLocale);
