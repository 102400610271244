import type { GTMConfig } from './lib/gtm';
import { isProd } from './utils/isProd';

export const siteConfig = {
  titleSuffix: ' | Baller',
  clickOutBaseUrl: process.env.REACT_APP_TRACKER_URL,
};

export const userLoginConfig = {
  email: isProd() ? '' : process.env.REACT_APP_LOGIN_EMAIL,
  password: isProd() ? '' : process.env.REACT_APP_LOGIN_PASSWORD,
};

export const gtmConfig: GTMConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID,
};
