import { ListClientsRequestParams, CreateClientRequestBody } from '../../../types/App/Request/Client';
import { ListClientView, FullClientView } from '../../../types/App/View/Client';
import { apiEndpoint, NoBody, StandardErrors, NoParams } from '../../../client';
import { ListSelectorView } from '../../../types/App/View/Common';
import { BaseView } from '../../../types/App/View';

export const list = apiEndpoint<ListClientView, ListClientsRequestParams, NoBody, StandardErrors>('GET')('/api/clients');

export const clientSelector = apiEndpoint<ListSelectorView, NoParams, NoBody, StandardErrors>('GET')('/api/clients/selector');

export const show = apiEndpoint<FullClientView, NoParams, NoBody, StandardErrors>('GET')('/api/clients/{id}');

export const create = apiEndpoint<FullClientView, NoParams, CreateClientRequestBody, StandardErrors>('POST')('/api/clients');

export const update = apiEndpoint<FullClientView, NoParams, CreateClientRequestBody, StandardErrors>('PUT')('/api/clients/{id}');

export const remove = apiEndpoint<BaseView, NoParams, NoBody, StandardErrors>('DELETE')('/api/clients/{id}');
