import React from 'react';
import { siteConfig } from '../config';
import { useTranslation } from 'react-i18next';
import { ExtractTranslationParams } from '../types/i18n';

export type PageTitleRenderer = <T extends string>(title: T, params?: ExtractTranslationParams<T>) => React.ReactNode;

// export interface ResourcePage<R extends ResourceInterface, Route extends string> {
//   route: Route,
//   absoluteRoute?: boolean,
//   requiredApiResources?: [],
// }

export interface ResourceInterface {
  titles?: string[],
  pageTitleRenderer?: PageTitleRenderer
}

export const defaultPageTitleRenderer: PageTitleRenderer = <T extends string,>(title: T, params?: ExtractTranslationParams<T>) => {
  const { t } = useTranslation();

  return `${t(title, params)}${siteConfig.titleSuffix}`;
};

export const setResourceDefaults = <T extends ResourceInterface, >(resource: T): T => {
  resource.titles = resource.titles ?? [];
  resource.pageTitleRenderer = resource.pageTitleRenderer ?? defaultPageTitleRenderer;

  return resource;
};
