import { ListTenantUsersRequestParams, TenantUserSelectorRequestParams, AddUserRequestBody, CreateUserRequestBody, InviteUserRequestBody, UpdateTenantUserRequestBody } from '../../../../types/App/Request/TenantUser';
import { ListTenantUserView, FullTenantUserView } from '../../../../types/App/View/TenantUser';
import { apiEndpoint, NoBody, StandardErrors, NoParams } from '../../../../client';
import { ListSelectorView } from '../../../../types/App/View/Common';
import { SuccessView } from '../../../../types/App/View';
import { ListCampaignsRequestParams } from '../../../../types/App/Request/Campaign';
import { ListCampaignView } from '../../../../types/App/View/Campaign';
import { FindUserByEmailRequestBody } from '../../../../types/App/Request/User';
import { FindUserView } from '../../../../types/App/View/User';

export const list = apiEndpoint<ListTenantUserView, ListTenantUsersRequestParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/users');

export const tenantUserSelector = apiEndpoint<ListSelectorView, TenantUserSelectorRequestParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/users/selector');

export const add = apiEndpoint<FullTenantUserView, NoParams, AddUserRequestBody, StandardErrors>('PUT')('/api/tenants/{tenantId}/users/add');

export const remove = apiEndpoint<SuccessView, NoParams, NoBody, StandardErrors>('DELETE')('/api/tenants/{tenantId}/users/{id}');

export const show = apiEndpoint<FullTenantUserView, NoParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/users/{id}');

export const campaigns = apiEndpoint<ListCampaignView, ListCampaignsRequestParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/users/{id}/campaigns');

export const find = apiEndpoint<FindUserView, NoParams, FindUserByEmailRequestBody, StandardErrors>('POST')('/api/tenants/{tenantId}/users/find');

export const create = apiEndpoint<FullTenantUserView, NoParams, CreateUserRequestBody, StandardErrors>('POST')('/api/tenants/{tenantId}/users');

export const invite = apiEndpoint<FullTenantUserView, NoParams, InviteUserRequestBody, StandardErrors>('POST')('/api/tenants/{tenantId}/users/invite');

export const update = apiEndpoint<FullTenantUserView, NoParams, UpdateTenantUserRequestBody, StandardErrors>('PUT')('/api/tenants/{tenantId}/users/{id}');
