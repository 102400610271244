import { ListCampaignsRequestParams, ShowCampaignRequestParams, ListCampaignParticipantsRequestParams, CreateCampaignRequestBody, UpdateCampaignRequestBody, ListCampaignConversionsRequestParams, CampaignAnalyticsRequestParams } from '../../../../types/App/Request/Campaign';
import { ListCampaignView, FullCampaignView, CampaignLinksStatsView, CampaignTriggersStatsView, CampaignParticipantStatsView, ListCampaignConversionView, CampaignAnalyticsView } from '../../../../types/App/View/Campaign';
import { apiEndpoint, NoBody, StandardErrors, NoParams } from '../../../../client';
import { ListSelectorView } from '../../../../types/App/View/Common';
import { SuccessView } from '../../../../types/App/View';
import { ListCampaignParticipantsView } from '../../../../types/App/View/CampaignParticipant';

export const list = apiEndpoint<ListCampaignView, ListCampaignsRequestParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/campaigns');

export const campaignSelector = apiEndpoint<ListSelectorView, NoParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/campaigns/selector');

export const approve = apiEndpoint<FullCampaignView, NoParams, NoBody, StandardErrors>('POST')('/api/tenants/{tenantId}/campaigns/{id}/approve');

export const show = apiEndpoint<FullCampaignView, ShowCampaignRequestParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/campaigns/{id}');

export const finish = apiEndpoint<FullCampaignView, NoParams, NoBody, StandardErrors>('PUT')('/api/tenants/{tenantId}/campaigns/{id}/finish');

export const remove = apiEndpoint<SuccessView, NoParams, NoBody, StandardErrors>('DELETE')('/api/tenants/{tenantId}/campaigns/{id}');

export const removeImage = apiEndpoint<SuccessView, NoParams, NoBody, StandardErrors>('DELETE')('/api/tenants/{tenantId}/campaigns/{campaignId}/image/{id}');

export const participantsSelectors = apiEndpoint<ListSelectorView, NoParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/campaigns/{id}/participants/selector');

export const userGroupsSelectors = apiEndpoint<ListSelectorView, NoParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/campaigns/{id}/userGroups/selector');

export const listParticipants = apiEndpoint<ListCampaignParticipantsView, ListCampaignParticipantsRequestParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/campaigns/{id}/participants');

export const create = apiEndpoint<FullCampaignView, NoParams, CreateCampaignRequestBody, StandardErrors>('POST')('/api/tenants/{tenantId}/campaigns');

export const update = apiEndpoint<FullCampaignView, NoParams, UpdateCampaignRequestBody, StandardErrors>('PUT')('/api/tenants/{tenantId}/campaigns/{id}');

export const linkStats = apiEndpoint<CampaignLinksStatsView, NoParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/campaigns/{id}/links/stats');

export const triggerStats = apiEndpoint<CampaignTriggersStatsView, NoParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/campaigns/{id}/triggers/stats');

export const participantsStats = apiEndpoint<CampaignParticipantStatsView, NoParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/campaigns/{id}/participants/stats');

export const listConversions = apiEndpoint<ListCampaignConversionView, ListCampaignConversionsRequestParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/campaigns/{id}/conversions');

export const analytics = apiEndpoint<CampaignAnalyticsView, CampaignAnalyticsRequestParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/campaigns/{id}/analytics');
