import React, { ComponentProps, ComponentType, LazyExoticComponent, Suspense } from 'react';
import LoadingScreen from './LoadingScreen';

const Loadable = <T extends ComponentType<any>, >(Component: LazyExoticComponent<T>) => (props: ComponentProps<T>) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

export default Loadable;
