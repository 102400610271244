import React from 'react';
import { Tab, TabProps, Tabs, TabsProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { calcProps, calcRenderNode, ModifyProps } from '../../utils/propHelpers';
import { MapValue } from '../../types/MapValues';

export interface DataGridTab extends MapValue {
  hidden?: () => boolean,
  disabled?: () => boolean,
  renderTab?: (props: TabProps) => React.ReactElement;
  props?: ModifyProps<TabProps>,
}

export type DataGridTabs = DataGridTab[];

export interface DataGridTabToolbar {
  tabs: DataGridTabs,
  current: string,
  onChange?: (tab: DataGridTab) => void,
  props?: ModifyProps<TabsProps>,
  translateLabel?: boolean,
  hidden?: boolean,
}

interface DataGridTabsToolbarProps {
  children: DataGridTabToolbar
}

const DataGridTabsToolbar = (props: DataGridTabsToolbarProps) => {
  const { children: config } = props;
  const { t } = useTranslation();

  config.translateLabel ??= true;

  const [currentTab, setCurrentTab] = React.useState<string>(config.current);
  const tabNodes: React.ReactNode[] = config.tabs.map((tabConfig): React.ReactNode => {
    if (tabConfig.hidden !== undefined && tabConfig.hidden()) {
      if (tabConfig.hidden() === true) {
        return null;
      }
    }

    let tabProps: TabProps = {
      key: tabConfig.value,
      label: config.translateLabel === true ? t(tabConfig.label) : (tabConfig.label ?? tabConfig.value),
      value: tabConfig.value,
      disabled: tabConfig.disabled !== undefined && tabConfig.disabled(),
    };

    tabProps = calcProps<TabProps>(tabProps, tabConfig.props);

    return calcRenderNode<TabProps>(Tab, tabConfig.renderTab, tabProps);
  });

  const handleTabChange = (event: React.ChangeEvent<{}>, value: string): void => {
    setCurrentTab(value);

    if (typeof config.onChange === 'function') {
      const foundTab = config.tabs.find((tab) => tab.value === value);
      if (!foundTab) {
        throw new Error(`Couldn't find tab with value "${value}"`);
      }

      config.onChange((foundTab));
    }
  };

  let tabsProps: TabsProps = {
    indicatorColor: 'primary',
    scrollButtons: 'auto',
    textColor: 'primary',
    variant: 'scrollable',
    value: currentTab,
    onChange: handleTabChange,
  };

  tabsProps = calcProps<TabsProps>(tabsProps, config.props);

  return (
    <Tabs {...tabsProps}>
      {tabNodes}
    </Tabs>
  );
};

export default DataGridTabsToolbar;
