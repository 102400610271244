import React from 'react';
import { Save } from '@material-ui/icons';
import importTranslation from '../hooks/ut';
import { useFormikContext } from 'formik';
import { CircularProgress, CircularProgressProps, IconButton, Tooltip } from '@material-ui/core';

export interface FormIconButtonProps extends React.ComponentPropsWithoutRef<typeof IconButton> {
  icon?: React.ReactNode,
  label?: string,
  actionDisabled?: boolean,
  isSubmitting?: boolean,
}

const FormIconButton = (props: FormIconButtonProps) => {
  const { t } = importTranslation();
  const { isSubmitting: formikIsSubmitting } = useFormikContext();

  const {
    icon,
    label = 'Save',
    children = icon ?? <Save fontSize="small" />,
    color = 'primary',
    type = 'submit',
    sx = { mx: 1 },
    actionDisabled = false,
    isSubmitting = false,
    disabled: disabledProp = false,
    ...other
  } = props;

  const submitting = isSubmitting || formikIsSubmitting;
  const disabled = submitting || disabledProp;

  const btn = (
    <IconButton
      color={color}
      disabled={disabled}
      type={type}
      sx={sx}
      onClick={(e) => (actionDisabled === true ? e.preventDefault() : true)}
      {...other}
    >
      {children}
    </IconButton>
  );

  const spinner = (
    <CircularProgress size={20} color={color as CircularProgressProps['color']} />
  );

  return (
    <Tooltip title={t(label)}>
      <div>{submitting ? spinner : btn}</div>
    </Tooltip>
  );
};

export default FormIconButton;
