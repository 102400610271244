import { getMapValue, MapValue, mapValuesList } from '../types/MapValues';
import {
  AssignmentTurnedIn,
  Autorenew,
  Block,
  Clear,
  DeleteForever,
  Drafts,
  ErrorOutline,
  FindInPage,
  HourglassDisabled,
  NotListedLocation,
  Schedule,
} from '@material-ui/icons';
import React from 'react';
import { DataGridColumns } from '../components/DataGrid/Column';
import {
  BaseTenantContractView,
  FullTenantContractView,
  ShortTenantContractView,
  TenantContractChargeView,
  TenantContractFinishConditionView,
} from '../api/types/App/View/TenantContract';
import {
  Box,
  Checkbox,
  InputAdornment,
  Link,
  Typography,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import i18n from 'i18next';
import {
  formatDate,
  timestampFormat,
  timestampFormatDistanceToNowStrict,
} from '../utils/timestampUtils';
import { StatusLabel } from '../components/Label';
import { fromUnixTime, getUnixTime, isFuture, isPast } from 'date-fns';
import UserAvatar from '../components/UserAvatar';
import { useTranslation } from 'react-i18next';
import { RequestBodyFormMap } from '../types/FormTypes';
import {
  CreateTenantContractRequestBody,
  FindContractRequestBody,
} from '../api/types/App/Request/TenantContract';
import { Yup } from '../lib/Yup';
import { ListSelectorView } from '../api/types/App/View/Common';
import {
  FormLayoutCard,
  FormLayoutGroup,
  WrappedContext,
} from '../types/FormLayoutType';
import {
  ChargeTypeRequestBody,
  FinishConditionRequestBody,
} from '../api/types/App/Request/TenantContract/CreateContract';
import { getIn } from 'formik';
import {
  CurrencyList,
  formatMoneyValue,
  MoneyValueValidationSchema,
} from '../types/Money';
import { BaseTenantView } from '../api/types/App/View/Tenant';
import { ActionList, ActionTypes } from '../types/ActionType';
import PencilAltIcon from '../icons/PencilAlt';
import { UseDialogProps } from '../hooks/useDialog';
import simpleApiCall from '../hooks/simpleApiCall';
import { history } from '../redux';
import { smartListBuilder } from '../utils/smartList';
import { Dashboard, DashboardType } from '../redux/slices/User';
import { acceptContract, remove } from '../api/endpoints/Api/Tenants/Contracts';
import LinearProgressWithLabel from '../components/LinearProgressWithLabel';

export const TenantContractStatusMap = mapValuesList([
  {
    label: 'Waiting',
    value: 'waiting',
    color: 'info',
    icon: <Drafts fontSize="small" />,
  },
  {
    label: 'Upcoming',
    value: 'upcoming',
    color: 'primary',
    icon: <Schedule fontSize="small" />,
  },
  {
    label: 'In Progress',
    value: 'in_progress',
    color: 'success',
    icon: <Autorenew fontSize="small" />,
  },
  {
    label: 'Finished',
    value: 'finished',
    color: 'secondary',
    icon: <AssignmentTurnedIn fontSize="small" />,
  },
  {
    label: 'Disabled',
    value: 'disabled',
    color: 'error',
    icon: <Clear fontSize="small" />,
  },
  {
    label: 'Limited',
    value: 'limited',
    color: 'warning',
    icon: <HourglassDisabled fontSize="small" />,
  },
  {
    label: 'Unknown',
    value: 'unknown',
    color: 'error',
    icon: <NotListedLocation fontSize="small" />,
  },
]);

export const getContractStatus = (
  contract: BaseTenantContractView
): MapValue => {
  let status = 'unknown';
  if (contract.status === 'waiting') {
    status = 'waiting';
  } else if (contract.status === 'finished') {
    status = 'finished';
  } else if (
    contract.status === 'active' &&
    isFuture(fromUnixTime(contract.start_at))
  ) {
    status = 'upcoming';
  } else if (
    contract.status === 'active' &&
    isPast(fromUnixTime(contract.start_at))
  ) {
    status = 'in_progress';
  } else {
    status = contract.status;
  }

  return TenantContractStatusMap.get(status);
};

export const getContractStatusText = (
  contact: BaseTenantContractView
): MapValue => {
  const status = getContractStatus(contact);

  let text = i18n.t('Unknown');
  if (status.value === 'waiting') {
    text = i18n.t('Waiting for client to accept');
  }

  if (status.value === 'disabled') {
    text = i18n.t('Disabled');
  }

  if (status.value === 'upcoming') {
    text = i18n.t('Starts in {{time}}', {
      time: timestampFormatDistanceToNowStrict(contact.start_at),
    });
  }

  if (status.value === 'in_progress') {
    text = i18n.t('Active');
  }

  if (status.value === 'limited') {
    text = i18n.t('Contract is limited. There are still some active campaigns');
  }

  if (status.value === 'finished') {
    text = i18n.t('Finished');
  }

  return {
    ...status,
    label: text,
  };
};

interface MapValueWithDescription extends MapValue {
  description: string;
  valueDescription: string;
  valueLabel: string;
}

const mapValueWithDescriptionList =
  smartListBuilder<MapValueWithDescription>().setField('value');

export const TenantContractChargeTypeMap = mapValueWithDescriptionList([
  {
    label: '% of Conversion',
    valueLabel: '{{value}}% of Conversion',
    value: 'conversion_percentage',
    icon: <Drafts fontSize="small" />,
    color: 'primary',
    description: '% of Conversion',
    valueDescription: '%',
  },
  {
    label: 'Fixed amount per Conversion',
    valueLabel: '{{value}} / Conversion',
    value: 'conversion_fixed_amount',
    icon: <Drafts fontSize="small" />,
    color: 'primary',
    description: 'Fixed amount per Conversion',
    valueDescription: 'fixed amount',
  },
  {
    label: 'Fixed Amount',
    valueLabel: 'Fixed Amount {{value}}',
    value: 'fixed_amount',
    icon: <Drafts fontSize="small" />,
    color: 'primary',
    description: 'Fixed Amount',
    valueDescription: 'fixed amount',
  },
]);

export const TenantContractFinishConditionTypeMap = mapValueWithDescriptionList(
  [
    {
      label: 'End Time',
      valueLabel: 'Finish at {{value}}',
      value: 'end_time',
      icon: <Drafts fontSize="small" />,
      color: 'secondary',
      description: 'Finish contract when specified time is reached',
      valueDescription: '',
    },
    {
      label: 'Campaign Count',
      valueLabel: '{{value}} Campaigns',
      value: 'campaign_count',
      icon: <Drafts fontSize="small" />,
      color: 'secondary',
      description:
        'Finish contract after specified number of campaigns finished',
      valueDescription: '',
    },
    {
      label: 'Conversion Count',
      valueLabel: '{{value}} Conversions',
      value: 'conversion_count',
      icon: <Drafts fontSize="small" />,
      color: 'secondary',
      description:
        'Finish contract after specified number of conversions recorded',
      valueDescription: '',
    },
    {
      label: 'Total Amount',
      valueLabel: 'Total Amount {{value}}',
      value: 'total_amount',
      icon: <Drafts fontSize="small" />,
      color: 'secondary',
      description: 'Finish contract after specified total amount is reached',
      valueDescription: '',
    },
    // {
    //   label: 'Manual',
    //   value: 'manual',
    //   icon: <Drafts fontSize="small" />,
    //   color: 'secondary',
    //   description: 'Only finish contract then manually is stopped',
    //   valueDescription: '',
    // },
  ]
);

export const formatFinishConditionValue = (
  c: TenantContractFinishConditionView
) => {
  switch (c.type) {
    case 'end_time':
      return formatDate(fromUnixTime(c.end_at));
    case 'total_amount':
      return String(c.amount);
    default:
      return String(c.amount);
  }
};

export const formatChargeConditionValue = (c: TenantContractChargeView) => {
  switch (c.type) {
    case 'conversion_percentage':
      return String(c.percentage);
    default:
      return formatMoneyValue(c.amount);
  }
};

export const getFinishConditionTitle = (c: TenantContractFinishConditionView) =>
  i18n.t(TenantContractFinishConditionTypeMap.field('valueLabel', c.type), {
    value: formatFinishConditionValue(c),
  });

export const getChargeConditionTitle = (c: TenantContractChargeView) =>
  i18n.t(TenantContractChargeTypeMap.field('valueLabel', c.type), {
    value: formatChargeConditionValue(c),
  });

export const getProgress = (c: BaseTenantContractView) => {
  if (c.status === 'finished' && c.progress > 100) {
    return 100;
  }

  return c.progress;
};

export const getFinishedReason = (c: ShortTenantContractView) => {
  if (c.finish_reason === 'manual') {
    return {
      label: 'Manual',
      value: 'manual',
      icon: <Block fontSize="small" />,
      color: 'error',
    };
  }

  if (c.active_campaigns > 0) {
    return {
      label: 'Reached Limit',
      value: 'limited',
      icon: <HourglassDisabled fontSize="small" />,
      color: 'warning',
    };
  }

  return {
    label: 'Finished',
    value: 'finished',
    color: 'primary',
  };
};

export const ListTenantContractColumns = (
  full: boolean = true
): DataGridColumns<ShortTenantContractView> => [
  {
    field: 'title',
    label: 'Title',
    renderCellValue: ({ id, title }: ShortTenantContractView) => (
      <Link
        color="textPrimary"
        component={RouterLink}
        to={`/connections/contracts/${id}`}
      >
        {title}
      </Link>
    ),
  },
  {
    field: 'client',
    label: 'Client',
    hidden: () => !full,
    renderCellValue: ({ destination }: ShortTenantContractView) => {
      const { t } = useTranslation();

      return (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
          }}
        >
          {destination && (
            <>
              <UserAvatar user={destination} />
              <Box sx={{ ml: 1 }}>{destination.client.company_name}</Box>
            </>
          )}
          {!destination && (
            <>
              <Typography color="textSecondary" variant="body2">
                {t('Unknown')}
              </Typography>
            </>
          )}
        </Box>
      );
    },
  },
  {
    field: 'tenant',
    label: 'Affiliate Agency',
    hidden: () => full,
    renderCellValue: ({ tenant }: ShortTenantContractView) => (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <UserAvatar user={tenant} />
        <Box sx={{ ml: 1 }}>{tenant.client.company_name}</Box>
      </Box>
    ),
  },
  {
    field: 'status',
    label: 'Status',
    align: 'center',
    headerAlign: 'center',
    renderCellValue: (contract) => (
      <>
        <StatusLabel
          value={getContractStatus(contract)}
          tooltip={getContractStatusText(contract).label}
        />
      </>
    ),
    // cellProps: {
    //   sx: {
    //     width: '1%',
    //     whiteSpace: 'nowrap',
    //   },
    // },
  },
  {
    field: 'progress',
    label: 'Progress',
    align: 'center',
    headerAlign: 'center',
    renderCellValue: (contract) => (
      <>
        <LinearProgressWithLabel color="info" value={getProgress(contract)} />
      </>
    ),
    // cellProps: {
    //   sx: {
    //     width: '1%',
    //     whiteSpace: 'nowrap',
    //   },
    // },
  },
  {
    field: 'campaigns',
    label: 'Campaigns',
    align: 'center',
    headerAlign: 'center',
    renderCellValue: ({ active_campaigns, all_campaigns }) =>
      `${active_campaigns} / ${all_campaigns}`,
    cellProps: {
      sx: {
        width: '1%',
        whiteSpace: 'nowrap',
      },
    },
  },
  // {
  //   field: 'charge_type',
  //   label: 'Charge Type',
  //   align: 'right',
  //   headerAlign: 'center',
  //   renderCellValue: ({ charge: { type } }) => (
  //     <>
  //       <Chip
  //         color={
  //           TenantContractChargeTypeMap.field(
  //             'color',
  //             type
  //           ) as ChipProps['color']
  //         }
  //         label={TenantContractChargeTypeMap.field('label', type)}
  //         variant="outlined"
  //       />
  //     </>
  //   ),
  //   // cellProps: {
  //   //   sx: {
  //   //     width: '1%',
  //   //     whiteSpace: 'nowrap',
  //   //   },
  //   // },
  // },
  // {
  //   field: 'finish_condition',
  //   label: 'Finish Condition',
  //   align: 'right',
  //   headerAlign: 'center',
  //   renderCellValue: ({ finish_condition: { type } }) => (
  //     <>
  //       <Chip
  //         color={
  //           TenantContractFinishConditionTypeMap.field(
  //             'color',
  //             type
  //           ) as ChipProps['color']
  //         }
  //         label={TenantContractFinishConditionTypeMap.field('label', type)}
  //         variant="outlined"
  //       />
  //     </>
  //   ),
  //   // cellProps: {
  //   //   sx: {
  //   //     width: '1%',
  //   //     whiteSpace: 'nowrap',
  //   //   },
  //   // },
  // },
  {
    field: 'all_users',
    label: 'All Users',
    align: 'center',
    headerAlign: 'center',
    hidden: () => !full,
    renderCellValue: ({ all_users }: BaseTenantContractView) =>
      all_users && <Checkbox checked={all_users} color="secondary" />,
    // cellProps: {
    //   sx: {
    //     width: '1%',
    //     whiteSpace: 'nowrap',
    //   },
    // },
  },
  {
    field: 'start_at',
    label: 'Starts At',
    headerAlign: 'center',
    align: 'center',
    renderCellValue: ({ start_at }: BaseTenantContractView) => (
      <>
        {timestampFormat(start_at)}
        {isFuture(fromUnixTime(start_at)) && (
          <Typography variant="subtitle2">
            {i18n.t('Starts in {{time}}', {
              time: timestampFormatDistanceToNowStrict(start_at),
            })}
          </Typography>
        )}
        {isPast(fromUnixTime(start_at)) && (
          <Typography variant="subtitle2">
            {i18n.t('Started {{time}} ago', {
              time: timestampFormatDistanceToNowStrict(start_at),
            })}
          </Typography>
        )}
      </>
    ),
    // cellProps: {
    //   sx: {
    //     width: '1%',
    //     whiteSpace: 'nowrap',
    //   },
    // },
  },
  // {
  //   field: 'created_at',
  //   label: 'Created At',
  //   headerAlign: 'center',
  //   align: 'center',
  //   hidden: () => !full,
  //   renderCellValue: renderCreatedAt,
  //   // cellProps: {
  //   //   sx: {
  //   //     width: '1%',
  //   //     whiteSpace: 'nowrap',
  //   //   },
  //   // },
  // },
  // {
  //   field: 'updated_at',
  //   label: 'Updated At',
  //   align: 'center',
  //   hidden: () => !full,
  //   renderCellValue: renderUpdatedAt,
  //   // cellProps: {
  //   //   sx: {
  //   //     width: '1%',
  //   //     whiteSpace: 'nowrap',
  //   //   },
  //   // },
  // },
];

export const CreateTenantContractChargeFormMap = (
  tenant?: BaseTenantView
): RequestBodyFormMap<ChargeTypeRequestBody, any> => ({
  type: {
    name: 'type',
    label: 'Type',
    type: 'list',
    listValues: TenantContractChargeTypeMap,
    initialValue: 'conversion_percentage',
    formFieldProp: {
      margin: 'none',
      size: 'small',
    },
    helperText: ({ formikContext }) =>
      TenantContractChargeTypeMap.field(
        'description',
        getIn(
          formikContext.values,
          `charge_type.${CreateTenantContractChargeFormMap().type.name}`
        ),
        'conversion_percentage'
      ),
  },
  percentage: {
    name: 'percentage',
    label: 'Amount',
    helperText: ({ formikContext }) =>
      TenantContractChargeTypeMap.field(
        'valueDescription',
        getIn(
          formikContext.values,
          `charge_type.${CreateTenantContractChargeFormMap().type.name}`
        ),
        'conversion_percentage'
      ),
    initialValue: '1',
    validationSchema: () =>
      Yup.number()
        .required()
        // eslint-disable-next-line no-template-curly-in-string
        .integer('${path} must be an full number without decimal point')
        .min(1),
    formFieldProp: () => (props) => ({
      ...props,
      margin: 'none',
      size: 'small',
      InputProps: {
        startAdornment: <InputAdornment position="start">%</InputAdornment>,
      },
    }),
  },
  amount: {
    name: 'amount',
    label: 'Amount',
    helperText: ({ formikContext }) =>
      TenantContractChargeTypeMap.field(
        'valueDescription',
        getIn(
          formikContext.values,
          `charge_type.${CreateTenantContractChargeFormMap().type.name}`
        ),
        'conversion_percentage'
      ),
    initialValue: '1',
    validationSchema: MoneyValueValidationSchema,
    formFieldProp: () => (props) => ({
      ...props,
      margin: 'none',
      size: 'small',
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            {getMapValue(CurrencyList, tenant?.currency, 'JPY').sign}
          </InputAdornment>
        ),
      },
    }),
  },
});

export const CreateTenantContractFinishConditionFormMap = (
  tenant?: BaseTenantView
): RequestBodyFormMap<FinishConditionRequestBody, any> => ({
  type: {
    name: 'type',
    label: 'Type',
    type: 'list',
    listValues: TenantContractFinishConditionTypeMap,
    initialValue: 'end_time',
    formFieldProp: {
      margin: 'none',
      size: 'small',
    },
    helperText: ({ formikContext }) =>
      TenantContractFinishConditionTypeMap.field(
        'description',
        getIn(
          formikContext.values,
          `finish_condition.${
            CreateTenantContractFinishConditionFormMap().type.name
          }`
        ),
        'end_time'
      ),
  },
  end_at: {
    name: 'end_at',
    label: 'End At',
    type: 'timestamp',
    formFieldProp: {
      margin: 'none',
      size: 'small',
    },
    initialValue: getUnixTime(new Date()) + 2592000,
  },
  amount: {
    name: 'amount',
    label: 'Amount',
    helperText: ({ formikContext }) =>
      TenantContractFinishConditionTypeMap.field(
        'valueDescription',
        getIn(
          formikContext.values,
          `finish_condition.${
            CreateTenantContractFinishConditionFormMap().type.name
          }`
        ),
        'end_time'
      ),
    initialValue: '1',
    validationSchema: ({ formikContext }) => {
      if (
        ['end_time', 'manual'].includes(
          getIn(
            formikContext.values,
            `finish_condition.${
              CreateTenantContractFinishConditionFormMap().type.name
            }`
          )
        )
      ) {
        return undefined;
      }

      if (
        getIn(
          formikContext.values,
          `finish_condition.${
            CreateTenantContractFinishConditionFormMap().type.name
          }`
        ) === 'total_amount'
      ) {
        return MoneyValueValidationSchema;
      }

      return (
        Yup.number()
          .required()
          // eslint-disable-next-line no-template-curly-in-string
          .integer('${path} must be an full number without decimal point')
          .min(1)
      );
    },
    formFieldProp:
      ({ formikContext }) =>
      (props) => {
        let adorment = '#';
        if (
          getIn(
            formikContext.values,
            `finish_condition.${
              CreateTenantContractFinishConditionFormMap().type.name
            }`
          ) === 'total_amount'
        ) {
          adorment = getMapValue(CurrencyList, tenant?.currency, 'JPY').sign;
        }

        return {
          ...props,
          margin: 'none',
          size: 'small',
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">{adorment}</InputAdornment>
            ),
          },
        };
      },
  },
});

export const CreateTenantContractFormMap = (
  userGroups: ListSelectorView = { values: [] }
): RequestBodyFormMap<CreateTenantContractRequestBody, any> => ({
  title: {
    name: 'title',
    label: 'Title',
    initialValue: '',
    validationSchema: Yup.string().required(),
  },
  description: {
    name: 'description',
    label: 'Description',
    type: 'quill',
    initialValue: '',
    validationSchema: Yup.string().required(),
    formFieldProp: {
      placeholder: 'Description',
      sx: {
        height: '300px',
      },
    },
  },
  start_at: {
    name: 'start_at',
    label: 'Start At',
    type: 'timestamp',
    initialValue: getUnixTime(new Date()),
  },
  user_groups: {
    name: 'user_groups',
    label: 'Affiliate Groups',
    type: 'checkboxes',
    listValues: userGroups.values,
    initialValue: [],
    formFieldProp: {
      size: 'small',
      margin: 'none',
    },
    hidden: (context, values) => values.all_users === true,
  },
  all_users: {
    name: 'all_users',
    label: 'All Affiliates',
    type: 'switch',
    initialValue: true,
    formFieldProp: {
      margin: 'none',
    },
  },
  charge_type: {
    name: 'charge_type',
    label: 'Charge Type',
    type: 'form_map',
    subFormMap: CreateTenantContractChargeFormMap(),
  },
  finish_condition: {
    name: 'finish_condition',
    label: 'Finish Condition',
    type: 'form_map',
    subFormMap: CreateTenantContractFinishConditionFormMap(),
  },
});
//
export const CreateTenantContractFormLayout = (
  tenant: BaseTenantView,
  userGroups: ListSelectorView = { values: [] }
): FormLayoutGroup => ({
  type: 'group',
  elements: [
    {
      type: 'row',
      elements: [
        {
          type: 'column',
          xs: 12,
          md: 6,
          lg: 8,
          elements: [
            {
              type: 'row',
              elements: [
                {
                  type: 'column',
                  xs: 12,
                  elements: [
                    {
                      type: 'card',
                      elements: [
                        {
                          type: 'field',
                          field: CreateTenantContractFormMap().title,
                        },
                        {
                          type: 'field',
                          field: CreateTenantContractFormMap().description,
                        },
                      ],
                    },
                    {
                      type: 'card',
                      title: 'Affiliate Groups',
                      cardContentProps: {
                        sx: {
                          pt: 0,
                        },
                      },
                      elements: [
                        {
                          type: 'row',
                          gridProps: () => (gridProps) => ({
                            ...gridProps,
                            spacing: 0,
                          }),
                          elements: [
                            {
                              type: 'column',
                              sx: {
                                alignItems: 'center',
                                display: 'flex',
                                pt: 0,
                              },
                              elements: [
                                {
                                  type: 'field',
                                  field:
                                    CreateTenantContractFormMap(userGroups)
                                      .all_users,
                                },
                                {
                                  type: 'box',
                                  sx: {
                                    ml: 2,
                                  },
                                  elements: [
                                    {
                                      type: 'field',
                                      field:
                                        CreateTenantContractFormMap(userGroups)
                                          .user_groups,
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'card',
                      title: 'Charge Type',
                      cardContentProps: {
                        sx: {
                          pt: 0,
                        },
                      },
                      elements: [
                        {
                          type: 'group',
                          elements: [
                            {
                              type: 'row',
                              elements: [
                                {
                                  type: 'column',
                                  xs: 12,
                                  elements: [
                                    {
                                      type: 'field',
                                      namePrefix: 'charge_type.',
                                      field:
                                        CreateTenantContractChargeFormMap()
                                          .type,
                                    },
                                  ],
                                },
                                {
                                  type: 'column',
                                  xs: 12,
                                  elements: [
                                    {
                                      type: 'field',
                                      namePrefix: 'charge_type.',
                                      hidden: ({ formikContext }) =>
                                        getIn(
                                          formikContext.values,
                                          `${
                                            CreateTenantContractFormMap()
                                              .charge_type.name
                                          }.${
                                            CreateTenantContractChargeFormMap()
                                              .type.name
                                          }`
                                        ) === 'conversion_percentage',
                                      field:
                                        CreateTenantContractChargeFormMap(
                                          tenant
                                        ).amount,
                                    },
                                    {
                                      type: 'field',
                                      namePrefix: 'charge_type.',
                                      hidden: ({ formikContext }) =>
                                        getIn(
                                          formikContext.values,
                                          `${
                                            CreateTenantContractFormMap()
                                              .charge_type.name
                                          }.${
                                            CreateTenantContractChargeFormMap()
                                              .type.name
                                          }`
                                        ) !== 'conversion_percentage',
                                      field:
                                        CreateTenantContractChargeFormMap()
                                          .percentage,
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    {
                      type: 'card',
                      title: 'Finish Condition',
                      cardContentProps: {
                        sx: {
                          pt: 0,
                        },
                      },
                      elements: [
                        {
                          type: 'group',
                          elements: [
                            {
                              type: 'row',
                              elements: [
                                {
                                  type: 'column',
                                  xs: 12,
                                  elements: [
                                    {
                                      type: 'field',
                                      namePrefix: 'finish_condition.',
                                      field:
                                        CreateTenantContractFinishConditionFormMap()
                                          .type,
                                    },
                                  ],
                                },
                                {
                                  type: 'column',
                                  xs: 12,
                                  elements: [
                                    {
                                      type: 'field',
                                      namePrefix: 'finish_condition.',
                                      hidden: ({ formikContext }) =>
                                        ['end_time', 'manual'].includes(
                                          getIn(
                                            formikContext.values,
                                            `${
                                              CreateTenantContractFormMap()
                                                .finish_condition.name
                                            }.${
                                              CreateTenantContractFinishConditionFormMap()
                                                .type.name
                                            }`
                                          )
                                        ),
                                      field:
                                        CreateTenantContractFinishConditionFormMap(
                                          tenant
                                        ).amount,
                                    },
                                    {
                                      type: 'field',
                                      namePrefix: 'finish_condition.',
                                      hidden: ({ formikContext }) =>
                                        getIn(
                                          formikContext.values,
                                          `${
                                            CreateTenantContractFormMap()
                                              .finish_condition.name
                                          }.${
                                            CreateTenantContractFinishConditionFormMap()
                                              .type.name
                                          }`
                                        ) !== 'end_time',
                                      field:
                                        CreateTenantContractFinishConditionFormMap()
                                          .end_at,
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: 'column',
          xs: 12,
          md: 6,
          lg: 4,
          elements: [
            {
              type: 'card',
              title: 'Settings',
              cardContentProps: {
                sx: {
                  pt: 0,
                },
              },
              elements: [
                {
                  type: 'field',
                  field: CreateTenantContractFormMap().start_at,
                },
              ],
            },
            {
              type: 'box',
              sx: {
                display: 'flex',
                justifyContent: 'flex-end',
                mt: 3,
              },
              elements: [
                {
                  type: 'form_button',
                  label: ({ formikContext }) =>
                    formikContext.submitCount === 0 || formikContext.isValid
                      ? 'Create Contract'
                      : 'There are some errors',
                  color: ({ formikContext }) =>
                    formikContext.submitCount === 0 || formikContext.isValid
                      ? 'primary'
                      : 'error',
                  actionDisabled: ({ formikContext }) =>
                    formikContext.submitCount === 0 || formikContext.isValid
                      ? undefined
                      : false,
                  startIcon: ({ formikContext }: WrappedContext) =>
                    formikContext.submitCount === 0 ||
                    formikContext.isValid ? undefined : (
                      <ErrorOutline fontSize="small" />
                    ),
                },
              ],
            },
          ],
        },
      ],
    },
  ],
});

export const EditAction = (
  currentDashboard: Dashboard
): ActionTypes<BaseTenantContractView> => ({
  type: 'route_link',
  label: 'Edit',
  variant: 'contained',
  hidden: ({ tenant }) => currentDashboard.id !== tenant.id,
  disabled: ({ status }) => status !== 'waiting',
  icon: <PencilAltIcon fontSize="small" />,
  to: (ctx) => `/connections/contracts/${ctx.id}`,
});

export const DeleteAction =
  (currentDashboard: Dashboard) =>
  (reloadData?: () => void): ActionTypes<BaseTenantContractView> => ({
    type: 'confirm_click',
    label: 'Remove',
    variant: 'contained',
    color: 'error',
    hidden: ({ tenant }) => currentDashboard.id !== tenant.id,
    disabled: ({ status }) => status !== 'waiting',
    icon: <DeleteForever fontSize="small" />,
    dialog: (
      context: FullTenantContractView
    ): UseDialogProps<FullTenantContractView> => ({
      title: 'Are you sure you want to delete contract "{{name}}"?',
      description:
        "This contract and all related data will be deleted immediately. You can't undo this action.",
      translationParams: {
        name: context.title,
      },
      level: 'error',
      closeOnOutsideClick: true,
      onConfirm: (c, close) =>
        simpleApiCall(remove, {
          onSuccessToastMessage: 'Contract "{{name}}" was deleted successfully',
          translationParams: { name: c.title },
          onSuccess: () => {
            close();
            if (reloadData !== undefined) {
              reloadData();
            } else {
              history.push('/connections/contracts');
            }
          },
        })({ id: c.id, tenantId: c.tenant.id }),
    }),
  });

export const AcceptAction =
  (currentDashboard: Dashboard) =>
  (reloadData?: () => void): ActionTypes<BaseTenantContractView> => ({
    type: 'confirm_click',
    label: 'Accept Contract',
    variant: 'contained',
    color: 'success',
    hidden: ({ tenant, destination }) =>
      currentDashboard.type !== DashboardType.TenantAdmin ||
      currentDashboard.id === tenant.id ||
      destination !== null,
    icon: <AssignmentTurnedIn fontSize="small" />,
    dialog: (
      context: FullTenantContractView
    ): UseDialogProps<FullTenantContractView> => ({
      title: 'Are you sure you want to accept contract "{{name}}"?',
      description:
        'The contract will be accepted and will be visible to affiliates from {{agency}}',
      translationParams: {
        name: context.title,
        agency: context.tenant.client.company_name,
      },
      level: 'warning',
      closeOnOutsideClick: true,
      onConfirm: (c, close) =>
        simpleApiCall(acceptContract, {
          onSuccessToastMessage:
            'Contract "{{name}}" was accepted successfully',
          translationParams: { name: c.title },
          onSuccess: () => {
            close();
            if (reloadData !== undefined) {
              reloadData();
            } else {
              history.push('/connections/contracts');
            }
          },
        })({
          tenantId: currentDashboard.id,
          id: c.id,
        }),
    }),
  });

export const ListTenantContractRowActions =
  (currentDashboard: Dashboard) =>
  (reloadData: () => void): ActionList<ShortTenantContractView> =>
    [EditAction(currentDashboard), DeleteAction(currentDashboard)(reloadData)];

export const FindContractFormMap = (): RequestBodyFormMap<
  FindContractRequestBody,
  any
> => ({
  code: {
    name: 'code',
    label: 'Contract Code',
    initialValue: '',
  },
});

export const FindContractFormLayout = (): FormLayoutCard => ({
  type: 'card',
  title: 'Information',
  headerDivider: true,
  bottomDivider: true,
  maxWidth: 'sm',
  sx: { mb: 2 },
  elements: [
    {
      type: 'row',
      elements: [
        {
          type: 'column',
          xs: 12,
          elements: [
            {
              type: 'field',
              field: FindContractFormMap().code,
            },
          ],
        },
      ],
    },
  ],
  bottomElements: [
    {
      type: 'box',
      sx: {
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2,
      },
      elements: [
        {
          type: 'form_button',
          label: 'Search',
          startIcon: <FindInPage fontSize="small" />,
        },
      ],
    },
  ],
});
