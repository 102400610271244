import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { ApiClientErrorInterface } from './ApiClientErrorInterface';

export class ApiClientError extends Error implements ApiClientErrorInterface {
  code?: string;

  parentError?: any;

  request?: AxiosRequestConfig;

  response?: AxiosResponse;

  constructor(message: string, code?: string, parentError?: any) {
    super(message);
    this.code = code;
    this.parentError = parentError;
  }
}
