import React from 'react';
import { Box, Divider } from '@material-ui/core';
import Tabs, { TabsProp } from '../Tabs';

export interface PageTabsProps<ContextType = any> extends TabsProp<ContextType> {
}

const PageTabs = <ContextType extends any = any, >(props: PageTabsProps<ContextType>) => (
  <>
    <Box sx={{ mt: 3 }} />
    <Tabs {...props} />
    <Divider />
  </>
);

export default PageTabs;
