import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography,
} from '@material-ui/core';
import { useDispatch } from '../../redux';
import { changeLocale } from '../../redux/slices/User';
import { LocaleTypeMap } from '../../types/Locale';
import { getMapValue } from '../../types/MapValues';

const LanguagePopover = () => {
  const anchorRef = React.useRef<HTMLButtonElement | null>(null);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [open, setOpen] = React.useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleChangeLanguage = (language: string): void => {
    i18n.changeLanguage(language);
    dispatch(changeLocale(language));
    setOpen(false);
  };

  const selectedOption = getMapValue(LocaleTypeMap, i18n.language, 'en');

  return (
    <>
      <IconButton
        onClick={handleOpen}
        ref={anchorRef}
      >
        {selectedOption.icon}
      </IconButton>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 },
        }}
      >
        {LocaleTypeMap.map((language) => (
          <MenuItem
            onClick={() => handleChangeLanguage(language.value)}
            key={language.value}
          >
            <ListItemIcon>
              {language.icon}
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  {language.label}
                </Typography>
              )}
            />
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default LanguagePopover;
