import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import importTranslation from '../../hooks/ut';
import { FullTenantContractView } from '../../api/types/App/View/TenantContract';
import { AssignmentTurnedIn, AttachMoney } from '@material-ui/icons';
import {
  getChargeConditionTitle,
  getFinishConditionTitle,
  TenantContractChargeTypeMap,
  TenantContractFinishConditionTypeMap,
} from '../../resources/TenantContract';

interface ConditionsProps {
  contract: FullTenantContractView;
}

const Conditions = (props: ConditionsProps) => {
  const { contract } = props;
  const { t } = importTranslation();

  return (
    <Box sx={{ mt: 2 }}>
      <Card>
        <CardHeader title={t('Conditions')} />
        <CardContent
          sx={{
            p: 0,
            '&:last-child': {
              p: 0,
            },
          }}
        >
          <Divider />
          <List>
            <ListItem divider>
              <ListItemIcon>
                <AttachMoney />
              </ListItemIcon>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ ml: 1 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography color="textPrimary" variant="h6">
                        {getChargeConditionTitle(contract.charge)}
                      </Typography>
                    </Box>
                    {TenantContractChargeTypeMap.field(
                      'description',
                      contract.charge.type
                    )}
                  </Box>
                </Box>
              </Box>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AssignmentTurnedIn />
              </ListItemIcon>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                  flexGrow: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box sx={{ ml: 1 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Typography color="textPrimary" variant="h6">
                        {getFinishConditionTitle(contract.finish_condition)}
                      </Typography>
                    </Box>
                    {TenantContractFinishConditionTypeMap.field(
                      'description',
                      contract.finish_condition.type
                    )}
                  </Box>
                </Box>
              </Box>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Conditions;
