import { DataGridColumns } from '../components/DataGrid/Column';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Chip, ChipProps, Link, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import { ActionList, ActionTypes } from '../types/ActionType';
import UserAvatar from '../components/UserAvatar';
import i18n from 'i18next';
import { timestampFormatDistanceToNowStrict } from '../utils/timestampUtils';
import { getMapValue } from '../types/MapValues';
import { renderCreatedAt, renderUpdatedAt } from '../components/TimestampCell';
import { formatMoneyValue } from '../types/Money';
import { Paid, Preview } from '@material-ui/icons';
import {
  FullCommissionInvoiceView,
  ShortCommissionInvoiceView,
} from '../api/types/App/View/CommissionInvoice';
import { InvoicePaidStatusMap } from './Invoice';
import { RequestBodyFormMap } from '../types/FormTypes';
import { MarkAsPaidRequestBody } from '../api/types/App/Request/CommissionInvoice';
import { FormLayoutCard } from '../types/FormLayoutType';

export const ListCommissionInvoicesColumns = (): DataGridColumns<ShortCommissionInvoiceView> => [
  {
    field: 'invoice.number',
    label: 'Invoice Number',
    renderCellValue: ({
      id,
      invoice: i,
    }) => (
      <Link
        color="textPrimary"
        component={RouterLink}
        to={`/commission/invoices/${id}`}
      >
        {i.number}
      </Link>
    ),
  },
  {
    field: 'invoice.paid',
    label: 'Status',
    align: 'right',
    headerAlign: 'center',
    renderCellValue: ({ invoice: i }) => (
      <>
        {i.paid === true && (
          <Tooltip title={i18n.t('Paid {{time}} ago', {
            time: timestampFormatDistanceToNowStrict(i.paid_at),
          })}
          >
            <Chip
              label={getMapValue(InvoicePaidStatusMap, 'paid').label}
              color={getMapValue(InvoicePaidStatusMap, 'paid').color as ChipProps['color']}
              size="small"
              clickable
              component={RouterLink}
              to={`/commissions/invoices/${i.id}`}
            />
          </Tooltip>
        )}
        {i.paid === false && (
          <Chip
            label={getMapValue(InvoicePaidStatusMap, 'not_paid').label}
            color={getMapValue(InvoicePaidStatusMap, 'not_paid').color as ChipProps['color']}
            size="small"
            clickable
            component={RouterLink}
            to={`/commissions/invoices/${i.id}`}
          />
        )}
      </>
    ),
    cellProps: {
      sx: {
        width: '1%',
        whiteSpace: 'nowrap',
      },
    },
  },
  {
    field: 'total',
    label: 'Total',
    align: 'right',
    headerAlign: 'right',
    renderCellValue: ({ invoice: i }) => (
      <>
        {formatMoneyValue(i.total)}
      </>
    ),
    headerProps: {
      sx: {
        width: '1%',
        whiteSpace: 'nowrap',
      },
    },
    cellProps: {
      sx: {
        width: '1%',
        whiteSpace: 'nowrap',
      },
    },
  },
  {
    field: 'campaign',
    label: 'Campaign',
    renderCellValue: ({ commission: c }) => (
      <Link
        color="textPrimary"
        component={RouterLink}
        to={`/campaigns/${c.campaign.id}`}
      >
        {c.campaign.title}
      </Link>
    ),
  },
  {
    field: 'campaign_participant',
    label: 'Participant',
    renderCellValue: ({ commission: c }) => (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <UserAvatar user={c.campaign_participant.user} size="mini" />
        <Box sx={{ ml: 1 }}>
          <Link
            color="inherit"
            component={RouterLink}
            to={{
              pathname: `/tenant/users/${c.campaign_participant.tenant_user.id}`,
            }}
            variant="subtitle2"
          >
            {c.campaign_participant.user.full_name}
          </Link>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            <Link
              color="textSecondary"
              href={`mailto:${c.campaign_participant.user.email}`}
            >
              {c.campaign_participant.user.email}
            </Link>
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    field: 'created_at',
    label: 'Created At',
    renderCellValue: renderCreatedAt,
    align: 'right',
  },
  {
    field: 'updated_at',
    label: 'Updated At',
    renderCellValue: renderUpdatedAt,
    align: 'right',
  },
];

export const MarkAsPaidAction = (isUser: boolean = false): ActionTypes<FullCommissionInvoiceView> => ({
  type: 'route_link',
  label: 'Mark As Paid',
  variant: 'contained',
  color: 'success',
  icon: <Paid fontSize="small" />,
  hidden: (c) => c.invoice.paid === true || isUser,
  to: (context) => `/commission/invoices/${context.id}/markAsPaid`,
});

export const ListCommissionInvoicesActions = (): ActionList => [
  {
    type: 'route_link',
    label: 'Inspect',
    variant: 'contained',
    icon: <Preview fontSize="small" />,
    to: (context) => `/commission/invoices/${context.id}`,
  },
];

export const MarkAsPaidFormMap: RequestBodyFormMap<MarkAsPaidRequestBody, any> = {
  payment_reference_number: {
    name: 'payment_reference_number',
    label: 'Payment Reference Number',
    initialValue: '',
  },
  payment_type: {
    name: 'payment_type',
    label: 'Payment Type',
    initialValue: '',
  }
};

export const MarkAsPaidFormLayout: FormLayoutCard<any> = {
  type: 'card',
  title: 'Mark As Paid Invoice',
  headerDivider: true,
  bottomDivider: true,
  elements: [
    {
      type: 'row',
      elements: [
        {
          type: 'column',
          xs: 12,
          elements: [
            {
              type: 'field',
              field: MarkAsPaidFormMap.payment_type,
            },
            {
              type: 'field',
              field: MarkAsPaidFormMap.payment_reference_number,
            }
          ]
        }
      ]
    }
  ],
  bottomElements: [
    {
      type: 'box',
      sx: {
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2,
      },
      elements: [
        {
          type: 'form_button',
        },
      ],
    },
  ],
};
