import React, { ChangeEvent } from 'react';
import { Checkbox, TableCell, TableRow, TableRowProps } from '@material-ui/core';
import { useStateEffect } from '../../hooks/useStateEffect';
import Column, { DataGridColumns } from './Column';
import useContextMenu, { ContextMenu } from '../../muiLib/ContextMenu';
import RowActions from './RowActions';
import { ActionList } from '../../types/ActionType';

interface RowProp<DataType, IdType> {
  children: {
    rowProps?: TableRowProps,
    columns: DataGridColumns<DataType>,
    data: DataType,
    id: IdType,
    isSelected?: boolean
    onSelectChange?: (row: DataType, selection: boolean) => void,
    contextMenu?: ContextMenu<DataType>,
    actions?: ActionList<DataType>,
  }
}

const Row = <DataType, IdType>(props: RowProp<DataType, IdType>) => {
  const { children: config } = props;
  const [selected, setSelected] = useStateEffect<boolean>(config.isSelected ?? false);

  const handleSelectChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelected(event.target.checked);

    if (config.onSelectChange !== undefined) {
      config.onSelectChange(config.data, event.target.checked);
    }
  };

  const rowProps: TableRowProps = {
    hover: true,
    key: `${config.id}`,
    selected: config.isSelected,
    ...(config.rowProps ?? {})
  };

  const cells = config.columns.filter((column) => column.hidden === undefined || column.hidden() === false)
    .map((column) => (
      <Column key={String(column.field)}>
        {{
          config: column,
          data: config.data,
        }}
      </Column>
    ));

  let actions;
  if (config.actions !== undefined) {
    actions = (
      <RowActions>
        {{
          actions: config.actions,
          data: config.data,
        }}
      </RowActions>
    );
  }

  let handleContextMenu;
  let contextMenu;
  if (config.contextMenu !== undefined) {
    [handleContextMenu, contextMenu] = useContextMenu(config.contextMenu, config.data);
  }

  return (
    <TableRow {...rowProps} onContextMenu={handleContextMenu}>
      <TableCell padding="checkbox">
        <Checkbox
          checked={selected}
          color="primary"
          onChange={handleSelectChange}
          value={selected}
        />
      </TableCell>
      {cells}
      {actions}
      {contextMenu}
    </TableRow>
  );
};

export default Row;
