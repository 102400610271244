import React from 'react';
import useLoadApiDataPaginated from '../hooks/useLoadApiDataPaginated';
import DataGrid, { DataGridConfig, DataGridTableProps } from './DataGrid';
import { PaginatedResultView } from '../api/types/App/View/Common';
import {
  ApiEndpointParamsType,
  ApiEndpointResponseViewType,
  ApiEndpointRouteParamsType,
  ApiEndpointTypeAny,
} from '../api/client';
import { DataGridColumns } from './DataGrid/Column';
import _ from 'lodash';
import { BaseResourceView } from '../api/types/App/View';

interface ListResourceTableProps<EndpointType extends ApiEndpointTypeAny,
  DataType,
  IdType = string> extends DataGridTableProps<DataType> {
  routeParams: ApiEndpointRouteParamsType<EndpointType>,
  params: ApiEndpointParamsType<EndpointType>,
  columns: DataGridColumns<DataType>,
  idValueGetter?: (data: DataType) => IdType,
  dataGridConfig?: Partial<DataGridConfig<DataType, IdType>>,
  perPageOptions?: number[],
}

const ListResourceTable = <EndpointType extends ApiEndpointTypeAny,
  FieldName extends keyof ApiEndpointResponseViewType<EndpointType>,
  FieldType = ApiEndpointResponseViewType<EndpointType>[FieldName],
  PaginatedType = FieldType extends PaginatedResultView<infer ViewType> ? ViewType : FieldType,
  IdType = string,
  >(
    {
      endpoint,
      field,
      ...rest
    }: { endpoint: EndpointType, field: FieldName } & ListResourceTableProps<EndpointType, PaginatedType, IdType>,
  ) => (
    (props: ListResourceTableProps<EndpointType, PaginatedType, IdType>) => {
      const {
        routeParams = {},
        params = {},
        actions,
        selected = [],
        hiddenColumns = [],
        dataGridConfig: additionalDataGridConfig = {},
        idValueGetter = (data) => ((data as unknown as BaseResourceView).id as unknown as IdType),
        perPageOptions = [10, 20, 30],
        columns,
      } = props;

      const {
        apiData,
        setPage,
        page,
        perPage,
        handleOnPerPageChange,
        loadApiData,
      } = useLoadApiDataPaginated(endpoint, field, perPageOptions, true)(routeParams, params);

      let data: PaginatedResultView<PaginatedType> = {
        total: 0,
        data: [],
      };
      if (apiData !== null) {
        data = apiData[field];
      }

      const actionList = typeof actions === 'function' ? actions(loadApiData) : actions;

      const dataGridConfig: DataGridConfig<PaginatedType, IdType> = {
        rows: data.data,
        selected,
        columns,
        hiddenColumns,
        idValueGetter,
        pagination: {
          count: data.total,
          page,
          rowsPerPage: perPage,
          rowsPerPageOptions: perPageOptions,
          onPageChange: (p) => setPage(p),
          onRowsPerPageChange: handleOnPerPageChange,
        },
        actions: actionList,
      };

      return (
        <DataGrid>
          {_.merge(dataGridConfig, additionalDataGridConfig)}
        </DataGrid>
      );
    }
  )(rest);
export default ListResourceTable;
