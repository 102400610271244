import _ from 'lodash';
import {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
} from '@material-ui/core/colors';
import useTheme from '../hooks/useTheme';

const avatarColors = [
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
];

const avatarColorKeys = [
  // '50',
  // '100',
  // '200',
  // '300',
  '400',
  '500',
  '600',
  '700',
  // '800',
  // '900',
  'A100',
  'A200',
  'A400',
  'A700',
];

export interface AvatarColor {
  backgroundColor: string,
  color: string,
}

export function getRandomAvatarColor(): AvatarColor {
  const { theme } = useTheme();
  const color = _.sample(avatarColors);
  const key = _.sample(avatarColorKeys);

  return {
    backgroundColor: color[key],
    color: theme.palette.getContrastText(color[key]),
  };
}

export function getAvatarColor(bg: string | null): AvatarColor {
  const { theme } = useTheme();

  if (bg === null) {
    const color = _.sample(avatarColors);
    const key = _.sample(avatarColorKeys);

    bg = color[key];
  }

  return {
    backgroundColor: bg,
    color: theme.palette.getContrastText(bg),
  };
}
