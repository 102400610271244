import React from 'react';
import { useRouteMatch } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import CreateAgencyPage from './Agency/CreateAgency';
import AgencyListPage from './Agency/ListAgency';
import ShowAgency from './Agency/ShowAgency';

const Agencies = () => {
  const { url: baseURL } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${baseURL}/create`}>
        <CreateAgencyPage />
      </Route>
      <Route exact path={`${baseURL}/:id`}>
        <ShowAgency />
      </Route>
      <Route path={`${baseURL}`}>
        <AgencyListPage />
      </Route>
    </Switch>
  );
};

export default Agencies;
