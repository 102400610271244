import React from 'react';
import {
  calculateComponent,
  calculateProps,
  ModifiableComponentProps,
  ModifiableComponentRender,
} from '../utils/propHelpers';
import { Box, Typography } from '@material-ui/core';
import { MapValue, parseColorString } from '../types/MapValues';
import importTranslation from '../hooks/ut';

interface MapValueLabelProps<T extends MapValue = MapValue> {
  value: T,
  noIcon?: boolean,
  noText?: boolean,
  noTranslate?: boolean,
  hidden?: boolean,
  color?: MapValue['color'],
  containerProps?: ModifiableComponentProps<typeof Box>
  textProps?: ModifiableComponentProps<typeof Typography>,
  renderContainer?: ModifiableComponentRender<typeof Box, T>,
  renderText?: ModifiableComponentRender<typeof Typography, T>,
}

const MapValueLabel = (props: MapValueLabelProps) => {
  const {
    value,
    noIcon = false,
    noText = false,
    noTranslate = false,
    color,
    containerProps,
    textProps,
    renderContainer,
    renderText,
    hidden = false
  } = props;

  if (hidden === true) {
    return null;
  }

  const { t } = importTranslation();

  return (
    <>
      {
      calculateComponent(
        renderContainer,
        Box,
        calculateProps(
          containerProps,
          {
            sx: {
              alignItems: 'center',
              display: 'flex',
              m: 2,
              color: parseColorString(value.color ?? color),
            },
            children: (
              <>
                {noIcon !== true && value.icon && (<>{value.icon}</>)}
                {noText !== true && (
                  <>
                    {
                      calculateComponent(
                        renderText,
                        Typography,
                        calculateProps(
                          textProps,
                          {
                            sx: {
                              ml: noIcon !== true ? 1 : 0,
                            },
                            children: noTranslate !== true ? t(value.label) : value.label
                          }
                        ),
                        value,
                      )
                    }
                  </>
                )}
              </>
            )
          }
        ),
        value
      )
    }
    </>
  );
};

export default MapValueLabel;
