import React from 'react';
import { useRouteMatch } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import ListContractsPage from './Connections/Contract/List';
import ShowContractPage from './Connections/Contract/Show';
import ContractCreatePage from './Connections/Contract/Create';
import FindContractPage from './Connections/Contract/FindContract';

const contracts = () => {
  const { url: baseURL } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${baseURL}/create`}>
        <ContractCreatePage />
      </Route>
      <Route exact path={`${baseURL}/find`}>
        <FindContractPage />
      </Route>
      <Route exact path={`${baseURL}/:id`}>
        <ShowContractPage />
      </Route>
      {/* <Route exact path={`${baseURL}/:id/edit`}> */}
      {/*  <EditAffiliatePage /> */}
      {/* </Route> */}
      <Route path={`${baseURL}`}>
        <ListContractsPage />
      </Route>
    </Switch>
  );
};

export default contracts;
