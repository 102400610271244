import React from 'react';
import { ActionTypes } from '../types/ActionType';
import ButtonAction from './Action/ButtonAction';
import IconButtonAction from './Action/IconButtonAction';
import { parseCalcType } from '../types/CalculatedType';

export interface ActionsProp<V> {
  type: 'button' | 'icon',
  actions: ActionTypes<V>[]
  context?: V,
}

const Actions = <V extends any = any, >({
  actions,
  type,
  context,
}: ActionsProp<V>) => (
  <>
    {actions
      .map((action) => {
        if (action.hidden !== undefined && parseCalcType(action.hidden, context) === true) {
          return null;
        }

        switch (type) {
          case 'button':
            return <ButtonAction key={parseCalcType(action.label, context)} context={context} action={action} />;
          case 'icon':
            return <IconButtonAction key={parseCalcType(action.label, context)} context={context} action={action} />;
          default:
            return null;
        }
      })}
  </>
  );

export default Actions;
