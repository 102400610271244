import * as React from 'react';
import MuiTextField, { TextFieldProps as MuiTextFieldProps } from '@material-ui/core/TextField';
import { FieldProps, getIn } from 'formik';

export interface TextFieldProps extends FieldProps, Omit<MuiTextFieldProps, 'name' | 'value' | 'error'> {
}

export function fieldToTextField(
  {
    disabled,
    field: {
      onBlur: fieldOnBlur,
      ...field
    },
    form: {
      isSubmitting,
      touched,
      errors,
    },
    onBlur,
    helperText,
    label,
    ...props
  }: TextFieldProps,
): MuiTextFieldProps {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    variant: props.variant,
    error: showError,
    helperText: showError ? fieldError.replace(field.name, label) : helperText,
    disabled: disabled ?? isSubmitting,
    label,
    onBlur: onBlur ?? ((e) => fieldOnBlur(e ?? field.name)),
    ...field,
    ...props,
  };
}

export function TextField(
  {
    children,
    ...props
  }: TextFieldProps,
) {
  return <MuiTextField {...fieldToTextField(props)}>{children}</MuiTextField>;
}

TextField.displayName = 'FormikMaterialUITextField';
