import React from 'react';
import Dialog, { DialogLevel } from '../components/Dialog';

export interface UseDialogProps<T> {
  title?: string,
  description?: string,
  translationParams?: Record<string, string>,
  icon?: React.ReactNode,
  cancelButtonText?: string,
  cancelButtonLevel?: DialogLevel,
  confirmButtonText?: string,
  confirmButtonLevel?: DialogLevel,
  level?: DialogLevel,
  closeOnOutsideClick: boolean,
  onClose?: (context: T, close: () => void) => void,
  onConfirm?: (context: T, close: () => void) => void,
  onCancel?: (context: T, close: () => void) => void,
}

export type UseDialogShowDialog<T> = (context: T, translationParams?: Record<string, string>, title?: string, description?: string) => void;

const useDialog = <T, >(props: UseDialogProps<T>) => {
  const [titleValue, setTitle] = React.useState<string>(props.title ?? '');
  const [descriptionValue, setDescription] = React.useState<string>(props.description ?? '');
  const [allTranslationParams, setTranslationParams] = React.useState<Record<string, string>>(props.translationParams ?? {});
  const [open, setOpen] = React.useState<boolean>(false);
  const [currentContext, setCurrentContext] = React.useState<T>();

  const close = () => {
    setOpen(false);
  };

  const showDialog = (context: T, translationParams?: Record<string, string>, title?: string, description?: string) => {
    if (title !== undefined) {
      setTitle(title);
    }

    if (description !== undefined) {
      setDescription(description);
    }

    if (translationParams !== undefined) {
      setTranslationParams((prev) => ({ ...prev, ...translationParams }));
    }

    setCurrentContext(context);
    setOpen(true);
  };

  const handleClose = () => {
    if (props.onClose !== undefined) {
      props.onClose(currentContext, close);
    } else if (props.closeOnOutsideClick) {
      close();
    }
  };

  const handleConfirm = () => {
    if (props.onConfirm !== undefined) {
      props.onConfirm(currentContext, close);
    }
  };

  const handleCancel = () => {
    if (props.onCancel !== undefined) {
      props.onCancel(currentContext, close);
    }

    close();
  };

  const dialog = (
    <Dialog
      title={titleValue}
      description={descriptionValue}
      translationParams={allTranslationParams}
      level={props.level}
      open={open}
      onClose={handleClose}
      onCancel={handleCancel}
      onConfirm={handleConfirm}
    />
  );

  return {
    dialog,
    showDialog,
  };
};

export default useDialog;
