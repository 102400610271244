import { BaseRequestBody } from '../api/types/App/Request';
import { CalcType, parseCalcType } from './CalculatedType';
import { ModifyComponentProps } from './RenderComponent';
import { AnySchema } from 'yup';
import { Yup } from '../lib/Yup';
import { MapValue } from './MapValues';
import { FieldAttributes } from 'formik/dist/Field';
import { HumanCase } from './Common';
import { FormikValues } from 'formik/dist/types';
import { WrappedContext } from './FormLayoutType';
import _ from 'lodash';

export type FormFieldType =
  'checkbox'
  | 'checkboxes'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week'
  | 'list'
  | 'select'
  | 'textarea'
  | 'autocomplete'
  | 'timestamp'
  | 'quill'
  | 'form_map'
  | 'form_map[]'
  | 'switch';

export type FormFieldDefinitionAny<ContextType = any> = FormFieldDefinition<ContextType, any, any, any>;
export type FormFieldDefinition<ContextType, ValueType, Name = string, Label = Name | string, RequestBody = FormikValues> = {
  label: Label,
  leftLabel?: Label,
  name: Name,
  initialValue?: CalcType<ValueType, ContextType>,
  type?: FormFieldType,
  helperText?: CalcType<string, ContextType>,
  disabled?: (context: ContextType, values: RequestBody) => boolean,
  hidden?: (context: ContextType, values: RequestBody) => boolean,
  validationSchema?: CalcType<AnySchema, ContextType>,
  formFieldProp?: CalcType<ModifyComponentProps<FieldAttributes<any>>>,
  listValues?: MapValue[],
  groupBy?: (option: MapValue) => string,
  textPrimary?: CalcType<string, ContextType>,
  textSecondary?: CalcType<string, ContextType>,
  translationParams?: Record<string, string>,
  subFormMap?: CalcType<RequestBodyFormMap<any, any>, ContextType>,
  arrayValidationSchema?: (subFormMapValidationSchema: AnySchema) => AnySchema,
  dontTranslate?: CalcType<boolean, ContextType>,
  dontRenderLabel?: CalcType<boolean, ContextType>,
};

export type FormFieldDefinitionContextType<CT> = CT extends FormFieldDefinition<infer T, any> ? T : never;
export type FormFieldDefinitionValueType<CT> = CT extends FormFieldDefinition<any, infer T> ? T : never;

export type FormFieldDefinitions<ContextType> = FormFieldDefinitionAny<ContextType>[];

export type RequestBodyFormMap<RequestBody extends BaseRequestBody, ContextType> = {
  [P in keyof RequestBody]: FormFieldDefinition<ContextType, RequestBody[P], P, HumanCase<P> | string, RequestBody>
};

type RequestBodyFormMapInitialValueType<RB extends BaseRequestBody> = {
  [P in keyof RB]: RB[P]
};

export type RequestBodyFormMapRequestBodyType<T> = T extends RequestBodyFormMap<infer RB, any> ? RB : never;
export type RequestBodyFormMapContextType<T extends RequestBodyFormMap<any, any>> = T extends RequestBodyFormMap<any, infer V> ? V : never;

export const formFieldDefinitionInitialValue = <ContextType extends any = any, >(
  field: FormFieldDefinitionAny,
  context: ContextType,
) => {
  if (field.type === 'form_map') {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return requestBodyFormMapInitialValue(parseCalcType(field.subFormMap, context), context[field.name] ?? {});
  }

  const { initialValue } = field;
  let value;
  if (typeof initialValue !== 'function') {
    if (context !== null && context !== undefined) {
      value = _.cloneDeep(context[field.name]) ?? initialValue;
    } else {
      value = initialValue;
    }
  } else {
    value = parseCalcType(initialValue, context);
  }

  if (field.type === 'form_map[]') {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return (value ?? []).map((subContext) => requestBodyFormMapInitialValue(parseCalcType(field.subFormMap, context), subContext ?? {}));
  }

  return value;
};

export const requestBodyFormMapInitialValue = <T extends RequestBodyFormMap<any, any>,
  RB = RequestBodyFormMapRequestBodyType<T>,
  C = RequestBodyFormMapContextType<T>,
  >(formMap: T, context: C): RequestBodyFormMapInitialValueType<RB> => {
  const result = {};
  Object.keys(formMap)
    .forEach((field) => {
      result[field] = formFieldDefinitionInitialValue(formMap[field], context);
    });

  return result as RequestBodyFormMapInitialValueType<RB>;
};

export const requestBodyFormMapValidationSchema = <T extends RequestBodyFormMap<any, any>,
  C = RequestBodyFormMapContextType<T>,
  >(formMap: T, context: WrappedContext<C>): AnySchema => {
  const result = {};
  Object.keys(formMap)
    .forEach((field) => {
      if (formMap[field].type === 'form_map') {
        result[field] = requestBodyFormMapValidationSchema(parseCalcType(formMap[field].subFormMap, context), context);
      }

      if (formMap[field].type === 'form_map[]') {
        const validationScheme = requestBodyFormMapValidationSchema(parseCalcType(formMap[field].subFormMap, context), context);

        if (typeof formMap[field].arrayValidationSchema === 'function') {
          result[field] = formMap[field].arrayValidationSchema(validationScheme);
        } else {
          result[field] = Yup.array(validationScheme);
        }
      } else if (formMap[field].validationSchema !== undefined) {
        result[field] = parseCalcType(formMap[field].validationSchema, context);
      }
    });

  return Yup.object()
    .shape(result);
};
