import React from 'react';
import Page, { PageProps } from '../../components/Page';
import FormikForm from '../../components/FormikForm';
import { show, update } from '../../api/endpoints/Api/Agencies';
import useLoadApiData from '../../hooks/useLoadApiData';
import { useParams } from 'react-router';
import { ContentView } from '../../components/ContentSwitcher';
import { Box, Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import UserAvatar from '../../components/UserAvatar';
import importTranslation from '../../hooks/ut';
import { userSelector } from '../../api/endpoints/Api/Users';
import { clientSelector } from '../../api/endpoints/Api/Clients';
import useAuth from '../../hooks/useAuth';
import { Redirect } from 'react-router-dom';
import useLoadApiSelectorData from '../../hooks/useLoadApiSelectorData';
import { DeleteAction, UpdateAgencyFormLayout, UpdateAgencyFormMap } from '../../resources/Agency';

const ShowAgencyPage = () => {
  const { currentDashboard } = useAuth();

  if (currentDashboard.type !== 'admin') {
    return (<Redirect to="/" />);
  }

  const { t } = importTranslation();
  const { id } = useParams<{ id: string }>();
  const {
    apiData,
    loadApiData,
  } = useLoadApiData(show)({ id });

  const {
    apiData: userList,
  } = useLoadApiSelectorData(userSelector)();

  const {
    apiData: clientList,
  } = useLoadApiSelectorData(clientSelector)();

  if (apiData === null) {
    return null;
  }

  const pageProps: PageProps = {
    pageHeaderProps: {
      title: 'Agency "{{name}}"',
      translationParams: { name: apiData.name },
      actions: [
        DeleteAction(),
      ],
      breadCrumbs: {
        items: [
          {
            label: 'Dashboard',
            to: '/dashboard',
          },
          {
            label: 'Agencies',
            to: '/agencies',
          },
          {
            label: 'Agency "{{name}}"',
            translationParams: {
              name: apiData.name,
            },
          },
        ],
      },
    },
    pageTabs: {
      tabs: [
        {
          value: 'Agency Information',
        },
        {
          value: 'Clients',
        },
        {
          value: 'Tenants',
        },
        {
          value: 'Users',
        },
      ],
    },
    title: 'Agency "{{name}}"',
    titleProps: { name: apiData.name },
    context: apiData,
  };

  return (
    <Page {...pageProps}>
      <ContentView value="Agency Information">
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <Card>
              <CardHeader
                title={t('Owned by "{{name}}"', { name: apiData.client.company_name })}
                sx={{ textAlign: 'center' }}
              />
              <CardContent>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                  }}
                >
                  <Box
                    sx={{
                      p: 1,
                      border: (theme) => `1px dashed ${theme.palette.divider}`,
                      borderRadius: '50%',
                    }}
                  >
                    <UserAvatar user={apiData} size="xlarge" />
                  </Box>
                  <Typography
                    color="textPrimary"
                    sx={{ mt: 1 }}
                    variant="subtitle2"
                  >
                    {apiData.name}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            lg={8}
            md={6}
            xl={9}
            xs={12}
          >
            <FormikForm
              endpoint={update} routeParams={{ id }}
              formMap={UpdateAgencyFormMap(userList ?? { values: [] }, clientList ?? { values: [] })}
              formLayout={UpdateAgencyFormLayout(userList ?? { values: [] }, clientList ?? { values: [] })}
              context={apiData}
              onSuccessMessage={'Agency "{{name}}" was updated successfully!'}
              onSuccessMessageParams={((response) => ({
                name: response.data.name,
              }))}
              onSuccess={() => loadApiData()}
            />
          </Grid>
        </Grid>
      </ContentView>
    </Page>
  );
};

export default ShowAgencyPage;
