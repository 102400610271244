import { CalcType, parseCalcType } from '../types/CalculatedType';
import { useTranslation } from 'react-i18next';

export const tp = <ContextType extends any = any, >(
  text: string,
  params: CalcType<Record<string, string>, ContextType>,
  context: ContextType,
) => {
  const { t } = useTranslation();

  return t(text, parseCalcType(params, context) ?? {});
};
