import { ExtractTranslationParams } from '../types/i18n';
import { useTranslation } from 'react-i18next';

const ut = <Text extends string, >(text: Text, params: ExtractTranslationParams<Text>) => {
  const { t } = useTranslation();

  return t(text, params);
};

export default ut;
