import { TableCell } from '@material-ui/core';
import { calculateProps } from '../../utils/propHelpers';
import { DataGridColumn } from './Column';
import type { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface ColumnHeaderProps<DataType> {
  children: {
    config: DataGridColumn<DataType>
  },
}

const ColumnHeader = <DataType, >(props: ColumnHeaderProps<DataType>) => {
  const { children } = props;
  const { t } = useTranslation();
  const { config } = children;

  const cellProps = calculateProps(config.headerProps, {
    align: config.headerAlign,
  });

  if (config.renderHeader !== undefined) {
    return config.renderHeader(cellProps);
  }

  let value: ReactNode = config.label !== '' ? t(String(config.label)) : '';
  if (config.renderHeaderValue !== undefined) {
    value = config.renderHeaderValue();
  }

  return (
    <TableCell {...cellProps}>
      {value}
    </TableCell>
  );
};

export default ColumnHeader;
