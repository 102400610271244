import { Checkbox, TableCell, TableHead as MUITableHead, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';
import { DataGridColumns } from './Column';
import ColumnHeader from './ColumnHeader';
import { ActionList } from '../../types/ActionType';

interface TableHeadProps<DataType> {
  children: {
    columns: DataGridColumns<DataType>,
    actions?: ActionList<DataType>,
    selectedAll: boolean,
    selectedSome: boolean,
    selectedAllChange: (event: ChangeEvent<HTMLInputElement>) => void,
  },
}

const TableHead = <T, >(props: TableHeadProps<T>) => {
  const {
    children: config,
  } = props;
  const { t } = useTranslation();

  let rowActions;
  if (config.actions !== undefined && config.actions.length > 0) {
    rowActions = (
      <TableCell
        align="right"
      >
        {t('Actions')}
      </TableCell>
    );
  }

  const cells = config.columns.filter((column) => column.hidden === undefined || column.hidden() === false)
    .map((column) => (
      <ColumnHeader key={String(column.field)}>
        {{
          config: column,
        }}
      </ColumnHeader>
    ));

  return (
    <MUITableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            checked={config.selectedAll}
            color="primary"
            indeterminate={config.selectedSome}
            onChange={config.selectedAllChange}
          />
        </TableCell>
        {cells}
        {rowActions}
      </TableRow>
    </MUITableHead>
  );
};

export default TableHead;
