import React from 'react';
import DataGridSwitch, { DataGridSwitchFilter } from './Switch';
import DataGridSelect, { DataGridSelectFilter } from './Select';

export type DataGridFilterType = 'select' | 'switch';

export interface BaseDataGridFilter {
  type: DataGridFilterType,
}

export type DataGridFilter = DataGridSelectFilter | DataGridSwitchFilter;

interface DataGridFilterProps {
  filter: DataGridFilter,
}

const DataGridFilterComponent = (props: DataGridFilterProps) => {
  const { filter } = props;

  switch (filter.type) {
    case 'select':
      return <DataGridSelect config={filter.config} />;
    case 'switch':
      return <DataGridSwitch config={filter.config} />;
    default:
      return null;
  }
};

export const renderDataGridFilters = (filters: DataGridFilter[]) => React.Children.toArray(filters.map((filter) => <DataGridFilterComponent filter={filter} />));

export default DataGridFilterComponent;
