import {
  ApiEndpointBodyType,
  ApiEndpointParamsType,
  ApiEndpointRouteParamsType,
  ApiEndpointTypeAny,
  GetResponseErrorViewType,
  GetResponseViewType,
  isResponseSuccess,
} from '../api/client';
import NProgress from 'nprogress';
import { JsonErrorView } from '../api/types/App/Response';
import { CalcType, parseCalcType } from '../types/CalculatedType';
import { toast } from 'react-hot-toast';
import i18next from 'i18next';
import { LocationDescriptor } from 'history';
import { history } from '../redux';

export type SimpleApiOptionsFor<T extends ApiEndpointTypeAny> = SimpleApiOptions<T>;

interface SimpleApiOptions<T extends ApiEndpointTypeAny> {
  showProgress?: boolean,
  onSuccess?: (data: GetResponseViewType<T>) => void,
  onError?: (errors: JsonErrorView<GetResponseErrorViewType<T>>[]) => void,
  onServerError?: (error) => void,
  onSuccessToastMessage?: CalcType<string, GetResponseViewType<T>>,
  onErrorToastMessage?: CalcType<string, GetResponseViewType<T>>,
  onSuccessRedirectTo?: (response: GetResponseViewType<T>) => LocationDescriptor,
  translationParams?: CalcType<Record<string, string>, GetResponseViewType<T>>,
}

const simpleApiCall = <T extends ApiEndpointTypeAny>(
  endpoint: T,
  options: SimpleApiOptions<T> = {},
) => async (
    routeParams: ApiEndpointRouteParamsType<T> = {},
    params: ApiEndpointParamsType<T> = {},
    body: ApiEndpointBodyType<T> = {},
  ) => {
    const t = i18next.t.bind(i18next);
    options.showProgress ??= true;

    try {
      if (options.showProgress === true) {
        NProgress.start();
      }

      const result = await endpoint(routeParams, params, body);
      console.log('Result', result);
      if (isResponseSuccess(result)) {
        if (options.onSuccessToastMessage !== undefined) {
          const msg = parseCalcType(options.onSuccessToastMessage, result.data);

          toast.success(t(msg, parseCalcType(options.translationParams, result.data)));
        }

        if (options.onSuccessRedirectTo !== undefined) {
          history.push(options.onSuccessRedirectTo(result.data));
        }

        options.onSuccess?.(result.data);
      } else {
        if (options.onErrorToastMessage !== undefined) {
          const msg = parseCalcType(options.onErrorToastMessage, result.data);

          toast.error(t(msg, parseCalcType(options.translationParams, result.data)));
        }

        options.onError?.(result.errors);
      }

      if (options.showProgress === true) {
        NProgress.done();
      }
    } catch (e) {
      if (e instanceof Error && e.message.includes('is not provided for route')) {
        throw e;
      }
      if (options.showProgress === true) {
        NProgress.done();
      }

      console.error(e);

      options.onServerError?.(e);
    }
  };

export default simpleApiCall;
