import React from 'react';
import useAuth from '../hooks/useAuth';
import { Redirect } from 'react-router-dom';
import Page, { PageProps } from '../components/Page';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Cancel, DeleteForever, DragIndicator } from '@material-ui/icons';
import Plus from '../icons/Plus';
import { ActionTypes } from '../types/ActionType';
import { UseDialogProps } from '../hooks/useDialog';
import simpleApiCall from '../hooks/simpleApiCall';
import { CategoryView } from '../api/types/App/View/Category';
import { create, list, remove, update } from '../api/endpoints/Api/Category';
import IconButtonAction from '../components/Action/IconButtonAction';
import useLoadApiData from '../hooks/useLoadApiData';
import PencilAltIcon from '../icons/PencilAlt';
import { RequestBodyFormMap } from '../types/FormTypes';
import { CreateCategoryRequestBody } from '../api/types/App/Request/Category';
import { FormLayoutBox } from '../types/FormLayoutType';
import FormikForm from '../components/FormikForm';
import { ApiEndpointType, NoParams } from '../api/client';

const removeCategoryAction = (reloadData: () => void): ActionTypes => ({
  type: 'confirm_click',
  label: 'Remove Category',
  variant: 'contained',
  color: 'error',
  icon: <DeleteForever fontSize="small" />,
  dialog: (): UseDialogProps<CategoryView> => ({
    title: 'Are you sure you want to delete this category and all it\'s sub-categories?',
    level: 'error',
    closeOnOutsideClick: true,
    onConfirm: (c, close) => simpleApiCall(remove, {
      onSuccessToastMessage: 'Category was deleted successfully',
      onSuccess: () => {
        close();

        reloadData();
      },
    })({
      id: String(c.id),
    }),
  }),
});

interface CategoryEditFormProps {
  category: CategoryView | null
  position?: number,
  parentId?: number | null,
  reloadData?: () => void,
  setShow: (value: boolean) => void,
}

const CategoryEditFormMap = (
  position?: number,
  parentId?: number | null,
): RequestBodyFormMap<CreateCategoryRequestBody, CategoryView> => ({
  english: {
    name: 'english',
    label: 'English',
    initialValue: '',
    formFieldProp: {
      size: 'small',
      fullWidth: true,
      autoFocus: true,
    },
  },
  japanese: {
    name: 'japanese',
    label: 'Japanese',
    initialValue: '',
    formFieldProp: {
      size: 'small',
      fullWidth: true,
    },
  },
  parent_id: {
    name: 'parent_id',
    label: 'Parent Id',
    // eslint-disable-next-line no-nested-ternary
    initialValue: (c) => (parentId !== undefined ? parentId : (c.parent_id !== null ? c.parent_id : null)),
  },
  position: {
    name: 'position',
    label: 'Position',
    initialValue: (c) => (position !== undefined ? position : c.position),
  },
});

const CancelEditCategoryAction = (setShow: (boolean) => void): ActionTypes<CategoryView> => ({
  type: 'click',
  label: 'Cancel',
  variant: 'contained',
  icon: <Cancel fontSize="small" />,
  onClick: () => () => setShow(false),
});

const CategoryEditFormLayout = (
  setShow: (boolean) => void,
  position?: number,
  parentId?: number | null,
): FormLayoutBox<CategoryView> => (
  {
    type: 'box',
    elements: [
      {
        type: 'row',
        gridProps: {
          spacing: 2,
        },
        elements: [
          {
            type: 'column',
            xs: 12,
            md: 7,
            elements: [
              {
                type: 'field',
                field: CategoryEditFormMap(position, parentId).english,
              },
            ],
          },
          {
            type: 'column',
            xs: 12,
            md: 4,
            elements: [
              {
                type: 'field',
                field: CategoryEditFormMap(position, parentId).japanese,
              },
            ],
          },
          {
            type: 'column',
            xs: 12,
            md: 1,
            sx: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            },
            elements: [
              {
                type: 'form_icon_button',
              },
              {
                type: 'actions',
                actionType: 'icon',
                actions: [
                  CancelEditCategoryAction(setShow),
                ],
              },
            ],
          },
        ],
      },
    ],
  });

const CategoryEdit = ({
  category,
  position,
  parentId,
  reloadData,
  setShow,
}: CategoryEditFormProps) => {
  let endpoint: ApiEndpointType<CategoryView, NoParams, CreateCategoryRequestBody, any, '/api/category' | '/api/category/{id}'>;
  if (!category) {
    endpoint = create;
  } else {
    endpoint = update;
  }

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      px: 2,
      py: 1,
      transition: (theme) => theme.transitions.create('background-color', {
        duration: theme.transitions.duration.shortest,
      }),
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: (theme) => theme.palette.action.hover,
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
      '& > form': {
        flexGrow: 1,
      },
    }}
    >
      <FormikForm
        endpoint={endpoint} formMap={CategoryEditFormMap(position, parentId)}
        formLayout={CategoryEditFormLayout(setShow, position, parentId)} context={category}
        onSuccessMessage="Category was saved successfully!"
        onSuccess={() => {
          reloadData();
          setShow(false);
        }}
        routeParams={(c) => (c !== null ? { id: c.id } : {})}
      />
    </Box>
  );
};

interface CategoryProps {
  category: CategoryView,
  reloadData?: () => void,
}

const Category = ({
  category,
  reloadData,
}: CategoryProps) => {
  const [showEdit, setShowEdit] = React.useState<boolean>(false);
  const [showAddNew, setShowAddNew] = React.useState<boolean>(false);

  const EditCategoryAction: ActionTypes<CategoryView> = {
    type: 'click',
    label: 'Edit',
    variant: 'contained',
    icon: <PencilAltIcon fontSize="small" />,
    onClick: () => () => setShowEdit(true),
  };

  const AddNewSubCategoryAction = (show: (boolean) => void): ActionTypes => ({
    type: 'click',
    label: 'Add new sub-category',
    icon: <Plus fontSize="small" />,
    onClick: () => () => show(true),
  });

  return (
    <Box
      sx={{
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        '&:last-of-type': {
          borderBottom: 0,
        },
      }}
    >
      {showEdit === false && (
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        px: 2,
        py: 1,
        transition: (theme) => theme.transitions.create('background-color', {
          duration: theme.transitions.duration.shortest,
        }),
        '&:hover': {
          textDecoration: 'none',
          backgroundColor: (theme) => theme.palette.action.hover,
          '@media (hover: none)': {
            backgroundColor: 'transparent',
          },
        },
      }}
      >
        <ListItemIcon>
          <DragIndicator fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          <Typography color="textPrimary">
            {category.english}
          </Typography>
          <Typography color="textSecondary">
            {category.japanese}
          </Typography>
        </ListItemText>
        <IconButtonAction
          context={category}
          action={AddNewSubCategoryAction(setShowAddNew)}
        />
        <IconButtonAction context={category} action={EditCategoryAction} />
        <IconButtonAction context={category} action={removeCategoryAction(reloadData)} />
      </Box>
      )}
      {showEdit === true && (
      <CategoryEdit category={category} setShow={setShowEdit} reloadData={reloadData} />
      )}
      {(category.children.length > 0 || showAddNew === true) && (
      <>
        <Divider />
        <Box
          sx={{ ml: 4 }}
        >
          {category.children.map((subCategory) => (
            <Category key={subCategory.id} category={subCategory} reloadData={reloadData} />
          ))}
          {showAddNew === true && (
          <Box sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            '&:last-of-type': {
              borderBottom: 0,
            },
          }}
          >
            <CategoryEdit
              category={null} setShow={setShowAddNew}
              position={category.children.length} parentId={category.id}
              reloadData={reloadData}
            />
          </Box>
          )}
        </Box>
      </>
      )}
    </Box>
  );
};

const AddNewCategoryAction = (show: (boolean) => void): ActionTypes => ({
  type: 'click',
  label: 'Add new category',
  icon: <Plus fontSize="small" />,
  onClick: () => () => show(true),
});

const CategoriesPage = () => {
  const { currentDashboard } = useAuth();
  const { t } = useTranslation();
  const [showNewEdit, setShowNewEdit] = React.useState<boolean>(false);

  if (currentDashboard.type !== 'admin') {
    return (<Redirect to="/" />);
  }

  const {
    apiData,
    loadApiData,
  } = useLoadApiData(list)();

  if (apiData === null) {
    return null;
  }

  const pageProps: PageProps = {
    pageHeaderProps: {
      title: 'Categories',
      translationParams: {},
      actions: [],
      breadCrumbs: {
        items: [
          {
            label: 'Dashboard',
            to: '/dashboard',
          },
          {
            label: 'Categories',
            to: '/categories',
          },
        ],
      },
    },
    title: 'Categories',
    compactWidth: 'md',
  };

  return (
    <Page {...pageProps}>
      <Card>
        <CardHeader
          title={t('Categories')} action={(
            <IconButtonAction context={{}} action={AddNewCategoryAction(setShowNewEdit)} />
        )}
        />
        <Box>
          {apiData.categories.map((category) => (
            <Category key={category.id} category={category} reloadData={loadApiData} />
          ))}

          {showNewEdit === true && (
          <Box sx={{
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            '&:last-of-type': {
              borderBottom: 0,
            },
          }}
          >
            <CategoryEdit
              category={null} setShow={setShowNewEdit}
              position={apiData.categories.length} parentId={null}
              reloadData={loadApiData}
            />
          </Box>
          )}
        </Box>
      </Card>
    </Page>
  );
};

export default CategoriesPage;
