import type { FC } from 'react';
import { Avatar, Box, ButtonBase } from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import { getAvatarColor } from '../../utils/colors';
import PopoverMenu from '../PopoverMenu';
import { AccountMenu } from '../../types/AccountMenu';

const AccountPopover: FC = () => {
  const { user } = useAuth();
  const { popover, buttonProps } = PopoverMenu(AccountMenu);

  return (
    <>
      <Box
        {...buttonProps}
        component={ButtonBase}
        sx={{
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Avatar
          sx={{
            ...getAvatarColor(user.apc),
            height: 32,
            width: 32,
          }}
        >
          {user.full_name[0]}
        </Avatar>
      </Box>
      {popover}
    </>
  );
};

export default AccountPopover;
