import React from 'react';
import Page, { PageProps } from '../../components/Page';
import FormikForm from '../../components/FormikForm';
import { show, update } from '../../api/endpoints/Api/Tenants/Users';
import {
  DeleteAction,
  EditTenantUserFormLayout,
  EditTenantUserFormMap,
} from '../../resources/TenantUser';
import useAuth from '../../hooks/useAuth';
import { Redirect } from 'react-router-dom';
import useLoadApiData from '../../hooks/useLoadApiData';
import { useParams } from 'react-router';
import { DashboardType } from '../../redux/slices/User';

const EditAffiliatePage = () => {
  const { currentDashboard } = useAuth();
  const { id } = useParams<{ id: string }>();

  if (currentDashboard.type !== DashboardType.UserTenantAdmin) {
    return (<Redirect to="/" />);
  }

  const { apiData: tenantUser } = useLoadApiData(show)({
    id,
    tenantId: currentDashboard.id,
  });

  if (!tenantUser) {
    return null;
  }

  const pageProps: PageProps = {
    context: tenantUser,
    pageHeaderProps: {
      title: 'Edit Affiliate "{{name}}"',
      translationParams: { name: tenantUser.user.full_name },
      actions: [
        DeleteAction('/affiliates'),
      ],
      breadCrumbs: {
        items: [
          {
            label: 'Dashboard',
            to: '/dashboard',
          },
          {
            label: 'Affiliates',
            to: '/affiliates',
          },
          {
            label: 'Edit Affiliate "{{name}}"',
            translationParams: {
              name: tenantUser.user.full_name,
            },
          },
        ],
      },
    },
    compactWidth: 'md',
    title: 'Edit Affiliate "{{name}}"',
    titleProps: { name: tenantUser.user.full_name },
  };

  return (
    <Page {...pageProps}>
      <FormikForm
        endpoint={update} formMap={EditTenantUserFormMap}
        formLayout={EditTenantUserFormLayout(true)} context={tenantUser}
        onSuccessMessage={'Affiliate "{{name}}" was updated successfully!'}
        onSuccessMessageParams={((response) => ({
          name: response.data.user.full_name,
        }))}
        onSuccessRedirectTo={(response) => `/affiliates/${response.data.id}`}
        routeParams={{
          tenantId: currentDashboard.id,
          id,
        }}
      />
    </Page>
  );
};
export default EditAffiliatePage;
