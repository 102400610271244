import * as React from 'react';
import { FieldProps, getIn } from 'formik';
import Quill from 'react-quill';
import QuillEditor from '../QuillEditor';
import { FormControl, FormHelperText } from '@material-ui/core';
import { OverridableStringUnion } from '@material-ui/types';
import { FormControlPropsColorOverrides } from '@material-ui/core/FormControl/FormControl';

export interface QuillProps extends FieldProps, Omit<React.ComponentProps<typeof Quill>, 'name' | 'value' | 'error'> {
  label: string,
  color?: OverridableStringUnion<'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
  FormControlPropsColorOverrides>;
  /**
   * If `true`, the label, input and helper text should be displayed in a disabled state.
   * @default false
   */
  disabled?: boolean;
  /**
   * If `true`, the label is displayed in an error state.
   * @default false
   */
  error?: boolean;
  /**
   * If `true`, the component will take up the full width of its container.
   * @default false
   */
  fullWidth?: boolean;
  /**
   * If `true`, the component is displayed in focused state.
   */
  focused?: boolean;
  /**
   * If `true`, the label is hidden.
   * This is used to increase density for a `FilledInput`.
   * Be sure to add `aria-label` to the `input` element.
   * @default false
   */
  hiddenLabel?: boolean;
  /**
   * If `dense` or `normal`, will adjust vertical spacing of this and contained components.
   * @default 'none'
   */
  margin?: 'dense' | 'normal' | 'none';
}

export function fieldToQuillField(
  {
    readOnly,
    field: {
      onBlur: fieldOnBlur,
      onChange: fieldOnChange,
      ...field
    },
    form: {
      isSubmitting,
      setFieldValue,
    },
    onChange,
    onBlur,
    ...props
  }: QuillProps,
): React.ComponentProps<typeof Quill> {
  return {
    readOnly: readOnly ?? isSubmitting,
    onBlur: (e, source, editor) => fieldOnBlur({
      target: {
        name: field.name,
        outerHTML: editor.getHTML(),
      },
    }),
    onChange: (value) => (value === '<p><br></p>' ? setFieldValue(field.name, '') : setFieldValue(field.name, value)),
    ...field,
    ...props,
  };
}

export function QuillField(
  {
    children,
    color,
    disabled,
    error,
    fullWidth,
    focused,
    hiddenLabel,
    margin,
    ...props
  }: QuillProps,
) {
  const {
    form,
    field,
    label,
  } = props;
  const fieldError = getIn(form.errors, field.name);
  const showError = getIn(form.touched, field.name) && !!fieldError;

  return (
    <FormControl
      color={color} disabled={disabled} error={showError} fullWidth={fullWidth}
      focused={focused} hiddenLabel={hiddenLabel} margin={margin}
    >
      <QuillEditor
        value={field.value}
        error={showError}
        {...fieldToQuillField(props)}
      />
      {showError && (
        <FormHelperText error>
          {`${fieldError}`.replace(field.name, label)}
        </FormHelperText>
      )}
    </FormControl>
  );
}

QuillField.displayName = 'FormikQuillField';
