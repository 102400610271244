import store from 'store/dist/store.legacy';

const authTokenKey = 'a3f24f4a11fd0135627ddd8ab9f40cbe';

export function hasToken(): boolean {
  return store.get(authTokenKey, false) !== false;
}

export function getToken(): string {
  return store.get(authTokenKey);
}

export function setToken(token: string): void {
  store.set(authTokenKey, token);
}

export function removeToken(): void {
  store.remove(authTokenKey);
}
