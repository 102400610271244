import React, { ReactNode } from 'react';
import type { Theme } from '@material-ui/core';

export interface ThemeContextValue {
  theme: Theme
}

interface ThemeProviderProps {
  children: ReactNode;
  theme: Theme;
}

const ThemeContext = React.createContext<ThemeContextValue>({
  theme: null,
});

export const ThemeProvider = (props: ThemeProviderProps) => {
  const {
    children,
    theme,
  } = props;

  return (
    <ThemeContext.Provider
      value={{
        theme,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContext;
