import React from 'react';
import importTranslation from '../../hooks/ut';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  timestampFormat,
  timestampFormatDistanceToNowStrict,
} from '../../utils/timestampUtils';
import UserAvatar from '../UserAvatar';
import { fromUnixTime, isFuture, isPast } from 'date-fns';
import i18n from 'i18next';
import TimestampCell from '../TimestampCell';
import { FullTenantContractView } from '../../api/types/App/View/TenantContract';
import copy from 'copy-to-clipboard';
import { ContentCopy } from '@material-ui/icons';
import LinearProgressWithLabel from '../LinearProgressWithLabel';
import useAuth from '../../hooks/useAuth';
import { DashboardType } from '../../redux/slices/User';
import { getProgress } from '../../resources/TenantContract';

interface SettingsProps {
  contract: FullTenantContractView;
}

const ContractSettings = (props: SettingsProps) => {
  const { contract } = props;
  const { currentDashboard } = useAuth();
  const { t } = importTranslation();
  return (
    <Box sx={{ mt: 2 }}>
      <Card>
        <CardHeader
          avatar={
            (currentDashboard.type === DashboardType.UserTenantAdmin &&
              ((contract.destination && (
                <UserAvatar user={contract.tenant} size="large" />
              )) ||
                (!contract.destination && (
                  <UserAvatar
                    user={{
                      id: 'ddd',
                      name: '-',
                      apc: null,
                    }}
                    size="large"
                  />
                )))) ||
            (currentDashboard.type === DashboardType.TenantAdmin && (
              <UserAvatar user={contract.tenant} size="large" />
            ))
          }
          disableTypography
          style={{ paddingBottom: 0 }}
          title={
            (currentDashboard.type === DashboardType.UserTenantAdmin &&
              ((contract.destination && (
                <Typography color="textPrimary" display="block">
                  {contract.destination.client.company_name}
                </Typography>
              )) ||
                (!contract.destination && (
                  <Typography color="textPrimary" display="block">
                    {t('Unknown')}
                  </Typography>
                )))) ||
            (currentDashboard.type === DashboardType.TenantAdmin && (
              <Typography color="textPrimary" display="block">
                {contract.tenant.client.company_name}
              </Typography>
            ))
          }
        />
        <CardContent sx={{ pt: 0 }}>
          <List>
            <ListItem
              disableGutters
              divider
              sx={{
                justifyContent: 'space-between',
                padding: 2,
              }}
            >
              <Typography color="textPrimary" variant="subtitle2">
                {t('Code')}
              </Typography>
              <Box sx={{ color: 'textSecondary' }}>
                <TextField
                  type="text"
                  size="small"
                  fullWidth
                  margin="dense"
                  onFocus={(e) => {
                    const { target } = e;
                    e.preventDefault();
                    target.focus();
                    target.setSelectionRange(0, contract.contract_code.length);
                  }}
                  value={contract.contract_code}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{
                          mr: -1,
                        }}
                      >
                        <Tooltip title={t('Copy')}>
                          <IconButton
                            onClick={() => {
                              copy(contract.contract_code, {
                                format: 'text/plain',
                              });
                            }}
                          >
                            <ContentCopy />
                          </IconButton>
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </ListItem>
            {(contract.destination?.id === currentDashboard.id ||
              contract.tenant.id === currentDashboard.id) &&
              contract.accepted_at !== null && (
                <ListItem
                  disableGutters
                  divider
                  sx={{
                    justifyContent: 'space-between',
                    padding: 2,
                  }}
                >
                  <Typography color="textPrimary" variant="subtitle2">
                    {t('Progress')}
                  </Typography>
                  <Box
                    sx={{
                      color: 'textSecondary',
                      flexGrow: 1,
                      maxWidth: '250px',
                    }}
                  >
                    <LinearProgressWithLabel
                      color="info"
                      value={getProgress(contract)}
                    />
                  </Box>
                </ListItem>
              )}
            {(contract.destination?.id === currentDashboard.id ||
              contract.tenant.id === currentDashboard.id) &&
              contract.accepted_at !== null && (
                <ListItem
                  disableGutters
                  divider
                  sx={{
                    justifyContent: 'space-between',
                    padding: 2,
                  }}
                >
                  <Typography color="textPrimary" variant="subtitle2">
                    {t('Accepted At')}
                  </Typography>
                  <Box sx={{ color: 'textSecondary' }}>
                    <TimestampCell
                      value={contract.accepted_at}
                      author={contract.accepted_by}
                    />
                  </Box>
                </ListItem>
              )}
            <ListItem
              disableGutters
              divider
              sx={{
                justifyContent: 'space-between',
                padding: 2,
              }}
            >
              <Typography color="textPrimary" variant="subtitle2">
                {t('Created At')}
              </Typography>
              <Box sx={{ color: 'textSecondary' }}>
                <TimestampCell
                  value={contract.created_at}
                  author={contract.created_by}
                />
              </Box>
            </ListItem>

            {contract.tenant.id === currentDashboard.id &&
              contract.updated_at !== null && (
                <ListItem
                  disableGutters
                  divider
                  sx={{
                    justifyContent: 'space-between',
                    padding: 2,
                  }}
                >
                  <Typography color="textPrimary" variant="subtitle2">
                    {t('Updated At')}
                  </Typography>
                  <Box sx={{ color: 'textSecondary' }}>
                    <TimestampCell
                      value={contract.updated_at}
                      author={contract.updated_by}
                    />
                  </Box>
                </ListItem>
              )}
            <ListItem
              disableGutters
              divider
              sx={{
                justifyContent: 'space-between',
                padding: 2,
              }}
            >
              <Typography color="textPrimary" variant="subtitle2">
                {t('Starts At')}
              </Typography>
              <Box>
                <Typography color="textSecondary" variant="body2">
                  {timestampFormat(contract.start_at)}
                </Typography>
                {isFuture(fromUnixTime(contract.start_at)) && (
                  <Typography variant="subtitle2">
                    {i18n.t('Starts in {{time}}', {
                      time: timestampFormatDistanceToNowStrict(
                        contract.start_at
                      ),
                    })}
                  </Typography>
                )}
                {isPast(fromUnixTime(contract.start_at)) && (
                  <Typography variant="subtitle2">
                    {i18n.t('Started {{time}} ago', {
                      time: timestampFormatDistanceToNowStrict(
                        contract.start_at
                      ),
                    })}
                  </Typography>
                )}
              </Box>
            </ListItem>
          </List>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ContractSettings;
