import React from 'react';
import { Redirect } from 'react-router-dom';
import PlusIcon from '../../icons/Plus';
import useAuth from '../../hooks/useAuth';
import Page, { PageProps } from '../../components/Page';
import { list as listUsers } from '../../api/endpoints/Api/Tenants/Users';
import { ListTenantUserActions, ListTenantUserColumns } from '../../resources/TenantUser';
import ListResourceTable from '../../components/ListResourceTable';
import { DashboardType } from '../../redux/slices/User';

const ListAffiliatesPage = () => {
  const { currentDashboard } = useAuth();

  if (currentDashboard.type !== DashboardType.UserTenantAdmin) {
    return (<Redirect to="/" />);
  }

  const roles = ['ROLE_PARTICIPANT'];

  const pageProps: PageProps = {
    pageHeaderProps: {
      title: 'Affiliates',
      translationParams: {},
      actions: [
        {
          type: 'route_link',
          to: () => '/affiliates/create',
          label: 'Add new',
          icon: <PlusIcon fontSize="small" />,
          variant: 'contained',
          color: 'primary',
        },
      ],
      breadCrumbs: {
        items: [
          {
            label: 'Dashboard',
            to: '/dashboard',
          },
          {
            label: 'Affiliates',
            to: '/affiliates',
          },
        ],
      },
    },
    title: 'Affiliates',
  };

  return (
    <Page {...pageProps}>
      <ListResourceTable
        endpoint={listUsers} field="tenant_users" routeParams={{ tenantId: currentDashboard.id }}
        params={{ roles }} actions={ListTenantUserActions('/affiliates')}
        columns={ListTenantUserColumns('/affiliates')}
      />
    </Page>
  );
};

export default ListAffiliatesPage;
