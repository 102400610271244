import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthGuard from '../components/AuthGuard';
import ChooseDashboard from '../pages/ChooseDashboard';
import LoginPage from '../pages/Security/Login';
import Dashboard from '../pages/Dashboard';
import InviteUserPage from '../pages/Security/InviteUser';
import ErrorNotFoundPage from '../pages/ErrorNotFound';

export default function routes() {
  return (
    <Switch>
      <Route exact path="/login">
        <LoginPage />
      </Route>
      <Route exact path="/user/invite/:inviteCode">
        <InviteUserPage />
      </Route>
      <AuthGuard>
        <Route exact path="/choose">
          <ChooseDashboard />
        </Route>
        <Route path="/">
          <Dashboard />
        </Route>
      </AuthGuard>
      <Route>
        <ErrorNotFoundPage />
      </Route>
    </Switch>
  );
}
