import ChartSquareBarIcon from '../icons/ChartSquareBar';
import ChartPieIcon from '../icons/ChartPie';
import FolderOpenIcon from '../icons/FolderOpen';
import ReceiptIcon from '@material-ui/icons/Receipt';
import BriefcaseIcon from '../icons/Briefcase';
import React, { ReactNode } from 'react';
import { DashboardType, DashboardTypeValues } from '../redux/slices/User';
import {
  AccountBalance,
  Business,
  Business as BusinessIcon,
  Category,
  Description,
  Group,
  LiveHelp,
  ManageAccounts as ManageAccountsIcon,
  Person,
  SupportAgent,
  Web,
} from '@material-ui/icons';

const admin = [
  {
    title: 'Management',
    items: [
      {
        title: 'Clients',
        path: '/clients',
        exact: false,
        strict: false,
        icon: <Business fontSize="small" />,
      },
      {
        title: 'Agencies',
        path: '/agencies',
        exact: false,
        strict: false,
        icon: <SupportAgent fontSize="small" />,
      },
      {
        title: 'Tenants',
        path: '/tenants',
        exact: false,
        strict: false,
        icon: <AccountBalance fontSize="small" />,
      },
      {
        title: 'Categories',
        path: '/categories',
        exact: false,
        strict: false,
        icon: <Category fontSize="small" />,
      },
      {
        title: 'Users',
        path: '/users',
        exact: false,
        strict: false,
        icon: <Person fontSize="small" />,
      },
    ],
  },
];
const agencyAdmin = [
  {
    title: 'Agency Management',
    items: [
      {
        title: 'Clients',
        path: '/clients',
        exact: false,
        strict: false,
        icon: <Business fontSize="small" />,
      },
      {
        title: 'Tenants',
        path: '/tenants',
        exact: false,
        strict: false,
        icon: <AccountBalance fontSize="small" />,
      },
      {
        title: 'Users',
        path: '/users',
        exact: false,
        strict: false,
        icon: <Person fontSize="small" />,
      },
    ],
  },
];

const superAdmin = [...admin];

const tenantAdmin = [
  {
    title: 'General',
    items: [
      {
        title: 'Overview',
        path: '/dashboard',
        icon: <ChartSquareBarIcon fontSize="small" />,
      },
      {
        title: 'Analytics',
        path: '/dashboard/analytics',
        icon: <ChartPieIcon fontSize="small" />,
      },
      // {
      //   title: 'Finance',
      //   path: '/dashboard/finance',
      //   icon: <ShoppingBagIcon fontSize="small" />,
      // },
      // {
      //   title: 'Company',
      //   path: '/company',
      //   icon: <Business fontSize="small" color="error" />,
      // },
    ],
  },
  {
    title: 'Campaigns',
    items: [
      {
        title: 'Campaigns',
        path: '/campaigns',
        icon: <BriefcaseIcon fontSize="small" />,
        exact: false,
        strict: false,
      },
      {
        title: 'Commissions',
        icon: <FolderOpenIcon fontSize="small" />,
        path: '/commissions',
        exact: false,
        strict: false,
      },
      {
        title: 'Commission Invoices',
        path: '/commission/invoices',
        icon: <ReceiptIcon fontSize="small" />,
        exact: false,
        strict: false,
      },
    ],
  },
  {
    title: 'Connections',
    items: [
      {
        title: 'Contracts',
        path: '/connections/contracts',
        icon: <BusinessIcon fontSize="small" />,
        exact: false,
        strict: false,
      },
      {
        title: 'Invoices',
        path: '/connections/invoices',
        icon: <ReceiptIcon fontSize="small" />,
        exact: false,
        strict: false,
      },
    ],
  },
  {
    title: 'Management',
    items: [
      {
        title: 'Trackers',
        path: '/tenant/trackers',
        exact: false,
        strict: false,
        icon: <Web fontSize="small" />,
      },
      {
        title: 'Users',
        path: '/tenant/users',
        exact: false,
        strict: false,
        icon: <Person fontSize="small" />,
      },
      {
        title: 'User Groups',
        path: '/tenant/user/groups',
        exact: false,
        strict: false,
        icon: <Group fontSize="small" />,
      },
      {
        title: 'FAQ',
        path: '/tenant/faq',
        exact: false,
        strict: false,
        icon: <LiveHelp fontSize="small" />,
      },
      {
        title: 'Terms & Conditions',
        path: '/tenant/tac',
        exact: false,
        strict: false,
        icon: <Description fontSize="small" />,
      },
    ],
  },
];

const userTenantAdmin = [
  {
    title: 'General',
    items: [
      {
        title: 'Overview',
        path: '/dashboard',
        icon: <ChartSquareBarIcon fontSize="small" />,
      },
      {
        title: 'Analytics',
        path: '/dashboard/analytics',
        icon: <ChartPieIcon fontSize="small" />,
      },
    ],
  },
  {
    title: 'Connections',
    items: [
      {
        title: 'Contracts',
        path: '/connections/contracts',
        icon: <BusinessIcon fontSize="small" />,
        exact: false,
        strict: false,
      },
      {
        title: 'Invoices',
        path: '/connections/invoices',
        icon: <ReceiptIcon fontSize="small" />,
        exact: false,
        strict: false,
      },
    ],
  },
  {
    title: 'Management',
    items: [
      {
        title: 'Users',
        path: '/tenant/users',
        exact: false,
        strict: false,
        icon: <ManageAccountsIcon fontSize="small" />,
      },
      {
        title: 'Affiliates',
        path: '/affiliates',
        exact: false,
        strict: false,
        icon: <Person fontSize="small" />,
      },
      {
        title: 'Affiliate Groups',
        path: '/affiliate/groups',
        exact: false,
        strict: false,
        icon: <Group fontSize="small" />,
      },
    ],
  },
];

const tenantUser = [
  {
    title: 'General',
    items: [
      {
        title: 'Overview',
        path: '/dashboard',
        icon: <ChartSquareBarIcon fontSize="small" />,
      },
      {
        title: 'Analytics',
        path: '/dashboard/analytics',
        icon: <ChartPieIcon fontSize="small" />,
      },
    ],
  },
  {
    title: 'Campaigns',
    items: [
      {
        title: 'Available Campaigns',
        path: '/campaigns',
        exact: false,
        strict: false,
        icon: <BriefcaseIcon fontSize="small" />,
      },
      {
        title: 'Commission Invoices',
        path: '/commission/invoices',
        icon: <ReceiptIcon fontSize="small" />,
        exact: false,
        strict: false,
      },
    ],
  },
  {
    title: 'Management',
    items: [
      {
        title: 'Profile',
        path: '/user/account',
        icon: <Person fontSize="small" />,
      },
      {
        title: 'FAQ',
        path: '/tenant/faq',
        exact: false,
        strict: false,
        icon: <LiveHelp fontSize="small" />,
      },
      {
        title: 'Terms & Conditions',
        path: '/tenant/tac',
        exact: false,
        strict: false,
        icon: <Description fontSize="small" />,
      },
    ],
  },
];

interface SectionItemChildren {
  title: string;
  path: string;
}

interface SectionItem {
  title: string;
  path: string | null;
  icon?: ReactNode | null;
  children?: SectionItemChildren[];
}

interface Section {
  title: string;
  items: SectionItem[];
}

const sections: Record<DashboardTypeValues, Section[]> = {
  [DashboardType.Admin]: admin,
  [DashboardType.SuperAdmin]: superAdmin,
  [DashboardType.AgencyAdmin]: agencyAdmin,
  [DashboardType.TenantAdmin]: tenantAdmin,
  [DashboardType.UserTenantAdmin]: userTenantAdmin,
  [DashboardType.TenantUser]: tenantUser,
  [DashboardType.UserTenantUser]: tenantUser,
};

export default sections;
