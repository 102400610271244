import { ListSelectorView } from '../../../../types/App/View/Common';
import { apiEndpoint, NoBody, NoParams, StandardErrors } from '../../../../client';
import { ListTenantContractsRequestParams, CreateTenantContractRequestBody, FindContractRequestBody } from '../../../../types/App/Request/TenantContract';
import { ListTenantContractsView, FullTenantContractView } from '../../../../types/App/View/TenantContract';
import { SuccessView } from '../../../../types/App/View';
import { ListTenantUsersRequestParams } from '../../../../types/App/Request/TenantUser';
import { ListTenantUserView } from '../../../../types/App/View/TenantUser';

export const activeContractSelector = apiEndpoint<ListSelectorView, NoParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/contracts/selector');

export const list = apiEndpoint<ListTenantContractsView, ListTenantContractsRequestParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/contracts');

export const create = apiEndpoint<FullTenantContractView, NoParams, CreateTenantContractRequestBody, StandardErrors>('POST')('/api/tenants/{tenantId}/contracts');

export const show = apiEndpoint<FullTenantContractView, NoParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/contracts/{id}');

export const remove = apiEndpoint<SuccessView, NoParams, NoBody, StandardErrors>('DELETE')('/api/tenants/{tenantId}/contracts/{id}');

export const find = apiEndpoint<FullTenantContractView, NoParams, FindContractRequestBody, StandardErrors>('POST')('/api/tenants/{tenantId}/contracts/find');

export const acceptContract = apiEndpoint<SuccessView, NoParams, NoBody, StandardErrors>('POST')('/api/tenants/{tenantId}/contracts/{id}/accept');

export const users = apiEndpoint<ListTenantUserView, ListTenantUsersRequestParams, NoBody, StandardErrors>('GET')('/api/tenants/{tenantId}/contracts/{id}/users');
