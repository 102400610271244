import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography,
} from '@material-ui/core';

interface LinearProgressWithLabelProps extends LinearProgressProps {
  value: number;
  hideLabel?: boolean;
}

const LinearProgressWithLabel = (props: LinearProgressWithLabelProps) => {
  const { hideLabel = false, value, color, ...rest } = props;
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          mr: 1,
        }}
      >
        <LinearProgress
          variant="determinate"
          /* eslint-disable-next-line no-nested-ternary */
          color={value <= 100 ? (value === 100 ? 'success' : color) : 'error'}
          value={Math.min(value, 100)}
          {...rest}
        />
      </Box>
      {!hideLabel && (
        <Box sx={{ minWidth: 35 }}>
          <Typography
            variant="body2"
            color={value <= 100 ? 'text.secondary' : 'error'}
          >
            {`${Math.round(value)}%`}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default LinearProgressWithLabel;
