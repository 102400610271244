import React from 'react';
import { MapValue } from '../types/MapValues';
import {
  Box,
  Button,
  ButtonProps,
  Divider,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuItemProps,
  Popover,
  PopoverProps,
  Typography,
  TypographyProps,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import usePopover from '../hooks/usePopover';
import { useTranslation } from 'react-i18next';

export enum PopoverMenuItemType {
  ISimple = 'simple',
  IButton = 'button',
  IDivider = 'divider',
  IRouterLink = 'routerLink',
  ILink = 'link',
  IElement = 'element',
  IItems = 'items',
}

export interface BasePopoverMenuItem extends Partial<MapValue> {
  renderMenuItem?: (props: MenuItemProps) => React.ReactNode,
  renderItemText?: (props: TypographyProps) => React.ReactNode,
  renderItemIcon?: (icon: React.ReactNode) => React.ReactNode,
  type: PopoverMenuItemType,
  margin?: number,
  padding?: number,
}

export interface SimplePopoverMenuItem extends BasePopoverMenuItem {
  type: PopoverMenuItemType.ISimple,
  onClick?: () => void,
}

export interface RouterLinkPopoverMenuItem extends BasePopoverMenuItem {
  type: PopoverMenuItemType.IRouterLink,
  to: string,
}

export interface LinkPopoverMenuItem extends BasePopoverMenuItem {
  type: PopoverMenuItemType.ILink,
  href: string,
  target?: string
}

export interface ButtonPopoverMenuItem extends BasePopoverMenuItem {
  type: PopoverMenuItemType.IButton,
  onClick: () => void,
  color?: ButtonProps['color'],
  variant?: ButtonProps['variant'],
}

export interface ElementPopoverMenuItem extends Partial<BasePopoverMenuItem> {
  type: PopoverMenuItemType.IElement,
  render: (item: ElementPopoverMenuItem) => React.ReactNode,
}

export interface ItemsPopoverMenuItem extends Partial<BasePopoverMenuItem> {
  type: PopoverMenuItemType.IItems,
  items: (menuItem: ItemsPopoverMenuItem) => PopoverMenuItems[],
}

export interface DividerPopoverMenuItem extends Partial<BasePopoverMenuItem> {
  type: PopoverMenuItemType.IDivider
}

export type PopoverMenuItem<T extends PopoverMenuItems> = T['type'] extends PopoverMenuItemType.IRouterLink ?
  RouterLinkPopoverMenuItem : T['type'] extends PopoverMenuItemType.ISimple ?
    SimplePopoverMenuItem : T['type'] extends PopoverMenuItemType.ILink ?
      LinkPopoverMenuItem : T['type'] extends PopoverMenuItemType.IButton ?
        ButtonPopoverMenuItem : T['type'] extends PopoverMenuItemType.IElement ?
          ElementPopoverMenuItem : T['type'] extends PopoverMenuItemType.IItems ?
            ItemsPopoverMenuItem : T['type'] extends PopoverMenuItemType.IDivider ?
              DividerPopoverMenuItem : never;

export type PopoverMenuItems =
  RouterLinkPopoverMenuItem
  | SimplePopoverMenuItem
  | LinkPopoverMenuItem
  | ButtonPopoverMenuItem
  | ElementPopoverMenuItem
  | ItemsPopoverMenuItem
  | DividerPopoverMenuItem;

export const PopoverMenuDivider: DividerPopoverMenuItem = {
  type: PopoverMenuItemType.IDivider,
};

const PopoverMenu = (
  items: PopoverMenuItems[],
  props?: PopoverProps,
) => {
  const { t } = useTranslation();
  const {
    popoverProps,
    buttonProps,
    setState,
    openState,
    open,
    close,
    toggle,
  } = usePopover();

  const itemsEl = <E extends PopoverMenuItems, >(i: PopoverMenuItem<E>[]) => i.map((
    item,
    index,
  ) => {
    const menuItemProps: MenuItemProps = {
      key: `${item.type}-${item.value ?? index}`,
    };

    if (item.renderMenuItem !== undefined) {
      return item.renderMenuItem(menuItemProps);
    }

    let icon;
    if (item.icon !== undefined) {
      if (item.renderItemIcon !== undefined) {
        icon = item.renderItemIcon(item.icon);
      } else {
        icon = <ListItemIcon>{item.icon}</ListItemIcon>;
      }
    }

    const labelProps: TypographyProps = {
      color: 'textPrimary',
      variant: 'subtitle2',
    };

    let label;
    if (item.renderItemText !== undefined) {
      label = item.renderItemText(labelProps);
    } else {
      label = (
        <ListItemText
          primary={(
            <Typography {...labelProps}>
              {t(item.label)}
            </Typography>
          )}
        />
      );
    }

    const el = () => {
      switch (item.type) {
        case PopoverMenuItemType.IDivider:
          return <Divider />;
        case PopoverMenuItemType.IRouterLink:
          return (
            <MenuItem
              component={RouterLink}
              onClick={close}
              to={item.to}
            >
              {icon}
              {label}
            </MenuItem>
          );
        case PopoverMenuItemType.ISimple:
          return (
            <MenuItem
              onClick={item.onClick}
            >
              {icon}
              {label}
            </MenuItem>
          );
        case PopoverMenuItemType.ILink:
          return (
            <MenuItem
              component={Link}
              href={item.href}
              target={item.target}
            >
              {icon}
              {label}
            </MenuItem>
          );
        case PopoverMenuItemType.IButton:
          return (
            <Button color={item.color} variant={item.variant} onClick={item.onClick} fullWidth>
              {t(item.label)}
            </Button>
          );
        case PopoverMenuItemType.IElement:
          return item.render(item);
        case PopoverMenuItemType.IItems:
          return itemsEl(item.items(item) as PopoverMenuItem<PopoverMenuItems>);
        default:
          return null;
      }
    };

    return (
      <Box sx={{ my: item.margin, p: item.padding }} key={menuItemProps.key}>
        {el()}
      </Box>
    );
  });

  const popover = (
    <Popover
      {...({
        ...popoverProps,
        props,
      })}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      keepMounted
      PaperProps={{
        sx: { width: 240 },
      }}
    >
      {itemsEl(items as PopoverMenuItem<PopoverMenuItems>)}
    </Popover>
  );

  return {
    popover,
    buttonProps,
    setState,
    openState,
    open,
    close,
    toggle,
  };
};

export default PopoverMenu;
