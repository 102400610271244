import React from 'react';
import type { Theme } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import type { SxProps } from '@material-ui/system';

interface LogoProps {
  sx?: SxProps<Theme>;
}

const LogoRoot = styled('svg')``;

const Logo = (props: LogoProps) => (
  <LogoRoot
    height="100"
    version="1.1"
    viewBox="0 0 618.08 206.8"
    width="300"
    {...props}
  >
    <title>MKP</title>
    <path
      d="M0,0A102,102,0,0,1,74.39,32.23,105.83,105.83,0,0,1,97.64,71.82a91.87,91.87,0,0,1,18.91-2.27c-.07,4.61-.07,9.23,0,13.84a136.42,136.42,0,0,0-15.59,2.38,177.2,177.2,0,0,1,1.2,20.07,127.49,127.49,0,0,1,14.3-3q.06,7,.1,13.92c-3.53,1-7.18,1.83-10.35,3.74a19.21,19.21,0,0,0-.93,31.88c3.32,2.28,7.35,3.08,11.18,4.1.06,4.66.08,9.31.09,14-5.51-.69-11.23-1.37-16.1-4.24-5.12-2.79-9-7.22-12.76-11.57-3.77,5.57-7.8,10.94-12,16.19,5.85,6.07,12.41,11.73,20.36,14.83,6.48,2.77,13.51,3.71,20.45,4.5q0,6.94,0,13.88A68,68,0,0,1,76.12,190.5a102.21,102.21,0,0,1-10.69-9.8c-6.92,4.82-13.81,9.81-21.48,13.41A102.09,102.09,0,0,1,0,203.94c.05-4.61.06-9.23.06-13.85,13.48-.83,27.16-3.19,39.21-9.59a79.36,79.36,0,0,0,17.76-12.11,106,106,0,0,1-7.16-20.81c-6.59,6.1-13.59,12-21.87,15.64-8.7,4-18.27,5.6-27.76,6.33C.12,164.8.11,160,0,155.29c6.5-.68,13.15-1.24,19.25-3.78a54.09,54.09,0,0,0,33.39-41.73C55.25,93.31,49.14,76,37.32,64.3A56.11,56.11,0,0,0,16.94,51.64c-5.41-2-11.19-2.46-16.89-3q0-7,0-14c6.67.37,13.41,1,19.8,3C38.11,43.13,53.49,57,61.35,74.28c2.43,5.4,3.86,11.18,5.36,16.9A89.58,89.58,0,0,1,84.46,77.56a101.73,101.73,0,0,0-8.25-19.11,88.11,88.11,0,0,0-53-41.08c-7.52-2.16-15.33-3-23.11-3.46Q.09,7,0,0ZM63.39,138c0,6.74,1.92,13.28,4.36,19.5C83,139.88,89.89,115.59,87.28,92.56A53.47,53.47,0,0,0,63.39,138Z"
      fill="#e7ae29"
    />
    <path
      d="M0,82.21c.06-4.68.09-9.36.27-14,5.86,1,12,1.74,17.09,5.06,8.26,5.25,14.75,14,15.81,23.87.69,6.34.2,13-2.54,18.82a34.59,34.59,0,0,1-16.44,16.51c-4.45,2-9.33,2.71-14.11,3.52,0-4.71,0-9.42.05-14.13,3.64-.93,7.46-1.67,10.65-3.77,5.23-3.56,8.82-9.7,8.63-16.11.16-6.42-3.5-12.5-8.72-16.06C7.52,83.81,3.68,83.14,0,82.21Z"
      fill="#e7ae29"
    />
    <path
      d="M163,203.74c.21-44.42-.09-88.85.15-133.27,15.51.11,31,0,46.53,0,11.5.06,23.45,4.5,30.66,13.76,7.48,10,8.91,24.07,4.14,35.54-1.89,4.76-5.48,8.52-9.08,12,4.3,2.67,8.63,5.56,11.6,9.75,5.89,7.73,6.82,17.89,6.2,27.28a35.8,35.8,0,0,1-11,24.61c-7.63,7.07-18.19,10.32-28.47,10.28Q188.4,203.81,163,203.74ZM182.49,87.82c-.06,9.38,0,18.75-.1,28.13a63,63,0,0,0,.46,8.79c6.24.81,12.55.31,18.82.44,5.79-.07,11.89.41,17.31-2,4.72-2.18,7-7.42,7.7-12.27,1.06-6.32.13-13.65-4.67-18.29-4.33-4.09-10.6-4.75-16.28-4.84C198,87.72,190.24,87.86,182.49,87.82Zm0,54.44q-.12,22.2,0,44.42c10.64-.21,21.29.34,31.92-.27a19.47,19.47,0,0,0,16.95-11.77,28.66,28.66,0,0,0,.56-18.93,18.46,18.46,0,0,0-12.81-12.23c-4.69-1.34-9.62-1.23-14.45-1.24Q193.59,142.32,182.51,142.26Z"
      fill="#fff"
    />
    <path
      d="M382.09,71a100.6,100.6,0,0,1,18.2.28c-.18,44.17.11,88.34-.15,132.51-6,0-12.05,0-18.07,0Q382.31,137.35,382.09,71Z"
      fill="#fff"
    />
    <path
      d="M421,70.72c6.06,0,12.12-.09,18.18,0,.27,29.74,0,59.49.12,89.23-.05,14.61.12,29.23-.1,43.84-6.08,0-12.17,0-18.25,0C421,159.41,420.92,115.07,421,70.72Z"
      fill="#fff"
    />
    <path
      d="M283.16,118.41c14-12.34,36.45-14.54,52.09-4.07,1.93,1.26,3.82,2.58,5.75,3.84.07-3.33.09-6.66.09-10q9.09,0,18.19,0-.19,47.74,0,95.51c-6.06.06-12.12,0-18.18.08,0-2.44-.09-4.88-.17-7.32-4.84,3.14-9.55,6.66-15.07,8.5-4.88,1.77-10.14,1.91-15.26,1.77-13.76-.19-27-7.73-34.76-18.95-6.42-9.25-9.22-20.69-8.88-31.87C267,142,272.39,127.61,283.16,118.41Zm23,7.11c-9.95,2.51-17.21,11.3-19.78,21-3.23,12.21-1,26.45,8,35.74,9.24,9.58,25.91,9.94,35.91,1.33,5.64-4.72,8.87-11.74,10.2-18.86,1.8-9.87,0-20.56-5.73-28.89A27,27,0,0,0,306.15,125.52Z"
      fill="#fff"
    />
    <path
      d="M482.55,110.72c12.72-4.82,27.62-4,39.42,3a45.7,45.7,0,0,1,20.45,27.73c1.92,6.91,1.82,14.15,1.48,21.25-24,.19-48-.11-72,.16,1.11,6.3,3.07,12.68,7.36,17.58a23.53,23.53,0,0,0,15.72,9c8.06,1,16.7-.11,23.67-4.51,4.66-2.94,7.8-7.58,11-12,4.87,3.15,9.69,6.37,14.52,9.59a61.13,61.13,0,0,1-8.17,10.69,47.32,47.32,0,0,1-30.18,13.46c-6.45.28-13.05.38-19.29-1.48a44.65,44.65,0,0,1-24.67-18.35c-10.73-16.64-11.2-39.18-1.68-56.47A42.93,42.93,0,0,1,482.55,110.72Zm2.81,17.91c-6.41,4.08-9.95,11.08-12.73,17.9,17.71.12,35.41.15,53.12,0-2.12-6.38-4.54-13.3-10.35-17.27C506.69,123.31,494.28,122.94,485.36,128.63Z"
      fill="#fff"
    />
    <path
      d="M588,113.24a33.52,33.52,0,0,1,15.73-5.47c4.86-.44,9.68.68,14.38,1.84,0,5.83,0,11.66-.08,17.49-4.28-1.45-8.65-3.25-13.27-2.87-8.82.45-16.53,6.5-20.77,14a39.06,39.06,0,0,0-5,19.67c-.06,15.27.08,30.55,0,45.83q-9.18.08-18.34,0-.1-46.51,0-93c6,0,12-.42,17.92.32a75.17,75.17,0,0,1,.62,10.06A61.14,61.14,0,0,1,588,113.24Z"
      fill="#fff"
    />
  </LogoRoot>
);

export default Logo;
