import React from 'react';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import { Box, Button, Card, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TextField as ManoTextField } from '../../Form/TextField';
import { BaseUserCreateStep } from '../TenantUserCreateWizard';

interface EmailStepProps extends BaseUserCreateStep{
  onNext?: (email: string) => void;
  onBack?: () => void;
}

const EmailStep = (
  {
    onBack,
    onNext,
    userType,
    stepNumber,
    ...other
  }: EmailStepProps,
) => {
  const { t } = useTranslation();

  return (
    <Formik
      initialValues={{
        email: '',
        submit: null,
      }}
      validationSchema={
        Yup
          .object()
          .shape({
            email: Yup
              .string()
              .email()
              .required(),
          })
      }
      onSubmit={async (values, {
        setErrors,
        setStatus,
        setSubmitting,
      }): Promise<void> => {
        try {
          setStatus({ success: true });
          setSubmitting(false);

          if (onNext) {
            onNext(values.email);
          }
        } catch (err) {
          console.error(err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {(
        {
          isSubmitting,
        },
      ): JSX.Element => (
        <Form {...other}>
          <Card sx={{ p: 3 }}>
            <Typography
              color="textPrimary"
              variant="h6"
            >
              {`${stepNumber}. ${t('Email Address')}`}
            </Typography>
            <Typography
              color="textSecondary"
              variant="body1"
            >
              {t('Please enter {{type}} email address', { type: userType })}
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Field
                component={ManoTextField}
                name="email"
                type="email"
                label={t('Email Address')}
                variant="outlined"
                fullWidth
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                mt: 6,
              }}
            >
              {onBack && (
                <Button
                  color="primary"
                  onClick={onBack}
                  size="large"
                  variant="text"
                >
                  {t('Previous')}
                </Button>
              )}
              <Box sx={{ flexGrow: 1 }} />
              <Button
                color="primary"
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                {t('Next')}
              </Button>
            </Box>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default EmailStep;
