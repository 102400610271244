import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import { configureStore as toolkitConfigureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import createRootReducer from './rootReducer';
import * as Sentry from '@sentry/react';
import { isProd } from '../utils/isProd';

const logger = createLogger();

export const history = createBrowserHistory();

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

export function configureStore(preloadedState: {}) {
  return toolkitConfigureStore(
    {
      reducer: createRootReducer(history),
      middleware(getDefaultMiddleware) {
        let defaultMiddleware = getDefaultMiddleware();

        if (!isProd()) {
          defaultMiddleware = defaultMiddleware.concat(logger);
        }

        return defaultMiddleware.concat(routerMiddleware(history));
      },
      devTools: !isProd(),
      preloadedState,
      enhancers: [
        sentryReduxEnhancer,
      ],
    },
  );
}
