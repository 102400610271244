import React from 'react';
import { useRouteMatch } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import MarkAsPaidPage from './Connections/Invoice/MarkAsPaid';
import useAuth from '../hooks/useAuth';
import { DashboardType } from '../redux/slices/User';
import ShowTenantContractInvoicePage from './Connections/Invoice/Show';
import ListTenantContractInvoicesPage from './Connections/Invoice/List';

const ContractInvoices = () => {
  const { url: baseURL } = useRouteMatch();
  const { currentDashboard } = useAuth();

  if (
    currentDashboard.type !== DashboardType.UserTenantAdmin &&
    currentDashboard.type !== DashboardType.TenantAdmin
  ) {
    return <Redirect to="/" />;
  }

  return (
    <Switch>
      <Route exact path={`${baseURL}/:id`}>
        <ShowTenantContractInvoicePage />
      </Route>
      <Route exact path={`${baseURL}/:id/markAsPaid`}>
        <MarkAsPaidPage />
      </Route>
      <Route path={`${baseURL}`}>
        <ListTenantContractInvoicesPage />
      </Route>
    </Switch>
  );
};

export default ContractInvoices;
