import React from 'react';
import { Redirect } from 'react-router-dom';
import PlusIcon from '../../icons/Plus';
import useAuth from '../../hooks/useAuth';
import Page, { PageProps } from '../../components/Page';
import { list as listUserGroups } from '../../api/endpoints/Api/Tenants/UserGroups';
import ListResourceTable from '../../components/ListResourceTable';
import {
  ListTenantUserGroupActions,
  ListTenantUserGroupColumns,
} from '../../resources/TenantUserGroup';
import { DashboardType } from '../../redux/slices/User';

const ListAffiliateGroupsPage = () => {
  const { currentDashboard } = useAuth();

  if (currentDashboard.type !== DashboardType.UserTenantAdmin) {
    return (<Redirect to="/" />);
  }

  const pageProps: PageProps = {
    pageHeaderProps: {
      title: 'Affiliate Groups',
      translationParams: {},
      actions: [
        {
          type: 'route_link',
          to: () => '/affiliate/groups/create',
          label: 'Add new',
          icon: <PlusIcon fontSize="small" />,
          variant: 'contained',
          color: 'primary',
        },
      ],
      breadCrumbs: {
        items: [
          {
            label: 'Dashboard',
            to: '/dashboard',
          },
          {
            label: 'Affiliate Groups',
            to: '/affiliate/groups',
          },
        ],
      },
    },
    title: 'Affiliate Groups',
  };

  return (
    <Page {...pageProps}>
      <ListResourceTable
        endpoint={listUserGroups} field="tenant_user_groups"
        routeParams={{ tenantId: currentDashboard.id }}
        params={{}} actions={ListTenantUserGroupActions('affiliate', '/affiliate/groups')}
        columns={ListTenantUserGroupColumns('/affiliate/groups')}
      />
    </Page>
  );
};

export default ListAffiliateGroupsPage;
