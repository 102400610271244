import {
  PopoverMenuDivider,
  PopoverMenuItems,
  PopoverMenuItemType,
  SimplePopoverMenuItem,
} from '../components/PopoverMenu';
import { Typography } from '@material-ui/core';
import useAuth from '../hooks/useAuth';
import UserIcon from '../icons/User';
import { useHistory } from 'react-router';
import { chooseDashboard, logout } from '../redux/slices/User';
import { LowPriority } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { store } from '../redux';

export const AccountMenu: PopoverMenuItems[] = [
  {
    type: PopoverMenuItemType.IRouterLink,
    padding: 2,
    to: '/user/account',
    renderItemText: () => {
      const { user } = useAuth();

      return (
        <Typography
          color="textPrimary"
          variant="subtitle1"
        >
          {user.full_name}
        </Typography>
      );
    },
  },
  PopoverMenuDivider,
  {
    type: PopoverMenuItemType.IRouterLink,
    to: '/user/account',
    label: 'Account',
    margin: 2,
    icon: <UserIcon fontSize="small" />,
  },
  // {
  //   type: PopoverMenuItemType.IRouterLink,
  //   to: '/user/settings',
  //   label: 'Settings',
  //   margin: 2,
  //   icon: <CogIcon fontSize="small" color="error" />,
  // },
  PopoverMenuDivider,
  {
    type: PopoverMenuItemType.IItems,
    items: () => {
      const { dashboards } = useAuth();
      const history = useHistory();
      const dispatch = useDispatch();

      if (dashboards.length > 0) {
        return [
          {
            type: PopoverMenuItemType.ISimple,
            label: 'Change Dashboard',
            icon: <LowPriority fontSize="small" />,
            margin: 2,
            onClick: () => {
              history.push('/');
              dispatch(chooseDashboard(null));
            },
          } as SimplePopoverMenuItem,
        ];
      }

      return [];
    },
  },
  PopoverMenuDivider,
  {
    type: PopoverMenuItemType.IButton,
    label: 'Logout',
    color: 'primary',
    variant: 'outlined',
    padding: 2,
    onClick: () => {
      store.dispatch(logout());
    },
  },
];
