import React from 'react';
import { Redirect } from 'react-router-dom';
import TenantUserCreateWizard from '../../components/TenantUser/TenantUserCreateWizard';
import Page, { PageProps } from '../../components/Page';
import ArrowLeft from '../../icons/ArrowLeft';
import useAuth from '../../hooks/useAuth';
import { DashboardType } from '../../redux/slices/User';
import { useTranslation } from 'react-i18next';

const AffiliateCreate = () => {
  const { currentDashboard } = useAuth();
  const { t } = useTranslation();

  if (currentDashboard.type !== DashboardType.UserTenantAdmin) {
    return (<Redirect to="/" />);
  }

  const roles = ['ROLE_PARTICIPANT'];

  const pageProps: PageProps = {
    pageHeaderProps: {
      title: 'Create a new affiliate wizard',
      translationParams: {},
      actions: [
        {
          type: 'route_link',
          to: () => '/affiliates',
          label: 'Cancel',
          icon: <ArrowLeft fontSize="small" />,
          variant: 'contained',
          color: 'secondary',
        },
      ],
      breadCrumbs: {
        items: [
          {
            label: 'Dashboard',
            to: '/dashboard',
          },
          {
            label: 'Affiliates',
            to: '/affiliates',
          },
        ],
      },
    },
    compactWidth: 'md',
    title: 'Create a new affiliate wizard',
  };

  return (
    <Page {...pageProps}>
      <TenantUserCreateWizard onlyRoles={roles} userType={t('affiliate')} successUrl="/affiliates" />
    </Page>
  );
};

export default AffiliateCreate;
