import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Button, Container, Typography } from '@material-ui/core';
import { RouterLink } from '../lib/RouterLink';
import { useTheme } from '@material-ui/system';
import importTranslation from '../hooks/ut';
import { siteConfig } from '../config';

interface ErrorNotFoundPageProps {
  insideDashboard?: boolean,
}

const ErrorNotFoundPage = ({ insideDashboard = false }: ErrorNotFoundPageProps) => {
  const theme = useTheme();
  const { t } = importTranslation();
  return (
    <>
      <Helmet>
        <title>{`${t('Page Not Found')}${siteConfig.titleSuffix}`}</title>
      </Helmet>
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: !insideDashboard ? 'background.paper' : undefined,
          display: 'flex',
          minHeight: '100%',
          px: 3,
          py: '80px',
        }}
      >
        <Container maxWidth="lg">
          <Typography align="center" color="textPrimary" variant="h1">
            {t('404: The page you are looking for isn’t here')}
          </Typography>
          <Typography align="center" color="textSecondary" sx={{ mt: 0.5 }} variant="subtitle2">
            {t('You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.')}
          </Typography>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 6,
          }}
          >
            <Box
              component="img"
              src={`/static/error/error404_${theme.palette.mode}.svg`}
              sx={{
                height: 'auto',
                maxWidth: '100%',
                width: 400,
              }}
            />
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 6,
          }}
          >
            <Button
              color="primary" component={RouterLink} to="/dashboard" variant="contained"
            >
              {t('Back to Home')}
            </Button>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ErrorNotFoundPage;
