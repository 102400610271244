import React from 'react';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import Markdown from '../Markdown';
import importTranslation from '../../hooks/ut';

interface DescriptionProps {
  text: string;
  title?: string;
}

const Description = (props: DescriptionProps) => {
  const { text, title = 'Description' } = props;
  const { t } = importTranslation();

  return (
    <Box sx={{ mt: 2 }}>
      <Card>
        <CardContent>
          <Box>
            <Typography color="textSecondary" sx={{ mb: 2 }} variant="overline">
              {t(title)}
            </Typography>
          </Box>
          <Box>
            <Markdown>{text}</Markdown>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Description;
