import React from 'react';
import Page, { PageProps } from '../../components/Page';
import { Box, Card, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useLoadApiData from '../../hooks/useLoadApiData';
import useAuth from '../../hooks/useAuth';
import DataGrid, { DataGridConfig } from '../../components/DataGrid';
import { ShortCampaignView } from '../../api/types/App/View/Campaign';
import {
  ListActiveCampaignDashboardColumns,
  ListRecentlyFinishedCampaignDashboardColumns,
} from '../../resources/CampaignAnalytics';
import ListCommissionInvoices from '../../components/CommissionInvoice/ListCommissionInvoices';
import { dashboardUserStats } from '../../api/endpoints/Api/Tenants/User/Analytics';
import { formatMoneyValue } from '../../types/Money';

interface OverviewCountProps {
  label: string,
  value: React.ReactNode,
}

const OverviewCount = (props: OverviewCountProps) => {
  const { t } = useTranslation();

  return (
    <Grid
      item
      xs={6}
      sm={4}
      md={2}
      sx={{
        alignItems: 'center',
        borderRight: (theme) => (
          {
            xs: `1px solid ${theme.palette.divider}`,
          }
        ),
        borderBottom: (theme) => (
          {
            md: 'none',
            xs: `1px solid ${theme.palette.divider}`,
          }
        ),
        display: 'flex',
        justifyContent: 'space-between',
        p: 3,
        '&:last-of-type': {
          borderRight: 0,
        },
      }}
    >
      <Box
        sx={{
          height: 54,
        }}
      >
        <Typography
          color="textSecondary"
          variant="overline"
        >
          {t(props.label)}
        </Typography>
        <Typography
          color="textPrimary"
          variant="h5"
        >
          {props.value}
        </Typography>
      </Box>
    </Grid>
  );
};

const TenantUserDashboardPage = () => {
  const { currentDashboard } = useAuth();
  const { t } = useTranslation();

  const { apiData } = useLoadApiData(dashboardUserStats)({ tenantId: currentDashboard.id });

  if (apiData === null) {
    return null;
  }

  const pageProps: PageProps = {
    title: 'Dashboard',
    compactWidth: 'lg',
  };

  const activeCampaignDataGridConfig: DataGridConfig<ShortCampaignView, string> = {
    title: t('Active Participated Campaigns'),
    columns: ListActiveCampaignDashboardColumns,
    rows: apiData.active_participating_campaigns,
    rowProps: {
      sx: {
        '&:last-child td': {
          border: 0,
        },
      },
    },
  };

  const recentlyFinishedCampaignDataGridConfig: DataGridConfig<ShortCampaignView, string> = {
    title: t('Recently Finished Participated Campaigns'),
    columns: ListRecentlyFinishedCampaignDashboardColumns,
    rows: apiData.recently_finished_campaign,
    rowProps: {
      sx: {
        '&:last-child td': {
          border: 0,
        },
      },
    },
  };

  return (
    <Page {...pageProps}>
      <Box sx={{ mt: 3 }}>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            xs={12}
          >
            <Card>
              <Grid container>
                <OverviewCount label="Participated Campaigns" value={apiData.active_participating_campaigns.length} />
                <OverviewCount label="Active Campaigns" value={apiData.active_campaigns} />
                <OverviewCount
                  label="Pending Invoices"
                  value={apiData.pending_commission_invoices.length}
                />
                <OverviewCount label="Pending Commissions Amount" value={formatMoneyValue(apiData.total_unapproved_commissions)} />
                <OverviewCount label="Unpaid Commissions Amount" value={formatMoneyValue(apiData.total_unpaid_invoices)} />
                <OverviewCount label="Active Campaigns Amount" value={formatMoneyValue(apiData.total_conversions_from_active_campaigns)} />
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Grid
          container
          spacing={3}
        >
          <Grid item xs={12}>
            <DataGrid>
              {activeCampaignDataGridConfig}
            </DataGrid>
          </Grid>
          <Grid item xs={12}>
            <DataGrid>
              {recentlyFinishedCampaignDataGridConfig}
            </DataGrid>
          </Grid>
          <Grid item xs={12}>
            <ListCommissionInvoices
              type="tenant_user_commissions" tenantUser={currentDashboard.tenantUser}
              title={t('Pending Commission Invoices')}
              initialTab="not_paid" hideTabs hideFilters
            />
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default TenantUserDashboardPage;
